import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import MarkdownView from "react-showdown";
import { useSelector } from "../../../hooks";
import { User } from "../../../models/user";

const UnlockableContentModal = ({ show, handleClose, nft }: any) => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.auth);

  return (
    <Modal
      className="custom modal-bg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="">Unlockable Content</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {user?.id !== nft?.owner?.id && (
          <div className="d-flex flex-column align-items-center justify-content-center mt-2 mb-3">
            <i
              style={{ fontSize: "100px" }}
              className="fa text-purple fa-eye-slash fa-lg mb-4"
            ></i>
            <h3 className="text-center">
              You must own this NFT to view the unlockable content
            </h3>
          </div>
        )}

        {user?.id && nft.owners?.some((x: User) => x.id == user.id) && (
          <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <p className="f-600 mb-4">Here is your extra content</p>
            <MarkdownView
              style={{ width: "100%" }}
              markdown={nft.unlockableContent}
              options={{ tables: true, emoji: true }}
            />
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UnlockableContentModal;
