import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import { blockChainData } from "../../../api/modules/nft";
import { PLATFORM_FEE_PERCENTAGE } from "../../../constants";
import { useActions, useConversion, useSelector } from "../../../hooks";
import { OrderType } from "../../../models/order";
import { validateCurrencyValue } from "../../../utils/validation";

import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import FeeInfo from "../ToolTips/FeeInfo";
import { getNftSmallUrl } from "../../../models/nft";
const CreateListing = ({
  show,
  handleClose,
  nft,
  editing,
  handleFinish,
}: any) => {
  const [listingType, setListingType] = useState(OrderType.SALE);
  const [listPrice, setListPrice] = useState(0);
  const [endingPrice, setEndingPrice] = useState(0);
  const [quantity, setQuantity] = useState("1");
  const { listing } = useSelector((state) => state.listing);
  const { user } = useSelector((state) => state.auth);
  const [auctionType, setAuctionType] = useState("English");
  const { saveListing, fetchNftMetaData } = useActions();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    moment().add(1, "week").add(30, "minute").toDate()
  );
  const [priceError, setPriceError] = useState("");
  const [endingPriceError, setEndingPriceError] = useState("");
  const { priceUSD } = useConversion(
    Number(listPrice),
    blockChainData[nft.blockchain].symbol
  );
  const { priceUSD: endingPriceUSD } = useConversion(
    Number(endingPrice),
    blockChainData[nft.blockchain].symbol
  );
  const displayPrice = Number(
    listPrice * (1 - (PLATFORM_FEE_PERCENTAGE + nft?.royaltyPercentage) / 100)
  ).toFixed(5);

  const { priceUSD: finalPriceUSD } = useConversion(
    displayPrice,
    blockChainData[nft.blockchain].symbol
  );

  const saleTypes = [
    { text: "Fixed Price", value: OrderType.SALE },
    { text: "Auction", value: OrderType.AUCTION },
  ];

  const availableNFTsForSale: number = (() => {
    const totalListed = nft.listings
      ?.filter((listing: any) => listing.seller?.id === user?.id)
      .reduce((acc: number, listing: any) => acc + listing.quantity, 0);

    return nft.amountIOwn - (totalListed || 0);
  })();

  const handleSelected = (item: any) => setListingType(item);

  useEffect(() => {
    if (editing && listing) {
      setListPrice(listing?.listPrice);
      setQuantity(listing?.quantity);
      setStartDate(listing?.startDate);
      setEndDate(listing?.endDate);
      setAuctionType(listing?.auctionType);
    }
  }, [editing, JSON.stringify(listing)]);

  useEffect(() => {
    if (nft) {
      setListPrice(nft?.price);
    }
  }, [nft]);

  const handleSetPrice = async (newValue: string) => {
    toast.dismiss();
    setPriceError("");

    const errorMessage = validateCurrencyValue(newValue);
    if (errorMessage) {
      setPriceError(errorMessage);
      return;
    }
    // Check if the input has more than 4 decimal points.
    const decimalParts = newValue.split(".");
    if (decimalParts[1] && decimalParts[1].length > 4) {
      setPriceError("Value cannot have more than 4 decimal points!");
      return;
    }

    if (parseFloat(newValue) < endingPrice) {
      setPriceError("Ending price should be less than starting price!");
      return;
    }

    setListPrice(parseFloat(newValue));
  };

  const handleSetEndingPrice = async (newValue: string) => {
    toast.dismiss();
    let errorMessage = validateCurrencyValue(newValue);
    if (parseFloat(newValue) > listPrice) {
      errorMessage = "Ending price should be less than starting price";
    }
    if (errorMessage) {
      setEndingPriceError(errorMessage);
    } else {
      setEndingPriceError("");
      setEndingPrice(parseFloat(newValue));
    }
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newQuantity = Number(e.target.value);
    // Ensure newQuantity is at least 1 and not more than maxQuantity
    newQuantity = Math.max(1, Math.min(availableNFTsForSale, newQuantity));
    setQuantity(newQuantity.toString());
  };

  return (
    <Modal className="custom modal-bg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="">
          {listingType === OrderType.SALE ? "Sell" : "Auction"} This NFTs
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mt-2 sell-auction-body mb-3">
          <div className="modal-nft-img w-auto me-4">
            <img
              src={getNftSmallUrl(nft?.nftMetaData?.thumbnail_image)}
              className="sell-this-nft"
              alt=""
            />
          </div>
          <div className="min-265">
            <h4 className="mb-1 f-28">{nft?.name}</h4>
            <p className="text-gray f-14">by {nft?.creator?.username}</p>
            {nft?.supply > 1 && (
              <div className="d-flex align-items-center">
                <p className="text-gray f-14 mb-0 me-1">Qty:</p>
                <input
                  value={quantity}
                  min={1}
                  max={availableNFTsForSale}
                  type="number"
                  className="w-30"
                  onChange={handleQuantityChange}
                />
                <span className="ms-1 text-muted">
                  of {availableNFTsForSale} left to sell
                </span>
              </div>
            )}
            {false && nft?.supply == 1 && (
              <div className="custom-radios">
                <div>
                  <label
                    onClick={() => handleSelected(OrderType.SALE)}
                    className={listingType === OrderType.SALE ? "selected" : ""}
                  >
                    <input
                      type="radio"
                      name="one"
                      checked={listingType === OrderType.SALE}
                      onChange={() => setListingType(OrderType.SALE)}
                    />
                    <span>Fixed Price</span>
                  </label>
                </div>
                <div>
                  <label
                    onClick={() => handleSelected(OrderType.AUCTION)}
                    className={
                      listingType === OrderType.AUCTION ||
                      listingType === OrderType.DUTCH_AUCTION
                        ? "selected"
                        : ""
                    }
                  >
                    <input
                      type="radio"
                      name="one"
                      checked={
                        listingType === OrderType.AUCTION ||
                        listingType === OrderType.DUTCH_AUCTION
                      }
                      onChange={() => setListingType(OrderType.AUCTION)}
                    />
                    <span>Auction</span>
                  </label>
                </div>
              </div>
            )}
            {/* {(listingType === OrderType.AUCTION ||
              listingType === OrderType.DUTCH_AUCTION) && (
              <div className="mt-2">
                <div className="title-border">
                  <h4>Choose type of auction:</h4>
                </div>
                <div className="auction-type">
                  <div className="d-flex align-items-center mb-2">
                    <input
                      type="radio"
                      name="auction-type"
                      value="English"
                      checked={listingType === OrderType.AUCTION}
                      onChange={() => setListingType(OrderType.AUCTION)}
                    />
                    <label className="ms-1 f-12">
                      Sell to Highest Bidder
                      <span className="f-10">
                        - when auction ends, the highest bidder wins!
                      </span>
                    </label>
                  </div>

                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="auction-type"
                      value="Dutch"
                      checked={listingType === OrderType.DUTCH_AUCTION}
                      onChange={() => setListingType(OrderType.DUTCH_AUCTION)}
                    />
                    <label className="ms-1 f-12">
                      Sell with Declining Price
                      <span className="f-10">
                        - if no bids, price automatically declines!
                      </span>
                    </label>
                  </div>
                </div>
              </div>
            )} */}
            <div className="mt-2">
              <label htmlFor="">Starting Price</label>
              <div className="price-amount">
                <div className="position-relative me-2">
                  <img
                    style={{
                      width: "auto",
                      height: "25px",
                      marginRight: "8px",
                    }}
                    src={blockChainData[nft?.blockchain]?.logo}
                    alt="eth"
                  />
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    <input
                      type="string"
                      className="outer-input"
                      placeholder="Listing Price"
                      defaultValue={listPrice}
                      onChange={(e: any) => handleSetPrice(e.target.value)}
                    />
                    <small className="text-gray">{"$" + priceUSD}</small>
                  </div>
                </div>
              </div>
              {priceError && (
                <div>
                  <span className="text-danger">{priceError}</span>
                </div>
              )}
            </div>

            {listingType === OrderType.DUTCH_AUCTION && (
              <div className="mt-2">
                <label htmlFor="">Ending Price</label>
                <div className="price-amount">
                  <div className="position-relative me-2">
                    <img
                      style={{
                        width: "auto",
                        height: "25px",
                        marginRight: "8px",
                      }}
                      src={blockChainData[nft?.blockchain]?.logo}
                      alt="eth"
                    />
                  </div>
                  <div>
                    <div className="d-flex align-items-center">
                      <input
                        type="string"
                        className="outer-input"
                        placeholder="Ending Price"
                        defaultValue={endingPrice}
                        onChange={(e: any) =>
                          handleSetEndingPrice(e.target.value)
                        }
                      />
                      <small className="text-gray">
                        {"$" + endingPriceUSD}
                      </small>
                    </div>
                  </div>
                </div>
                {endingPriceError && (
                  <div>
                    <span className="text-danger">{endingPriceError}</span>
                  </div>
                )}
              </div>
            )}
            <div className="d-flex align-items-center mt-3 justify-content-between">
              <div className="d-flex flex-column">
                <label htmlFor="">Start date</label>
                <DatePicker
                  minDate={new Date()}
                  showTimeSelect
                  className="f-10"
                  selected={startDate}
                  onChange={(date: any) => {
                    if (endDate && date > endDate) {
                      const newEndDate = moment(date).add(1, "week");
                      setEndDate(newEndDate.toDate());
                    }
                    setStartDate(date);
                  }}
                  dateFormat="MM/dd/yy h:mm aa"
                />
              </div>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between">
                  <label htmlFor="">End date</label>
                </div>
                <DatePicker
                  minDate={startDate}
                  className="f-10"
                  showTimeSelect
                  selected={endDate}
                  onChange={(date: any) => {
                    if (date > startDate) {
                      setEndDate(date);
                    } else {
                      toast.warn("End date should be greater than Start date", {
                        autoClose: 1000,
                      });
                    }
                  }}
                  dateFormat="MM/dd/yy h:mm aa"
                />
              </div>
            </div>
            <div className="mt-3 custom-fee">
              <div className="row mb-2">
                <div className="col-3 d-flex align-items-center">
                  <span className="f-14 fw-600">
                    Fees
                    <span className="text-purple f-10 ms-1">
                      <FeeInfo>
                        <i className="fa-solid fa-circle-exclamation"></i>
                      </FeeInfo>
                    </span>
                  </span>
                </div>
                <div className="col-9">
                  <div className="d-flex align-items-center justify-content-between ">
                    <div>
                      <span className="text-gray f-12">
                        Service fee {PLATFORM_FEE_PERCENTAGE}%
                      </span>
                    </div>
                    <div>
                      <span className="text-gray f-12">
                        Creator Royalty {nft?.royaltyPercentage}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3 d-flex align-items-center">
                  <span className="f-14 fw-600">You Get</span>
                </div>
                <div className="col-9">
                  <span className="text-gray f-12">
                    {`${displayPrice} ${blockChainData[nft.blockchain].symbol}`}
                  </span>
                  <span className="text-gray f-12 ms-2">({finalPriceUSD})</span>
                </div>
              </div>
            </div>

            <button
              className="btn-main mt-3 w-100"
              disabled={!!priceError || !!endingPriceError}
              onClick={() =>
                saveListing(
                  {
                    listPrice,
                    endPrice:
                      listingType === OrderType.DUTCH_AUCTION
                        ? endingPrice
                        : listPrice,
                    priceUSD,
                    quantity,
                    startDate,
                    endDate,
                    listingType,
                  },
                  async () => {
                    // await fetchNftMetaData(nft.id);
                    handleFinish(listingType);
                  }
                )
              }
            >
              {listingType === OrderType.SALE
                ? "LIST THIS NFT FOR SALE"
                : "START NFT AUCTION"}
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateListing;
