import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useAccount } from "wagmi";
import { useSelector } from "../../hooks";
import FeaturedCollections from "./FeaturedCollections";
import HeaderSliderItem from "./HeaderSliderItem";
const Jumbotron = ({
  collections: featuredCollections,
  setShowConnectWallet,
}: any) => {
  const fadeInUp = keyframes`
    0% {
      opacity: 0;
      -webkit-transform: translateY(40px);
      transform: translateY(40px);
    }
    100% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    `;
  const navigate = useNavigate();
  const { isConnected } = useAccount();
  const { featuredNfts } = useSelector((state) => state.nfts);
  const PrevArrow = (props: any) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} custom-arrow`} onClick={onClick}>
        <span>
          <i className="fa fa-arrow-left"></i>
        </span>
      </div>
    );
  };
  const NextArrow = (props: any) => {
    const { className, onClick } = props;
    return (
      <div className={`${className} custom-arrow`} onClick={onClick}>
        <span>
          <i className="fa fa-arrow-right"></i>
        </span>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  return (
    <div className="container">
      <div className="row zen_home">
        <div className="col-lg-6 pe-4 d-flex flex-column">
          <div className="top-text">
            <div className="spacer-single"></div>
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={0}
              duration={600}
              triggerOnce
            >
              <h6 className="header">
                <span className="text-uppercase f-18">ZingIt Market</span>
              </h6>
            </Reveal>
            <div className="spacer-5"></div>
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={300}
              duration={600}
              triggerOnce
            >
              <h1 className="f-54">Buy, Sell, or Create NFT’s Easily</h1>
            </Reveal>
            <div className="spacer-10"></div>
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={800}
              duration={900}
              triggerOnce
            >
              <div className="buttons">
                <button
                  onClick={() => navigate("/explore")}
                  className="btn-main explore lead"
                >
                  Explore
                </button>
                <button
                  onClick={
                    isConnected
                      ? () => navigate("/create-nft")
                      : () => setShowConnectWallet(true)
                  }
                  className="btn-secondary lead"
                >
                  Create
                </button>
              </div>
              <div className="mb-sm-30"></div>
            </Reveal>
          </div>

          <FeaturedCollections collections={featuredCollections} />
        </div>
        <div className="col-lg-6 zen_right">
          <Slider {...settings}>
            {featuredNfts?.nfts?.map((nft, id) => (
              <HeaderSliderItem nft={nft} key={id} />
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};
export default Jumbotron;
