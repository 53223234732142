import { NFT } from "../../models/nft";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface NftState {
  loading: boolean;
  error: string | null;
  nfts: {
    total: number;
    nfts: NFT[] | null;
    append?: boolean;
    loading?: boolean;
  };
  nft: NFT | null;
  featuredNfts: {
    total: number;
    nfts: NFT[] | null;
    loading: boolean;
  };
  topNfts: {
    total: number;
    nfts: NFT[] | null;
    loading: boolean;
  };
  newNfts: {
    total: number;
    nfts: NFT[] | null;
    loading: boolean;
  };
  likedNfts: {
    total: number;
    nfts: NFT[] | null;
    loading: boolean;
  };
  purchasedNfts: {
    total: number;
    nfts: NFT[] | null;
    loading: boolean;
  };
  createdNfts: {
    total: number;
    nfts: NFT[] | null;
    loading: boolean;
  };
  availableNfts: {
    total: number;
    nfts: NFT[] | null;
    loading: boolean;
  };
  featuredGreen: {
    total: number;
    nfts: NFT[] | null;
    loading: boolean;
  };
  stats: {
    pageViews: number;
  };
}

const initialState = {
  loading: false,
  error: null,
  nfts: {
    total: 0,
    nfts: [],
    append: false,
    loading: false,
  },
  nft: null,
  featuredNfts: {
    total: 0,
    nfts: [],
    loading: false,
  },
  topNfts: {
    total: 0,
    nfts: [],
    loading: false,
  },
  newNfts: {
    total: 0,
    nfts: [],
    loading: false,
  },
  likedNfts: {
    total: 0,
    nfts: [],
    loading: false,
  },
  createdNfts: {
    total: 0,
    nfts: [],
    loading: false,
  },
  purchasedNfts: {
    total: 0,
    nfts: [],
    loading: false,
  },
  availableNfts: {
    total: 0,
    nfts: [],
    loading: false,
  },
  featuredGreen: {
    total: 0,
    nfts: [],
    loading: false,
  },
  stats: {
    pageViews: 0,
  },
};

const reducer = (state: NftState = initialState, action: Action): NftState => {
  switch (action.type) {
    case ActionType.FETCH_NFT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.FETCH_NFT_SUCCESS:
      let result = syncItem(action.payload, state);
      return {
        ...result,
        nft: action.payload,
        loading: false,
      };
    case ActionType.FETCH_NFT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.UPDATE_PRICE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.UPDATE_PRICE_SUCCESS:
      let updatePriceResult = syncItem(action.payload, state);
      return {
        ...updatePriceResult,
        nft: action.payload,
      };
    case ActionType.UPDATE_PRICE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.FETCH_NFTS:
      return {
        ...state,
        error: null,
        nfts: {
          ...state.nfts,
          loading: true,
        },
      };
    case ActionType.FETCH_NFTS_SUCCESS:
      if (action.payload.append) {
        return {
          ...state,
          error: null,
          nfts: {
            nfts: [...state.nfts.nfts!, ...action.payload.nfts],
            total: action.payload.total,
            loading: false,
          },
        };
      } else
        return {
          ...state,
          error: null,
          nfts: { ...action.payload, loading: false },
        };
    case ActionType.FETCH_NFTS_ERROR:
      return {
        ...state,
        error: action.payload,
        nfts: {
          ...state.nfts,
          loading: true,
        },
      };
    case ActionType.CREATE_NFT:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.CREATE_NFT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        nft: action.payload,
      };
    case ActionType.CREATE_NFT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.FETCH_PURCHASED_NFTS:
      return {
        ...state,
        purchasedNfts: { ...state.purchasedNfts, loading: true },
        error: null,
      };
    case ActionType.FETCH_PURCHASED_NFTS_SUCCESS:
      return {
        ...state,
        error: null,
        purchasedNfts: { ...action.payload, loading: false },
      };
    case ActionType.FETCH_PURCHASED_NFTS_ERROR:
      return {
        ...state,
        purchasedNfts: { ...state.purchasedNfts, loading: false },
        error: action.payload,
      };
    case ActionType.FETCH_AUTHOR_AVAILABLE_NFTS:
      return {
        ...state,
        availableNfts: { ...state.availableNfts, loading: true },
        error: null,
      };
    case ActionType.FETCH_AUTHOR_AVAILABLE_NFTS_SUCCESS:
      return {
        ...state,
        error: null,
        availableNfts: { ...action.payload, loading: false },
      };
    case ActionType.FETCH_AUTHOR_AVAILABLE_NFTS_ERROR:
      return {
        ...state,
        availableNfts: { ...state.availableNfts, loading: false },
        error: action.payload,
      };
    case ActionType.FETCH_FEATURED_NFTS:
      return {
        ...state,
        featuredNfts: { ...state.featuredNfts, loading: true },
        error: null,
      };
    case ActionType.FETCH_FEATURED_NFTS_SUCCESS:
      return {
        ...state,
        error: null,
        featuredNfts: { ...action.payload, loading: false },
      };
    case ActionType.FETCH_FEATURED_NFTS_ERROR:
      return {
        ...state,
        featuredNfts: { ...state.featuredNfts, loading: false },
        error: action.payload,
      };
    case ActionType.FETCH_LIKED_NFTS:
      return {
        ...state,
        likedNfts: { ...state.likedNfts, loading: true },
        error: null,
      };
    case ActionType.FETCH_LIKED_NFTS_SUCCESS:
      return {
        ...state,
        error: null,
        likedNfts: { ...action.payload, loading: false },
      };
    case ActionType.FETCH_LIKED_NFTS_ERROR:
      return {
        ...state,
        likedNfts: { ...state.likedNfts, loading: false },
        error: action.payload,
      };
    case ActionType.FETCH_CREATED_NFTS:
      return {
        ...state,
        createdNfts: { ...state.createdNfts, loading: true },
        error: null,
      };
    case ActionType.FETCH_CREATED_NFTS_SUCCESSS:
      return {
        ...state,
        error: null,
        createdNfts: { ...action.payload, loading: false },
      };
    case ActionType.FETCH_CREATED_NFTS_ERROR:
      return {
        ...state,
        createdNfts: { ...state.createdNfts, loading: false },
        error: action.payload,
      };
    case ActionType.FETCH_NEW_NFTS:
      return {
        ...state,
        newNfts: { ...state.newNfts, loading: true },
        error: null,
      };
    case ActionType.FETCH_NEW_NFTS_SUCCESS:
      return {
        ...state,
        newNfts: { ...action.payload, loading: false },
        error: null,
      };
    case ActionType.FETCH_NEW_NFTS_ERROR:
      return {
        ...state,
        newNfts: { ...state.newNfts, loading: false },
        error: action.payload,
      };
    case ActionType.FETCH_TOP_NFTS:
      return {
        ...state,
        topNfts: { ...state.topNfts, loading: true },
        error: null,
      };
    case ActionType.FETCH_TOP_NFTS_SUCCESS:
      return {
        ...state,
        error: null,
        topNfts: { ...action.payload, loading: false },
      };
    case ActionType.FETCH_TOP_NFTS_ERROR:
      return {
        ...state,
        topNfts: { ...state.topNfts, loading: false },
        error: action.payload,
      };
    case ActionType.FETCH_FEATURED_GREEN_NFTS:
      return {
        ...state,
        featuredGreen: { ...state.featuredGreen, loading: true },
        error: null,
      };
    case ActionType.FETCH_FEATURED_GREEN_NFTS_SUCCESS:
      return {
        ...state,
        error: null,
        featuredGreen: { ...action.payload, loading: false },
      };
    case ActionType.FETCH_FEATURED_GREEN_NFTS_ERROR:
      return {
        ...state,
        featuredGreen: { ...state.featuredGreen, loading: false },
        error: action.payload,
      };
    case ActionType.TOGGLE_LIKE_NFT:
      return {
        ...state,
        error: null,
      };
    case ActionType.TOGGLE_LIKE_NFT_SUCCESS:
      let likeResult = syncItem(action.payload.nft, state);
      return {
        ...likeResult,
        loading: false,
      };
    case ActionType.TOGGLE_LIKE_NFT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.FREEZE_METADATA:
      return {
        ...state,
        error: null,
      };
    case ActionType.FREEZE_METADATA_SUCCESS:
      let res = syncItem(action.payload.nft, state);
      return {
        ...res,
      };
    case ActionType.FREEZE_METADATA_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.CLEAR_NFTS:
      return {
        ...state,
        nft: null,
        nfts: {
          total: 0,
          nfts: [],
        },
        featuredNfts: {
          total: 0,
          nfts: [],
          loading: false,
        },
        topNfts: {
          total: 0,
          nfts: [],
          loading: false,
        },
        newNfts: {
          total: 0,
          nfts: [],
          loading: false,
        },
        likedNfts: {
          total: 0,
          nfts: [],
          loading: false,
        },
        createdNfts: {
          total: 0,
          nfts: [],
          loading: false,
        },
        availableNfts: {
          total: 0,
          nfts: [],
          loading: false,
        },
        purchasedNfts: {
          total: 0,
          nfts: [],
          loading: false,
        },
        loading: false,
      };
    case ActionType.FETCH_NFT_PAGE_VIEWS_SUCCESS:
      return {
        ...state,
        stats: { ...state.stats, pageViews: action.payload },
      };
    default:
      return state;
  }
};

const syncItem = (updatedNft: any, state: NftState) => {
  state.nfts.nfts!.forEach((element: any, index: number) => {
    if (element.id === updatedNft.id) {
      state.nfts.nfts![index] = updatedNft;
    }
  });
  state.featuredNfts.nfts!.forEach((element: any, index: number) => {
    if (element.id === updatedNft.id) {
      state.featuredNfts.nfts![index] = updatedNft;
    }
  });
  state.likedNfts.nfts!.forEach((element: any, index: number) => {
    if (element.id === updatedNft.id) {
      if (!updatedNft.isLiked) delete state.likedNfts.nfts![index];
    }
  });
  state.topNfts.nfts!.forEach((element: any, index: number) => {
    if (element.id === updatedNft.id) {
      state.topNfts.nfts![index] = updatedNft;
    }
  });
  state.newNfts.nfts!.forEach((element: any, index: number) => {
    if (element.id === updatedNft.id) {
      state.newNfts.nfts![index] = updatedNft;
    }
  });
  state.createdNfts.nfts!.forEach((element: any, index: number) => {
    if (element.id === updatedNft.id) {
      state.createdNfts.nfts![index] = updatedNft;
    }
  });
  state.purchasedNfts.nfts!.forEach((element: any, index: number) => {
    if (element.id === updatedNft.id) {
      state.purchasedNfts.nfts![index] = updatedNft;
    }
  });

  state.availableNfts.nfts!.forEach((element: any, index: number) => {
    if (element.id === updatedNft.id) {
      state.availableNfts.nfts![index] = updatedNft;
    }
  });
  if (state.nft && state.nft.id === updatedNft.id) state.nft = updatedNft;
  return {
    ...state,
    loading: false,
    error: null,
  };
};
export default reducer;
