import { Collection } from "../../models/collection";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface CollectionState {
  loading: boolean;
  error: string | null;
  collections: any[];
  myCollections: any[];
  collection: Collection | null;
  featuredCollections: {
    total: number;
    collections: Collection[] | null;
  };
  topCollections: {
    total: number;
    collections: Collection[] | null;
  };
  likedCollections: {
    total: number;
    collections: Collection[] | null;
  };
}

const initialState = {
  loading: false,
  error: null,
  collections: [],
  myCollections: [],
  collection: null,
  featuredCollections: {
    total: 0,
    collections: [],
  },
  likedCollections: {
    total: 0,
    collections: [],
  },
  topCollections: {
    total: 0,
    collections: [],
  },
};

const reducer = (
  state: CollectionState = initialState,
  action: Action
): CollectionState => {
  switch (action.type) {
    case ActionType.CREATE_COLLECTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.CREATE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collections: action.payload,
      };
    case ActionType.CREATE_COLLECTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        collections: [],
      };
    case ActionType.UPDATE_COLLECTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.UPDATE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        // collections: action.payload,
      };
    case ActionType.UPDATE_COLLECTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        // collections: [],
      };
    case ActionType.FETCH_COLLECTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.FETCH_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collections: action.payload,
      };
    case ActionType.FETCH_COLLECTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        collections: [],
      };
    case ActionType.FETCH_SINGLE_COLLECTION:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.FETCH_SINGLE_COLLECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collection: action.payload.collection,
      };
    case ActionType.FETCH_SINGLE_COLLECTION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        collection: null,
      };
    case ActionType.FETCH_MY_COLLECTIONS:
      return {
        ...state,
        loading: true,
        error: null,
        collections: [],
        myCollections: [],
      };
    case ActionType.FETCH_MY_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collections: [],
        myCollections: action.payload,
      };
    case ActionType.FETCH_MY_COLLECTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        collections: [],
        myCollections: [],
      };
    case ActionType.FETCH_FEATURED_COLLECTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.FETCH_FEATURED_COLLECTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        collections: [],
        featuredCollections: action.payload,
      };
    case ActionType.FETCH_FEATURED_COLLECTIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        collections: [],
      };
    default:
      return state;
  }
};

export default reducer;
