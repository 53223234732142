import React from "react";
import { ErrorBoundary } from "react-error-boundary";

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

const MyErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const ErrorFallback = ({
    error,
    resetErrorBoundary,
  }: {
    error: Error;
    resetErrorBoundary: () => void;
  }) => {
    return (
      <div>
        <h1>An error occurred: {error.message}</h1>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app here
      }}
      onError={(error: any, info: any) => {
        // log the error to the console
        console.error("Caught by error boundary: ", error, info);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

export default MyErrorBoundary;
