import { ItemType } from "@opensea/seaport-js/lib/constants";
import { Collection } from "./collection";
import { Event } from "./event";
import { Order } from "./order";
import { User } from "./user";

export const getNftSmallUrl = (url: string) => {
  const baseUrl = "https://assets.zing.it/";
  let strippedUrl = url.replace(baseUrl, "");
  const isGif = strippedUrl.toLowerCase().endsWith(".gif");
  strippedUrl = strippedUrl.replace(
    "https://zenft-bucket.s3.amazonaws.com/",
    ""
  );
  strippedUrl = strippedUrl.replace(
    "https://zenft-bucket.s3.us-east-1.amazonaws.com/",
    ""
  );
  const imageRequest = {
    bucket: "zenft-bucket",
    key: strippedUrl,
    edits: {
      resize: {
        width: 300,
        fit: "cover",
      },
    },
  };

  const encodedObject = btoa(JSON.stringify(imageRequest));
  const displayURL = `https://d26z45c7morr93.cloudfront.net/${encodedObject}`;

  return isGif ? url : displayURL;
};
export interface NftSearchParams {
  status?: boolean;
  showExplicit?: boolean;
  search?: string | null | undefined;
  page?: number;
  page_size?: number;
}
export enum NftType {
  ZingItArt721 = "ZingItArt721",
  ZingItTradable1155 = "ZingItTradable1155",
}

export const GREEN_CHAINS = ["polygonMumbai", "polygon"];

export enum NftStatus {
  Created = "created",
  Pending = "pending",
  Minted = "minted",
  Burned = "burned",
  Purchasing = "purchasing",
}
export interface IAttribute {
  trait_type: string;
  display_type: string;
  value: string;
}
export interface INftMetaData {
  name?: string;
  description?: string;
  royaltyPercentage: number;
  thumbnail_image?: string;
  animation_url?: string;
  image_data?: string;
  image?: string;
  file_type?: string;
  creation_date?: number;
  external_url?: string;
  youtube_url?: string;
  attributes?: IAttribute[];
  nsfw: boolean;
  nftCollection?: Collection;
  creator?: string;
}

export interface NFT {
  createdAt: string;
  updatedAt: string;
  id?: string;
  events?: Event[];
  name: string;
  description: string;
  royaltyPercentage: number;
  tokenId?: string;
  tokenType?: ItemType;
  tokenAddress?: string;
  nftCollection: Collection;
  supply: number;
  price: number;
  explicit: boolean;
  nftMetaData: INftMetaData;
  tokenURI: string;
  blockchain: string;
  isFeatured: boolean;
  isDisabled: boolean;
  status?: NftStatus;
  likes: number;
  isLiked: boolean;
  forSale: boolean;
  owners?: User[];
  numberOwned: number;
  isAnOwner: boolean;
  amountIOwn: number;
  creator?: User;
  type: NftType;
  bids?: Order[];
  defaultListing?: Order;
  listings?: Order[];
  metaDataFrozen: boolean;
  priceHistory: any;
  unlockableContent?: string;
  hasUnlockable?: boolean;
}
