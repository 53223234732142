import { ItemType } from "@opensea/seaport-js/lib/constants";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { blockChainData } from "../../../api/modules/nft";
import {
  ERROR_CODES,
  PLATFORM_FEE_PERCENTAGE,
} from "../../../constants/constants";
import {
  useActions,
  useConversion,
  useForceSwitchNetwork,
  useSelector,
} from "../../../hooks";
import { OrderStatus, OrderType } from "../../../models/order";
import {
  TokenData,
  sellAtPrice,
  startAuction,
} from "../../../services/seaport.service";
import { SeaportContext } from "../../../state/context/SeaportContext";
import FeeInfo from "../ToolTips/FeeInfo";
import { getNftSmallUrl } from "../../../models/nft";

const SellNft = ({
  show,
  handleClose,
  nft,
  handleContinue,
  isAuction,
  noteText,
  noteColor,
  refetch,
}: any) => {
  const [startListing, setStartListing] = useState(false);
  const { listing } = useSelector((state) => state.listing);
  const { updateNft, placeOrder } = useActions();
  const seaport = React.useContext(SeaportContext);
  const { address } = useAccount();
  const [displayPrice, setDisplayPrice] = useState<any>(0);
  const { isCorrectNetwork, checkNetwork } = useForceSwitchNetwork(nft);
  const { user } = useSelector((state: any) => state.auth);

  const finalDisplayPrice = Number(
    listing?.listPrice *
      (1 - (PLATFORM_FEE_PERCENTAGE + nft?.royaltyPercentage) / 100)
  ).toFixed(5);

  const { priceUSD: finalPriceUSD } = useConversion(
    finalDisplayPrice,
    blockChainData[nft.blockchain].symbol
  );
  useEffect(() => {
    const priceNumber = parseFloat(listing?.listPrice || 0);
    const roundedPrice =
      priceNumber < 0.00001 ? priceNumber.toExponential() : priceNumber;
    setDisplayPrice(roundedPrice);
  }, [listing?.listPrice]);

  const availableNFTsForSale: number = (() => {
    const totalListed = nft.listings
      ?.filter((listing: any) => listing.seller?.id === user?.id)
      .reduce((acc: number, listing: any) => acc + listing.quantity, 0);

    return nft.amountIOwn - (totalListed || 0);
  })();
  async function saveListing(order: any) {
    try {
      await placeOrder(
        {
          hash: JSON.stringify(order),
          nftId: nft?.id,
          status: OrderStatus.CREATED,
          amount: listing?.listPrice,
          endAmount: listing?.endPrice,
          quantity: listing?.quantity,
          type: listing.listingType,
          buyerAddress: "",
          sellerAddress: user?.walletAddress,
          startTime: order.parameters.startTime.toString(),
          endTime: order.parameters.endTime.toString(),
        },
        () => {}
      );
      if (refetch) {
        await refetch();
        setStartListing(false);
        handleClose();
      } else {
        setStartListing(false);
        handleClose();
      }
    } catch (e: any) {
      setTimeout(() => {
        setStartListing(false);
      }, 500);
      toast.error(ERROR_CODES[e.code] || e.message);
    }
  }

  async function createOrder() {
    try {
      const _isCorrectNetwork = await checkNetwork();
      if (
        !_isCorrectNetwork ||
        seaport === null ||
        nft === null ||
        startListing ||
        address === undefined
      )
        return;

      if (availableNFTsForSale < 1) {
        toast.error("You are already selling all of your copies of this NFT");
        return;
      }

      setStartListing(true);
      let endTime = moment().add(10, "years").unix();
      let startTime = moment().unix();

      if (listing.endDate) {
        endTime = moment(listing.endDate).unix();
      }

      if (listing.startDate) {
        startTime = moment(listing.startDate).unix();
      }

      if (startTime > endTime) {
        toast.error("Start time cannot be greater than end time");
        return;
      }

      const tokenType =
        nft?.tokenType.toString() === ItemType.ERC721.toString()
          ? ItemType.ERC721
          : ItemType.ERC1155;

      const tokenData: TokenData = {
        address: nft?.tokenAddress,
        tokenId: nft?.tokenId,
        type: tokenType,
        quantity: listing?.quantity,
      };
      let order;
      switch (listing.listingType) {
        case OrderType.SALE:
          order = await sellAtPrice(
            nft,
            tokenData,
            listing?.listPrice.toString(),
            startTime,
            endTime
          );
          break;
        case OrderType.AUCTION:
        case OrderType.DUTCH_AUCTION:
          order = await startAuction(
            nft,
            tokenData,
            listing?.listPrice.toString(),
            listing?.endPrice.toString(),
            startTime,
            endTime
          );
          break;
      }
      await saveListing(order);
    } catch (error: any) {
      setTimeout(() => {
        setStartListing(false);
      }, 500);
      toast.error(ERROR_CODES[error.code] || error.message);
    }
  }

  return (
    <Modal
      className="custom modal-bg bigger"
      show={show}
      backdrop={startListing ? "static" : false}
      onHide={() => {
        toast.dismiss();

        !startListing && handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="d-flex align-items-center">
          <button
            type="button"
            className="btn-secondary tiny"
            aria-label="Back"
            onClick={() => handleContinue()}
          >
            <i className="fa fa-arrow-left"></i>
          </button>{" "}
          {listing?.listingType === OrderType.SALE ? "Sell" : "Auction"} This
          NFT
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mt-2 mb-3 sell-auction-body">
          <div className="modal-nft-img w-auto me-4">
            <img
              src={getNftSmallUrl(nft?.nftMetaData?.thumbnail_image)}
              className="sell-this-nft"
              alt=""
            />
          </div>
          <div className="min-265">
            <h4 className="mb-1 f-28">{nft?.name}</h4>
            <p className="text-gray f-14 mb-1">by {nft?.creator?.username}</p>
            <p className="f-14 mt-1 f-700">
              <span className="text-gray me-1">Qty:</span>
              {listing?.quantity}
            </p>

            {isAuction === "Auction" && (
              <div className="mt-2 d-flex align-items-center">
                <p className="sellthis-title f-14 mb-0">Auction</p>
                <p className="mb-0 f-12">Sell to Highest Bidder</p>
              </div>
            )}

            <div className="mt-2 d-flex align-items-center">
              <p className="sellthis-title f-14 mb-0">Price per item</p>
              <div className="f-22">
                <span className="f-15 text-deepGray">
                  <i className="fa-brands fa-ethereum"></i>
                </span>
                <span className="f-22 mx-1">
                  {displayPrice} {blockChainData[nft.blockchain].symbol}
                </span>
                <small className="text-gray f-12">
                  {"$" + listing?.priceUSD}
                </small>
              </div>
            </div>
            <div className="mt-2 d-flex align-items-center">
              <p className="sellthis-title f-14 mb-0">Start Date</p>
              <div className="">
                <span className="f-12 text-deepGray">
                  <i className="fa-regular fa-calendar"></i>
                </span>
                <small className="text-gray ms-1 f-12">
                  {moment(listing?.startDate).format("MM/DD/YY h:mm A")}
                </small>
              </div>
            </div>
            <div className="mt-2 d-flex align-items-center">
              <p className="sellthis-title f-14 mb-0">End Date</p>
              <div className="">
                <span className="f-12 text-deepGray">
                  <i className="fa-regular fa-calendar"></i>
                </span>
                <small className="text-gray ms-1 f-12">
                  {listing?.endDate
                    ? moment(listing?.endDate).format("MM/DD/YY h:mm A")
                    : "Forever"}
                </small>
              </div>
            </div>

            <div className="mt-3 custom-fee">
              <div className="row mb-2">
                <div className="col-3 d-flex align-items-center">
                  <span className="f-14 fw-600">
                    Fees
                    <span className="text-purple f-10 ms-1">
                      <FeeInfo>
                        <i className="fa-solid fa-circle-exclamation"></i>
                      </FeeInfo>
                    </span>
                  </span>
                </div>
                <div className="col-9">
                  <div className="d-flex align-items-center justify-content-between ">
                    <div>
                      <span className="text-gray f-12">
                        Service fee {PLATFORM_FEE_PERCENTAGE}%
                      </span>
                    </div>
                    <div>
                      <span className="text-gray f-12">
                        Creator Royalty {nft?.royaltyPercentage}%
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3 d-flex align-items-center">
                  <span className="f-14 fw-600">You Get</span>
                </div>
                <div className="col-9">
                  <span className="text-gray f-12">
                    {`${finalDisplayPrice} ${
                      blockChainData[nft.blockchain].symbol
                    }`}
                  </span>
                  <span className="text-gray f-12 ms-2">({finalPriceUSD})</span>
                </div>
              </div>
            </div>

            <div className="d-flex align-items-center mt-3 sell-auction-footer">
              {!startListing && (
                <button
                  onClick={() => createOrder()}
                  className={`w-100 ${
                    startListing ? "btn-gray" : "btn-main me-2"
                  }`}
                >
                  {listing ? "LIST NOW" : "LIST THIS NFT FOR SALE"}
                </button>
              )}
              {startListing && (
                <div className="mb-2 d-flex flex-column w-100 align-items-center">
                  <ThreeDots
                    height="60"
                    width="60"
                    color="#8364e2"
                    ariaLabel="loading"
                  />
                  <span> Creating Order....</span>
                </div>
              )}
            </div>
            {noteText && (
              <p className={`${noteColor} f-11 text-center mt-2`}>
                NOTE: {noteText}
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SellNft;
