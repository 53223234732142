import { ActionType } from "../action-types";
import { Action } from "../actions";

interface Web3State {
  currentChain: number;
  isMetaMaskInstalled: boolean;
  isConnected: boolean;
  showWalletModal: boolean;
  showWrapCurrencyModal: boolean;
  conversions: any | null;
}

const initialState = {
  currentChain: 0,
  isMetaMaskInstalled: false,
  isConnected: false,
  showWalletModal: false,
  showWrapCurrencyModal: false,
  conversions: { ETH: { USD: 0 } },
};

const reducer = (
  state: Web3State = initialState,
  action: Action
): Web3State => {
  switch (action.type) {
    case ActionType.UPDATE_CURRENT_CHAIN:
      return {
        ...state,
        currentChain: action.payload,
      };
    case ActionType.UPDATE_WALLET_INSTALLATION_STATUS:
      return {
        ...state,
        isMetaMaskInstalled: action.payload,
      };
    case ActionType.UPDATE_WALLET_CONNECTION_STATUS:
      return {
        ...state,
        isConnected: action.payload,
      };
    case ActionType.UPDATE_WALLET_MODAL:
      return {
        ...state,
        showWalletModal: action.payload,
      };
    case ActionType.UPDATE_CONVERSIONS:
      return {
        ...state,
        conversions: action.payload,
      };
    case ActionType.SHOW_WRAP_CURRENCY_MODAL:
      return {
        ...state,
        showWrapCurrencyModal: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
