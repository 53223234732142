const Privacy = () => {
  return (
    <div className="m-5">
      <h2 className="c11 c23">PRIVACY POLICY</h2>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c3">
        <span className="c2">Effective Date: 1/1/2023</span>
      </p>
      <p className="c3">
        <span className="c2">Last Updated: 1/1/2023</span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c11">
          zeNFT, Inc. (&ldquo;zeNFT,&rdquo; &ldquo;we&rdquo; or
          &ldquo;us&rdquo;) is committed to protecting your privacy. We have
          prepared this Privacy Policy to describe to you our practices
          regarding the Personal Information (as defined below) we collect, why
          we collect it, and how we use and disclose it. This Privacy Policy
          applies to data collection by us and shall apply to your use of the
          website,{" "}
        </span>
        <span className="c15 c11">Zing.it,</span>
        <span className="c2">
          &nbsp;mobile applications, software, communications, capabilities and
          services of zeNFT (&ldquo;Services&rdquo;) owned by us, but excluding
          services that state that they are offered under a different privacy
          policy.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          We reserve the right to change any of our policies and practices at
          any time, but you can always find the latest version of this Privacy
          Policy here on this page.
        </span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c0 c8">
        <span className="c9">When this Privacy Policy Applies</span>
      </p>
      <p className="c0 c6 c8">
        <span className="c23 c11"></span>
      </p>
      <p className="c0 c8">
        <span className="c2">
          Our Privacy Policy applies to all of the Services offered by us and
          our affiliates, but excludes services that have separate privacy
          policies that do not incorporate this Privacy Policy.
        </span>
      </p>
      <p className="c0 c8 c6">
        <span className="c2"></span>
      </p>
      <p className="c0 c8">
        <span className="c2">
          Our Privacy Policy does not apply to services offered by other
          companies or individuals, including products or sites that may be
          displayed to you, or other sites linked from our Services. Our Privacy
          Policy does not cover the information practices of other companies and
          organizations who advertise our Services, and who may use cookies,
          pixel tags and other technologies to serve and offer relevant ads.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c9"></span>
      </p>
      <p className="c0 c8">
        <span className="c9">Terms of Use</span>
      </p>
      <p className="c0 c8 c6">
        <span className="c23 c11"></span>
      </p>
      <p className="c0 c8">
        <span className="c2">
          By accessing or using the Services in any manner, you also agree to be
          bound by zeNFT&rsquo;s Terms of Use (&ldquo;Terms&rdquo;). Please read
          the Terms carefully. If you do not accept all of the terms and
          conditions contained in or incorporated by reference into the Terms,
          please do not use our Services.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c9"></span>
      </p>
      <p className="c0">
        <span className="c9">Personal Information We Collect</span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          As used herein, &ldquo;Personal Information&rdquo; means information
          that identifies or is reasonably capable of identifying an individual,
          directly or indirectly, and information that is capable of being
          associated with an identified or reasonably identifiable individual.
        </span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_8-0 start">
        <li className="c3 c10">
          <span className="c1">Personal Information We Collect From You</span>
          <span className="c2">.</span>
        </li>
      </ol>
      <p className="c3 c6 c22">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          We may collect the following categories of Personal Information
          directly from you: -{" "}
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <ul className="c12 lst-kix_list_9-0 start">
        <li className="c0 c16 li-bullet-0">
          <span className="c2">
            Identifying information&nbsp;such as name, email, phone number,
            mailing address;
          </span>
        </li>
        <li className="c0 c16 li-bullet-0">
          <span className="c2">Login credentials</span>
        </li>
        <li className="c0 c16 li-bullet-0">
          <span className="c2">Wallet address;</span>
        </li>
        <li className="c0 c16 li-bullet-0">
          <span className="c2">
            Correspondence&nbsp;such as information you provide to us in
            correspondence, including registering an account and customer
            support.
          </span>
        </li>
      </ul>
      <p className="c3 c6 c20">
        <span className="c1"></span>
      </p>
      <ol className="c12 lst-kix_list_8-0">
        <li className="c3 c10">
          <span className="c1">
            Personal Information We Collect Automatically
          </span>
          <span className="c2">.</span>
        </li>
      </ol>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          We may collect the following categories of Personal Information
          automatically through your use of our services:
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <ul className="c12 lst-kix_list_9-0">
        <li className="c0 c16 li-bullet-0">
          <span className="c2">
            Online identifiers&nbsp;such as IP address, domain name;
          </span>
        </li>
        <li className="c0 c16 li-bullet-0">
          <span className="c2">
            Device information&nbsp;such as hardware, operating system, browser;
          </span>
        </li>
        <li className="c0 c16 li-bullet-0">
          <span className="c2">
            Usage data&nbsp;such as system activity, internal and external
            information related to zeNFT pages that you visit, clickstream
            information; and
          </span>
        </li>
        <li className="c0 c16 li-bullet-0">
          <span className="c2">Geolocation data.</span>
        </li>
      </ul>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          Our automatic collection of Personal Information may involve the use
          of Cookies. When you access our Services, we may make use of the
          standard practice of placing tiny data files called cookies, flash
          cookies, pixel tags, or other tracking tools (herein,
          &ldquo;Cookies&rdquo;) on your computer or other devices used to
          access our Services. We use Cookies to help us recognize you as a
          customer, collect information about your use of our Services to better
          customize our services and content for you, and collect information
          about your computer or other access devices to: (i) ensure that your
          account security has not been compromised by detecting irregular,
          suspicious, or potentially fraudulent account activities; (ii) assess
          and improve our services and advertising campaigns.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          Please note that if you reject cookies, you will not be able to use
          some or all of our Services. If you do not consent to the placing of
          Cookies on your device, please do not visit, access, or use our
          Services.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_8-0">
        <li className="c0 c10">
          <span className="c1">
            Personal Information We Collect from Third Parties.
          </span>
        </li>
      </ol>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          We may collect and/or verify the following categories of Personal
          Information about you from third parties, including service providers
          and our affiliates:
        </span>
      </p>
      <p className="c0 c6 c22">
        <span className="c2"></span>
      </p>
      <ul className="c12 lst-kix_list_9-0">
        <li className="c0 c16 li-bullet-0">
          <span className="c2">
            Transaction information&nbsp;such as public blockchain data;
          </span>
        </li>
        <li className="c0 c16 li-bullet-0">
          <span className="c2">
            Additional information&nbsp;at our discretion to comply with legal
            obligations.
          </span>
        </li>
      </ul>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c9">How We Use Your Personal Information</span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          We collect Personal Information about you in an attempt to provide you
          with the best experience possible in connection with our services,
          protect you from risks related to improper use and fraud, and help us
          maintain and improve our Services. We may use your Personal
          Information to:
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_10-0 start">
        <li className="c0 c7 li-bullet-1">
          <span className="c1">Provide you with our Services</span>
          <span className="c2">
            . We use your Personal Information to provide you with our Services
            pursuant to the Terms.
          </span>
        </li>
      </ol>
      <p className="c0 c4">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_10-0">
        <li className="c0 c7 li-bullet-1">
          <span className="c1">
            Comply with Legal and Regulatory Requirements
          </span>
          <span className="c2">
            . We process your Personal Information as required by applicable
            laws and regulations.
          </span>
        </li>
      </ol>
      <p className="c0 c4">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_10-0">
        <li className="c0 c7 li-bullet-1">
          <span className="c1">Detect and Prevent Fraud</span>
          <span className="c2">
            . We process your Personal Information to detect and prevent fraud
            on your account.
          </span>
        </li>
      </ol>
      <p className="c0 c4">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_10-0">
        <li className="c0 c7 li-bullet-1">
          <span className="c1">
            Protect the Security and Integrity of our Services
          </span>
          <span className="c2">
            . We use your Personal Information, including information about your
            device and your activity on zeNFT to maintain the security of your
            account and the zeNFT platform.
          </span>
        </li>
      </ol>
      <p className="c0 c4">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_10-0">
        <li className="c0 c7 li-bullet-1">
          <span className="c1">Provide You with Customer Support</span>
          <span className="c2">
            . We process your Personal Information when you contact our support
            team with questions about or issues with your account.
          </span>
        </li>
      </ol>
      <p className="c8 c6 c13">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_10-0">
        <li className="c0 c7 li-bullet-1">
          <span className="c1">Communications with You</span>
          <span className="c2">
            . We may send you periodic emails about the Services. We may contact
            you to discuss your account, remind you about features of the
            Services and update you about a support request.{" "}
          </span>
        </li>
      </ol>
      <p className="c0 c4">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_10-0">
        <li className="c0 c7 li-bullet-2">
          <span className="c1">Market our Services</span>
          <span className="c2">
            . We may contact you with information about our Services. We will
            only do so with your permission, which can be revoked at any time.
          </span>
        </li>
      </ol>
      <p className="c0 c4">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_10-0">
        <li className="c0 c7 li-bullet-1">
          <span className="c1">Other Business Purposes</span>
          <span className="c2">
            . We may use your Personal Information for additional purposes if
            that purpose is disclosed to you before we collect the information
            or if we obtain your consent.
          </span>
        </li>
      </ol>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c9">How We Share Your Personal Information</span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          We will not share your Personal Information with third parties, except
          as described below:
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_11-0 start">
        <li className="c0 c10">
          <span className="c1">Service Providers</span>
          <span className="c2">
            . We may share your Personal Information with third-party service
            providers for business or commercial purposes, including fraud
            detection and prevention, security threat detection, payment
            processing, customer support, data analytics, information
            technology, advertising and marketing, network infrastructure,
            storage, transaction monitoring. We share your Personal Information
            with these service providers only so that they can provide us with
            the services, and we prohibit our service providers from using or
            disclosing your Personal Information for any other purpose.{" "}
          </span>
        </li>
      </ol>
      <p className="c0 c6 c20">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_11-0">
        <li className="c0 c10">
          <span className="c1">Law Enforcement</span>
          <span className="c2">
            . We may be compelled to share your Personal Information with law
            enforcement, government officials, and regulators.
          </span>
        </li>
      </ol>
      <p className="c8 c6 c19">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_11-0">
        <li className="c0 c10">
          <span className="c1">Corporate Transactions</span>
          <span className="c2">
            . We may disclose Personal Information in the event of a proposed or
            consummated merger, acquisition, reorganization, asset sale, or
            similar corporate transaction, or in the event of a bankruptcy or
            dissolution.
          </span>
        </li>
      </ol>
      <p className="c19 c8 c6">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_11-0">
        <li className="c0 c10">
          <span className="c1">Professional Advisors</span>
          <span className="c2">
            . We may share your Personal Information with our professional
            advisors, including legal, accounting, or other consulting services
            for purposes of audits or to comply with our legal obligations.
          </span>
        </li>
      </ol>
      <p className="c8 c6 c20 c24">
        <span className="c2"></span>
      </p>
      <ol className="c12 lst-kix_list_11-0">
        <li className="c3 c10">
          <span className="c1">Consent</span>
          <span className="c2">
            . We may share your Personal Information with your consent.
          </span>
        </li>
      </ol>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c3">
        <span className="c2">
          If we decide to modify the purpose for which your Personal Information
          is collected and used, we will amend this Privacy Policy.
        </span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c3">
        <span className="c9">Security of Personal Information</span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          We cannot guarantee absolute security of your Personal Information,
          but we work hard to protect you from unauthorized access to or
          unauthorized alteration, disclosure, or destruction of Personal
          Information we collect and store. Measures we take include encryption
          of our website communications with SSL; optional two-factor
          authentication; periodic review of our Personal Information
          collection, storage, and processing practices; and restricted access
          to your Personal Information on a need-to-know basis for our
          employees, contractors and agents who are subject to strict
          contractual confidentiality obligations and may be disciplined or
          terminated if they fail to meet these obligations.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c9">Data Retention</span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          We may retain your Personal Information as long as you continue to use
          the Services, have an account with us, or for as long as is otherwise
          necessary to fulfill the purposes outlined in this Policy. You can ask
          to close your account by contacting us using the information in the
          Contact Us section below, and we will delete your Personal Information
          on request. We may, however, retain Personal Information for an
          additional period as if permitted or required under applicable laws,
          for legal, tax, or regulatory reasons, or for legitimate and lawful
          business purposes.
        </span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c3">
        <span className="c9">California Privacy Rights</span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          California consumers have the following additional rights under the
          CCPA with respect to their Personal Information. Please understand,
          however, that these rights are not absolute, and we may decline
          requests as permitted under applicable law.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c11 c18">Right to Know.</span>
        <span className="c11 c21">
          &nbsp;You have a right to request that we disclose to you what
          Personal Information we have collected, used, shared, or sold about
          you, and why they collected, used, shared, or sold that information.{" "}
        </span>
      </p>
      <p className="c0 c6">
        <span className="c1"></span>
      </p>
      <p className="c0">
        <span className="c18 c11">Right to Request Access</span>
        <span className="c2">
          .&nbsp;You may submit a request that zeNFT disclose the categories of
          Personal Information we have collected about you, the categories of
          sources from which your Personal Information is collected, the
          business or commercial purpose for collecting your Personal
          Information, the categories of third parties with whom we share your
          Personal Information, and the specific pieces of Personal Information
          we have collected about you in the past 12 months.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c18 c11">Right to Request Deletion</span>
        <span className="c2">
          .&nbsp;You may submit a request that zeNFT delete the Personal
          Information about you that we have collected from you.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c18 c11">
          Right Not to Receive Discriminatory Treatment
        </span>
        <span className="c2">
          .&nbsp;You have the right to exercise the privacy rights conferred by
          the CCPA without discriminatory treatment.
        </span>
      </p>
      <p className="c0 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c11">
          You may submit a request to access or delete your Personal Information
          by emailing us at&nbsp;
        </span>
        <span className="c11 c15">
          <a href="mailto:info@zing.it?subject=Delete%20Personal%20Information">
            info@zing.it
          </a>
        </span>
        <span className="c11">.</span>
        <span className="c2">
          &nbsp;We are legally obligated to verify your identity when you submit
          a request. We may request additional information from you, like your
          date of birth or a personal identification number, to verify your
          identity. If we are unable to verify your identity, we may deny your
          request. If you submit a request to delete your Personal Information,
          you will also be required to confirm the deletion request by email.
        </span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c3">
        <span className="c9">
          Information For Persons From Outside the United States
        </span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c0">
        <span className="c2">
          zeNFT is based in the United States and governed by U.S. law. If you
          are accessing our site from outside the United States, please be aware
          that Personal Information collected through the site will be processed
          in the United States. Data protection laws in the United States are
          likely different from those of your country of residence. By
          submitting your Personal Information through our site, you acknowledge
          that your Personal Information will be processed in the United States
          in accordance with this policy.{" "}
        </span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c3">
        <span className="c9">Changes to This Policy</span>
      </p>
      <p className="c3 c6">
        <span className="c9"></span>
      </p>
      <p className="c0 c8">
        <span className="c2">
          zeNFT reserves the right to change or modify this Policy at any time
          and in our sole discretion. If we make material changes to this
          Policy, we will use reasonable efforts to provide notice of such
          changes, such as by providing notice through the Services or updating
          the &ldquo;Last Updated&rdquo; date at the beginning of this Policy.
          By continuing to access or use the Services, you confirm your
          acceptance of the revised Policy and all of the terms incorporated
          therein by reference effective as of the date this Policy is updated.
          It is your sole responsibility to review this Policy from time to time
          to view such changes and to ensure that you understand the terms and
          conditions that apply when you access or use the Services.
        </span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c3">
        <span className="c9">Contact Us</span>
      </p>
      <p className="c3 c6">
        <span className="c2"></span>
      </p>
      <p className="c3">
        <span className="c11">
          If you have questions or concerns regarding this policy or our
          processing of your Personal Information, please feel free to email us
          at&nbsp;
        </span>
        <span className="c15 c11">
          {" "}
          <a href="mailto:info@zing.it?subject=Personal%20Information">
            info@zing.it
          </a>{" "}
        </span>
      </p>
      <p className="c3 c6">
        <span className="c9"></span>
      </p>
      <div className="c25">
        <p className="c3">
          <span className="c5">
            You should have a specific privacy designated email, if possible.
          </span>
        </p>
      </div>
      <div className="c25">
        <p className="c3">
          <span className="c5">Same comment as above.</span>
        </p>
      </div>
    </div>
  );
};
export default Privacy;
