export enum ActionType {
  LOGIN_USER = "login_user",
  LOGIN_USER_SUCCESS = "login_user_success",
  LOGIN_USER_ERROR = "login_user_error",
  SIGN_UP_USER = "sign_up_user",
  SIGN_UP_USER_SUCCESS = "sign_up_user_success",
  SIGN_UP_USER_ERROR = "sign_up_user_error",
  IS_LOGGED_IN = "is_logged_in",
  SAVE_WALLET_ADDRESS = "save_wallet_address",
  CLEAR_WALLET = "clear_wallet",
  IS_WALLET_CONNECTED = "is_wallet_connect",
  VERIFY_USER = "verify_user",
  VERIFY_USER_SUCCESS = "verify_user_success",
  VERIFY_USER_ERROR = "verify_user_error",
  UPDATE_USER = "update_user",
  UPDATE_USER_SUCCESS = "update_user_success",
  UPDATE_USER_ERROR = "update_user_error",
  FETCH_USER = "fetch_user",
  FETCH_USER_SUCCESS = "fetch_user_success",
  FETCH_USER_ERROR = "fetch_user_error",
  UPDATE_NFT = "update_nft",
  UPDATE_NFT_SUCCESS = "update_nft_success",
  UPDATE_NFT_ERROR = "update_nft_error",
  CREATE_NFT = "create_nft",
  CREATE_NFT_SUCCESS = "create_nft_success",
  CREATE_NFT_ERROR = "create_nft_error",
  FETCH_NFTS = "fetch_nfts",
  FETCH_NFTS_SUCCESS = "fetch_nfts_success",
  FETCH_NFTS_ERROR = "fetch_nfts_error",
  FETCH_NFT = "fetch_nft",
  FETCH_NFT_SUCCESS = "fetch_nft_success",
  FETCH_NFT_ERROR = "fetch_nft_error",
  FETCH_NFT_PAGE_VIEWS = "fetch_nft_page_views",
  FETCH_NFT_PAGE_VIEWS_SUCCESS = "fetch_nft_page_views_success",
  FETCH_NFT_PAGE_VIEWS_ERROR = "fetch_nft_page_views_error",
  CLEAR_NFTS = "clear_nfts",
  UPDATE_CURRENT_CHAIN = "update_current_chain",
  UPDATE_WALLET_INSTALLATION_STATUS = "update_wallet_installation_status",
  UPDATE_WALLET_CONNECTION_STATUS = "update_wallet_connection_status",
  UPDATE_AUTH_STATUS = "update_auth_status",
  UPDATE_TOAST = "update_toast",
  UPDATE_WALLET_MODAL = "update_wallet_modal",
  SHOW_WRAP_CURRENCY_MODAL = "show_wrap_currency_modal",
  UPDATE_CONVERSIONS = "update_conversions",
  FETCH_FEATURED_NFTS = "fetch_featured_nft",
  FETCH_FEATURED_NFTS_SUCCESS = "fetch_featured_nft_success",
  FETCH_FEATURED_NFTS_ERROR = "fetch_featured_nft_error",
  FETCH_TOP_NFTS = "fetch_top_nft",
  FETCH_TOP_NFTS_SUCCESS = "fetch_top_nft_success",
  FETCH_TOP_NFTS_ERROR = "fetch_top_nft_error",
  FETCH_NEW_NFTS = "fetch_new_nft",
  FETCH_NEW_NFTS_SUCCESS = "fetch_new_nft_success",
  FETCH_NEW_NFTS_ERROR = "fetch_new_nft_error",
  FETCH_FEATURED_AUTHORS = "fetch_featured_authors",
  FETCH_FEATURED_AUTHORS_SUCCESS = "fetch_featured_authors_success",
  FETCH_FEATURED_AUTHORS_ERROR = "fetch_featured_authors_error",
  FETCH_TOP_AUTHORS = "fetch_top_authors",
  FETCH_TOP_AUTHORS_SUCCESS = "fetch_top_authors_success",
  FETCH_TOP_AUTHORS_ERROR = "fetch_top_authors_error",
  FETCH_AUTHOR = "fetch_author",
  FETCH_AUTHOR_SUCCESS = "fetch_author_success",
  FETCH_AUTHOR_ERROR = "fetch_author_error",
  FETCH_LIKED_NFTS = "fetch_my_liked_nfts",
  FETCH_LIKED_NFTS_SUCCESS = "fetch_my_liked_nfts_success",
  FETCH_LIKED_NFTS_ERROR = "fetch_my_liked_nfts_error",
  FETCH_FOLLOWED_USERS_ERROR = "fetch_followed_users_error",
  FETCH_FOLLOWED_USERS_SUCCESS = "fetch_followed_users_success",
  FETCH_FOLLOWED_USERS = "fetch_followed_users",
  FETCH_FOLLOWERS = "fetch_followers",
  FETCH_FOLLOWERS_SUCCESS = "fetch_followers_success",
  FETCH_FOLLOWERS_ERROR = "fetch_followers_error",
  FETCH_PURCHASED_NFTS = "fetch_purchased_nfts",
  FETCH_PURCHASED_NFTS_SUCCESS = "fetch_purchased_nfts_success",
  FETCH_PURCHASED_NFTS_ERROR = "fetch_purchased_nfts_error",
  FETCH_USER_OFFERS_RECEIVED = "fetch_user_offers_received",
  FETCH_USER_OFFERS_RECEIVED_SUCCESS = "fetch_user_offers_received_success",
  FETCH_USER_OFFERS_RECEIVED_ERROR = "fetch_user_offers_received_error",
  FETCH_NEW_AUTHORS_SUCCESS = "fetch_new_authors_success",
  FETCH_NEW_AUTHORS = "fetch_new_authors",
  FETCH_NEW_AUTHORS_ERROR = "fetch_new_authors_error",
  CLEAR_AUTHORS = "CLEAR_AUTHORS",
  TOGGLE_LIKE_NFT = "TOGGLE_LIKE_NFT",
  TOGGLE_LIKE_NFT_SUCCESS = "TOGGLE_LIKE_NFT_SUCCESS",
  TOGGLE_LIKE_NFT_ERROR = "TOGGLE_LIKE_NFT_ERROR",
  FETCH_NFTS_SUCCESS_APPEND = "FETCH_NFTS_SUCCESS_APPEND",
  FETCH_CREATED_NFTS_ERROR = "FETCH_CREATED_NFTS_ERROR",
  FETCH_CREATED_NFTS_SUCCESSS = "FETCH_CREATED_NFTS_SUCCESSS",
  FETCH_CREATED_NFTS = "FETCH_CREATED_NFTS",
  FETCH_AUTHOR_AVAILABLE_NFTS = "FETCH_AUTHOR_AVAILABLE_NFTS",
  FETCH_AUTHOR_AVAILABLE_NFTS_SUCCESS = "FETCH_AUTHOR_AVAILABLE_NFTS_SUCCESS",
  FETCH_AUTHOR_AVAILABLE_NFTS_ERROR = "FETCH_AUTHOR_AVAILABLE_NFTS_ERROR",
  FETCH_AUTHORS = "FETCH_AUTHORS",
  FETCH_AUTHORS_SUCCESS = "FETCH_AUTHORS_SUCCESS",
  FETCH_AUTHORS_ERROR = "FETCH_AUTHORS_ERROR",
  FETCH_EVENTS = "FETCH_EVENTS",
  FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS",
  FETCH_EVENTS_ERROR = "FETCH_EVENTS_ERROR",
  FETCH_USER_EVENTS = "FETCH_USER_EVENTS",
  FETCH_USER_EVENTS_SUCCESS = "FETCH_USER_EVENTS_SUCCESS",
  FETCH_USER_EVENTS_ERROR = "FETCH_USER_EVENTS_ERROR",
  FETCH_NFT_EVENTS = "FETCH_NFT_EVENTS",
  FETCH_NFT_EVENTS_SUCCESS = "FETCH_NFT_EVENTS_SUCCESS",
  FETCH_NFT_EVENTS_ERROR = "FETCH_NFT_EVENTS_ERROR",
  CLEAR_EVENTS = "CLEAR_EVENTS",
  CREATE_COLLECTION = "create_collection",
  CREATE_COLLECTION_SUCCESS = "create_collection_success",
  CREATE_COLLECTION_ERROR = "create_collection_error",
  UPDATE_COLLECTION = "update_collection",
  UPDATE_COLLECTION_SUCCESS = "update_collection_success",
  UPDATE_COLLECTION_ERROR = "update_collection_error",
  FETCH_COLLECTION = "fetch_collection",
  FETCH_COLLECTION_SUCCESS = "fetch_collection_success",
  FETCH_COLLECTION_ERROR = "fetch_collection_error",
  FETCH_SINGLE_COLLECTION = "fetch_single_collection",
  FETCH_SINGLE_COLLECTION_SUCCESS = "fetch_single_collection_success",
  FETCH_SINGLE_COLLECTION_ERROR = "fetch_single_collection_error",
  FETCH_MY_COLLECTIONS = "fetch_my_collections",
  FETCH_MY_COLLECTIONS_SUCCESS = "fetch_my_collections_success",
  FETCH_MY_COLLECTIONS_ERROR = "fetch_my_collections_error",
  FETCH_FEATURED_COLLECTIONS = "fetch_featured_collections",
  FETCH_FEATURED_COLLECTIONS_SUCCESS = "fetch_featured_collections_success",
  FETCH_FEATURED_COLLECTIONS_ERROR = "fetch_featured_collections_error",
  FETCH_LIKED_COLLECTIONS = "fetch_liked_collections",
  FETCH_LIKED_COLLECTIONS_SUCCESS = "fetch_liked_collections_success",
  FETCH_LIKED_COLLECTIONS_ERROR = "fetch_liked_collections_error",
  FETCH_TOP_COLLECTIONS = "fetch_top_collections",
  FETCH_TOP_COLLECTIONS_SUCCESS = "fetch_top_collections_success",
  FETCH_TOP_COLLECTIONS_ERROR = "fetch_top_collections_error",
  FETCH_NONCE_SUCCESS = "fetch_nonce_success",
  FETCH_NONCE_ERROR = "fetch_nonce_error",
  FETCH_NONCE = "fetch_nonce",
  VERIFY_SIGNATURE = "verify_signature",
  VERIFY_SIGNATURE_SUCCESS = "verify_signature_success",
  VERIFY_SIGNATURE_ERROR = "verify_signature_error",
  LOGOUT_USER = "logout_user",
  LOGOUT_USER_SUCCESS = "logout_user_success",
  LOGOUT_USER_ERROR = "logout_user_error",
  SAVE_LISTING = "save_listing",
  SAVE_LISTING_SUCCESS = "save_listing_success",
  SAVE_LISTING_ERROR = "save_listing_error",
  ORDER_PLACED = "order_placed",
  ORDER_PLACED_SUCCESS = "order_placed_success",
  ORDER_PLACED_ERROR = "order_placed_error",
  ORDER_CANCELED = "order_canceled",
  ORDER_CANCELED_SUCCESS = "order_canceledd_success",
  ORDER_CANCELED_ERROR = "order_canceled_error",
  ORDER_ACCEPTED = "order_accepted",
  ORDER_ACCEPTED_SUCCESS = "order_accepted_success",
  ORDER_ACCEPTED_ERROR = "order_accepted_error",
  FREEZE_METADATA = "FREEZE_METADATA",
  FREEZE_METADATA_SUCCESS = "FREEZE_METADATA_SUCCESS",
  FREEZE_METADATA_ERROR = "FREEZE_METADATA_ERROR",
  UPDATE_PRICE = "UPDATE_PRICE",
  UPDATE_PRICE_SUCCESS = "UPDATE_PRICE_SUCCESS",
  UPDATE_PRICE_ERROR = "UPDATE_PRICE_ERROR",
  FETCH_FEATURED_GREEN_NFTS = "FETCH_FEATURED_GREEN_NFTS",
  FETCH_FEATURED_GREEN_NFTS_SUCCESS = "FETCH_FEATURED_GREEN_NFTS_SUCCESS",
  FETCH_FEATURED_GREEN_NFTS_ERROR = "FETCH_FEATURED_GREEN_NFTS_ERROR",
  FETCH_NFT_ORDERS = "FETCH_NFT_ORDERS",
  FETCH_NFT_ORDERS_SUCCESS = "FETCH_NFT_ORDERS_SUCCESS",
  FETCH_NFT_ORDERS_ERROR = "FETCH_NFT_ORDERS_ERROR",
  FETCH_NFT_OFFERS = "FETCH_NFT_OFFERS",
  FETCH_NFT_OFFERS_SUCCESS = "FETCH_NFT_OFFERS_SUCCESS",
  FETCH_NFT_OFFERS_ERROR = "FETCH_NFT_OFFERS_ERROR",
  UPDATE_ORDER = "UPDATE_ORDER",
  UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS",
  UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR",
}
