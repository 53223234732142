import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { blockChainData } from "../../api/modules/nft";
import { useDisplayPrice } from "../../hooks/modules/useDisplayPrice";

const HeaderSliderItem = ({ nft }: any) => {
  const navigate = useNavigate();
  const [displayPrice] = useDisplayPrice(nft?.price);
  const [height, setHeight] = useState(0);
  const onImgLoad = ({ target: img }: any) => {
    let currentHeight = height;
    if (currentHeight < img.offsetHeight) {
      setHeight(img.offsetHeight);
    }
  };

  return (
    <div className="side-feature" key={nft.id + "_itemSlide"}>
      <a href={`/item/${nft.id}`}>
        <img
          onLoad={onImgLoad}
          src={nft.nftMetaData?.thumbnail_image}
          style={{
            objectFit: "cover",
            objectPosition: "100% 0",
          }}
          className="lazy nft__item_preview"
          alt=""
        />
      </a>
      <div className="float-right">
        <h6 className="title">{nft.name}</h6>
        <p className="side-name">{nft.creator?.username || "UNKNOWN"}</p>
        <p className="side-name"> {nft.nftCollection?.owner.username}</p>
        <div
          className="d-flex align-items-center"
          style={{ margin: "5px 0px" }}
        >
          <img
            style={{
              width: "auto",
              height: "25px",
              marginRight: "8px",
            }}
            src={blockChainData[nft.blockchain].logo}
            alt={blockChainData[nft.blockchain].symbol}
          />
          <p className="f-700 eth_cost mb-0">
            {displayPrice} {blockChainData[nft.blockchain].symbol}
          </p>
        </div>

        <button
          className="btn-main"
          onClick={() => navigate(`/item/${nft.id}`)}
        >
          Buy Now
        </button>
      </div>
    </div>
  );
};
export default memo(HeaderSliderItem);
