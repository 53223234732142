import { useCallback, useEffect, useRef, useState } from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { useActions, useSelector } from "../../hooks";
import { Collection } from "../../models/collection";
import { NFT, NftType } from "../../models/nft";
import { User } from "../../models/user";
import NftGrid from "../Partials/NftGrid";

const SearchPage = () => {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { user } = useSelector((state) => state.auth);

  type filterOptionType = {
    choice: string;
    value: string;
    selected: boolean;
  };
  type SearchFilter = {
    type: string;
    options: filterOptionType[];
  };

  const defaultFilters: SearchFilter[] = [
    // {
    //   type: "type",
    //   options: [
    //     { choice: "One of Kind", value: NftType.ZingItArt721, selected: false },
    //     {
    //       choice: "Limited Editions",
    //       value: NftType.ZingItTradable1155,
    //       selected: false,
    //     },
    //   ],
    // },
    {
      type: "collections",
      options: [],
    },
    {
      type: "creator",
      options: [],
    },
    {
      type: "chains",
      options: [],
    },
    {
      type: "sensitive",
      options: [
        {
          choice: "Show Explicit Content",
          value: "showExplicit",
          selected: false,
        },
      ],
    },
  ];

  const [filterOptions, setFilterOptions] = useState(defaultFilters);
  const [tagsModified, setTagsModified] = useState(false);

  const [sortItems, setSortItems] = useState([
    { type: "newly created", value: "-createdAt", active: true },
    { type: "oldest", value: "createdAt", active: false },
    { type: "highest price", value: "-price", active: false },
    { type: "lowest price", value: "price", active: false },
  ]);

  const { fetchNfts } = useActions();

  const { nfts } = useSelector((state) => state.nfts);

  useEffect(() => {
    setFiltersFromUrl();

    return () => {};
  }, [user?.id]);

  useEffect(() => {
    if (nfts.nfts?.length) {
      const uniqueNftCollections = nfts.nfts?.reduce<Collection[]>(
        (accumulator, currentItem: NFT) => {
          const existingCollection = accumulator.find(
            (collection: Collection) =>
              collection.id === currentItem.nftCollection.id
          );

          if (!existingCollection) {
            accumulator.push(currentItem.nftCollection);
          }

          return accumulator;
        },
        []
      );
      const collectionsFromUrl =
        searchParams.get("collections")?.split(",") || [];
      const clonedFilterOptions = [...filterOptions];
      const collectionFilter = clonedFilterOptions.find(
        (f) => f.type === "collections"
      );
      if (!collectionFilter) return;
      collectionFilter!.options = [...uniqueNftCollections!].map((c) => ({
        choice: c.name,
        selected: collectionsFromUrl.includes(c.id!),
        value: c.id!,
      }));
      setFilterOptions(clonedFilterOptions);
    }
    return () => {};
  }, [nfts]);

  useEffect(() => {
    if (nfts.nfts?.length) {
      const uniqueNftCreators = nfts.nfts?.reduce<User[]>(
        (accumulator, currentItem: NFT) => {
          const existingCreators = accumulator.find(
            (collection: User) => collection.id === currentItem.creator?.id
          );

          if (!existingCreators) {
            accumulator.push(currentItem.creator!);
          }

          return accumulator;
        },
        []
      );

      const authorsFromUrl = searchParams.get("creator")?.split(",") || [];
      const clonedFilterOptions = [...filterOptions];
      const collectionFilter = clonedFilterOptions.find(
        (f) => f.type === "creator"
      );
      if (!collectionFilter) return;

      collectionFilter.options = [...uniqueNftCreators!]
        .filter((c) => c !== undefined)
        .map((c) => ({
          choice: c.username!,
          selected: authorsFromUrl.includes(c.id!),
          value: c.id!,
        }));

      const defaultFilter = defaultFilters.find((f) => f.type === "creator");
      defaultFilter!.options = collectionFilter.options;
      setFilterOptions(clonedFilterOptions);
    }
    return () => {};
  }, [nfts]);

  useEffect(() => {
    if (nfts.nfts?.length) {
      const uniqueNftBlockchains = new Set(nfts.nfts?.map((x) => x.blockchain));

      const chainsFromUrl = searchParams.get("chains")?.split(",") || [];
      const clonedFilterOptions = [...filterOptions];
      const chainsFilter = clonedFilterOptions.find((f) => f.type === "chains");
      if (!chainsFilter) return;

      chainsFilter.options = [...uniqueNftBlockchains!]
        .filter((c) => c !== undefined)
        .map((c) => ({
          choice: c,
          selected: chainsFromUrl.includes(c),
          value: c,
        }));

      const defaultFilter = defaultFilters.find((f) => f.type === "chains");
      defaultFilter!.options = chainsFilter.options;
      setFilterOptions(clonedFilterOptions);
    }
    return () => {};
  }, [nfts]);

  const setFiltersFromUrl = () => {
    const filters: any = [];
    searchParams.forEach((val, key) => {
      if (key && val.trim()) filters.push(key);
    });
    let filtersToUpdate = filterOptions;
    filters.forEach((filter: any) => {
      const statuses = searchParams.get(filter)?.split(",");
      filtersToUpdate = filterOptions.map((f) => {
        return f.type === filter
          ? {
              ...f,
              options: f.options.map((o) => ({
                ...o,
                selected: statuses!.includes(o.value),
              })),
            }
          : f;
      });
    });

    const sortFromUrl = searchParams.get("sort");
    if (sortFromUrl) {
      const newSortItems = sortItems.map((f) => {
        if (f.value === sortFromUrl) {
          f.active = true;
        } else {
          f.active = false;
        }
        return f;
      });
      setSortItems(newSortItems);
    }
    setFilterOptions(filtersToUpdate);

    handleFetchNewNfts();
  };

  const debounceRef = useRef<NodeJS.Timeout | null>(null);
  const handleFetchNewNfts = useCallback(async () => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => {
      const prevFilters: any = {};
      searchParams.forEach((val, key) => {
        prevFilters[key] = val;
      });
      fetchNfts(prevFilters);
    }, 300); // 300ms debounce time
  }, [searchParams]);

  const handleCheckboxSelection = (e: any, value: any) => {
    const { name } = e.target;
    const newFilterOptions = filterOptions.map((f, i) => {
      if (name === f.type) {
        f.options.map((option, index) => {
          if (option.value === value) {
            option.selected = !option.selected;
          }
          return option;
        });
      }
      return f;
    });

    setFilterOptions(newFilterOptions);

    const filterOption = newFilterOptions.find((o) => o.type === name);
    if (filterOption) {
      setFilter(
        name,
        filterOption.options
          .filter((o) => o.selected)
          .map((o) => o.value)
          .join(",")
      );
    }
  };

  const [tags, setTags] = useState<filterOptionType[]>([]);

  useEffect(() => {
    let getTags: any = [];

    filterOptions.forEach((f) => {
      f.options.forEach((option) => {
        if (option.selected) {
          getTags.push(option);
        }
      });
    });

    setTags(getTags);
  }, [filterOptions]);

  const clearTags = () => {
    const filtersToRemove: any = {};
    filterOptions.forEach((f) => {
      if (f.options.some((o) => o.selected)) filtersToRemove[f.type] = "";
    });
    const existingFilters: any = [];
    searchParams.forEach((val, key) => {
      existingFilters[key] = val;
    });
    setSearchParams({ ...existingFilters, ...filtersToRemove });
    setFilterOptions(
      filterOptions.map((f) => {
        return {
          ...f,
          options: f.options.map((o) => ({
            ...o,
            selected: false,
          })),
        };
      })
    );
    setTagsModified(!tagsModified);
  };

  const removeTag = (t: any) => {
    const newFilterOptions = filterOptions.map((f) => {
      f.options.map((option) => {
        if (option.value === t) {
          option.selected = false;
          setFilter(
            f.type,
            f.options
              .filter((o) => o.selected)
              .map((o) => o.value)
              .join(",")
          );
        }
        return option;
      });
      return f;
    });

    setFilterOptions(newFilterOptions);
    setTagsModified(!tagsModified);
  };

  const handleSortItems = async (item: any) => {
    const newSortItems = sortItems.map((f) => {
      if (f.type === item) {
        f.active = true;
        setFilter("sort", f.value);
      } else {
        f.active = false;
      }
      return f;
    });

    setSortItems(newSortItems);
  };

  const setFilter = (filter: any, value: any) => {
    const prevFilters: any = {};
    searchParams.forEach((val, key) => {
      prevFilters[key] = val;
    });
    setSearchParams({ ...prevFilters, [filter]: value });
  };

  const validationSchema = Yup.object().shape({
    searchTerm: Yup.lazy(() => Yup.string().required("Text Required")),
  });

  const noNfts = (
    <>
      <h3 className="no-result-h2" style={{ fontWeight: "normal" }}>
        Oops! No Nfts found. Head to our explore page for exciting discoveries.
      </h3>
      <button onClick={() => navigate("/explore")} className="btn-main ml-auto">
        {" "}
        Explore Now
      </button>
    </>
  );

  useEffect(() => {
    handleFetchNewNfts();
  }, [sortItems, tagsModified]);

  return (
    <div>
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb">
          <div className="container custom-container">
            <h2 className="style-2">Search Results</h2>
            <div className="search xs-only d-flex mb-4">
              <input
                type="text"
                name="searchTerm"
                id="quick_search"
                defaultValue={searchParams.get("search") || ""}
                className="form-control mb-0 me-2"
                placeholder="Search..."
                onChange={(e: any) => {
                  setSearchParams({
                    search: e.target.value,
                  });
                }}
              />

              <button
                className="btn-main ml-auto"
                style={{ width: "80px" }}
                onClick={() => handleFetchNewNfts()}
              >
                Apply
              </button>
            </div>
            <div className="search-section">
              <div className="filters desktop-accord">
                <div className="top">
                  <div className="filter-icon">
                    <i className="fa-solid fa-filter"></i>
                  </div>
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <h6>Filters</h6>
                    <button
                      className="btn-main ml-auto"
                      onClick={() => handleFetchNewNfts()}
                    >
                      Apply
                    </button>
                  </div>
                  {/* <span className="text-purple f-12">close</span> */}
                </div>

                <Accordion alwaysOpen defaultActiveKey={["0", "1", "4"]}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Price</Accordion.Header>
                    <Accordion.Body>
                      <div className="filter-inputs">
                        {/* <select name="" id="">
                          <option value="eth">ETH</option>
                          <option value="btc">BTC</option>
                        </select> */}
                        <input
                          value={searchParams.get("minPrice")!}
                          type="number"
                          placeholder="MIN"
                          onChange={(e) =>
                            setFilter("minPrice", e.target.value)
                          }
                        />
                        <span>to</span>
                        <input
                          value={searchParams.get("maxPrice")!}
                          type="number"
                          placeholder="MAX"
                          onChange={(e) =>
                            setFilter("maxPrice", e.target.value)
                          }
                        />
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  {filterOptions.map((f, i) => {
                    const { type, options } = f;
                    return (
                      <Accordion.Item eventKey={`${i + 1}`} key={i}>
                        <Accordion.Header>{type}</Accordion.Header>
                        <Accordion.Body>
                          {options.map((m, index) => {
                            const { choice, selected, value } = m;
                            return (
                              <div key={index}>
                                <span>{choice}</span>
                                <input
                                  type="checkbox"
                                  name={type}
                                  onChange={(e) =>
                                    handleCheckboxSelection(e, value)
                                  }
                                  checked={selected}
                                />
                              </div>
                            );
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    );
                  })}
                </Accordion>

                <button
                  className="btn-main w-100"
                  onClick={() => handleFetchNewNfts()}
                >
                  Apply
                </button>
              </div>

              <div className="filter-content">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="desktop filter-right">
                    {sortItems.map((sortItem, i) => {
                      const { type, active } = sortItem;
                      return (
                        <h6
                          key={i}
                          className={active ? "active" : ""}
                          onClick={() => handleSortItems(type)}
                        >
                          {type}
                        </h6>
                      );
                    })}
                  </div>
                  <div className="mobile-filter-right">
                    <Dropdown>
                      <Dropdown.Toggle className="btn-main" id="dropdown-basic">
                        Sort
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="filter-right">
                          {sortItems.map((sortItem, i) => {
                            const { type, active } = sortItem;
                            return (
                              <h6
                                key={i}
                                className={active ? "active" : ""}
                                onClick={() => handleSortItems(type)}
                              >
                                {type}
                              </h6>
                            );
                          })}
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="mobile-filter">
                    <Dropdown>
                      <Dropdown.Toggle className="btn-main" id="dropdown-basic">
                        Filters
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="filters">
                          <Accordion className="mobile-accord">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>Price</Accordion.Header>
                              <Accordion.Body>
                                <div className="filter-inputs">
                                  {/* <select name="" id="">
                                    <option value="ETH">ETH</option>
                                    <option value="MATCH">MATIC</option>
                                  </select> */}
                                  <input
                                    value={searchParams.get("minPrice")!}
                                    type="number"
                                    placeholder="MIN"
                                    onChange={(e) =>
                                      setFilter("minPrice", e.target.value)
                                    }
                                  />
                                  <span>to</span>
                                  <input
                                    value={searchParams.get("maxPrice")!}
                                    type="number"
                                    placeholder="MAX"
                                    onChange={(e) =>
                                      setFilter("maxPrice", e.target.value)
                                    }
                                  />
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>

                            {filterOptions.map((f, i) => {
                              const { type, options } = f;
                              return (
                                <Accordion.Item eventKey={`${i + 1}`} key={i}>
                                  <Accordion.Header>{type}</Accordion.Header>
                                  <Accordion.Body>
                                    {options.map((m, index) => {
                                      const { choice, selected, value } = m;
                                      return (
                                        <div key={index}>
                                          <span>{choice}</span>
                                          <input
                                            type="checkbox"
                                            name={type}
                                            onChange={(e) =>
                                              handleCheckboxSelection(e, value)
                                            }
                                            checked={selected}
                                          />
                                        </div>
                                      );
                                    })}
                                  </Accordion.Body>
                                </Accordion.Item>
                              );
                            })}
                          </Accordion>
                          <button
                            className="btn-main w-100"
                            onClick={() => handleFetchNewNfts()}
                          >
                            Apply
                          </button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>

                <div className="filter-tags">
                  {tags.map((t, i) => {
                    return (
                      <div key={i}>
                        {t.choice}
                        <span
                          className="ms-2"
                          onClick={() => removeTag(t.value)}
                        >
                          <i className="fa-solid fa-xmark"></i>
                        </span>
                      </div>
                    );
                  })}
                  {tags.length !== 0 && (
                    <span
                      className="f-12 text-purple"
                      style={{ lineHeight: "24px" }}
                      onClick={clearTags}
                    >
                      Clear All
                    </span>
                  )}
                </div>

                <div className="mt-4">
                  <h2 className="header f-36">NFTs</h2>
                </div>
                <NftGrid
                  list={nfts.nfts}
                  cardClassName="col-lg-3 col-md-4 col-6"
                  loading={nfts?.loading}
                  noNftComponent={noNfts}
                  showLoadMore={true}
                  fetchMore={() => handleFetchNewNfts()}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SearchPage;
