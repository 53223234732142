import { User } from "./user";

export type Order = {
  id?: string;
  hash: string;
  amount: number;
  endAmount?: number;
  nft: string;
  nftId: string;
  buyerAddress: string;
  buyer: User;
  seller: User;
  sellerAddress: string;
  type: OrderType;
  createdAt: string;
  status: OrderStatus;
  updatedAt: string;
  quantity: number;
  startTime: string;
  endTime: string;
};

export interface OrderAttrs {
  hash: string;
  status: OrderStatus;
  type: OrderType;
  amount: number;
  endAmount?: number;
  quantity: number;
  nftId: string;
  buyerAddress: string;
  sellerAddress: string;
  startTime: string;
  endTime: string;
}

export enum OrderType {
  AUCTION = "auction",
  DUTCH_AUCTION = "auction_dutch",
  OFFER = "offer",
  SALE = "sale",
}
export enum OrderStatus {
  CREATED = "created",
  CANCELLED = "cancelled",
  FULFILLED = "fulfilled",
  UNFULFILLED = "unfulfilled",
  ACCEPTED = "accepted",
}

export interface OrderSearchParams {
  type?: OrderType;
  page?: number;
  page_size?: number;
}
