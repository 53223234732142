import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useActions, useForceSwitchNetwork } from "../../../hooks";
import { burn } from "../../../services/seaport.service";
import { getNftSmallUrl } from "../../../models/nft";

const BurnNftModal = ({ show, handleClose, nft }: any) => {
  const [loading, setLoading] = useState(false);
  const { isCorrectNetwork, checkNetwork } = useForceSwitchNetwork(nft);
  const { burnNft } = useActions();
  async function burnThis() {
    const _isCorrectNetwork = await checkNetwork();

    if (!nft || !_isCorrectNetwork) return;
    setLoading(true);

    try {
      if (nft?.tokenId) {
        const transaction = await burn(nft!);
        await transaction.wait();
        setLoading(false);
      }

      await burnNft(nft?.id, () => {
        handleClose(true);
      });
    } catch (e: any) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      toast.error("Burning Failed");
    }
  }

  return (
    <Modal
      className="custom modal-bg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title className="">Mint Now</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mt-2 mb-3 offer-wallet-body">
          <div className="modal-nft-img w-auto">
            {" "}
            <img
              src={getNftSmallUrl(nft?.nftMetaData?.thumbnail_image)}
              className="sell-this-nft"
              alt=""
            />
            <p className="f-16 mt-1 mb-1 f-700">
              <span className="text-gray me-1">Qty:</span>1
            </p>
          </div>
          <div className="ms-1">
            <h2 className="mb-0">{nft?.name}</h2>
            <p className="f-11 mb-2">
              Creator:{" "}
              <span className="text-purple me-4">{nft?.creator?.username}</span>{" "}
              <span className="text-purple">{nft?.collection?.name}</span>
            </p>
            <div className="mb-4">
              <p className="f-600 mt-3 mb-1">
                Are you sure you want to {nft?.tokenId ? "burn" : "delete"} this
                NFT?
              </p>
              <p className="f-12 f-300">This action cannot be undone.</p>
            </div>
            {loading ? (
              <ThreeDots
                height="20"
                width="60"
                color="#8364e2"
                ariaLabel="loading"
              />
            ) : (
              <button className="btn-red solid" onClick={() => burnThis()}>
                <b> {nft?.tokenId ? "Burn" : "Delete"} Now </b>
              </button>
            )}
            <p className="f-12 f-300">{""}</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BurnNftModal;
