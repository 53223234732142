import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PolygonInfo = (props: any) => {
  const tooltip = (
    <Tooltip>
      Polygon is a scaling solution for Ethereum supporting fast and low-cost
      transactions for decentralized applications on its network.
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={tooltip}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default PolygonInfo;
