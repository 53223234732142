import { fetchBalance } from "@wagmi/core";
import { useEffect, useState } from "react";
import FadeLoader from "react-spinners/ClipLoader";
import { useAccount, useNetwork } from "wagmi";
import { blockChainData, chainIdMap } from "../../../api/modules/nft";
import { useActions, useSelector } from "../../../hooks";

const WalletBalance = () => {
  const account = useAccount();
  const [balanceUSD, setBalanceUSD] = useState("0");
  const [wrappedCoin, setWrappedCoin] = useState<any>();
  const [coin, setCoin] = useState<any>();
  const [wBalanceUSD, setWBalanceUSD] = useState("0");
  const [pageLoading, setPageLoading] = useState(false);
  const { chain, chains } = useNetwork();
  const { conversions, showWrapCurrencyModal } = useSelector(
    (state: any) => state.web3
  );
  const { setShowWrapCurrencyModal } = useActions();

  useEffect(() => {
    setWrappedCoin(null);
    setBalanceUSD("0");
    setWBalanceUSD("0");
    setCoin(null);
    if (!chain || chain?.unsupported) return;
    const chainName = chain ? chainIdMap[chain!.id] : "";
    const chainData = blockChainData[chainName];
    setPageLoading(true);

    fetchBalance({
      address: account.address!,
      chainId: chain.id,
    })
      .then((coin) => {
        setCoin(coin);
        if (!coin || !conversions.USD) return;

        const coinBalance =
          parseFloat(coin ? coin.formatted : "0") /
          conversions.USD[coin.symbol];

        const coinUSDBal = coinBalance.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        setBalanceUSD(coinUSDBal);
      })
      .finally(() => {
        setPageLoading(false);
      });
  }, [chain?.id, conversions.USD]);

  useEffect(() => {
    if (!chain || chain?.unsupported) return;
    const chainName = chain ? chainIdMap[chain!.id] : "";
    const chainData = blockChainData[chainName];
    if (!chainData) return;
    setPageLoading(true);

    fetchBalance({
      address: account.address!,
      token: chainData.wrappedCoin,
    })
      .then((wrappedCoin) => {
        if (!wrappedCoin || !conversions.USD) return;

        setWrappedCoin(wrappedCoin);
        const wrappedCoinBal =
          parseFloat(wrappedCoin ? wrappedCoin.formatted : "0") /
          conversions.USD[wrappedCoin.symbol];

        const wrappedCoinUSDBal = wrappedCoinBal.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        setWBalanceUSD(wrappedCoinUSDBal);
      })
      .finally(() => {
        setPageLoading(false);
      });
  }, [conversions.USD]);

  const truncateAddress = (address?: string) => {
    if (!address) return "No Account";
    const match = address.match(
      /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
    );
    if (!match) return address;
    return `${match[1]}…${match[2]}`;
  };

  return (
    <div className="popshow">
      <div className="d-name">
        <div className="d-wallet mb-4">
          <h4 className="d-none d-md-block">My Wallet</h4>
          <span id="wallet" className="d-wallet-address">
            {truncateAddress(account.address)}
          </span>
        </div>
      </div>
      {!pageLoading ? (
        <div className="d-balance mb-4">
          <h4>Balance</h4>
          {!isNaN(coin?.formatted) && (
            <span>
              {Number(coin.formatted).toLocaleString(undefined, {
                maximumFractionDigits: 5,
              }) +
                " " +
                coin?.symbol}
            </span>
          )}
          <br />
          <span> ${balanceUSD} USD</span>
          <br />
          <br />
          {wrappedCoin && !isNaN(wrappedCoin?.formatted) && (
            <>
              <span>
                {Number(wrappedCoin?.formatted).toLocaleString(undefined, {
                  maximumFractionDigits: 5,
                }) || 0}{" "}
                {wrappedCoin?.symbol}
              </span>
              <br />
              <span> ${wBalanceUSD} USD</span>
            </>
          )}
        </div>
      ) : (
        <div className="loader">
          <FadeLoader
            loading={pageLoading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
      <button
        className="btn-main"
        onClick={() => setShowWrapCurrencyModal(true)}
      >
        Wrap {coin?.symbol}
      </button>
    </div>
  );
};

export default WalletBalance;
