import moment from "moment";
import { Link } from "react-router-dom";

const Footer = () => (
  <footer className="footer-light">
    <div className="container">
      <div className="row">
        <div className="col-md-3 col-sm-4 col-xs-1">
          <div className="widget">
            <img
              src="/img/zingitwhite.svg"
              style={{ height: "40px", marginBottom: "10px" }}
              className="img-fluid d-3"
              alt="ZingIt"
            />
            <p>
              ZingIt seeks to expand NFTs to make them available and accessible
              for all. We want them to become a part of everyday life.
            </p>
          </div>
        </div>
        <div className="col-md-2 col-sm-4 col-xs-1">
          <div className="widget">
            <h5>Marketplace</h5>
            <ul>
              <li>
                <Link to="/explore">All NFTs</Link>
              </li>
              {/* <li> 
              
                <Link to=''>Art</Link>
              </li>
              <li>
                <Link to=''>Music</Link>
              </li>
              <li>
                <Link to=''>Domain Names</Link>
              </li>
              <li>
                <Link to=''>Virtual World</Link>
              </li>
              <li>
                <Link to=''>Collectibles</Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-md-2 col-sm-4 col-xs-1">
          <div className="widget">
            <h5>My Account</h5>
            <ul>
              <li>
                <Link to="profile">My Profile</Link>
              </li>
              <li>
                <Link to="">My Settings</Link>
              </li>
              {/* <li>
                <Link to=''>Suggestions</Link>
              </li>
              <li>
                <Link to=''>Discord</Link>
              </li>
              <li>
                <Link to=''>Docs</Link>
              </li>
              <li>
                <Link to=''>Newsletter</Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-md-2 col-sm-4 col-xs-1">
          <div className="widget">
            <h5>Resources</h5>
            <ul>
              <li>
                <Link to="">Help</Link>
              </li>
              <li>
                <Link to="">Blog</Link>
              </li>
              {/* <li>
                <Link to=''>Brand Assets</Link>
              </li>
              <li>
                <Link to=''>Blog</Link>
              </li>
              <li>
                <Link to=''>Forum</Link>
              </li>
              <li>
                <Link to=''>Mailing List</Link>
              </li> */}
            </ul>
          </div>
        </div>
        <div className="col-md-2 col-sm-4 col-xs-1">
          <div className="widget">
            <h5>Company</h5>
            <ul>
              <li>
                <Link to="">About Us</Link>
              </li>
              <li>
                <Link to="">Careers</Link>
              </li>
              <li>
                <Link to="">Why We're Different</Link>
              </li>
              {/* <li>
                <Link to=''>Brand Assets</Link>
              </li>
              <li>
                <Link to=''>Blog</Link>
              </li>
              <li>
                <Link to=''>Forum</Link>
              </li>
              <li>
                <Link to=''>Mailing List</Link>
              </li> */}
            </ul>
          </div>
          {/* <div className='widget'>
            <h5>Company</h5>
            <p>
              Signup for our newsletter to get the latest news in your inbox.
            </p>
            <form
              action='#'
              className='row form-dark'
              id='form_subscribe'
              method='post'
              name='form_subscribe'
            >
              <div className='col text-center'>
                <input
                  className='form-control'
                  id='txt_subscribe'
                  name='txt_subscribe'
                  placeholder='enter your email'
                  type='text'
                />
                <Link to='' id='btn-subscribe'>
                  <i className='arrow_right bg-color-secondary'></i>
                </Link>
                <div className='clearfix'></div>
              </div>
            </form>
            <div className='spacer-10'></div>
            <small>Your email is safe with us. We don't spam.</small>
          </div> */}
        </div>
      </div>
    </div>
    <div className="subfooter">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="de-flex">
              <div className="de-flex-col">
                <span onClick={() => window.open("", "_self")}>
                  <span className="copy">
                    &copy; Copyright {moment().year()} - ZingIt Marketplace
                  </span>
                </span>
              </div>
              <div className="de-flex-col">
                <div className="social-icons">
                  {/* <span onClick={() => window.open('', '_self')}>
                    <i className='fa fa-facebook fa-lg'></i>
                  </span> */}
                  <span style={{ marginRight: 10 }}>
                    <Link to="/privacy">Privacy Policy</Link>
                  </span>
                  {/* <div style={{ marginRight: 20 }} /> */}
                  <span
                  // onClick={() =>
                  //   window.open(
                  //     'https://www.linkedin.com/company/ZingIt',
                  //     '_blank'
                  //   )
                  // }
                  >
                    <Link to="/tos">Terms of Service</Link>
                  </span>
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-facebook fa-lg"></i>
                  </span>
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-twitter fa-lg"></i>
                  </span>
                  <span onClick={() => window.open("", "_self")}>
                    <i className="fa fa-instagram fa-lg"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
export default Footer;
