import { Event } from "../../models/event";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface EventsState {
  loading: boolean;
  error: string | null;
  events: {
    total: number;
    events: Event[] | null;
    append?: boolean;
    loading: boolean;
  };
  nftEvents: {
    total: number;
    events: Event[] | null;
    append?: boolean;
    loading: boolean;
  };
  userEvents: {
    total: number;
    events: Event[] | null;
    append?: boolean;
    loading: boolean;
  };
}

const initialState = {
  loading: false,
  error: null,
  events: {
    total: 0,
    events: [],
    append: false,
    loading: false,
  },
  nftEvents: {
    total: 0,
    events: [],
    append: false,
    loading: false,
  },
  userEvents: {
    total: 0,
    events: [],
    append: false,
    loading: false,
  },
};

const reducer = (
  state: EventsState = initialState,
  action: Action
): EventsState => {
  switch (action.type) {
    case ActionType.FETCH_EVENTS:
      return {
        ...state,
        loading: true,
        error: null,
        events: { ...state.events, loading: true },
      };
    case ActionType.FETCH_EVENTS_SUCCESS:
      if (action.payload.append) {
        return {
          ...state,
          error: null,
          events: {
            events: [...state.events.events!, ...action.payload.events!],
            total: action.payload.total,
            loading: false,
          },
        };
      } else
        return {
          ...state,
          loading: false,
          error: null,
          events: { ...action.payload, loading: false },
        };
    case ActionType.FETCH_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        events: { ...state.events, loading: false },
      };
    case ActionType.FETCH_NFT_EVENTS:
      return {
        ...state,
        loading: true,
        error: null,
        nftEvents: { ...state.nftEvents, loading: true },
      };
    case ActionType.FETCH_NFT_EVENTS_SUCCESS:
      if (action.payload.append) {
        return {
          ...state,
          error: null,
          nftEvents: {
            events: [...state.nftEvents.events!, ...action.payload.events!],
            total: action.payload.total,
            loading: false,
          },
        };
      } else
        return {
          ...state,
          loading: false,
          error: null,
          nftEvents: { ...action.payload, loading: false },
        };
    case ActionType.FETCH_NFT_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        nftEvents: { ...state.nftEvents, loading: false },
        error: action.payload,
      };
    case ActionType.FETCH_USER_EVENTS:
      return {
        ...state,
        loading: true,
        userEvents: { ...state.userEvents, loading: true },
        error: null,
      };
    case ActionType.FETCH_USER_EVENTS_SUCCESS:
      if (action.payload.append) {
        return {
          ...state,
          error: null,
          userEvents: {
            events: [...state.userEvents.events!, ...action.payload.events!],
            total: action.payload.total,
            loading: false,
          },
        };
      } else
        return {
          ...state,
          loading: false,
          error: null,
          userEvents: { ...action.payload, loading: false },
        };
    case ActionType.FETCH_USER_EVENTS_ERROR:
      return {
        ...state,
        loading: false,
        userEvents: { ...state.userEvents, loading: false },
        error: action.payload,
      };
    case ActionType.CLEAR_EVENTS:
      return {
        ...state,
        error: null,
        events: {
          total: 0,
          events: [],
          loading: false,
        },
        nftEvents: {
          total: 0,
          events: [],
          loading: false,
        },
        userEvents: {
          total: 0,
          events: [],
          loading: false,
        },
      };
    default:
      return state;
  }
};

export default reducer;
