import { useEffect, useState } from "react";

export const useDisplayPrice = (
  price = 0,
  maxDecimalPlaces = 6,
  maximumPrice = 1000000
) => {
  const [displayPrice, setDisplayPrice] = useState("0");

  useEffect(() => {
    if (!price || Number.isNaN(price)) {
      setDisplayPrice("0");
      return;
    }

    //this was an attempt to switch to scientific notation when the price is too low
    const _isOverMaxDecimals = (number: any) => {
      const string = number.toString();
      const decimalIndex = string.indexOf(".");
      if (decimalIndex === -1) {
        return false; // the number has no decimal points
      }
      return string.substring(decimalIndex + 1).length > maxDecimalPlaces;
    };

    const roundedPrice = parseFloat(Number(price).toFixed(5));

    setDisplayPrice((roundedPrice || 0).toString());
  }, [price, maxDecimalPlaces, maximumPrice]);

  return [displayPrice, setDisplayPrice];
};
