import { keyframes } from "@emotion/react";
import { memo } from "react";
import Reveal from "react-awesome-reveal";
import { useNavigate } from "react-router-dom";
import { NFT, getNftSmallUrl } from "../../models/nft";

const FeaturedCollections = ({ collections: featuredCollections }: any) => {
  const navigate = useNavigate();

  const inline = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
.d-inline{
  display: inline-block;
 }
`;

  return (
    <Reveal
      className="onStep d-inline mt-auto xs-hide"
      keyframes={inline}
      delay={900}
      duration={1200}
      triggerOnce
    >
      {featuredCollections.collections[0] && (
        <div>
          <div className="spacer-single"></div>
          <div className="jumbo-imgs home_custom">
            {featuredCollections.collections[0].nfts.map(
              (x: NFT, index: any) => (
                <div
                  key={index}
                  className="featured_btn"
                  onClick={() => navigate(`/item/${x.id}`)}
                >
                  <img
                    alt={x?.nftMetaData?.name}
                    src={getNftSmallUrl(x.nftMetaData.thumbnail_image!)}
                  />
                </div>
              )
            )}
            <div
              className="text-left border-0 home-dads"
              onClick={() =>
                navigate(
                  `/collection?id=${featuredCollections.collections[0].id}`
                )
              }
            >
              <div className="profile-img">
                {
                  <img
                    alt={featuredCollections.collections[0].names}
                    src={featuredCollections.collections[0].logo}
                  />
                }
              </div>
              <p className="mb-0 text-purple">
                {featuredCollections.collections[0].name}
              </p>
            </div>
          </div>
        </div>
      )}
    </Reveal>
  );
};
export default memo(FeaturedCollections);
