import { memo, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { useActions, useSelector } from "../../hooks";
import Footer from "../Partials/Footer";

import FadeLoader from "react-spinners/ClipLoader";
import HelmetMetadata from "../Partials/HelmetMetadata";
import NftActivities from "../Partials/NftActivities";
import NftCollectionList from "../Partials/NftCollectionList";
import NftProperties from "../Partials/NftDetailProperties";
import NftDetails from "../Partials/NftDetails";
import NftDisplay from "../Partials/NftDisplay";
import NftOffers from "../Partials/NftOffers";
import NftInfo from "../Partials/NftInfo";
import { Breakpoint } from "react-socks";
import NftListings from "../Partials/NftListings";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    border-bottom: solid 1px #ccc !important;
  }
  .mr40{
    margin-right: 40px;
  }
  .mr15{
    margin-right: 15px;
  }
  .btn2{
    background: #f6f6f6;
    color: #333 !important;
  }
  .liked{
    color:red;
  }
  .eth_cost{
    font-size:42px
  }
`;

const NftDetail = () => {
  const { nft, stats } = useSelector((state) => state.nfts);
  const { user } = useSelector((state) => state.auth);
  const { fetchNft, fetchNftPageViews } = useActions();
  const [pageLoading, setPageLoading] = useState(true);
  let { id: nftId } = useParams();
  const displayRef: any = useRef();

  useEffect(() => {
    window.document.title = "ZingIt - Details";
    if (nft) window.document.title = `ZingIt - ${nft.name}`;
  }, [nft]);

  useEffect(() => {
    handleFetchNft();
  }, [user?.id]);

  const handleFetchNft = async () => {
    setPageLoading(true);
    if (nftId) {
      fetchNft(nftId, () => {
        setTimeout(() => {
          setPageLoading(false);
        }, 1000);
      });
      fetchNftPageViews(nftId);
    }
  };

  if (pageLoading)
    return (
      <div className="loader">
        <FadeLoader
          loading={pageLoading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );

  return nft?.nftMetaData ? (
    <div>
      <HelmetMetadata nft={nft} />
      <GlobalStyles />
      <>
        <Breakpoint medium up>
          <section className="container item_detail_page">
            <div className="row mt-md-5">
              <div className="col-md-5">
                <NftDisplay
                  displayRef={displayRef}
                  nftFile={nft.nftMetaData.animation_url}
                  className="img-fluid img-rounded mb-sm-30 detail_img"
                  fileType={nft.nftMetaData.file_type}
                  previewImageFile={nft.nftMetaData.thumbnail_image}
                />
                <div
                  className="addthis_inline_share_toolbox"
                  data-url={`${window.location.host}/item/${nftId}`}
                  data-title={`ZingIt - ${nft.name}`}
                ></div>
                <NftProperties nft={nft} />
                <NftDetails nft={nft} />
              </div>
              <div className="col-md-7">
                <NftInfo nft={nft} stats={stats} />

                <NftOffers nft={nft} user={user!} refetch={handleFetchNft} />
                <NftListings nft={nft} user={user!} refetch={handleFetchNft} />
                <NftActivities nft={nft} user={user!} />
                {/* <NftPriceHistory nft={nft} /> */}
                <NftCollectionList nft={nft} />
              </div>
            </div>
          </section>
        </Breakpoint>
        <Breakpoint small down>
          <NftDisplay
            displayRef={displayRef}
            nftFile={nft.nftMetaData.animation_url}
            className="img-fluid img-rounded mb-sm-30 detail_img w-100 mb-0"
            fileType={nft.nftMetaData.file_type}
            previewImageFile={nft.nftMetaData.thumbnail_image}
          />
          <div
            className="addthis_inline_share_toolbox"
            data-url={`${window.location.host}/item/${nftId}`}
            data-title={`ZingIt - ${nft.name}`}
          ></div>
          <div className="mx-2">
            <NftInfo nft={nft} stats={stats} />
            <NftProperties nft={nft} />
            <NftDetails nft={nft} />
            <NftOffers nft={nft} user={user!} refetch={handleFetchNft} />
            <NftListings nft={nft} user={user!} refetch={handleFetchNft} />
            <NftActivities nft={nft} user={user!} />
            <NftCollectionList nft={nft} />
          </div>
        </Breakpoint>
      </>
      <Footer />
    </div>
  ) : (
    <></>
  );
};

export default memo(NftDetail);
