import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MumbaiInfo = (props: any) => {
  const tooltip = (
    <Tooltip>
      Mumbai Testnet is a Polygon test environment for risk-free trials and
      blockchain development using test tokens without monetary value.
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={tooltip}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default MumbaiInfo;
