import { ExternalProvider } from "@ethersproject/providers";
import { Seaport } from "@opensea/seaport-js";
import { ItemType } from "@opensea/seaport-js/lib/constants";
import {
  CreateOrderInput,
  OrderComponents,
} from "@opensea/seaport-js/lib/types";
import { BigNumber, ethers } from "ethers";
import ERC20_ABI from "../blockchain/artifacts/contracts/Erc20.abi.json";
import ZingItArt721 from "../blockchain/artifacts/contracts/ZingItArt721.json";
import ZingItTradable1155 from "../blockchain/artifacts/contracts/ZingItTradable1155.json";
import { BASE_URL } from "../components/Partials/constants";
import { NFT, NftType } from "../models/nft";
import { n18 } from "../utils/formatter";
import { blockChainData } from "../api/modules/nft";
window.Buffer = window.Buffer || require("buffer").Buffer;
//TODO: This was copied from the seaport docs, but it's not working
type OrderWithSignature = {
  parameters: OrderComponents;
  signature: string;
};

export const SeaportAddress = "0x00000000006c3852cbef3e08e8df289169ede581";
export interface TokenData {
  address: string;
  tokenId: string;
  type: ItemType;
  quantity: number;
}

const initializeSeaport = () => {
  if (!window.ethereum) return;
  const provider = new ethers.providers.Web3Provider(
    window.ethereum as ExternalProvider
  );

  const seaport = new Seaport(provider);
  return seaport;
};

export const sellAtPrice = async (
  nft: NFT,
  tokenData: TokenData,
  price: BigNumber | string,
  startTime?: number,
  endTime?: number
): Promise<OrderWithSignature> => {
  const signer = await getSigner().getAddress();
  const seaport = initializeSeaport();
  const contract = getContract(nft);

  const startAmount = n18(price.toString());
  const royaltyInfo = await contract.royaltyInfo(
    tokenData.tokenId,
    startAmount
  );
  const treasuryAddress = await contract.treasuryAddress();
  const serviceFeePercentage = await contract.serviceFee();
  const feesBasesPoints = serviceFeePercentage * 100;

  const royaltyBasesPoints =
    getRoyaltyPercentage(startAmount, royaltyInfo.royaltyAmount) * 100;

  let fees = [
    {
      basisPoints: feesBasesPoints,
      recipient: treasuryAddress,
    },
  ];

  if (
    royaltyInfo?.receiver !== signer &&
    royaltyInfo.receiver !== ethers.constants.AddressZero &&
    royaltyBasesPoints > 0
  ) {
    fees.push({
      recipient: royaltyInfo?.receiver,
      basisPoints: royaltyBasesPoints,
    });
  }

  const orderObj: CreateOrderInput = {
    startTime: ethers.BigNumber.from(startTime?.toString() || "0").toString(),
    endTime: ethers.BigNumber.from(endTime?.toString() || "0").toString(),
    allowPartialFills: true,
    offer: [
      tokenData.type === ItemType.ERC721
        ? {
            itemType: ItemType.ERC721,
            token: tokenData.address,
            identifier: tokenData.tokenId,
            amount: tokenData.quantity.toString(),
          }
        : {
            itemType: ItemType.ERC1155,
            token: tokenData.address,
            identifier: tokenData.tokenId,
            amount: tokenData.quantity.toString(),
          },
    ],
    consideration: [
      {
        amount: startAmount.toString(),
        recipient: signer,
      },
    ],
    fees: fees,
  };
  const { executeAllActions } = await seaport!.createOrder(orderObj, signer);
  return executeAllActions();
};

export const startAuction = async (
  nft: NFT,
  tokenData: TokenData,
  highAmount: string,
  lowAmount: string,
  startTime: number,
  endTime: number
): Promise<OrderWithSignature> => {
  if (highAmount < lowAmount)
    throw new Error("High amount must be greater than low amount");
  if (tokenData.quantity > 1)
    throw new Error("Auctions not supported on NFT's with multiple supply");

  const signer = await getSigner().getAddress();
  const seaport = initializeSeaport();
  const startAmount = n18(highAmount);
  const endAmount = n18(lowAmount);

  const contract = getContract(nft);

  const treasuryAddress = await contract.treasuryAddress();

  const serviceFeePercentage = await contract.serviceFee();
  const feesBasesPoints = serviceFeePercentage * 100;
  const royaltyInfo = await contract.royaltyInfo(
    tokenData.tokenId,
    startAmount
  );

  const royaltyBasesPoints =
    getRoyaltyPercentage(startAmount, royaltyInfo.royaltyAmount) * 100;

  let fees = [
    {
      basisPoints: feesBasesPoints,
      recipient: treasuryAddress,
    },
  ];

  if (
    royaltyInfo?.receiver !== signer &&
    royaltyInfo?.receiver !== ethers.constants.AddressZero &&
    royaltyBasesPoints > 0
  ) {
    fees.push({
      recipient: royaltyInfo?.receiver,
      basisPoints: royaltyBasesPoints,
    });
  }
  const wrappedEthAddress = blockChainData[nft?.blockchain]?.wrappedCoin;

  const { executeAllActions } = await seaport!.createOrder(
    {
      startTime: ethers.BigNumber.from(startTime?.toString() || "0").toString(),
      endTime: ethers.BigNumber.from(endTime?.toString() || "0").toString(),
      offer: [
        {
          itemType: ItemType.ERC1155,
          token: tokenData.address,
          identifier: tokenData.tokenId,
          amount: tokenData.quantity.toString(),
        },
      ],
      consideration: [
        {
          token: wrappedEthAddress,
          amount: startAmount.toString(),
          endAmount: endAmount.toString(),
          recipient: signer,
        },
      ],
      fees: fees,
    },
    signer
  );
  return executeAllActions();
};

function getSigner() {
  const provider = new ethers.providers.Web3Provider(
    window.ethereum as ExternalProvider
  );
  const signer = provider.getSigner();
  return signer;
}
const getContract = (nft: NFT) => {
  const signer = getSigner();
  let jsonFile: any;
  switch (nft.type) {
    case NftType.ZingItArt721:
      jsonFile = ZingItArt721;
      break;
    case NftType.ZingItTradable1155:
      jsonFile = ZingItTradable1155;
      break;
    default:
      break;
  }
  const contract = new ethers.Contract(
    jsonFile.networks[nft.blockchain].address,
    jsonFile.abi,
    signer
  );
  return contract;
};

export const lazyMint = async (
  nft: NFT,
  quantity: number
): Promise<ethers.ContractTransaction> => {
  const contract = getContract(nft);
  let serviceFee = await contract.serviceFee();
  const signer = getSigner();
  const nftPriceWei = n18(nft!.price.toString());

  // Estimate gas for the transaction
  const data = {
    receiver: signer.getAddress(),
    tokenUri: nft.tokenURI || `${BASE_URL}/nft/${nft.id}/metadata`,
    dbId: nft.id,
    creator: nft.creator?.walletAddress,
    price: nftPriceWei,
    supply: nft.supply,
    royaltyFee: nft.royaltyPercentage ? nft.royaltyPercentage * 100 : 0,
    amountPurchased: quantity,
  };

  // Define the transaction data
  const txData = await contract.populateTransaction.lazyMint(data);
  // Estimate the gas limit
  const gasLimit = await signer.estimateGas({
    to: contract.address,
    data: txData.data,
    value: nftPriceWei.add(serviceFee),
  });

  // Send the transaction with the estimated gas limit
  const transaction = await signer.sendTransaction({
    to: contract.address,
    data: txData.data,
    gasLimit: gasLimit,
    value: nftPriceWei.add(serviceFee),
  });

  await transaction.wait();
  return transaction;
};

export const checkAllowance = async (
  nft: NFT,
  erc20Address: string,
  amount: BigNumber
): Promise<boolean> => {
  const contract = getContract(nft);
  const signer = await contract.signer;
  const signerAddress = await signer.getAddress();
  const erc20Contract = new ethers.Contract(erc20Address, ERC20_ABI, signer);
  const contractApproved = await erc20Contract.allowance(
    signerAddress,
    SeaportAddress
  );
  return contractApproved.gte(amount);
};
export const requestAllowance = async (
  nft: NFT,
  erc20Address: string,
  amount: BigNumber
): Promise<any> => {
  const contract = getContract(nft);
  const maxAllowance = ethers.constants.MaxUint256;
  const signer = await contract.signer;
  const signerAddress = await signer.getAddress();
  const erc20Contract = new ethers.Contract(erc20Address, ERC20_ABI, signer);
  const contractApproved = await erc20Contract.allowance(
    signerAddress,
    SeaportAddress
  );
  if (!contractApproved.gte(amount)) {
    const approveTx = await erc20Contract.approve(SeaportAddress, maxAllowance);
    return approveTx;
  }
  return null;
};

export const burn = async (nft: NFT): Promise<ethers.ContractTransaction> => {
  const contract = getContract(nft);
  let transaction = await contract.burn(nft.tokenId);
  return transaction;
};

export const mint = async (nft: NFT): Promise<ethers.ContractTransaction> => {
  const contract = getContract(nft);
  const signer = await contract.signer.getAddress();
  const nftPriceWei = n18(nft!.price.toString());
  let transaction;
  const data = {
    receiver: signer,
    tokenUri: nft.tokenURI || `${BASE_URL}/nft/${nft.id}/metadata`,
    dbId: nft.id,
    creator: signer,
    price: nftPriceWei,
    royaltyFee: nft.royaltyPercentage ? nft.royaltyPercentage * 100 : 0,
    supply: nft.supply,
    amountPurchased: nft.supply,
  };

  switch (nft.type) {
    case NftType.ZingItArt721:
      transaction = await contract.mint(data, {
        value: 0,
      });
      break;
    case NftType.ZingItTradable1155:
      transaction = await contract.mint(data, {
        value: 0,
      });
      break;
    default:
      break;
  }

  // await transaction.wait();
  return transaction;
};

function getRoyaltyPercentage(startAmount: BigNumber, feeAMount: BigNumber) {
  return (Number(feeAMount) / Number(startAmount)) * 100;
}
export const makeOffer = async (
  nft: NFT,
  tokenData: TokenData,
  price: string,
  currencyAddress: string,
  startTime?: BigNumber | string,
  endTime?: BigNumber | string
): Promise<OrderWithSignature> => {
  const seaport = initializeSeaport();
  const signer = await getSigner().getAddress();
  const offerAmount = n18(price.toString());
  const contract = getContract(nft);
  const treasuryAddress = await contract.treasuryAddress();
  const serviceFeePercentage = await contract.serviceFee();
  const royaltyInfo = await contract.royaltyInfo(
    tokenData.tokenId,
    offerAmount
  );

  const feesBasesPoints = serviceFeePercentage * 100;
  const royaltyBasesPoints =
    getRoyaltyPercentage(offerAmount, royaltyInfo.royaltyAmount) * 100;

  let treasuryFee = 0; // Initialize the treasury fee to 0
  let royaltyPayment = 0; // Initialize the royalty payment to 0

  if (feesBasesPoints > 0) {
    // Calculate the treasury fee
    treasuryFee = (feesBasesPoints * offerAmount.toNumber()) / 10000;
  }

  if (
    royaltyInfo?.receiver !== signer &&
    royaltyInfo?.receiver !== nft.creator?.walletAddress &&
    royaltyInfo.receiver !== ethers.constants.AddressZero &&
    royaltyBasesPoints > 0
  ) {
    // Calculate the royalty payment
    royaltyPayment = (royaltyBasesPoints * offerAmount.toNumber()) / 10000;
  }

  const offerAmountAfterTreasury = offerAmount.toNumber() - treasuryFee;

  const offerArray = [
    {
      amount: offerAmountAfterTreasury.toString(), // Use the adjusted offer amount
      token: currencyAddress,
    },
    {
      amount: treasuryFee.toString(), // Add the treasury fee to the offer array
      token: currencyAddress, // Assuming treasury fee is in the same currency
    },
  ];

  if (royaltyPayment > 0) {
    // Add the royalty payment to the offer array if the condition is met
    offerArray.push({
      amount: royaltyPayment.toString(),
      token: currencyAddress, // Assuming royalty payment is in the same currency
    });
  }

  const offerObj: CreateOrderInput = {
    endTime: ethers.BigNumber.from(endTime?.toString() || "0").toString(),
    startTime: ethers.BigNumber.from(startTime?.toString() || "0").toString(),
    allowPartialFills: true,
    offer: offerArray, // Use the offer array with or without royalty payment
    consideration: [
      tokenData.type === ItemType.ERC721
        ? {
            itemType: ItemType.ERC721,
            token: tokenData.address,
            identifier: tokenData.tokenId,
            recipient: signer,
          }
        : {
            itemType: ItemType.ERC1155,
            token: tokenData.address,
            identifier: tokenData.tokenId,
            amount: tokenData.quantity.toString(),
            recipient: signer,
          },
    ],
  };

  const { executeAllActions } = await seaport!.createOrder(offerObj, signer);
  const order = await executeAllActions();
  return order;
};

//cancels all orders and offers made by the offerer
export const cancelAllOrders =
  async (): Promise<ethers.ContractTransaction> => {
    const seaport = initializeSeaport();
    const signer = await getSigner().getAddress();
    const transaction = await seaport!.bulkCancelOrders(signer).transact();
    return transaction;
  };

//cancels orders and offers mande by the offerer for specific token(s)
export const cancelOrders = async (
  order: OrderWithSignature[]
): Promise<ethers.ContractTransaction> => {
  const parameters = order.map((o) => o.parameters);
  const seaport = initializeSeaport();
  const signer = await getSigner().getAddress();
  const transaction = await seaport!
    .cancelOrders(parameters, signer)
    .transact();
  return transaction;
};

const fulfillOrder = async (order: OrderWithSignature, quantity?: number) => {
  const seaport = initializeSeaport();
  const signer = await getSigner().getAddress();
  const { executeAllActions: executeAllFulfillActions } =
    await seaport!.fulfillOrder({
      unitsToFill: BigNumber.from(quantity || 1),
      order,
      accountAddress: signer,
    });
  return executeAllFulfillActions();
};

export const buyNow = async (
  order: OrderWithSignature,
  quantity: number
): Promise<ethers.ContractTransaction> => {
  return fulfillOrder(order, quantity);
};

export const acceptOffer = async (
  order: OrderWithSignature
): Promise<ethers.ContractTransaction> => {
  return fulfillOrder(order);
};
