import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";

const CreateCollectionModal = ({
  show,
  handleClose,
  handleCreateCollection,
}: any) => {
  const [unlockableContent, setUnlockableContent] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [logoTemp, setLogoTemp] = useState<any | null>("");

  const handleImage = (event: any, type?: string) => {
    let file = event.target.files[0];

    let reader = new FileReader();
    reader.onloadend = () => {
      setImage(file);
      setLogoTemp(reader?.result);
    };
    reader.readAsDataURL(file);
  };
  return (
    <Modal className="custom modal-bg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="">Create Collection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-between mb-4">
          <h5 className="mb-0 f-300">
            Collection Name <small className="text-danger">*</small>
          </h5>
          <input
            type="text"
            name="item_title"
            id="item_title"
            className="form-control"
            onChange={(event) => setUnlockableContent(event.target.value)}
          />
          <div className="col-xs-12 col-md-6">
            <h3 className="f-22">Image</h3>
            <p className="text-secondary f-12 f-600">
              This image will also be used for navigation. 350 x 350
              recommended.
            </p>
            <div
              className="cc-img-wrap logo w-100 mb-2"
              style={{ cursor: "pointer", border: "1px solid #ccc" }}
              onClick={() => document.getElementById("upload-image")?.click()}
            >
              <img
                className="w-100"
                src={
                  logoTemp
                    ? logoTemp
                    : "https://excellance.com/wp-content/plugins/photonic/include/images/placeholder.png"
                }
                alt=""
              />
            </div>
            <input
              name="collectionImage"
              type="file"
              id="upload-image"
              hidden
              accept="image/*"
              onChange={(event) => handleImage(event)}
            />
          </div>
          <div>
            {!loading && (
              <button
                className="btn-main"
                disabled={!unlockableContent}
                onClick={async () => {
                  setLoading(true);
                  await handleCreateCollection(unlockableContent, image);
                  setLoading(false);
                }}
              >
                CREATE COLLECTION
              </button>
            )}

            {loading && (
              <div>
                <ThreeDots
                  height="60"
                  width="60"
                  color="#8364e2"
                  ariaLabel="loading"
                />
                <p style={{ color: "#8364e2" }}>Creating Collection</p>
              </div>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateCollectionModal;
