import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import "elegant-icons/style.css";
import "et-line/style.css";
import "font-awesome/css/font-awesome.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { Helmet } from "react-helmet";
import { Provider } from "react-redux";
import { BreakpointProvider } from "react-socks";
import { WagmiConfig } from "wagmi";
import "./assets/animated.css";
import "./assets/style.scss";
import App from "./components/App";
import { wagmiConfig } from "./components/web3New";
import { store } from "./state";
import { SeaportContextProvider } from "./state/context/SeaportContext";
import MyErrorBoundary from "./components/Partials/ErrorBoundry";
import { clarity } from "react-microsoft-clarity";

const root = ReactDOM.createRoot(document.getElementById("root")!);
clarity.init(process.env.REACT_APP_CLARITY_ID || "");
if (clarity.hasStarted()) clarity.consent();
root.render(
  <React.StrictMode>
    <Helmet>
      <script
        defer
        data-domain={window.location.host}
        src="https://plausible.zing.it/js/plausible.js"
      ></script>

      <script>
        {`window.fwSettings = {'widget_id': ${
          process.env.REACT_APP_FRESHWORKS_ID || "72000002028"
        }};
           !function () { if ("function" != typeof window.FreshworksWidget) 
           { var n = function () { n.q.push(arguments) }; n.q = [], window.FreshworksWidget = n } }() 
           `}
      </script>
      <script
        type="text/javascript"
        src={`https://widget.freshworks.com/widgets/${
          process.env.REACT_APP_FRESHWORKS_ID || 72000002028
        }.js`}
        async
        defer
      ></script>
    </Helmet>
    <WagmiConfig config={wagmiConfig}>
      <Provider store={store}>
        <SeaportContextProvider>
          <BreakpointProvider>
            <MyErrorBoundary>
              <App />
            </MyErrorBoundary>
          </BreakpointProvider>
        </SeaportContextProvider>
      </Provider>
    </WagmiConfig>
  </React.StrictMode>
);
