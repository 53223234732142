import moment from "moment";
import { useEffect, useState } from "react";

const Clock = ({ deadline }: any) => {
  const [time, setTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (!deadline) return;
    getTimeUntil(deadline);
    setInterval(() => {
      getTimeUntil(deadline);
    }, 1000);
  }, [deadline]);

  const leading0 = (num: any) => {
    return num < 10 ? "0" + num : num;
  };

  const getTimeUntil = (deadline: string | Number) => {
    const deadlineDate = moment.unix(Number(deadline));
    const now = moment();

    if (Number(deadlineDate) > now.unix()) {
      const remainingTime = moment.duration(deadlineDate.diff(moment()));
      if (remainingTime.asSeconds() < 0) {
        setTime({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      } else {
        const days = Math.floor(remainingTime.asDays());
        const hours = remainingTime.hours();
        const minutes = remainingTime.minutes();
        const seconds = remainingTime.seconds();
        setTime({ days, hours, minutes, seconds });
      }
    } else {
      const remainingTime = moment.duration(now.diff(deadlineDate));
      if (remainingTime.asSeconds() > 0) {
        const days = Math.floor(remainingTime.asDays());
        const hours = remainingTime.hours();
        const minutes = remainingTime.minutes();
        const seconds = remainingTime.seconds();
        setTime({ days, hours, minutes, seconds });
      }
    }
  };

  return (
    <div>
      <div className="Clock-days">{leading0(time.days)}d</div>
      <div className="Clock-hours">{leading0(time.hours)}h</div>
      <div className="Clock-minutes">{leading0(time.minutes)}m</div>
      <div className="Clock-seconds">{leading0(time.seconds)}s</div>
    </div>
  );
};
export default Clock;
