import { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { Footer } from "..";
import { useActions, useSelector } from "../../hooks";
import { EventType } from "../../models/event";
import { DEFAULT_USER_IMAGE } from "../Partials/constants";
import { getUserSmallImageUrl } from "../../models/user";
import { getNftSmallUrl } from "../../models/nft";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.sticky.white {
    background: #403f83;
    border-bottom: solid 1px #403f83;
  }
  header#myHeader.navbar .search #quick_search{
    color: #fff;
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader.navbar.white .btn, .navbar.white a, .navbar.sticky.white a{
    color: #fff;
  }
  header#myHeader .dropdown-toggle::after{
    color: rgba(255, 255, 255, .5);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: block !important;
  }
  @media only screen and (max-width: 1199px) {
    .navbar{
      background: #403f83;
    }
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #fff;
    }
    .item-dropdown .dropdown a{
      color: #fff !important;
    }
  }
`;

const Activity = function () {
  const [page, setPage] = useState(1);
  const [percentageLoaded, setPercentageLoaded] = useState(0);

  const { fetchEvents, clearEvents } = useActions();
  const { events, total, loading } = useSelector(
    (state) => state.events.events
  );
  useEffect(() => {
    window.document.title = "ZingIt - Activity";
    fetchEvents();
    return () => {
      clearEvents();
    };
  }, []);

  const loadMore = () => {
    const newPage = page + 1;
    setPage(newPage);
    fetchEvents({ page: newPage });
  };
  const filter = (type?: EventType) => {
    const newPage = 1;
    setPage(newPage);
    fetchEvents({ types: type && [type] });
  };

  const getPercentage = () =>
    events ? Math.floor((events.length / total) * 100) : 0;

  useEffect(() => {
    setPercentageLoaded(getPercentage());
  }, [JSON.stringify(events)]);

  return (
    <div>
      <GlobalStyles />

      <section
        className="jumbotron breadcumb no-bg"
        style={{ backgroundImage: `url(${"./img/background/subheader.jpg"})` }}
      >
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h1 className="text-center">Activity</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container">
        <div className="row">
          <div className="col-md-8">
            <ul className="activity-list">
              {events &&
                events.map((evnt, index) => {
                  switch (evnt.type) {
                    case EventType.UserFollow:
                      return (
                        <li key={index} className="act_follow">
                          <img
                            className="lazy"
                            src={
                              evnt.from?.userImage
                                ? getUserSmallImageUrl(evnt.from?.userImage)
                                : DEFAULT_USER_IMAGE
                            }
                            alt=""
                          />
                          <div className="act_list_text">
                            <h4>{evnt.from?.username}</h4>
                            started following{" "}
                            <span className="color">{evnt.user.username}</span>
                            <span className="act_list_date">
                              {evnt.createdAt}
                            </span>
                          </div>
                        </li>
                      );
                    case EventType.NftTransferred:
                      return (
                        <li className="act_sale" key={index}>
                          <img
                            className="lazy"
                            src={getNftSmallUrl(
                              evnt.nft.nftMetaData.thumbnail_image!
                            )}
                            alt=""
                          />
                          <div className="act_list_text">
                            <h4>{evnt.nft.name}</h4>1 edition purchased by{" "}
                            <span className="color">{evnt.from?.username}</span>
                            for 0.02 ETH
                            <span className="act_list_date">
                              {evnt.createdAt}
                            </span>
                          </div>
                        </li>
                      );
                    case EventType.Login:
                      return (
                        <li className="act_login" key={index}>
                          <img
                            className="lazy"
                            src={
                              evnt.from?.userImage
                                ? getUserSmallImageUrl(evnt.from?.userImage)
                                : DEFAULT_USER_IMAGE
                            }
                            alt=""
                          />
                          <div className="act_list_text">
                            <h4>{evnt.from?.username}</h4>
                            logged in
                            <span className="act_list_date">
                              {evnt.createdAt}
                            </span>
                          </div>
                        </li>
                      );
                    case EventType.NftLiked:
                      return (
                        <li className="act_like" key={index}>
                          <img
                            className="lazy"
                            src={getNftSmallUrl(
                              evnt.nft.nftMetaData.thumbnail_image!
                            )}
                            alt=""
                          />
                          <div className="act_list_text">
                            <h4>{evnt.nft.name}</h4>
                            liked by{" "}
                            <span className="color">{evnt.from?.username}</span>
                            <span className="act_list_date">
                              {evnt.createdAt}
                            </span>
                          </div>
                        </li>
                      );
                    case EventType.NftUnliked:
                      return (
                        <li className="act_un_like" key={index}>
                          <img
                            className="lazy"
                            src={getNftSmallUrl(
                              evnt.nft.nftMetaData.thumbnail_image!
                            )}
                            alt=""
                          />
                          <div className="act_list_text">
                            <h4>{evnt.nft.name}</h4>
                            un-liked by{" "}
                            <span className="color">{evnt.from?.username}</span>
                            <span className="act_list_date">
                              {evnt.createdAt}
                            </span>
                          </div>
                        </li>
                      );
                    case EventType.NftBid:
                      return (
                        <li className="act_offer" key={index}>
                          <img
                            className="lazy"
                            src={getNftSmallUrl(
                              evnt.nft.nftMetaData.thumbnail_image!
                            )}
                            alt=""
                          />
                          <div className="act_list_text">
                            <h4>{evnt.nft.name}</h4>
                            <span className="color">
                              {evnt.from?.username}
                            </span>{" "}
                            offered 0.002 ETH
                            <span className="act_list_date">
                              {evnt.createdAt}
                            </span>
                          </div>
                        </li>
                      );
                    default:
                      return <></>;
                  }
                })}

              {!loading && percentageLoaded < 100 && (
                <div className="col-lg-12">
                  <div className="spacer-single"></div>
                  <span onClick={loadMore} className="btn-main lead m-auto">
                    Load More ({percentageLoaded}%)
                  </span>
                </div>
              )}
            </ul>
          </div>

          <div className="col-md-4">
            <span className="filter__l">Filter</span>
            <span className="filter__r" onClick={() => filter()}>
              Reset
            </span>
            <div className="spacer-half"></div>
            <div className="clearfix"></div>
            <ul className="activity-filter">
              <li
                id="sale"
                className="filter_by_sales"
                onClick={() => filter(EventType.NftBid)}
              >
                <i className="fa fa-shopping-basket"></i>Sales
              </li>
              <li
                id="like"
                className="filter_by_likes"
                onClick={() => filter(EventType.NftLiked)}
              >
                <i className="fa fa-heart"></i>Likes
              </li>
              <li
                id="offer"
                className="filter_by_offers"
                onClick={() => filter(EventType.OfferSubmitted)}
              >
                <i className="fa fa-gavel"></i>Offers
              </li>
              <li
                id="follow"
                className="filter_by_followings"
                onClick={() => filter(EventType.UserFollow)}
              >
                <i className="fa fa-check"></i>Followings
              </li>
            </ul>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Activity;
