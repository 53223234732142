import { useRef } from "react";
import { NFT } from "../../models/nft";
import NftDisplay from "./NftDisplay";
type propType = { nft: NFT };

const NftCollectionList = (props: propType) => {
  const { nft } = props;
  const displayRef: any = useRef();

  if (nft.nftCollection?.nfts?.length === 0) return <></>;
  return (
    <div className="item_more_coll mt-4">
      <h4>More From This Collection</h4>
      <div className="d-flex align-items-center">
        {nft.nftCollection?.nfts?.map((x, index) => (
          <div
            onClick={() => {
              //@ts-ignore
              window.location = `/item/${x?.id}`;
            }}
            key={index}
            className="more_coll"
          >
            {x.nftMetaData && (
              <NftDisplay
                displayRef={displayRef}
                thumbnail={true}
                className=""
                nftFile={x.nftMetaData.animation_url}
                fileType={x.nftMetaData.file_type}
                previewImageFile={x.nftMetaData.thumbnail_image}
              />
            )}
            <h6>{x.name}</h6>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NftCollectionList;
