import { useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import ReactCrop, { Crop } from "react-image-crop";
import { canvasPreview } from "../imageCrop/canvasPreview";
import centerAspectCrop from "../imageCrop/centerAspectCrop";
import { useDebounceEffect } from "../imageCrop/useDebounceEffect";

const ProfileEditModal = ({
  show,
  handleClose,
  img,
  setUserImage,
  previewCanvasRefShow,
  completedCrop,
  setCompletedCrop,
}: any) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [aspect, setAspect] = useState<number | undefined>(16 / 12);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [imgSrc, setImgSrc] = useState<null | any>("");

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRefShow.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRefShow.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    //@ts-ignore
    [completedCrop, scale, rotate]
  );

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      setImgSrc(e.target.files[0]);
      reader.onloadend = () => {
        setUserImage(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  return (
    <Modal
      className="custom modal-bg profile-img-edit"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="img-edit-wrap">
          {/* {!!imgSrc && ( */}
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
          >
            <img
              crossOrigin="anonymous"
              ref={imgRef}
              alt=""
              src={img}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </div>
        <p className="edit-text m-0 text-white f-12">
          <label>
            <span>Edit</span>
            <input
              type="file"
              accept="image/*"
              onChange={onSelectFile}
              className="text-purple bg-white"
            />
          </label>
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default ProfileEditModal;
