import { useContext, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { blockChainData } from "../../../api/modules/nft";
import { ERROR_CODES } from "../../../constants";
import {
  useActions,
  useConversion,
  useForceSwitchNetwork,
} from "../../../hooks";
import { buyNow, lazyMint } from "../../../services/seaport.service";
import { SeaportContext } from "../../../state/context/SeaportContext";
import moment from "moment";
import { getNftSmallUrl } from "../../../models/nft";

const BuyNft = ({
  show,
  handleClose,
  label,
  buttonAction,
  noteColor,
  labelIcon,
  btnColor,
  noteText,
  nft,
  quantity,
  order,
  handleComplete,
}: any) => {
  const [transactionHash, setTransactionHash] = useState("");
  const [startPurchasing, setStartPurchasing] = useState(false);
  const [refreshingMetaData, setRefreshingMetaData] = useState(false);
  const [saveToBlockchain, setSaveToBlockchain] = useState(false);
  const [unreviewedCollection, setUnreviewedCollection] = useState(false);
  const { fetchNftMetaData, updateOrderPurchase } = useActions();
  const [price, setPrice] = useState(nft.defaultListing?.amount || nft.price);

  const { priceUSD } = useConversion(
    price || 0,
    nft ? blockChainData[nft.blockchain].symbol : "ETH"
  );
  const seaport = useContext(SeaportContext);
  const { isCorrectNetwork, checkNetwork } = useForceSwitchNetwork(nft);

  const refreshMetaData = async () => {
    setRefreshingMetaData(true);
    if (nft) {
      await fetchNftMetaData(nft.id!);
      setRefreshingMetaData(false);
    }
  };
  const calculateCurrentPrice = () => {
    if (!nft.defaultListing) return;
    const endTime = Number(nft.defaultListing.endTime);
    const startTime = Number(nft.defaultListing.startTime);
    const currentTime = moment().unix();
    const endAmount = nft.defaultListing?.endAmount || 0;
    const startAmount = nft.defaultListing!.amount;
    const timeDifference = endTime - startTime;
    const timeElapsed = currentTime - startTime;
    const timeRemaining = endTime - currentTime;
    const decrementPerSecond = (startAmount - endAmount) / timeDifference;
    const amountToSubtract = decrementPerSecond * timeElapsed;
    const currentPrice = startAmount - amountToSubtract;
    if (timeRemaining > -1 && currentPrice >= endAmount) {
      setPrice(currentPrice.toPrecision(4));
    }
  };

  useEffect(() => {
    //calculateCurrentPrice();
  }, [show]);

  const buyNft = async () => {
    const _isCorrectNetwork = await checkNetwork();
    if (!_isCorrectNetwork || seaport === null) return;

    await refreshMetaData();
    setStartPurchasing(true);

    if (!order && !nft.tokenId) {
      try {
        const transaction = await lazyMint(nft, quantity);
        setTransactionHash(transaction.hash);
        setStartPurchasing(false);
        setSaveToBlockchain(true);

        await transaction.wait();
        setSaveToBlockchain(false);

        handleComplete();
      } catch (e: any) {
        setTimeout(() => {
          setSaveToBlockchain(false);
          setStartPurchasing(false);
        }, 500);
        console.error(e);
        toast.error(ERROR_CODES[e.code] || "Lazy minting Transaction Failed");
      }
    } else {
      try {
        const transaction = await buyNow(JSON.parse(order.hash), quantity);
        setTransactionHash(transaction.hash);
        setStartPurchasing(false);
        setSaveToBlockchain(true);
        updateOrderPurchase(order.id, quantity);
        await transaction.wait();
        setSaveToBlockchain(false);
        handleComplete();
      } catch (e: any) {
        setTimeout(() => {
          setSaveToBlockchain(false);
          setStartPurchasing(false);
        }, 5000);
        toast.error(ERROR_CODES[e.code] || "Transaction Failed");
      }
    }
  };

  return (
    <Modal
      className="custom modal-bg"
      show={show}
      onHide={() => {
        toast.dismiss();
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="">
          {label}
          {labelIcon && (
            <span className="ms-1 f-12 text-purple">
              <i className="fa-solid fa-circle-exclamation"></i>
            </span>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mt-2 mb-3 nft-modal-body">
          <div className="modal-nft-img w-auto me-4">
            <img
              src={getNftSmallUrl(nft?.nftMetaData?.thumbnail_image)}
              className="sell-this-nft"
              alt=""
            />
            <p className="f-16 mt-1 f-700">
              <span className="text-gray me-1">Qty:</span>
              {quantity} of {order?.quantity || nft.supply}
            </p>
          </div>
          <div className="ms-1 min-265">
            <span className="text-purple">{nft.nftCollection.name}</span>
            <h2 className="mb-0">{nft?.name}</h2>
            <p className="f-12 mb-2">
              Creator:{" "}
              <span className="text-purple me-4">{nft.creator.username}</span>
              {/* {" "}
              Owner: <span className="text-purple">{nft.owner.username}</span> */}
            </p>
            <div>
              <p className="text-gray f-16">
                Price:{" "}
                <span className="text-black me-3">
                  {price} {blockChainData[nft.blockchain].symbol}
                </span>
                <span className="f-11">{`$${priceUSD} USD`}</span>
              </p>

              <div>
                {transactionHash && saveToBlockchain ? (
                  <p>
                    <a
                      href={`${
                        blockChainData[nft.blockchain].baseUrl
                      }/tx/${transactionHash}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Click Here To Monitor Transaction
                    </a>
                  </p>
                ) : (
                  <div />
                )}
              </div>

              <div className="mb-2 d-flex align-items-center">
                {(nft?.forSale || !nft.tokenId) &&
                  !refreshingMetaData &&
                  !startPurchasing &&
                  !saveToBlockchain && (
                    <button
                      disabled={!unreviewedCollection}
                      className={`${
                        !startPurchasing && !saveToBlockchain
                          ? btnColor
                          : "btn-gray"
                      } text-uppercase`}
                      onClick={() => buyNft()}
                    >
                      {buttonAction}
                    </button>
                  )}
              </div>
              <div className="my-3">
                <input
                  type="checkbox"
                  name="UnreviewedCollection"
                  className="me-2"
                  disabled={startPurchasing || saveToBlockchain}
                  onChange={(e) => setUnreviewedCollection(e.target.checked)}
                  checked={unreviewedCollection}
                />
                I understand that ZingIt has not reviewed this collection and
                that blockchain transactions are irreversible.
              </div>
              {(startPurchasing || saveToBlockchain || refreshingMetaData) && (
                <div className="mb-2 d-flex flex-column align-items-center">
                  <ThreeDots
                    height="15"
                    width="165"
                    color="#8364e2"
                    ariaLabel="loading"
                  />
                  {refreshingMetaData && <span>Validating Nft....</span>}
                  {saveToBlockchain && (
                    <span> Saving to the Blockchain....</span>
                  )}
                  {startPurchasing && <span> Processing Order....</span>}
                </div>
              )}
            </div>
            {noteText && (
              <p className={`${noteColor} f-11 text-center mt-2`}>
                NOTE: {noteText}
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default BuyNft;
