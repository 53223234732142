import moment from "moment";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAccount, useBalance, useNetwork } from "wagmi";
import { useActions, useForceSwitchNetwork, useSelector } from "../../hooks";
import { NFT } from "../../models/nft";
import { Order, OrderType } from "../../models/order";
import BuyNft from "./Modals/BuyNft";
import CongratulationsModal from "./Modals/CongratulationsModal";
import CreateListing from "./Modals/CreateListing";
import EditListing from "./Modals/EditListing";
import MakeOfferModal from "./Modals/MakeOfferModal";
import MintNow from "./Modals/MintNow";
import OfferInfo from "./Modals/OfferInfo";
import SellNft from "./Modals/SellNft";
import WalletModal from "./Modals/WalletModal";
import BuyButtonDisabledInfo from "./ToolTips/BuyButtonDisabledInfo";
import BuyButton from "./BuyButton";

export const NftActionButtons = ({
  nft,
  maxButtons = 5,
}: {
  nft: NFT;
  maxButtons?: number;
}) => {
  const [showPurchase, setShowPurchase] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [showMintNow, setShowMintNow] = useState(false);
  const [showSellModal, setShowSellModal] = useState(false);
  const [showEditListing, setShowEditListing] = useState(false);
  const [showConfirmSellModal, setShowConfirmSellModal] = useState(false);
  const [isAuction, setIsAuction] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showMakeOfferModal, setShowMakeOfferModal] = useState(false);
  const [showOfferSubmitted, setShowOfferSubmitted] = useState(false);
  const [showAcceptOffer, setShowAcceptOffer] = useState(false);
  const [showCongratsPurchase, setShowCongratsPurchase] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<Order>();
  const [quantity, setQuantity] = useState(1);
  const { chain } = useNetwork();
  const { fetchNftMetaData } = useActions();
  const navigate = useNavigate();
  const { isConnected, address } = useAccount();
  const { data: walletData } = useBalance({ address });
  const { user } = useSelector((state) => state.auth);
  const { listings } = useSelector((state) => state.listing);
  const { isCorrectNetwork, checkNetwork } = useForceSwitchNetwork(nft);
  let maxAvailableQuantity = listings?.reduce((total, listing) => {
    return total + (listing?.quantity || 0);
  }, 0);

  const price = nft.defaultListing?.amount || nft.price || 0;

  const handleFetchNft = async () => {
    if (nft.id) {
      return await fetchNftMetaData(nft.id);
    }
  };
  const handleClickBuyNft = async (quantity: number) => {
    setQuantity(quantity);
    await checkNetwork();
    if (parseFloat(walletData!.formatted) <= parseFloat(price?.toString())) {
      toast.error(
        "Please add money to your wallet. Click the wallet icon at the top."
      );
    } else {
      setShowPurchase(true);
    }
  };

  const handleClickMintNow = async () => {
    await checkNetwork();
    setShowMintNow(true);
  };

  const handleClickMakeOffer = async () => {
    await checkNetwork();
    setShowMakeOfferModal(true);
  };

  const handleClickSellNft = async () => {
    await checkNetwork();
    setShowSellModal(true);
  };
  const now = Number(moment().unix());

  const isNotExpired = () => {
    if (!nft.forSale) return true;
    return nft.forSale && Number(nft.defaultListing?.endTime) > now;
  };

  const isNotPreOrder = () => {
    if (!nft.forSale) return true;
    return nft.forSale && Number(nft.defaultListing?.startTime) < now;
  };
  const canMakeOffer = () => {
    //not listed
    //not auction
    //listed, not expired
    return !nft.forSale || (isNotExpired() && isNotPreOrder());
  };

  const canBuyItem = () => {
    //not on sale, not minted
    //on sale, not on auction, not expired
    //on sale, on auction, price is lower than highest bid
    // const hasHigherBid = nft.bids?.some((x) => x.amount > price);
    return (isNotExpired() && isNotPreOrder()) || !nft.tokenId;
  };

  const hasListingOtherThanMine = () =>
    nft.listings?.some((listing) => listing.seller?.id !== user?.id);

  const hasOwnersOtherThanMe = () =>
    user && nft.owners?.some((owner) => owner?.id !== user?.id);
  const availableNFTsForSale: number = (() => {
    const totalListed = nft.listings
      ?.filter((listing: any) => listing.seller?.id === user?.id)
      .reduce((acc: number, listing: any) => acc + listing.quantity, 0);

    return nft.amountIOwn - (totalListed || 0);
  })();

  const getDisabledReason = (buyButton = false) => {
    if (!hasListingOtherThanMine) {
      return "You cannot buy your own NFT.";
    }
    if (!isNotExpired() && nft.tokenId) {
      return `This ${nft.defaultListing?.type} is expired.`;
    }
    if (!isNotPreOrder() && nft.tokenId) {
      return `This ${nft.defaultListing?.type} has not started.`;
    }
    if (!nft.forSale && nft.tokenId) {
      return "This NFT is not for sale.";
    }
    if (buyButton && nft.bids?.some((x) => x.amount > price)) {
      return "This NFT has a higher offer.";
    }
  };

  if (!isConnected || !user?.walletAddress)
    return (
      <>
        <button
          className="btn-main me-1 col-12"
          onClick={() => {
            setShowConnectWallet(true);
          }}
        >
          Connect Your Wallet
        </button>
        <WalletModal
          show={showConnectWallet}
          handleClose={() => setShowConnectWallet(false)}
        />
      </>
    );

  const buttonsToShow = [];

  // Buy Button condition
  if (
    (nft.forSale &&
      hasListingOtherThanMine() &&
      nft.defaultListing?.type === OrderType.SALE) ||
    (!nft.tokenId && nft.creator?.id !== user?.id)
  ) {
    buttonsToShow.push(
      <BuyButtonDisabledInfo
        key={nft.id + "_buyButton"}
        show={!canBuyItem()}
        text={getDisabledReason(true)}
      >
        <BuyButton
          disabled={!canBuyItem()}
          maxQuantity={maxAvailableQuantity || 0}
          minQuantity={1}
          onClick={handleClickBuyNft}
        />
      </BuyButtonDisabledInfo>
    );
  }

  // Mint Now Button condition
  if (!nft.tokenId && user?.id === nft?.creator?.id) {
    buttonsToShow.push(
      <button
        key={nft.id + "_mintNowButton"}
        className="btn-main me-1 col-6"
        onClick={handleClickMintNow}
      >
        Mint Now
      </button>
    );
  }

  // Sell Button condition
  if (nft.tokenId && user?.id && nft.owners?.some((x) => x.id == user.id)) {
    buttonsToShow.push(
      <BuyButtonDisabledInfo
        key={nft.id + "_sellButton"}
        show={availableNFTsForSale < 1}
        text={"You have no NFTs available for sale"}
      >
        <button
          className="btn-main me-1 col-6"
          disabled={availableNFTsForSale < 1}
          onClick={handleClickSellNft}
        >
          Sell
        </button>
      </BuyButtonDisabledInfo>
    );
  }

  // Make Offer Button condition
  if (hasOwnersOtherThanMe() && isConnected && nft.tokenId) {
    buttonsToShow.push(
      <BuyButtonDisabledInfo
        key={nft.id + "_makeOfferButton"}
        show={!canMakeOffer()}
        text={getDisabledReason()}
      >
        <button
          className={
            (nft.forSale ||
            !nft.tokenId ||
            (user?.id && nft.owners?.some((x) => x.id == user.id))
              ? "btn-secondary"
              : "btn-main") + " me-1 col-6"
          }
          disabled={!canMakeOffer()}
          onClick={handleClickMakeOffer}
        >
          {nft.defaultListing?.type !== OrderType.SALE && nft.forSale
            ? "Place Bid"
            : "Make Offer"}
        </button>
      </BuyButtonDisabledInfo>
    );
  }

  // Edit Button condition
  if (!nft.tokenId && user?.id === nft?.creator?.id) {
    buttonsToShow.push(
      <button
        key={nft.id + "_editButton"}
        className="btn-secondary me-1 col-6"
        disabled={false}
        onClick={() => {
          navigate(`/edit-nft/${nft?.id}`);
          window.location.reload();
        }}
      >
        Edit
      </button>
    );
  }

  return (
    <>
      {
        //limit the buttons to show
        buttonsToShow.slice(0, maxButtons)
      }

      <BuyNft
        showLoading={true}
        show={showPurchase}
        handleClose={() => setShowPurchase(false)}
        label="Purchase This NFT"
        buttonAction="PURCHASE"
        noteColor="text-green"
        btnColor="btn-main wide"
        noteText="Check Your wallet to sign this transaction"
        nft={nft}
        order={nft.defaultListing}
        quantity={quantity}
        handleComplete={() => {
          setShowPurchase(false);
          setShowCongratsPurchase(true);
        }}
      />
      <CongratulationsModal
        show={showCongratsPurchase}
        handleClose={async () => {
          await handleFetchNft();
          setShowCongratsPurchase(false);
        }}
        label="Congratulations! It’s Yours!"
        noteText="Tell the world about your new purchase!"
        noteColor="text-green"
        nft={nft}
      />

      <MintNow
        show={showMintNow}
        nft={nft}
        refetch={async () => await handleFetchNft()}
        noteText="Check Your wallet to sign this transaction"
        noteColor="text-green"
        handleClose={() => setShowMintNow(false)}
      />

      <CreateListing
        show={showSellModal}
        handleClose={() => setShowSellModal(false)}
        handleFinish={(saleType: OrderType) => {
          saleType === OrderType.SALE
            ? setIsAuction(false)
            : setIsAuction(true);
          setShowConfirmSellModal(true);
          setShowSellModal(false);
        }}
        editing={editing}
        nft={nft}
      />
      <SellNft
        show={showConfirmSellModal}
        handleClose={() => setShowConfirmSellModal(false)}
        nft={nft}
        isAuction={isAuction}
        noteText="Check Your wallet to sign this transaction"
        noteColor="text-green"
        handleContinue={() => {
          setShowConfirmSellModal(false);
          setShowSellModal(true);
          setEditing(true);
        }}
        refetch={async () => {
          return await handleFetchNft();
        }}
      />
      <MakeOfferModal
        show={showMakeOfferModal}
        nft={nft}
        handleClose={async (order?: Order) => {
          setShowMakeOfferModal(false);
          if (!order) return;
          setSelectedOffer(order);
          setShowOfferSubmitted(true);
          await handleFetchNft();
        }}
        amountError={true}
        noteText="Check Your wallet to sign this transaction"
        noteColor="text-green"
      />
      <OfferInfo
        showBtn={false}
        nft={nft}
        offer={selectedOffer}
        show={showOfferSubmitted}
        handleClose={async (refresh = false) => {
          if (refresh) await handleFetchNft();
          setShowOfferSubmitted(false);
        }}
        label="Offer Made!"
        info="Your offer has been made."
        moreInfo="Let’s see if the Seller accepts your offer."
        isCancelOffer={false}
        showInfo={true}
      />

      <OfferInfo
        show={showAcceptOffer}
        offer={selectedOffer}
        nft={nft}
        handleClose={async (refresh = false) => {
          if (refresh) await fetchNftMetaData(nft.id!);

          setShowAcceptOffer(false);
        }}
        label="Accept Offer"
        noteText="Check Your wallet to sign this transaction"
        noteColor="text-green"
        info={""}
        showInfo={false}
        moreInfo={""}
        showBtn={true}
        isCancelOffer={false}
      />

      {/*Deprecated
       <EditListing
        show={showEditListing}
        nft={nft}
        handleClose={async (refresh = false) => {
          if (refresh) await handleFetchNft();
          setShowEditListing(false);
        }}
        noteText="Check Your wallet to sign this transaction"
        noteColor="text-green"
      /> */}
    </>
  );
};
