import axios from "axios";

const instance = axios.create({
  baseURL: "https://lambda.zingit.xyz",
});

const ethToFiat = () => {
  return instance.get(`/convert-to-fiat`, {
    params: {
      symbol: "USD",
      convert: "ETH,MATIC,WMATIC,WETH",
      amount: 1,
    },
  });
};

export { ethToFiat };
