import { useEffect, useState } from "react";
import { blockChainData } from "../../api/modules/nft";
import { useConversion } from "../../hooks";
import { useDisplayPrice } from "../../hooks/modules/useDisplayPrice";
import { NFT } from "../../models/nft";
import moment from "moment";
type propType = { nft: NFT };

const NFTCurrentPrice = (props: propType) => {
  const { nft } = props;
  const [price, setPrice] = useState(nft.defaultListing?.amount || nft.price);
  const [displayPrice] = useDisplayPrice(price);
  const { priceUSD } = useConversion(
    price || 0,
    nft ? blockChainData[nft.blockchain]?.symbol : "ETH"
  );

  // If it's a Dutch auction
  useEffect(() => {
    let timer: any;

    const calculateCurrentPrice = () => {
      if (!nft.defaultListing) return;
      const endTime = Number(nft.defaultListing.endTime);
      const startTime = Number(nft.defaultListing.startTime);
      const currentTime = moment().unix();
      const endAmount = nft.defaultListing?.endAmount || 0;
      const startAmount = nft.defaultListing!.amount;
      const timeDifference = endTime - startTime;
      const timeElapsed = currentTime - startTime;
      const timeRemaining = endTime - currentTime;
      const decrementPerSecond = (startAmount - endAmount) / timeDifference;
      const amountToSubtract = decrementPerSecond * timeElapsed;
      const currentPrice = startAmount - amountToSubtract;
      if (timeRemaining > -1 && currentPrice >= endAmount) {
        setPrice(currentPrice);
      } else {
        clearInterval(timer);
      }
    };
    if (nft.defaultListing?.type === "auction_dutch") {
      // Initial calculation
      calculateCurrentPrice();

      // Recalculate every second
      timer = setInterval(() => {
        calculateCurrentPrice();
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [nft]);

  return (
    <div className="my-2">
      <p className="curr_price">Current Price:</p>
      <div className="item_author_price">
        <span className="d-flex" style={{ margin: "5px 0px" }}>
          <img
            style={{
              width: "auto",
              height: "25px",
            }}
            className="mr-1"
            src={blockChainData[nft.blockchain].logo}
            alt={blockChainData[nft.blockchain].symbol}
          />
          <p className="eth_cost mb-0 mx-1">
            {displayPrice} {blockChainData[nft.blockchain].symbol}
          </p>
          <span className="price-usd">(${priceUSD})</span>
        </span>
      </div>
    </div>
  );
};

export default NFTCurrentPrice;
