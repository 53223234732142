import { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAccount } from "wagmi";
import { DEFAULT_USER_IMAGE } from "../constants";
import Notifications from "./Notifications";
import WalletBalances from "./WalletBalances";
import { getUserSmallImageUrl } from "../../../models/user";

const HeaderMenu = ({
  toggleUserMenu,
  setCurrPage,
  showUserMenu,
  refWalletMenu,
  handleWalletClick,
  showNotificationMenu,
  handleNotificationClick,
  showWalletMenu,
  handleLogout,
  setShowConnectWallet,
}: any) => {
  const account = useAccount();
  const { user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const handleCurrPage = (page: string) => setCurrPage(page);
  const [userImage, setUserImage] = useState();

  useEffect(() => {
    if (user?.userImage) {
      setUserImage(user?.userImage);
    }
  }, [JSON.stringify(user)]);

  const refUserMenu = useOnclickOutside(() => {
    toggleUserMenu(false);
  });

  return (
    <div className="mainside">
      <div style={{ display: "flex", alignItems: "center" }}>
        {user && (
          <div className="side-feature create mobile-hide">
            <button
              onClick={() => navigate("/create-nft")}
              className={"btn-main"}
            >
              Create
            </button>{" "}
          </div>
        )}
        {user && (
          <div className="side-feature con_discon de-menu-profile">
            <i
              className="fa-regular fa-bell"
              style={{
                fontSize: 25,
              }}
              onClick={() => {
                // handleNotificationClick();
                navigate("/profile/Offers");
              }}
            />
            {user?.notificationCount > 0 && (
              <span
                className="badge rounded-pill bg-danger position-absolute"
                style={{ top: "-5px", right: "-10px" }}
              >
                {user?.notificationCount}
              </span>
            )}
            {account.isConnected &&
            user?.walletAddress &&
            showNotificationMenu ? (
              <Notifications />
            ) : (
              <div />
            )}
          </div>
        )}

        <div className="ps-2 side-feature con_discon mobile-hide">
          <button
            onClick={
              user ? () => handleLogout() : () => setShowConnectWallet(true)
            }
            className={user?.walletAddress ? "disabled" : "btn-main mx-4"}
          >
            {user?.walletAddress ? "Sign Out" : "Sign In"}
          </button>
        </div>

        {account.isConnected && user && user?.walletAddress ? (
          <div
            id="de-click-menu-profile"
            className="de-menu-profile1 mobile-hide"
            ref={refWalletMenu}
            onClick={() => handleWalletClick()}
          >
            <img
              src="/img/walletIcon.png"
              alt="Wallet"
              style={{
                height: 35,
                width: 35,
                marginRight: 15,
                marginLeft: 15,
              }}
            />
            {account.isConnected && user?.walletAddress && showWalletMenu ? (
              <WalletBalances />
            ) : (
              <div />
            )}
          </div>
        ) : (
          <div />
        )}

        <div
          id="de-click-menu-profile"
          className="de-menu-profile"
          onClick={() => toggleUserMenu(!showUserMenu)}
          ref={refUserMenu}
        >
          {account.isConnected && user?.walletAddress && (
            <img
              src={
                userImage ? getUserSmallImageUrl(userImage) : DEFAULT_USER_IMAGE
              }
              alt=""
              style={{
                height: "40px",
                width: "40px",
                cursor: "pointer",
                objectFit: "cover",
              }}
            />
          )}
          {showUserMenu && account.isConnected && user?.walletAddress && (
            <div className="popshow">
              <div className="d-name">
                <h4>{user?.username ?? "New User"}</h4>
              </div>
              <div className="d-line"></div>
              <ul className="de-submenu-profile">
                <li>
                  <span
                    onClick={() =>
                      navigate(
                        user?.username
                          ? `/profile`.replace(/ /g, "")
                          : "/profile/NewUser"
                      )
                    }
                  >
                    <i className="fa fa-user"></i> My profile
                  </span>
                </li>
                <li>
                  <span onClick={() => navigate("/profile/edit")}>
                    <i className="fa fa-pencil"></i> Edit profile
                  </span>
                </li>
                <li>
                  <span onClick={() => navigate("/collections")}>
                    <i className="fa fa-user"></i> My collections
                  </span>
                </li>
                <li>
                  <span onClick={handleLogout}>
                    <i className="fa fa-sign-out"></i> Sign out
                  </span>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderMenu;
