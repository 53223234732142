import { useEffect, useState } from "react";
import { isIE, isSafari } from "react-device-detect";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createGlobalStyle } from "styled-components";
import { useAccount, useDisconnect, useNetwork } from "wagmi";
import {
  AuthorProfile,
  Collections,
  CreateCollection,
  CreateNFT,
  EditNFT,
  EditProfile,
  Explore,
  Header,
  Home,
  MyProfile,
  NftDetail,
  SingleCollection,
  WhatIsAWallet,
} from ".";
import { useActions, useSelector } from "../hooks";
import { User } from "../models/user";
import Activity from "./Pages/Activity";
import BulkUpload from "./Pages/BulkUpload";
import EditCollection from "./Pages/EditCollection";
import Landing from "./Pages/Landing";
import MintGreen from "./Pages/MintGreen";
import Page404 from "./Pages/Page404";
import Page500 from "./Pages/Page500";
import Privacy from "./Pages/Privacy";
import SearchPage from "./Pages/SearchPage";
import Tos from "./Pages/Tos";
import OnBoarding from "./Partials/Modals/OnBoarding";
import SiteAccessModal from "./Partials/Modals/SiteAccessModal";
import ScrollToTop from "./Partials/ScrollToTop";

const GlobalStyles = createGlobalStyle`
  :root {
    scroll-behavior: unset;
  }
`;

const RequireAuth = ({
  children,
  user,
}: {
  children: JSX.Element;
  user: User | null;
}) => {
  let location = useLocation();
  const storedUser = localStorage.getItem("token");
  if (user) {
    if (user.walletAddress) {
      return children;
    } else {
      <h1>Loading...</h1>;
    }
  } else {
    if (!storedUser)
      return <Navigate to="/" state={{ from: location }} replace />;
  }
  return children;
};

const App = () => {
  const { user } = useSelector((state) => state.auth);
  const { updateConversions, fetchUser, logout } = useActions();
  const { chain, chains } = useNetwork();
  const [showOnBoarding, setShowOnBoarding] = useState(false);
  const account = useAccount();
  useEffect(() => {
    window.addEventListener("focus", fetchUser);
    return () => window.removeEventListener("focus", fetchUser);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      updateConversions();
    }, 80000);

    updateConversions();

    return () => {
      clearInterval(interval);
    };
  }, [updateConversions]);

  useEffect(() => {
    if (isSafari || isIE) {
      toast.dismiss();
      toast.warn(
        "You are not able to conduct Web3 functions using this browser",
        { autoClose: false, hideProgressBar: true, closeOnClick: true }
      );
    }
  }, []);

  useEffect(() => {
    toast.dismiss();

    const supportedChainIds = chains.map((chain) => chain.id);
    if (process.env.REACT_APP_ENV_NAME == "production") {
      if (
        (chain && chain?.testnet) ||
        (chain && !supportedChainIds.includes(chain?.id))
      ) {
        toast.error(
          `You are currently logged into the ${chain.network} network!`,
          {
            autoClose: false,
            closeButton: true,
            hideProgressBar: true,
            closeOnClick: true,
          }
        );
      }
    } else {
      if (
        (chain && !supportedChainIds.includes(chain.id)) ||
        (chain && !chain.testnet)
      ) {
        toast.error(`You are not using a supported test network!`, {
          autoClose: false,
          closeButton: true,
          hideProgressBar: true,
          closeOnClick: true,
        });
      }
    }
  }, [chain && chain.id]);

  useEffect(() => {
    const currentPage = window.location.pathname;
    const ignorePages = ["/tos", "/privacy", "/what-is-a-wallet"];
    if (user && user?.isNewUser && !ignorePages.includes(currentPage))
      setShowOnBoarding(true);
  }, [user?.isNewUser]);

  const { disconnect } = useDisconnect({
    onSuccess() {
      logout();
      setTimeout(() => window.location.reload(), 1000);
    },
  });

  const handleLogout = () => {
    disconnect();
    logout();
  };

  return (
    <div className="wrapper">
      <GlobalStyles />

      <Router>
        <SiteAccessModal show={false} password="zinger">
          <ScrollToTop />
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/tos" element={<Tos />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/what-is-a-wallet" element={<WhatIsAWallet />} />
            <Route path="/mint-green-info" element={<MintGreen />} />
            <Route path="/collections" element={<Collections />} />
            <Route path="/collection" element={<SingleCollection />} />
            <Route
              path="/create-collection"
              element={
                <RequireAuth user={user}>
                  <CreateCollection />
                </RequireAuth>
              }
            />
            <Route
              path="/edit-collection/:id"
              element={
                <RequireAuth user={user}>
                  <EditCollection />
                </RequireAuth>
              }
            />
            <Route path="/explore" element={<Explore />} />
            <Route
              path="/edit-nft/:id"
              element={
                <RequireAuth user={user}>
                  <EditNFT />
                </RequireAuth>
              }
            />
            <Route
              path="/create-nft"
              element={
                <RequireAuth user={user}>
                  <CreateNFT />
                </RequireAuth>
              }
            />
            <Route path="/author/:id" element={<AuthorProfile />} />
            <Route
              path="/profile/edit"
              element={
                <RequireAuth user={user}>
                  <EditProfile />
                </RequireAuth>
              }
            />
            <Route
              path="/profile/:active_tab?"
              element={
                <RequireAuth user={user}>
                  <MyProfile />
                </RequireAuth>
              }
            />

            {/* new pages */}
            <Route path="/creators" element={<Landing />} />
            <Route path="/search-page" element={<SearchPage />} />
            <Route path="/health" element={<>Health Check</>} />
            <Route path="/item/:id" element={<NftDetail />} />
            <Route path="/activity" element={<Activity />} />
            <Route path="/contact" />
            <Route path="error-500" element={<Page500 />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </SiteAccessModal>

        <OnBoarding
          show={showOnBoarding}
          disconnect={handleLogout}
          handleClose={() => {
            setShowOnBoarding(false);
          }}
        />
      </Router>

      <ToastContainer position={toast.POSITION.TOP_CENTER} className="mt-81" />
    </div>
  );
};

export default App;
