import { IAttribute } from "../../models/nft";

const NftProperties = ({ nft }: any) => {
  if (!nft?.nftMetaData.attributes?.length) return <div></div>;
  return (
    <div className="detail_properties text-center">
      <h4>NFT Properties</h4>
      <div>
        {nft?.nftMetaData.attributes?.length &&
          nft?.nftMetaData.attributes?.map((item: IAttribute, i: any) => {
            return (
              <div className="property" key={i}>
                <h6
                  style={{
                    fontFamily: "familjenGrotesk",
                    fontSize: 14,
                  }}
                >
                  {item?.trait_type}
                </h6>
                <p
                  className="detail_title  mb-3"
                  style={{ fontFamily: "teko", fontSize: 28 }}
                >
                  {item?.value}
                </p>
                {/* <p className="detail_stat f-14">
                {item?.statistic} have this
              </p> */}
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default NftProperties;
