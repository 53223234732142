import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { SiweMessage } from "siwe";
import {
  useAccount,
  useConnect,
  useDisconnect,
  useNetwork,
  useSignMessage,
} from "wagmi";
import { ERROR_CODES } from "../../../constants";
import { useActions, useSelector } from "../../../hooks";
import CreateNewWallet from "./CreateNewWallet";
import { clarity } from "react-microsoft-clarity";

const WalletModal = ({ show, handleClose }: any) => {
  const { nonce } = useSelector((state: any) => state.auth);
  const [showCreateWallet, setShowCreateWallet] = useState(false);
  const { fetchNonce, verifyMessage, logout } = useActions();
  const { signMessageAsync } = useSignMessage();
  const { chain } = useNetwork();
  const { isConnected, address } = useAccount();
  const [loading, setLoading] = useState(false);
  const { disconnect } = useDisconnect();

  const { connect, connectors, error, isLoading, pendingConnector, isSuccess } =
    useConnect({
      onSuccess(data) {
        signIn(data?.chain?.id, data?.account);
      },
      onError(error) {
        toast.error(error.message);
      },
    });

  const msg = (address: string) =>
    `Welcome to ZingIt! Click to sign in and accept the ZingIt Terms of Service: https://zing.it/tos. This request will not trigger a blockchain transaction or cost any gas fees. Your authentication status will reset after 30 days. Wallet Address: ${address}`;

  const handleLogout = () => {
    disconnect();
    logout();
  };
  const signIn = async (chainId: number, address: string) => {
    try {
      if (!address || !chainId) return;

      setLoading(true);

      await fetchNonce();

      const message = new SiweMessage({
        domain: window.location.host,
        address,
        statement: msg(address),
        uri: window.location.origin,
        version: "1",
        chainId,
        nonce: nonce,
      });

      const signature = await signMessageAsync({
        message: message.prepareMessage(),
      });

      await verifyMessage(
        message,
        signature,
        address,
        async (data) => {
          handleClose();
          setLoading(false);
          if (clarity.hasStarted()) {
            clarity.identify("USER_ID", { userProperty: address });
          }
        },
        async () => {
          setLoading(false);
          toast.error("Wallet Disconnected");
          return handleLogout();
        }
      );
    } catch (e: any) {
      //sign failed
      const errorMessage = ERROR_CODES[e.cause?.code];
      if (errorMessage) toast.error(errorMessage);
      else console.error(e.message);

      setLoading(false);
      handleLogout();
    }
  };

  return (
    <>
      <Modal
        className="custom modal-bg connect-wallet"
        show={show}
        onHide={handleClose}
        // style={{ backgroundImage: `url("../../../img/modal-bg.png")` }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="">
            Get Started by Connecting your Wallet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="f-13">
              ZingIt is the NFT marketplace for the rest of us. First thing
              you’ll need is a <span className="text-purple">wallet</span>.
              <br /> In the world of Web3, a wallet does two main things:
            </p>
            <div className="connect-wallet-modal row mt-3">
              <div className="d-flex col-xs-12 col-sm-6">
                <div className="rounded custom">1</div>
                <div>
                  <h4>Stores your Digital Assets</h4>
                  <p>
                    Your wallet lets you send, receive, store, and display your
                    cypto currency and NFTs
                  </p>
                </div>
              </div>

              <div className="d-flex col-xs-12 col-sm-6">
                <div className="rounded custom">2</div>
                <div>
                  <h4>Allows you to Log In</h4>
                  <p>
                    No need to create multiple passwords, just connect your
                    wallet.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-start border-top">
          <div className="d-flex align-items-center w-100 justify-content-between connect-wallet-footer">
            <div className="d-flex align-items-center">
              {connectors.map((connector, i) => {
                return (
                  <div
                    className={`${i < 2 && "me-5"} text-center`}
                    style={{ visibility: `${i == 1 ? "visible" : "hidden"}` }}
                    key={connector.id}
                  >
                    <div className="rounded bigger custom mx-auto">
                      <img
                        src={
                          connector.name === "Coinbase Wallet"
                            ? "../../../img/coinbase-logo.png"
                            : connector.name === "MetaMask"
                            ? "../../../img/MetaMask_Fox.svg.png"
                            : "../../../img/WalletConnect-Logo-tumb.png"
                        }
                        alt=""
                        onClick={() => {
                          if (address && chain?.id) {
                            console.log("already connected");
                            signIn(chain?.id, address);
                          } else {
                            console.log("not connected");
                            connect({ connector });
                          }
                        }}
                      />
                    </div>
                    <p className="f-12">
                      {connector.name == "Coinbase Wallet"
                        ? "Coinbase"
                        : connector.name}
                    </p>
                    <button
                      className="btn-transparent mb-3"
                      style={
                        connector.name == "WalletConnect"
                          ? {
                              backgroundColor: "lightgray",
                              borderColor: "gray",
                              color: "gray",
                            }
                          : {}
                      }
                      disabled={
                        isLoading ||
                        loading ||
                        connector.name == "WalletConnect"
                      }
                      onClick={() => {
                        if (connector.name !== "MetaMask") handleClose();

                        if (connector.name === "MetaMask" && !connector.ready) {
                          window.open("https://metamask.io/download", "_blank");
                        } else {
                          if (address && chain?.id) {
                            console.log("already connected");
                            signIn(chain?.id, address);
                          } else {
                            console.log("not connected");
                            connect({ connector });
                          }
                        }
                      }}
                    >
                      {((!isLoading && !loading) ||
                        connector.id !== pendingConnector?.id) &&
                        (connector.ready
                          ? connector.name == "WalletConnect"
                            ? "COMING SOON"
                            : "CONNECT"
                          : "INSTALL NOW")}

                      {(isLoading || loading) &&
                        connector.id === pendingConnector?.id && (
                          <div>
                            <ThreeDots
                              wrapperStyle={{ display: "inline-block" }}
                              height="20"
                              width="50"
                              color="#8364e2"
                              ariaLabel="loading"
                            />
                          </div>
                        )}
                    </button>
                  </div>
                );
              })}
            </div>
            <div>
              <div>
                <Link
                  to="/what-is-a-wallet"
                  className="text-purple f-13 fw-400"
                  onClick={() => handleClose()}
                >
                  <span className="me-1">
                    <i className="fa-solid fa-circle-exclamation"></i>
                  </span>
                  What is a wallet and how do I set one up?
                </Link>
              </div>
              <button
                className="btn-main ms-3 mt-2 w-100"
                onClick={() => {
                  setShowCreateWallet(true);
                  handleClose();
                }}
              >
                I NEED A NEW WALLET
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <CreateNewWallet
        show={showCreateWallet}
        handleClose={() => setShowCreateWallet(false)}
      />
    </>
  );
};

export default WalletModal;
