export type User = {
  id?: string;
  firstName?: string;
  lastName?: string;
  bio?: string;
  username?: string;
  email?: string;
  phoneNumber?: string;
  userImage?: string;
  profileImage?: string;
  bannerImage?: string;
  walletAddress?: string;
  isNewUser?: boolean;
  followers?: number;
  nonce?: number;
  social?: any;
  createdAt?: string;
  updatedAt?: string;
  totalNfts?: number;
  totalGreenNfts?: number;
  totalFavoriteNfts?: number;
  isVerified?: boolean;
  numberOfOffers?: number;
};

export const getUserBannerImageUrl = (url: string) => {
  const baseUrl = "https://assets.zing.it/";
  let strippedUrl = url.replace(baseUrl, "");
  strippedUrl = strippedUrl.replace(
    "https://zenft-bucket.s3.amazonaws.com/",
    ""
  );
  strippedUrl = strippedUrl.replace(
    "https://zenft-bucket.s3.us-east-1.amazonaws.com/",
    ""
  );
  const isGif = strippedUrl.toLowerCase().endsWith(".gif");

  const imageRequest = {
    bucket: "zenft-bucket",
    key: strippedUrl,
    edits: {
      resize: {
        height: 400,
        fit: "cover",
      },
    },
  };

  const encodedObject = btoa(JSON.stringify(imageRequest));
  const displayURL = `https://d26z45c7morr93.cloudfront.net/${encodedObject}`;

  return isGif ? url : displayURL;
};
export const getUserSmallImageUrl = (url: string) => {
  const baseUrl = "https://assets.zing.it/";
  let strippedUrl = url.replace(baseUrl, "");
  strippedUrl = strippedUrl.replace(
    "https://zenft-bucket.s3.amazonaws.com/",
    ""
  );
  strippedUrl = strippedUrl.replace(
    "https://zenft-bucket.s3.us-east-1.amazonaws.com/",
    ""
  );
  const isGif = strippedUrl.toLowerCase().endsWith(".gif");

  const imageRequest = {
    bucket: "zenft-bucket",
    key: strippedUrl,
    edits: {
      resize: {
        width: 145,
        fit: "cover",
      },
    },
  };

  const encodedObject = btoa(JSON.stringify(imageRequest));

  const displayURL = `https://d26z45c7morr93.cloudfront.net/${encodedObject}`;

  return isGif ? url : displayURL;
};

export interface UserSearchParams {
  status?: boolean;
  search?: string | null | undefined;
  page?: number;
  page_size?: number;
}

export const truncateAddress = (address?: string) => {
  if (!address) return "";
  const match = address.match(
    /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const toCapitalized = (str: string) =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
