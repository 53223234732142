import moment from "moment";
import { blockChainData } from "../../api/modules/nft";
import { EventType } from "../../models/event";
import { NFT } from "../../models/nft";
import { truncateAddress, User } from "../../models/user";
type propType = { nft: NFT; user: User };

const NftActivities = (props: propType) => {
  const { nft, user } = props;
  const eventUser = (owner: User) => {
    if (!owner) return "";
    if (owner?.id === user?.id) return "you";
    return owner.username || truncateAddress(owner.walletAddress);
  };
  return (
    <div className="item_net_activity mt-4">
      <h4>Net Activity</h4>
      <div className="table-wrapper">
        <table className="table table-borderless table-bordered m-0">
          <thead>
            <tr>
              <th>Event</th>
              <th>Price</th>
              <th>From</th>
              <th>To</th>
              <th>Date</th>
            </tr>
          </thead>
          {nft?.events && (
            <tbody>
              {nft.events.map((event, i) => {
                const eventPrice = event.price
                  ? event.price.toLocaleString(undefined, {
                      maximumFractionDigits: 6,
                    })
                  : 0;
                switch (event.type) {
                  case EventType.NftTransferred:
                    return (
                      <tr key={i}>
                        <td>Transferred</td>
                        <td>
                          {eventPrice} {blockChainData[nft.blockchain].symbol}
                        </td>
                        <td>
                          <a href={`/author/${event.from?.id}`}>
                            {" "}
                            {event.from && eventUser(event.from)}
                          </a>
                        </td>
                        <td>
                          <a href={`/author/${event.to?.id}`}>
                            {" "}
                            {event.to && eventUser(event.to)}
                          </a>
                        </td>
                        <td>{moment(event.updatedAt).fromNow()}</td>
                      </tr>
                    );
                  case EventType.NftMinted:
                    return (
                      <tr key={i}>
                        <td>Minted</td>
                        <td>
                          {eventPrice} {blockChainData[nft.blockchain].symbol}
                        </td>
                        <td>0x000000....</td>
                        <td>
                          <a href={`/author/${event.from?.id}`}>
                            {" "}
                            {event.from && eventUser(event.from)}
                          </a>
                        </td>
                        <td>{moment(event.updatedAt).fromNow()}</td>
                      </tr>
                    );
                  case EventType.NftLazyMinted:
                    return (
                      <tr key={i}>
                        <td>Lazy Minted</td>
                        <td>
                          {eventPrice} {blockChainData[nft.blockchain].symbol}
                        </td>
                        <td>0x000000....</td>
                        <td>
                          <a href={`/author/${event.from?.id}`}>
                            {" "}
                            {event.from && eventUser(event.from)}
                          </a>
                        </td>
                        <td>{moment(event.updatedAt).fromNow()}</td>
                      </tr>
                    );
                  case EventType.NftCreated:
                    return (
                      <tr key={i}>
                        <td>Created</td>
                        <td></td>
                        <td></td>
                        <td>
                          <a href={`/author/${event.from?.id}`}>
                            {" "}
                            {event.from && eventUser(event.from)}
                          </a>
                        </td>
                        <td>{moment(event.updatedAt).fromNow()}</td>
                      </tr>
                    );
                  case EventType.NftListed:
                  case EventType.NftAuctionEnabled:
                    return (
                      <tr key={i}>
                        <td>Listed</td>
                        <td>
                          {eventPrice} {blockChainData[nft.blockchain].symbol}
                        </td>
                        <td></td>
                        <td>
                          <a href={`/author/${event.from?.id}`}>
                            {" "}
                            {event.from && eventUser(event.from)}
                          </a>
                        </td>
                        <td>
                          {moment(event.updatedAt).fromNow()}{" "}
                          {event.transaction && (
                            <a
                              target="_blank"
                              href={`${
                                blockChainData[nft.blockchain].baseUrl
                              }/tx/${event.transaction.transactionHash}`}
                              rel="noreferrer"
                            >
                              Track It!
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  case EventType.NftListingCanceled:
                    return (
                      <tr key={i}>
                        <td>Canceled</td>
                        <td>
                          {eventPrice} {blockChainData[nft.blockchain].symbol}
                        </td>
                        <td></td>
                        <td>
                          <a href={`/author/${event.from?.id}`}>
                            {" "}
                            {event.from && eventUser(event.from)}
                          </a>
                        </td>
                        <td>
                          {moment(event.updatedAt).fromNow()}{" "}
                          {event.transaction && (
                            <a
                              target="_blank"
                              href={`${
                                blockChainData[nft.blockchain].baseUrl
                              }/tx/${event.transaction.transactionHash}`}
                              rel="noreferrer"
                            >
                              Track It!
                            </a>
                          )}
                        </td>
                      </tr>
                    );
                  default:
                    return <tr key={i}>{}</tr>;
                }
              })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default NftActivities;
