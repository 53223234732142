import { useState } from "react";
import { NFT } from "../../models/nft";
import ListOfOwners from "./Modals/ListOfOwners";
import { DEFAULT_USER_IMAGE } from "./constants";
import { useNavigate } from "react-router-dom";
import { getUserSmallImageUrl } from "../../models/user";
type propType = { nft: NFT; className?: string };

const NftOwnerDisplay = (props: propType) => {
  const { nft, className } = props;
  const [showOwners, setShowOwners] = useState(false);
  const navigate = useNavigate();

  return (
    <div className={className || "d-flex"}>
      <div
        style={{ cursor: "pointer" }}
        onClick={() => navigate(`/author/${nft.creator?.id}`)}
        className="d-flex align-items-center author_list_pp col-6 p-0"
      >
        <div className="p_list_info">
          <span>
            <img
              className="lazy"
              src={
                nft.creator?.userImage
                  ? getUserSmallImageUrl(nft.creator?.userImage)
                  : DEFAULT_USER_IMAGE
              }
              alt=""
            />
          </span>
        </div>
        <div className="item_author_owner ms-3">
          <span>Creator</span>

          <b className="item_author_username text-purple">
            {" "}
            {nft.creator?.username || "UNKNOWN"}
          </b>
        </div>
      </div>

      {nft.defaultListing && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            nft.owners!.length > 1
              ? setShowOwners(true)
              : navigate(`/author/${nft.owners![0].id}`)
          }
          className="d-flex align-items-center author_list_pp no-hover col-6 p-0 justify-content-end"
        >
          <div className="p_list_info author_list_pp">
            <span>
              <a href={`/author/${nft.defaultListing?.seller?.id}`}>
                <img
                  className="lazy"
                  src={
                    nft.defaultListing.seller?.userImage
                      ? getUserSmallImageUrl(
                          nft.defaultListing.seller?.userImage
                        )
                      : DEFAULT_USER_IMAGE
                  }
                  alt=""
                />
              </a>
            </span>
          </div>
          <div className="item_author_owner ms-3">
            <span>Owner</span>
            <b className="item_author_username text-purple">
              {nft.defaultListing.seller?.username || "UNKNOWN"}
            </b>
          </div>
        </div>
      )}

      {!nft.defaultListing && (
        <div
          style={{ cursor: "pointer" }}
          onClick={() =>
            nft.owners!.length > 1
              ? setShowOwners(true)
              : navigate(`/author/${nft.owners![0].id}`)
          }
          className="d-flex align-items-center author_list_pp no-hover col-6 p-0 justify-content-end"
        >
          {nft.owners?.slice(0, 5).map((owner, index) => (
            <div
              key={index}
              className="d-flex align-items-center circle author_list_pp"
              style={{
                zIndex: 5 - index,
                marginLeft: index === 0 ? 0 : "-20px",
                opacity: 1 - index * 0.2,
              }}
            >
              <div className="p_list_info">
                <span>
                  <img
                    className="lazy"
                    src={
                      owner.userImage
                        ? getUserSmallImageUrl(owner.userImage)
                        : DEFAULT_USER_IMAGE
                    }
                    alt=""
                  />
                </span>
              </div>
            </div>
          ))}
          <div className="ms-3 item_author_owner">
            {nft.owners!.length == 1 && <span>Owner</span>}

            <b className="cursor-pointer text-purple">
              {nft.owners!.length == 1
                ? nft.owners![0].username
                : nft.owners?.length + " Owners"}
            </b>
          </div>
        </div>
      )}

      <ListOfOwners
        show={showOwners}
        nft={nft}
        handleClose={() => setShowOwners(false)}
      />
    </div>
  );
};

export default NftOwnerDisplay;
