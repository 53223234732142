import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import validator from "validator";
import { useActions, useSelector } from "../../../hooks";
import OnBoardingSuccess from "./OnBoardingSuccess";

const OnBoarding = ({ show, handleClose, disconnect }: any) => {
  const { user, error } = useSelector((state) => state.auth);
  const { updateProfile } = useActions();
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [terms, setTerms] = useState(false);
  const [opt, setOpt] = useState(false);
  const [savingData, setSavingData] = useState(false);
  const [customError, setCustomError] = useState("");
  const [onBoardingSuccessModal, setOnBoardingSuccessModal] = useState(false);

  const validateEmail = (email: string) => {
    if (!validator.isEmail(email)) {
      setCustomError("Please use a valid Email Address");
    } else {
      setCustomError("");
    }
    return email;
  };

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  useEffect(() => {
    if (user) {
      setEmail(user?.email || "");
      setUsername(user?.username || "");
    }
  }, [user?.email]);

  return (
    <>
      <Modal
        className="custom modal-bg"
        show={show}
        onHide={() => {
          disconnect && disconnect();
          toast("Please log in again and enter your profile information");
          handleClose();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title className="">You’re almost done!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="almost-done-body">
            <p className="f-13">
              Choose a user name and give us your email to get notified when
              your NFTs sell.
            </p>
            <div>
              <div className="input-div">
                <label className="mb-1 f-13">User Name</label>
                <input
                  type="text"
                  defaultValue={user?.username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>

              <div className="input-div mt-3">
                <label className="mb-1 f-13">Email</label>
                <input
                  type="text"
                  defaultValue={user?.email}
                  onChange={(e) => setEmail(validateEmail(e.target.value))}
                />
                {customError ? (
                  <label className="text-red">{customError}</label>
                ) : (
                  <div />
                )}
              </div>
            </div>
          </div>
          <div className="mt-4 d-flex almost-done-footer">
            <button
              disabled={
                !terms || !!customError || !email || !username || savingData
              }
              className="btn-main wide mb-3 me-3"
              onClick={async () => {
                setSavingData(true);
                await updateProfile(
                  { ...user, username, email, isVerified: true },
                  user?.id!,
                  user?.profileImage,
                  user?.bannerImage,
                  () => {
                    setSavingData(false);
                    handleClose();
                    setOnBoardingSuccessModal(true);
                    setEmail("");
                    setUsername("");
                  }
                );
                setSavingData(false);
              }}
            >
              {savingData ? (
                <ThreeDots
                  height="20"
                  width="60"
                  color="#fff"
                  ariaLabel="loading"
                />
              ) : (
                "COMPLETE SIGN UP"
              )}
            </button>

            <div className="position-relative">
              {!terms ? (
                <label className="f-12 text-red ms-3 warning-custom">
                  You must check the box to sign up
                </label>
              ) : (
                <div />
              )}

              <div className="input-checkbox d-flex align-items-center">
                <input
                  type="checkbox"
                  checked={terms}
                  onChange={() => setTerms(!terms)}
                />
                <label className="f-12 text-gray ms-1">
                  I accept the{" "}
                  <a href="/tos" target="_blank" className="text-purple">
                    Terms of Service
                  </a>{" "}
                  and
                  <a href="/privacy" target="_blank" className="text-purple">
                    Privacy Policy
                  </a>
                </label>
              </div>
              <div className="input-checkbox d-flex align-items-center">
                <input
                  type="checkbox"
                  checked={opt}
                  onChange={() => setOpt(!opt)}
                />
                <label className="f-12 text-gray ms-1">
                  Opt me in for ZingIt Marketing emails
                </label>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <OnBoardingSuccess
        label="Congratulations! It’s for Sale!"
        noteText="Tell the world about your new NFT for sale!"
        show={onBoardingSuccessModal}
        handleClose={() => setOnBoardingSuccessModal(false)}
      />
    </>
  );
};

export default OnBoarding;
