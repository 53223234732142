import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Footer } from "../";
import { useActions, useSelector } from "../../hooks";
import CollectionCard from "../Partials/CollectionCard";

const Collections: React.FC = () => {
  const { fetchMyCollections } = useActions();
  const { myCollections, loading } = useSelector((state) => state.collections);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    fetchMyCollections();
  }, [user?.id]);

  return (
    <div>
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb">
          <div className="container">
            <h2 className="style-2">My Collections</h2>
            <button
              className="btn-main"
              onClick={() => navigate("/create-collection")}
            >
              Create
            </button>
            <div className="spacer-20"></div>

            <CollectionCard
              loading={loading}
              collections={myCollections}
              emptyText="No NFT's found in this collection"
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default Collections;
