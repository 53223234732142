import { memo } from "react";
const NoWallet = () => {
  return (
    <div>
      <section className="container">
        <div className="row">
          <div className="spacer-double"></div>
          <div className="col-md-12 center">
            <h4>No Wallet Connected</h4>
            <br />
            <span
              onClick={() => window.open("/profile", "_self")}
              className="btn-main lead"
            >
              ATTACH YOUR WALLET
            </span>
          </div>
        </div>
      </section>
    </div>
  );
};

export default memo(NoWallet);
