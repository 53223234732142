import { UserSearchParams } from "../../models/user";
import setAuthToken from "../utils/setAuthToken";
//import { NftSearchParams } from '../../models/nft';
const instance = setAuthToken();

const getFeaturedAuthors = (params?: UserSearchParams) => {
  return instance.get(`/featured/authors`, { params });
};

const getTopAuthors = (params?: UserSearchParams) => {
  return instance.get(`/featured/authors/top`, { params });
};

const getAuthors = (params: UserSearchParams) => {
  return instance.get(`/author`, { params });
};
const fetchAuthor = (authorId: string) => {
  return instance.get(`/author/${authorId}`);
};

const fetchAuthorAvailableNfts = (
  authorId: string,
  params?: UserSearchParams
) => {
  return instance.get(`/author/${authorId}/nfts`, {
    params: { ...params, type: "available" },
  });
};

const fetchAuthorNfts = (authorId: string, params?: UserSearchParams) => {
  return instance.get(`/author/${authorId}/nfts`, {
    params: { ...params, type: "created" },
  });
};

const fetchFollowers = (userId: string, params?: UserSearchParams) => {
  return instance.get(`/user/${userId}/followers`, { params });
};

const fetchFollowedUsers = (userId: string, params?: UserSearchParams) => {
  return instance.get(`/user/${userId}/followed`, { params });
};

const fetchUserOffersReceived = (userId: string, params?: UserSearchParams) => {
  return instance.get(`/user/${userId}/offers`, { params });
};

export {
  fetchAuthorNfts,
  fetchAuthorAvailableNfts,
  getTopAuthors,
  fetchAuthor,
  getAuthors,
  getFeaturedAuthors,
  fetchFollowers,
  fetchFollowedUsers,
  fetchUserOffersReceived,
};
