import { ItemType } from "@opensea/seaport-js/lib/constants";
import {
  WETH_ADDRESS,
  WETH_ADDRESS_GOERLI,
  WETH_ADDRESS_SEPOLIA,
  WMATIC_ADDRESS,
  WMATIC_ADDRESS_MUMBAI,
} from "../../constants";
import { NftSearchParams, NftType } from "../../models/nft";
import { OrderAttrs } from "../../models/order";
import getPlausibleInstance from "../utils/getPlausibleInstance";
import setAuthToken from "../utils/setAuthToken";

export const blockChainData: any = {
  polygon: {
    logo: "/img/blockchains/polygon.png",
    symbol: "MATIC",
    baseUrl: "https://polygonscan.com",
    chainId: 137,
    wrappedCoin: WMATIC_ADDRESS.address,
  },
  mainnet: {
    logo: "/img/blockchains/ethereum.png",
    symbol: "ETH",
    baseUrl: "https://etherscan.io/",
    chainId: 1,
    wrappedCoin: WETH_ADDRESS.address,
  },
  polygonMumbai: {
    logo: "/img/blockchains/polygon.png",
    symbol: "MATIC",
    baseUrl: "https://mumbai.polygonscan.com",
    chainId: 80001,
    wrappedCoin: WMATIC_ADDRESS_MUMBAI.address,
  },
  goerli: {
    logo: "/img/blockchains/ethereum.png",
    symbol: "ETH",
    baseUrl: "https://goerli.etherscan.io",
    chainId: 5,
    wrappedCoin: WETH_ADDRESS_GOERLI.address,
  },
  sepolia: {
    logo: "/img/blockchains/ethereum.png",
    symbol: "ETH",
    baseUrl: "https://goerli.etherscan.io",
    chainId: 11155111,
    wrappedCoin: WETH_ADDRESS_SEPOLIA.address,
  },
};
export const chainIdMap: any = {
  1: "mainnet",
  137: "polygon",
  80001: "polygonMumbai",
  11155111: "sepolia",
  5: "goerli",
};

interface Property {
  key: string;
  value: string;
  statistic: string;
}
export interface NFTUpdate {
  tokenId?: string;
  tokenURI?: string;
  nftId: string;
  owner: string;
  name?: string;
  nftFile?: any;
  nftFileType?: string;
  previewImage?: any;
  description?: string;
  link?: string;
  hasUnlockable?: boolean;
  unlockableContent?: string;
  properties?: any[];
  supply?: Number;
  explicit?: Boolean;
  blockchain?: string;
  forSale?: Boolean;
  status?: string;
  price?: number;
  royaltyPercentage?: number;
  nftCollection?: string;
  metaDataFrozen?: boolean;
}

export interface NFTCreate {
  captchaToken: string;
  name: string;
  tokenAddress: string;
  description?: string;
  link?: string;
  hasUnlockable?: boolean;
  unlockableContent?: string;
  properties?: Property[];
  supply?: Number;
  explicit?: Boolean;
  blockchain?: string;
  nftFile?: string;
  nftFileType?: string;
  tokenType?: ItemType;
  previewImage?: string;
  nftCollection?: string;
  price?: number;
  type?: NftType;
  royaltyPercentage?: number;
  tokenURI?: string;
}

const instance = setAuthToken();
const plausibleInstance = getPlausibleInstance();

const createNft = ({
  captchaToken,
  name,
  tokenAddress,
  description,
  link,
  price,
  hasUnlockable,
  unlockableContent,
  properties,
  supply,
  explicit,
  blockchain,
  tokenURI,
  nftFile,
  nftFileType,
  previewImage,
  nftCollection,
  tokenType,
  type,
  royaltyPercentage,
}: NFTCreate) => {
  return instance.post("/nft", {
    captchaToken,
    name,
    tokenAddress,
    description,
    link,
    hasUnlockable,
    unlockableContent,
    properties,
    supply,
    explicit,
    blockchain,
    nftFile,
    nftFileType,
    previewImage,
    nftCollection,
    tokenType,
    type,
    price,
    royaltyPercentage,
  });
};

const placeOrder = (attr: OrderAttrs) => {
  return instance.post(`/nft/${attr.nftId}/orders`, attr);
};
const cancelOrder = (id: string) => {
  return instance.delete(`/orders/${id}`);
};

const listNfts = (params?: NftSearchParams) => {
  return instance.get(`/nft`, { params });
};

const getNftOrders = (id: string) => {
  return instance.get(`/nft/${id}/orders`);
};

const getNftOffers = (id: string) => {
  return instance.get(`/nft/${id}/offers`);
};

const getOneNft = (id: string) => {
  return instance.get(`/nft/${id}/refresh`);
};
const refreshNftMetaData = (id: string) => {
  return instance.post(`/nft/${id}/refresh`);
};

const getFeaturedNfts = (params?: NftSearchParams) => {
  return instance.get(`/featured/nfts`, { params });
};
const getFeaturedGreenNfts = (params?: NftSearchParams) => {
  return instance.get(`/featured/nfts/green`, { params });
};

const getTopNfts = (params?: NftSearchParams) => {
  return instance.get(`/featured/nfts/top`, { params });
};

const getNewNfts = (params?: NftSearchParams) => {
  return instance.get(`/featured/nfts/new`, { params });
};

const getLikedNfts = (userId: string, params?: NftSearchParams) => {
  return instance.get(`/user/${userId}/nfts/liked`, { params });
};

const getPurchasedNfts = (userId: string, params?: NftSearchParams) => {
  return instance.get(`/user/${userId}/nfts/purchased`, { params });
};
const freezeMetadata = (nftId: string, ipfsUrl: string) => {
  return instance.post(`/nft/${nftId}/freezeMetadata`, { tokenURI: ipfsUrl });
};
const updatePrice = (nftId: string, newPrice: number) => {
  return instance.post(`/nft/${nftId}/updatePrice`, { newPrice });
};
const toggleLikeNft = (nftId: string) => {
  return instance.post(`/nft/${nftId}/toggleLike`);
};

const burnNft = (nftId: string) => {
  return instance.delete(`/nft/${nftId}`);
};

const updateOrderPurchase = (orderId: string, amountPurchased: number) => {
  return instance.put(`/orders/${orderId}`, { amountPurchased });
};

const nftPageViews = (nftId: string) => {
  const siteId = window.location.host;
  const page = `/item/${nftId}`;
  return plausibleInstance.get(
    `/stats/aggregate?metrics=visitors&site_id=${siteId}&filters=event:page==${page}&period=custom&date=2021-01-01,2100-01-01`
  );
};

const updateNft = (
  {
    nftId,
    name,
    owner,
    description,
    link,
    hasUnlockable,
    unlockableContent,
    properties,
    supply,
    explicit,
    blockchain,
    nftFile,
    nftFileType,
    previewImage,
    royaltyPercentage,
    forSale,
    status,
    price,
    tokenId,
    nftCollection,
  }: NFTUpdate,
  freezeMetadata: boolean
) => {
  return instance.put(
    `/nft/${nftId}?`,
    {
      ...(tokenId && { tokenId }),
      ...(name && { name }),
      ...(description && { description }),
      ...(owner && { owner }),
      ...(link && { link }),
      ...(hasUnlockable !== undefined && { hasUnlockable }),
      ...(unlockableContent && { unlockableContent }),
      ...(properties && { properties }),
      ...(supply && { supply }),
      ...(explicit !== undefined && { explicit }),
      ...(blockchain && { blockchain }),
      ...(nftFile && { nftFile }),
      ...(nftFileType && { nftFileType }),
      ...(royaltyPercentage && { royaltyPercentage }),
      ...(previewImage && { previewImage }),
      ...(forSale && { forSale }),
      ...(price && { price }),
      ...(status && { status }),
      ...(nftCollection && { nftCollection }),
    },
    { params: { freezeMetadata } }
  );
};

export {
  createNft,
  listNfts,
  getOneNft,
  getFeaturedNfts,
  getFeaturedGreenNfts,
  getTopNfts,
  getNewNfts,
  getLikedNfts,
  getPurchasedNfts,
  toggleLikeNft,
  updateNft,
  nftPageViews,
  placeOrder,
  refreshNftMetaData,
  cancelOrder,
  freezeMetadata,
  updatePrice,
  burnNft,
  getNftOrders,
  getNftOffers,
  updateOrderPurchase,
};
