/* eslint-disable react-hooks/exhaustive-deps */
import { Field, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { setDefaultBreakpoints } from "react-socks";
import { toast } from "react-toastify";
import { createGlobalStyle } from "styled-components";
import { useAccount, useDisconnect } from "wagmi";
import * as Yup from "yup";
import { useActions, useSelector } from "../../../hooks";
import WalletModal from "../Modals/WalletModal";
import WrapEthModal from "../Modals/WrapEth";
import HeaderMenu from "./HeaderMenu";
import MobileHeaderMenu from "./MobileHeaderMenu";
import { clarity } from "react-microsoft-clarity";

const GlobalStyles = createGlobalStyle`
  .mainside{
    .connect-wal{
      display: none;
    }
    .logout{
      display: flex;
      align-items: center;
    }
    
  }
`;

const Header = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  let search = searchParams.get("search");

  const { showWrapCurrencyModal } = useSelector((state) => state.web3);
  const { user } = useSelector((state) => state.auth);
  const { logout, setShowWrapCurrencyModal } = useActions();

  const [showMobileNavigation, toggleMobileNavigation] = useState(false);
  const [showUserMenu, toggleUserMenu] = useState(false);
  const [showWalletMenu, toggleWalletMenu] = useState(false);
  const [showNotificationMenu, toggleNotificationMenu] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [address, setAddress] = useState("");
  const [currPage, setCurrPage] = useState("/");
  const account = useAccount();
  const location = useLocation();
  useEffect(() => {
    if (
      address &&
      (account.address !== address || account.address !== user?.walletAddress)
    ) {
      setAddress("");
      handleLogout();
      // if a transaction in progress, then abort it
    } else if (!address) {
      if (clarity.hasStarted())
        clarity.setTag("accountChange", account.address!);
      setAddress(account.address!);
    }
  }, [account.address]);

  const { nft } = useSelector((state) => state.nfts);

  const { disconnect } = useDisconnect({
    onSuccess() {
      logout();
      setTimeout(() => window.location.reload(), 1000);
    },
  });

  const validationSchema = Yup.object().shape({
    searchTerm: Yup.lazy(() => Yup.string().required("Text Required")),
  });

  const refWalletMenu = useOnclickOutside(() => {
    toggleWalletMenu(false);
  });

  const handleWalletClick = () => {
    toggleWalletMenu(!showWalletMenu);
  };

  const handleNotificationClick = () => {
    toggleNotificationMenu(!showNotificationMenu);
  };
  const handleLogout = () => {
    if (account.isConnected) disconnect();
    else logout();
  };

  useEffect(() => {
    setCurrPage(location.pathname);
  }, []);

  return (
    <>
      <GlobalStyles />
      <header id="myHeader" className="navbar white">
        <div className="container">
          <div className="row w-100-nav">
            <div className="logo px-0">
              <div className="navbar-title navbar-item">
                <Link to="/">
                  <img
                    src="/img/zingitcolored.svg"
                    style={{ height: "50px" }}
                    className="img-fluid d-block"
                    alt="#"
                  />
                  <img
                    src="/img/zingitcolored.svg"
                    style={{ height: "50px" }}
                    className="img-fluid d-3"
                    alt="#"
                  />
                  <img
                    src="/img/zingitcolored.svg"
                    style={{ height: "50px" }}
                    className="img-fluid d-none"
                    alt="#"
                  />
                </Link>
              </div>
            </div>

            <div className="search">
              <Formik
                enableReinitialize
                validationSchema={validationSchema}
                initialValues={{ searchTerm: search || "" }}
                onSubmit={async (values) => {
                  window.location.href = `/search-page?search=${values.searchTerm}`;
                }}
              >
                <Form>
                  <Field
                    type="text"
                    name="searchTerm"
                    id="quick_search"
                    className="form-control xs-hide"
                    placeholder="Search..."
                  />
                </Form>
              </Formik>
            </div>

            <MobileHeaderMenu
              showMobileNavigation={showMobileNavigation}
              setCurrPage={setCurrPage}
              toggleMobileNavigation={toggleMobileNavigation}
              currPage={currPage}
              refWalletMenu={refWalletMenu}
              handleWalletClick={handleWalletClick}
              handleNotificationClick={handleNotificationClick}
              showNotificationMenu={showNotificationMenu}
              showWalletMenu={showWalletMenu}
              handleLogout={handleLogout}
              setShowConnectWallet={setShowConnectWallet}
            />

            <HeaderMenu
              toggleUserMenu={toggleUserMenu}
              setCurrPage={setCurrPage}
              showUserMenu={showUserMenu}
              showNotificationMenu={showNotificationMenu}
              refWalletMenu={refWalletMenu}
              handleWalletClick={handleWalletClick}
              handleNotificationClick={handleNotificationClick}
              showWalletMenu={showWalletMenu}
              handleLogout={handleLogout}
              setShowConnectWallet={setShowConnectWallet}
            />
          </div>

          <button
            className="nav-icon"
            onClick={() => toggleMobileNavigation(!showMobileNavigation)}
          >
            <div className="menu-line white"></div>
            <div className="menu-line1 white"></div>
            <div className="menu-line2 white"></div>
          </button>
        </div>
      </header>
      <WalletModal
        show={showConnectWallet}
        handleClose={() => setShowConnectWallet(false)}
      />
      <WrapEthModal
        nft={nft}
        show={showWrapCurrencyModal}
        handleClose={() => setShowWrapCurrencyModal(false)}
      />
    </>
  );
};
export default Header;
