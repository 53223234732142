import { ItemType } from "@opensea/seaport-js/lib/constants";
import moment from "moment";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { blockChainData } from "../../api/modules/nft";
import { useActions } from "../../hooks";
import { NFT } from "../../models/nft";
import { truncateAddress } from "../../models/user";
import NftStats from "./NftStats";
import MarkdownView from "react-showdown";
import NFTCurrentPrice from "./NFTCurrentPrice";
import { NftActionButtons } from "./NftActionButtons";
import NftOwnerDisplay from "./NftOwnerDisplay";
import UnlockableContentModal from "./Modals/UnlockableContentModal";
type propType = { nft: NFT; stats: any };

const NftInfo = (props: propType) => {
  const { nft, stats } = props;
  const [refreshingMetaData, setRefreshingMetaData] = useState(false);
  const { fetchNftMetaData } = useActions();
  const [showUnlockable, setShowUnlockable] = useState(false);

  const handleRefreshMetaData = async () => {
    setRefreshingMetaData(true);
    if (nft) {
      await fetchNftMetaData(nft.id!);
      setRefreshingMetaData(false);
    }
  };
  return (
    <div className="item_info">
      <NftStats nft={nft} stats={stats} />
      <div className="d-flex flex-row mt-2">
        <div className="item_author col-12 col-lg-6">
          <NFTCurrentPrice nft={nft} />
          <div className="mb-3 d-flex flex-row justify-content-between col-12 col-lg-10">
            <NftActionButtons nft={nft} />
          </div>
          <NftOwnerDisplay className="d-flex col-12 col-lg-10" nft={nft} />
        </div>
      </div>

      {nft.description && (
        <div className="item_author_desc mt-4">
          <h4>Description</h4>
          <div>
            <MarkdownView
              markdown={nft.description}
              options={{ tables: true, emoji: true }}
            />
          </div>
        </div>
      )}
      {nft.hasUnlockable && (
        <button
          className="btn-secondary no-transform mt-4 px-2"
          onClick={() => setShowUnlockable(true)}
        >
          <i className="fa-solid fa-lock me-1"></i>
          <span className="text-black">This NFT has </span>
          <span className="text-purple">Unlockable Content</span>
        </button>
      )}

      <UnlockableContentModal
        show={showUnlockable}
        nft={nft}
        handleClose={() => setShowUnlockable(false)}
      />
    </div>
  );
};

export default NftInfo;
