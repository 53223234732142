import setAuthToken from "../utils/setAuthToken";

const instance = setAuthToken();

interface GetSignedUrlParams {
  folder: "images" | "nfts" | "collections";
  userId: string;
  contentType: string;
}

const getSignedUrl = (params: GetSignedUrlParams) => {
  const { folder, userId, contentType } = params;
  return instance.get(`/upload/${userId}?folder=${folder}&type=${contentType}`);
};

export { getSignedUrl };
