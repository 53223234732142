const WhatIsAWallet = () => {
  return (
    <div className="container pt-4">
      <p className="f-22">What is a wallet?</p>

      <p>
        What is a Wallet? In simple words,{" "}
        <span className="f-600">web3 wallets</span> allow users to store, send,
        and receive cryptocurrencies. Plus, through these wallets,{" "}
        <span className="f-600">users</span>{" "}
        <span className="f-600">
          interact with smart contracts to buy, sell, and create NFTs
        </span>
        and connect with communities.
      </p>
      <p>
        Overall, they are <span className="f-600">non-custodial wallets</span>,
        meaning you can transact in a more secure way without worrying about
        middlemen such as banks. Also, you have full control over your funds,
        and you don’t have to go through the series of
        KnowYourCustomer/AntiMoneyLaundering steps while creating one.
      </p>
      <p>Here are some of the major terms associated with web3 wallets:</p>
      <ul>
        <li>
          <span className="f-600">Public Key:</span> This is an alphanumeric
          string that allows you to deposit and receive crypto transactions.
        </li>
        <li>
          <span className="f-600">Private Key:</span> Similar to passwords, it
          is a large number used to confirm transactions. Everyone has access to
          web3 wallet public keys, but a private key allows you to claim
          ownership to access your funds.
        </li>
        <li>
          <span className="f-600">Seed Phrase:</span> It is a list of 12–24
          random words used to retrieve a crypto wallet.
        </li>
      </ul>
      <p>
        You can use your web3 wallet from anywhere in the world. Let’s explore
        its major benefits.
      </p>
      <p className="f-16 f-600">Privacy and Anonymity</p>
      <p>
        Protection of users’ privacy is one of the best features web3 wallets
        brought. Using these wallets, your personal data is safe and protected.
        This means that while you transact with another address, it is almost
        impossible to know the recipient.
      </p>
      <p className="f-16 f-600">Enhanced Security</p>
      <p>
        The safety of your funds is another important benefit you get from using
        web3 wallets. They ensure your investment is safe and secure while
        transacting. In addition, your information, identification, and
        accessibility to your funds are secure. Most of the wallets use an
        encrypted sign-up/sign-in procedure, requiring you to input your
        details. This ensures there’s no breach and others can’t access your
        wallets.
      </p>
      <p className="f-22 f-600">How to Set Up a New Wallet</p>
      <p className="f-16 f-600">Set up a Coinbase wallet</p>
      <p>The steps are easy:</p>
      <ol>
        <li>
          Download and install the <a href="">Coinbase Wallet browser extension</a> from
          Coinbase.
        </li>
        <li>Select “Create a new wallet.”</li>
        <li>
          Record your uniquely assigned Secret Recovery Phrase. If you lose this
          series of words or forget what order they’re in, you will lose access
          to your wallet, and it will be unrecoverable. We recommend writing the
          phrase on a piece of paper and storing it in a memorable, safe, and
          secure place.
        </li>
        <li>
          The next step will ask you to confirm the phrase by selecting the
          first and last words of the phrase respectively.
        </li>
        <li>Next, create a username and password.</li>
        <li>
          Coinbase Wallet should now be set up and will appear in your browser
          extensions.
        </li>
        <li>If you have any trouble, visit <a href="">Coinbase Support.</a></li>
      </ol>
      <p>Was this content helpful? <a href="">Let us know.</a></p>
    </div>
  );
};

export default WhatIsAWallet;
