import React, { useState, CSSProperties, forwardRef } from "react";

interface BuyButtonProps {
  onClick: (quantity: number) => void;
  maxQuantity: number;
  disabled: boolean;
  minQuantity: number;
}

const BuyButton = forwardRef((props: BuyButtonProps, ref) => {
  const { onClick, maxQuantity, disabled, minQuantity } = props;
  const [quantity, setQuantity] = useState<number>(1);

  const buttonStyle: CSSProperties = {
    transition: "all 0.3s ease",
  };

  const quantityInputStyle: CSSProperties = {
    borderRadius: "5px",
    width: "50px",
    textAlign: "center",
    marginRight: "10px",
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newQuantity = Number(e.target.value);
    // Ensure newQuantity is at least 1 and not more than maxQuantity
    newQuantity = Math.max(minQuantity, Math.min(maxQuantity, newQuantity));
    setQuantity(newQuantity);
  };

  const handleClick = () => {
    onClick(quantity);
  };
  let supportMultiple = false;

  if (maxQuantity > 1 && supportMultiple) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          disabled={disabled}
          type="number"
          max={maxQuantity}
          min={minQuantity}
          value={quantity}
          onChange={handleQuantityChange}
          style={quantityInputStyle}
        />
        <button
          disabled={disabled}
          className="btn-main col-6 me-2"
          style={buttonStyle}
          onClick={handleClick}
        >
          {`Buy ${quantity} Now`}
        </button>
      </div>
    );
  } else {
    return (
      <button
        disabled={disabled}
        className="btn-main col-6 me-2"
        style={buttonStyle}
        onClick={handleClick}
      >
        {"Buy Now"}
      </button>
    );
  }
});

export default BuyButton;
