import { publicProvider } from "@wagmi/core/providers/public";
import { configureChains, createConfig } from "wagmi";
import { goerli, mainnet, polygon, polygonMumbai, sepolia } from "wagmi/chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { alchemyProvider } from "wagmi/providers/alchemy";

const { chains, publicClient } = configureChains(
  [goerli, polygon, polygonMumbai, mainnet, sepolia],
  [
    alchemyProvider({
      apiKey: "e-xOq-MYcEzf_9zhdVKwNNAMA5BhgBdi",
    }),
    publicProvider(),
  ]
);

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "ZingIt",
      },
    }),
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "9cf7e87939ed913960fd02a5f559a10b",
        showQrModal: true,
      },
    }),
  ],
  publicClient,
});

export { chains, wagmiConfig };
