import { useNavigate } from "react-router-dom";

const Page404 = () => {
  const navigate = useNavigate();
  return (
    <div className="error-404">
      <div>
        <h2>YOU'VE FOUND NOTHING!</h2>
        <p>
          If that was your intention, congratulations!! <br />
          If not, we suggest you go back HOME and start over...
        </p>
        <button className="btn-main" onClick={() => navigate("/")}>
          GO HOME
        </button>
      </div>

      <div>
        <img
          src="/img/error_500.png"
          className="img-fluid lonely-guy"
          alt="#"
        />
      </div>
    </div>
  );
};

export default Page404;
