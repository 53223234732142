/* eslint-disable react-hooks/exhaustive-deps */
import { Editor } from "@tinymce/tinymce-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { blockChainData } from "../../api/modules/nft";
import { PLATFORM_FEE_PERCENTAGE } from "../../constants";
import { useActions, useConversion, useSelector } from "../../hooks";
import { useDisplayPrice } from "../../hooks/modules/useDisplayPrice";
import { Collection } from "../../models/collection";
import { IAttribute, NFT, NftType } from "../../models/nft";
import { toCapitalized } from "../../models/user";
import { validateCurrencyValue } from "../../utils/validation";
import Footer from "../Partials/Footer";
import BurnNftModal from "../Partials/Modals/BurnNftModal";
import CreateCollectionModal from "../Partials/Modals/CreateCollectionModal";
import NftDisplay from "../Partials/NftDisplay";
import { DEFAULT_AUDIO_IMAGE, DEFAULT_USER_IMAGE } from "../Partials/constants";
import FadeLoader from "react-spinners/ClipLoader";

const EditNft = () => {
  const acceptedFiles =
    ".PNG, .JPG, .GIF, .WEBP, .MP4, .SVG, .WEBM, .MP3, .WAV, .OGG, .GLB, .GLTF";
  const { user } = useSelector((state: any) => state.auth);
  const { fetchMyCollections, fetchNft } = useActions();
  const { myCollections, loading: collectionsLoading } = useSelector(
    (state) => state.collections
  );

  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [freezingMetaData, setFreezingMetadata] = useState(false);
  const [price, setPrice] = useState<any>(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState<string | undefined>("");
  const [nftFile, setNftFile] = useState<any>(null);
  const [previewImageFile, setPreviewImageFile] = useState<any>("");
  const [requirePreviewImage, setRequirePreviewImage] = useState(false);
  const [link, setLink] = useState<string | undefined>("");
  const [properties, setProperties] = useState<IAttribute[]>([]);
  const [trait_type, setTrait_type] = useState("");
  const [value, setValue] = useState("");
  const [statistic, setStatistic] = useState("");
  const [hasUnlockable, setHasUnlockable] = useState(false);
  const [unlockableContent, setUnlockableContent] = useState<
    string | undefined
  >("");
  const [explicit, setExplicit] = useState(false);
  const [supply, setSupply] = useState(0);
  const [collection, setCollection] = useState("");
  const [blockchain, setBlockchain] = useState("ethereum");
  const [type, setType] = useState("");
  const { updateNft, createCollection } = useActions();
  const navigate = useNavigate();
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [royaltyPercentage, setRoyaltyPercentage] = useState(0);
  const [tokenURI, setTokenURI] = useState("");
  const [priceError, setPriceError] = useState("");
  const [displayPrice] = useDisplayPrice(Number(price));
  const [royaltyError, setRoyaltyError] = useState("");
  const [showBurnNft, setShowBurnNft] = useState(false);
  const [invalidLink, setInvalidLink] = useState(false);

  const [showCollectionModal, setShowCollectionModal] = useState(false);
  const handleShowAddCollectionModal = () => setShowCollectionModal(true);

  const [previewUrl, setPreviewUrl] = useState("");
  const [displayUrl, setDisplayUrl] = useState("");
  const displayRef: any = useRef();

  let { id: nftId } = useParams();
  const { nft } = useSelector((state) => state.nfts);
  const { priceUSD } = useConversion(
    price || 0,
    nft ? blockChainData[nft.blockchain].symbol : "ETH"
  );

  const handleImageData = async (data: string) => {
    const base64Response = await fetch(data);
    const blob = await base64Response.blob();
    setPreviewImageFile(blob);
    setPreviewUrl(URL.createObjectURL(blob));
  };

  const handleLinkChange = (event: any) => {
    const value = event.target.value;

    if (value.match(/^(http:\/\/|https:\/\/)/i) || value === "") {
      setLink(value);
      setInvalidLink(false);
    } else {
      setInvalidLink(true);
    }
  };

  useEffect(() => {
    if (nftId) {
      fetchMyCollections(() => {
        fetchNft(nftId!, () => {
          setPageLoading(false);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (nft && myCollections?.length) {
      if (!nft.isAnOwner || nft.metaDataFrozen || nft.tokenId) {
        navigate("/"); //unauthorized access
      }
    }
    if (nft && !type) {
      // TODO: !type is needed to handle the dual render
      setName(nft!.name);
      setPrice(nft!.price);
      setDescription(nft!.description);
      setNftFile(nft.nftMetaData.animation_url);
      setPreviewImageFile(nft.nftMetaData.thumbnail_image);
      setPreviewUrl(nft.nftMetaData.thumbnail_image!);
      setDisplayUrl(nft.nftMetaData.animation_url!);
      setUnlockableContent(nft.unlockableContent);
      setRequirePreviewImage(
        nft.nftMetaData?.file_type!.includes("audio") ||
          nft.nftMetaData?.file_type!.includes("video")
      );

      setRoyaltyPercentage(nft!.royaltyPercentage);
      setSupply(nft!.supply);
      setTokenURI(nft!.tokenURI);
      setLink(nft.nftMetaData.external_url);
      setCollection(nft!.nftCollection?.id || "");
      setType(nft!.type);
      nft?.nftMetaData?.attributes &&
        setProperties(nft!.nftMetaData.attributes);
      setExplicit(nft!.nftMetaData.nsfw);
      setHasUnlockable(nft!.hasUnlockable ? true : false);
    }
  }, [nft]);

  const isURL = (data: any) => {
    return !(data instanceof Blob || data instanceof File);
  };
  const inValidateSize = (filesArr: any) =>
    filesArr.find((x: any) => {
      const fileSize = Math.round(x.size / 1024);
      return fileSize >= 200000 || fileSize < 1;
    });

  const handleCreateCollection = async (
    collectionName: string,
    image: string
  ) => {
    setLoading(true);
    await createCollection(
      collectionName,
      user?.id!,
      image,
      image,
      image,
      "",
      async (collection?: Collection, error?: string) => {
        if (collection) {
          setLoading(true);
          setShowCollectionModal(false);
          await fetchMyCollections();
          setCollection(collection?.id?.toString() || "undefined");
        } else {
          toast.error(error || "Collection Creation Failed!");
        }
        setLoading(false);
      },
      "",
      "",
      "",
      "",
      ""
    );
    setLoading(false);
  };

  const handleFileImport = (e: any) => {
    const filesArr = Array.prototype.slice.call(e.target.files);
    if (inValidateSize(filesArr)) {
      toast.error("Invalid File Size.");
      return;
    }

    const file = filesArr[0];
    if (!file) {
      return;
    }

    setPreviewImageFile(null);
    setPreviewUrl("");

    const mediaType = file.type.split("/")[0];
    !isURL(nftFile) && URL.revokeObjectURL(nftFile);
    setNftFile(file);
    setDisplayUrl(URL.createObjectURL(file));

    if (mediaType === "audio") {
      setPreviewImageFile(DEFAULT_AUDIO_IMAGE);
      setPreviewUrl(DEFAULT_AUDIO_IMAGE);
    }
    setRequirePreviewImage(mediaType === "audio" || mediaType === "video");
    resetVideo();
  };
  const handlePreviewImageImport = (e: any) => {
    const filesArr = Array.prototype.slice.call(e.target.files);
    if (inValidateSize(filesArr)) {
      toast.error("Invalid File Size.");
      return;
    }

    const file = filesArr[0];
    if (!file) {
      return;
    }
    !isURL(previewImageFile) && URL.revokeObjectURL(previewImageFile);
    setPreviewImageFile(file);

    setPreviewUrl(URL.createObjectURL(file));
    resetVideo();
  };

  const resetVideo = () => {
    const videoElement = displayRef.current;
    if (!videoElement) return;
    videoElement.currentTime = 0;
    videoElement.pause();
    videoElement.load();
  };

  const handelEditNft = async (freezeMetadata = false) => {
    toast.dismiss();
    if (
      requirePreviewImage &&
      !previewImageFile &&
      !nft?.nftMetaData.thumbnail_image
    ) {
      toast.error("Preview Image Required!");

      window.scrollTo(0, 0);
      return;
    }
    if (user?.id) {
      setLoading(true);
      const nftFileType = nftFile?.type;
      await updateNft(
        {
          nft: nft!,
          callback: (nft: NFT | null) => {
            setLoading(false);
            if (nft) navigate("/item/" + nft.id);
            else {
              toast.error("A Error occurred creating the NFT!");
            }
          },
          nftId: nft?.id!,
          owner: user?.id!,
          name,
          previewImage: previewImageFile,
          nftFile,
          nftFileType,
          description,
          tokenURI,
          link,
          hasUnlockable,
          unlockableContent,
          properties,
          royaltyPercentage,
          supply,
          explicit,
          nftCollection: collection,
          price,
        },
        freezeMetadata
      );
    } else {
      toast.error("Check Required Fields!");
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    window.document.title = "ZingIt | Edit NFT ";
  }, []);

  const handleDescriptionChange = useCallback((value: string) => {
    setDescription(value);
  }, []);

  const handleUnlockableContentChange = useCallback((value: string) => {
    setUnlockableContent(value);
  }, []);

  const handleSetPrice = async (newValue: string) => {
    toast.dismiss();
    const errorMessage = validateCurrencyValue(newValue);
    if (errorMessage) {
      setPriceError(errorMessage);
    } else {
      setPriceError("");
      setPrice(newValue);
    }
  };

  const handleSetRoyalty = async (newValue: string) => {
    toast.dismiss();
    const errorMessage = validateCurrencyValue(newValue, 2);
    if (errorMessage) {
      setRoyaltyError(errorMessage);
    } else {
      setRoyaltyError("");
      setRoyaltyPercentage(Number(newValue));
    }
  };

  if (pageLoading || collectionsLoading) {
    return (
      <div className="loader">
        <FadeLoader
          loading={pageLoading || collectionsLoading}
          aria-label="Loading Spinner"
          size={100}
          data-testid="loader"
        />
      </div>
    );
  }
  if (!nft) {
    return <></>;
  }
  return (
    <div>
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h2 className="style-2">Edit NFT</h2>
              </div>
            </div>
          </div>
        </div>
        <section className="container">
          <div className="row mx-0">
            <div className="col-lg-8 mb-5">
              <form
                id="form-create-item"
                className="form-border"
                onSubmit={(e) => e.preventDefault()}
              >
                <div className="field-set">
                  <h5 className="mb-0 f-300">
                    <span>
                      Upload your NFT Image, Video or Audio
                      <span className="text-danger">*</span>
                    </span>
                  </h5>
                  {!nftFile && (
                    <span className="text-danger f-12 c-nft-info ">
                      This field is required
                    </span>
                  )}
                  <div className="d-create-file">
                    <p id="file_name">{acceptedFiles} Max 200mb.</p>

                    <div className="browse">
                      <input
                        type="button"
                        disabled={loading}
                        id="get_file"
                        className="btn-main"
                        value="Browse"
                      />
                      <input
                        id="upload_file"
                        disabled={loading}
                        type="file"
                        multiple={false}
                        accept={acceptedFiles}
                        onChange={(event) => {
                          handleFileImport(event);
                        }}
                      />
                    </div>
                  </div>
                  <div className="spacer-10" />
                  {requirePreviewImage && (
                    <>
                      <h5>
                        Preview Image
                        <span className="text-danger">*</span>
                      </h5>

                      <div className="d-create-file">
                        <p id="file_name">
                          {" "}
                          Please provide a custom preview image (PNG, JPG, or
                          GIF){" "}
                        </p>

                        <div className="browse">
                          <input
                            type="button"
                            disabled={loading}
                            id="get_file"
                            className="btn-main"
                            value="Browse"
                          />
                          <input
                            id="upload_file"
                            disabled={loading}
                            type="file"
                            multiple={false}
                            accept={".PNG, .JPG, .GIF"}
                            onChange={(event) => {
                              handlePreviewImageImport(event);
                            }}
                          />
                        </div>
                      </div>
                      <div className="spacer-10" />
                    </>
                  )}

                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-2 f-300 d-flex flex-column">
                      <span>
                        Title<span className="text-danger">*</span>
                      </span>
                      <span className="my-0 f-12 c-nft-info">
                        Your title will appear on the item's detail page.
                      </span>
                      {!name && (
                        <span className="text-danger f-12 c-nft-info ">
                          Name is required
                        </span>
                      )}
                    </h5>
                    <p className="mb-0">
                      <span className="text-danger">*</span>Required Fields
                    </p>
                  </div>
                  <input
                    defaultValue={nft?.name}
                    type="text"
                    disabled={loading}
                    name="item_title"
                    id="item_title"
                    className="form-control"
                    placeholder="e.g. 'Crypto Funk"
                    onChange={(event) => setName(event.target.value)}
                  />

                  <div className="spacer-10"></div>
                  <h5 className="mb-2 f-300 d-flex flex-column">
                    <span>Description</span>
                    <span className="my-0 f-12 c-nft-info">
                      Your description will appear on the item’s detail page.
                    </span>
                  </h5>
                  <Editor
                    initialValue={nft.description}
                    apiKey="tldx88f1crkakdy51vzb6cm2ljlvl4v075uxcpt3v0ug8wwk"
                    onEditorChange={handleDescriptionChange}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | link " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  <div className="spacer-10"></div>

                  <h5 className="mb-2 f-300 d-flex flex-column">
                    Item Link
                    <span className="my-0 f-12 c-nft-info">
                      ZingIt will include a URL to your website, showcasing your
                      NFT.
                    </span>
                    {invalidLink && (
                      <span className="text-danger f-12 ms-0 mt-1">
                        Enter valid Link
                      </span>
                    )}
                  </h5>
                  <input
                    defaultValue={nft?.nftMetaData?.external_url}
                    type="text"
                    name="ext_link"
                    disabled={loading}
                    pattern="^(http:\/\/|https:\/\/).*$"
                    id="ext_link"
                    onChange={handleLinkChange}
                    className="form-control"
                    placeholder="https://yoursite.com/asset/1"
                  />

                  <div className="spacer-10"></div>

                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 className="mb-2 f-300 d-flex flex-column">
                        Collection
                        <span className="my-0 f-12 c-nft-info">
                          Add your NFT to a Collection
                        </span>
                      </h5>
                    </div>
                    <div>
                      <input
                        disabled={collectionsLoading || loading}
                        type="button"
                        id="submit"
                        className="btn-transparent"
                        value="ADD +"
                        onClick={() => handleShowAddCollectionModal()}
                      />
                    </div>
                  </div>
                  {nft && (
                    <select
                      className="form-select"
                      disabled={loading}
                      defaultValue={nft.nftCollection?.id}
                      aria-label="Default select example"
                      onChange={(e) => setCollection(e.target.value)}
                    >
                      {!myCollections?.length && (
                        <option value={"undefined"}>
                          {user?.username
                            ? toCapitalized(user?.username)
                            : "Undefined"}{" "}
                          Collection
                        </option>
                      )}
                      {myCollections?.length &&
                        myCollections?.map((item) => {
                          return (
                            <React.Fragment key={item.id}>
                              <option value={item.id}>{item.name}</option>
                            </React.Fragment>
                          );
                        })}
                    </select>
                  )}

                  <div className="spacer-20"></div>

                  {nft?.type === NftType.ZingItTradable1155 && (
                    <>
                      <h5>Supply</h5>
                      <p>
                        The number of items than can be minted. No gas cost to
                        you!
                      </p>
                      <div className="spacer-20" />
                      <input
                        type="number"
                        disabled={loading}
                        name="supply"
                        id="supply"
                        className="form-control"
                        value={supply}
                        onChange={(event) =>
                          setSupply(Number(event.target.value))
                        }
                      />

                      <div className="spacer-30"></div>
                    </>
                  )}
                  <div className="d-flex c-nft-price">
                    <div>
                      <h5 className="mb-0 f-300">Price</h5>
                      {priceError && (
                        <span className="text-danger">{priceError}</span>
                      )}
                      <div className="inner mb-2">
                        <div className="c-nft-input">
                          <input
                            type="text"
                            name="item_title"
                            id="item_title"
                            disabled={loading}
                            className="form-control"
                            defaultValue={
                              nft?.price > 0 ? nft?.price : undefined
                            }
                            placeholder={`0 ${
                              blockChainData[nft.blockchain].symbol
                            }`}
                            onChange={(event) => {
                              handleSetPrice(event.target.value);
                            }}
                          />
                          <small>${priceUSD} USD</small>
                        </div>

                        <img
                          style={{
                            marginTop: "5px",
                            width: "auto",
                            height: "25px",
                          }}
                          className="ms-1"
                          src={blockChainData[nft.blockchain].logo}
                          alt={blockChainData[nft.blockchain].symbol}
                        />
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-0 f-300">Royalties</h5>
                      {royaltyError && (
                        <div className="mb-1">
                          <span className="text-danger">{royaltyError}</span>
                        </div>
                      )}
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-inline-flex align-items-center justify-content-end">
                          <input
                            type="text"
                            name="item_title"
                            id="item_title"
                            disabled={loading}
                            className="form-control mb-0"
                            placeholder={"0%"}
                            defaultValue={nft?.royaltyPercentage || ""}
                            onChange={(event) => {
                              handleSetRoyalty(event.target.value);
                            }}
                          />
                          <small>%</small>
                        </div>
                        <span className="my-0 f-12 c-nft-info">
                          Enter % requested on future sales
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="spacer-20"></div>

                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h5 className="mb-2 f-300 d-flex flex-column">
                          Properties
                          <span className="my-0 f-12 c-nft-info">
                            Add the description of the unique properties of your
                            NFT
                          </span>
                        </h5>
                      </div>
                      <div style={{ marginLeft: "40%" }}>
                        <input
                          disabled={
                            nft?.metaDataFrozen || collectionsLoading || loading
                          }
                          type="button"
                          id="submit"
                          className="btn-transparent"
                          value="ADD +"
                          onClick={() => setShowAddProperty(true)}
                        />
                      </div>
                    </div>

                    {properties?.length > 0 && (
                      <div>
                        {properties.map((item, i) => {
                          return (
                            <div key={i}>
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                  <div className="ms-2 me-auto">
                                    <div className="fw-bold">
                                      {item.trait_type}
                                    </div>
                                    {item.value}
                                  </div>
                                  <div className="icontype">
                                    <i
                                      className="fa fa-trash fa-lg"
                                      style={{
                                        color: "purple",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        let tempArr = [...properties];
                                        tempArr.splice(i, 1);
                                        setProperties(tempArr);
                                      }}
                                    ></i>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </>

                  <div className="spacer-20"></div>

                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="mb-2 f-300 d-flex flex-column">
                        Unlockable Content
                        <span className="my-0 f-12 c-nft-info">
                          Include unlockable content that can only be revealed
                          by the owner of the item.
                        </span>
                        {hasUnlockable && !unlockableContent && (
                          <span className="text-danger f-12 c-nft-info ms-0">
                            Content Required
                          </span>
                        )}
                      </h5>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          disabled={loading}
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={hasUnlockable}
                          onChange={() => setHasUnlockable(!hasUnlockable)}
                        />
                      </div>
                    </div>
                  </div>

                  {hasUnlockable ? (
                    <>
                      <Editor
                        initialValue={nft?.unlockableContent || ""}
                        id="unlockable_content"
                        apiKey="tldx88f1crkakdy51vzb6cm2ljlvl4v075uxcpt3v0ug8wwk"
                        onEditorChange={handleUnlockableContentChange}
                        init={{
                          height: 200,
                          menubar: false,
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | blocks | link " +
                            "bold italic forecolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                      <div className="spacer-20" />
                    </>
                  ) : null}

                  <div className="spacer-20"></div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="mb-2 f-300 d-flex flex-column">
                        Explicit & Sensitive Content
                        <span className="my-0 f-12 c-nft-info">
                          Set this item as explicit and sensitive content
                        </span>
                      </h5>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input text-purple"
                          type="checkbox"
                          disabled={loading}
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={explicit}
                          onChange={() => setExplicit(!explicit)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="spacer-30"></div>
                  <div className="mt-3 custom-fee">
                    <div className="d-flex align-items-center">
                      <div className="f-15 sellthis-title fw-600 mb-0">
                        <h5 className="mb-0 f-300 d-inline-flex align-items-center">
                          Fees
                          <span className="text-purple f-12 ms-1">
                            <i className="fa-solid fa-circle-exclamation"></i>
                          </span>
                        </h5>
                      </div>
                    </div>
                    <p className="text-gray my-1 f-13 mb-0">
                      <span>
                        {PLATFORM_FEE_PERCENTAGE}% Final Sale Fee (only charged
                        if NFT sells)
                      </span>
                    </p>
                  </div>

                  {!loading ? (
                    <div className="d-flex justify-content-between mt-3">
                      <button
                        onClick={() => setShowBurnNft(true)}
                        className="btn-red btn-disabled me-2 col-4"
                      >
                        Delete nft
                      </button>
                      {!nft?.metaDataFrozen &&
                        true &&
                        (freezingMetaData ? (
                          <ThreeDots
                            height="60"
                            width="60"
                            color="#8364e2"
                            ariaLabel="loading"
                          />
                        ) : (
                          <button
                            className="btn-secondary me-2 col-4"
                            disabled={loading}
                            onClick={() => handelEditNft(true)}
                          >
                            Freeze Metadata
                          </button>
                        ))}
                      {nft?.metaDataFrozen && (
                        <button
                          disabled={true}
                          className="btn-transparent btn-disabled me-2 col-4"
                        >
                          Metadata Frozen
                        </button>
                      )}
                      <button
                        disabled={
                          nft?.metaDataFrozen ||
                          !name ||
                          collectionsLoading ||
                          loading ||
                          invalidLink ||
                          (hasUnlockable && !unlockableContent) ||
                          !!royaltyError ||
                          !!priceError ||
                          (requirePreviewImage && !previewImageFile)
                        }
                        onClick={() => handelEditNft(false)}
                        type="submit"
                        className="btn-main col-4"
                      >
                        Save Changes
                      </button>
                    </div>
                  ) : (
                    <div>
                      <ThreeDots
                        height="60"
                        width="60"
                        color="#8364e2"
                        ariaLabel="loading"
                      />
                      <p style={{ color: "#8364e2" }}>Updating NFT</p>
                    </div>
                  )}
                </div>
              </form>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 ml-custom">
              <h5>Preview item</h5>
              <div className="nft__item m-0">
                <div className="author_list_pp mb-3 p-2">
                  <span>
                    <img
                      className="lazy"
                      src={user?.userImage || DEFAULT_USER_IMAGE}
                      alt=""
                    />
                    {/* <i className='fa fa-check'></i> */}
                  </span>
                </div>
                <div className="nft__item_wrap">
                  {nft && (
                    <NftDisplay
                      displayRef={displayRef}
                      onImageData={handleImageData}
                      doNotShrink
                      extractPreview
                      className={"lazy nft__item_preview"}
                      fileType={nftFile?.type || nft.nftMetaData?.file_type}
                      nftFile={
                        nftFile
                          ? isURL(nftFile)
                            ? nftFile
                            : displayUrl
                          : `${nft.nftMetaData?.animation_url}`
                      }
                      previewImageFile={
                        previewImageFile
                          ? isURL(previewImageFile)
                            ? previewImageFile
                            : previewUrl
                          : ""
                      }
                    />
                  )}
                </div>
                <div className="nft__item_info p-2">
                  <span>
                    <h4>{name}</h4>
                  </span>
                  {displayPrice && nft && (
                    <div className="nft__item_price">
                      <p className="curr_price">Current price</p>
                      <p className="d-flex" style={{ margin: "5px 0px" }}>
                        <img
                          style={{
                            width: "auto",
                            height: "25px",
                            marginRight: "8px",
                          }}
                          src={blockChainData[nft.blockchain].logo}
                          alt={blockChainData[nft.blockchain].symbol}
                        />
                        <span className="price-eth">
                          {displayPrice} {blockChainData[nft.blockchain].symbol}
                        </span>{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <Footer />
      {showAddProperty && (
        <div className="checkout">
          <div className="maincheckout">
            <button
              className="btn-close"
              onClick={() => setShowAddProperty(false)}
            ></button>
            <div>
              <h6>Create A Property</h6>
            </div>
            <div className="detailcheckout mt-4">
              <div className="listcheckout">
                <h6>Property Name</h6>
                <input
                  type="text"
                  disabled={loading}
                  name="buy_now_qty"
                  id="buy_now_qty"
                  placeholder="e.g. 'Background Color'"
                  onChange={(event) => setTrait_type(event.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="detailcheckout mt-4">
              <div className="listcheckout">
                <h6>Property Value</h6>
                <input
                  type="text"
                  name="buy_now_qty"
                  disabled={loading}
                  id="buy_now_qty"
                  placeholder="e.g. 'Green"
                  onChange={(event) => setValue(event.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <button
              className="btn-main lead mb-5"
              onClick={() => {
                let tempArr = properties;
                tempArr.push({ value, trait_type, display_type: "string" });
                setProperties(tempArr);
                setShowAddProperty(false);
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
      <CreateCollectionModal
        show={showCollectionModal}
        handleClose={() => setShowCollectionModal(false)}
        handleCreateCollection={handleCreateCollection}
      />
      <BurnNftModal
        show={showBurnNft}
        nft={nft}
        handleClose={(wasBurned: boolean) => {
          if (wasBurned) {
            navigate("/");
          } else {
            setShowBurnNft(false);
          }
        }}
      />
    </div>
  );
};

export default EditNft;
