/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useEffect, useState } from "react";
import FadeLoader from "react-spinners/ClipLoader";
import { NFT } from "../../models/nft";

import NftCard from "./NftCard";
// import { shuffleArray } from '../../state/utils';
// import { useSearchParams } from "react-router-dom";

//react functional component
const NftGrid = ({
  showLoadMore = true,
  fetchMore = (params: any) => {},
  list,
  total,
  cardClassName,
  loading,
  showSell = false,
  noNftComponent = null,
  showEmptyText = true,
  emptyText = "Oops! No Nfts found. Head to our explore page for exciting discoveries.",
}: any) => {
  const [page, setPage] = useState(1);
  const [percentageLoaded, setPercentageLoaded] = useState(0);

  const fetch = async (newPage: number) => {
    fetchMore({ page: newPage }, true);
  };

  useEffect(() => {
    setPercentageLoaded(getPercentage());
  }, [JSON.stringify(list)]);

  const loadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    fetch(newPage);
  };

  const getPercentage = () =>
    list ? Math.floor((list.length / total) * 100) : 0;

  if (loading && page === 1)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <FadeLoader
          className="text-center"
          loading={loading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );

  if ((!list || !list.length) && showEmptyText)
    return noNftComponent || <h2 className="no-result-h2"> {emptyText}</h2>;

  return (
    <div className="row">
      {list &&
        list.map((nft: NFT, index: number) => (
          <NftCard nft={nft} key={index} className={cardClassName} />
        ))}

      {showLoadMore && percentageLoaded < 100 && (
        <div className="col-lg-12">
          <div className="spacer-single"></div>
          <button
            onClick={() => !loading && loadMore()}
            disabled={loading}
            className="btn-main m-auto"
          >
            {loading ? "Loading..." : `Load More (${percentageLoaded}%)`}
          </button>
        </div>
      )}
    </div>
  );
};

export default memo(NftGrid);
