import { User } from "../../models/user";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface AuthState {
  loading: boolean;
  error: string | null;
  user: User | null;
  walletAddress: string;
  signature: string;
  sigVerified: boolean;
  hasWalletConnected: boolean;
  userId: string;
  token: string | null;
  isAuthenticated: boolean;
  nonce: string | null;
}

const initialState = {
  loading: false,
  error: null,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")!)
    : null,
  userId: "",
  walletAddress: "",
  signature: "",
  sigVerified: false,
  hasWalletConnected: false,
  token: localStorage.getItem("token"),
  isAuthenticated: false,
  nonce: "",
};

const reducer = (
  state: AuthState = initialState,
  action: Action
): AuthState => {
  switch (action.type) {
    case ActionType.SIGN_UP_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.SIGN_UP_USER_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        hasWalletConnected: false,
        walletAddress: action.payload.walletAddress!,
        user: action?.payload,
        userId: action.payload?.id!,
        isAuthenticated: true,
      };
    case ActionType.SIGN_UP_USER_ERROR:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    case ActionType.IS_WALLET_CONNECTED:
      return {
        ...state,
        hasWalletConnected: true,
        signature: action.payload,
      };
    case ActionType.SAVE_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: action.payload,
      };
    case ActionType.CLEAR_WALLET:
      return {
        ...state,
        user: null,
        userId: "",
        walletAddress: "",
        hasWalletConnected: false,
      };
    case ActionType.VERIFY_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.VERIFY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ActionType.VERIFY_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.UPDATE_USER:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload,
      };
    case ActionType.UPDATE_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.FETCH_USER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ActionType.FETCH_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        user: action.payload,
      };
    case ActionType.FETCH_USER_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
        user: null,
      };
    case ActionType.UPDATE_AUTH_STATUS:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case ActionType.FETCH_NONCE:
      return {
        ...state,
        nonce: "",
      };
    case ActionType.FETCH_NONCE_SUCCESS:
      return {
        ...state,
        nonce: action.payload,
      };
    case ActionType.FETCH_NONCE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case ActionType.VERIFY_SIGNATURE:
      return {
        ...state,
        sigVerified: false,
        error: "",
      };
    case ActionType.VERIFY_SIGNATURE_SUCCESS:
      const { token, user } = action.payload;
      return {
        ...state,
        user: user,
        token: token,
        sigVerified: true,
        walletAddress: user.walletAddress!,
      };
    case ActionType.VERIFY_SIGNATURE_ERROR:
      return {
        ...state,
        sigVerified: false,
        error: action.payload,
      };
    case ActionType.LOGOUT_USER:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case ActionType.LOGOUT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        user: null,
      };
    case ActionType.LOGOUT_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
