import { ActionType } from "../action-types";
import { Action } from "../actions";

export interface ToastState {
  toastOpen: boolean;
  toastType: string;
  toastMessage: string;
}

const initialState = {
  toastOpen: false,
  toastType: "success",
  toastMessage: "",
};

const reducer = (
  state: ToastState = initialState,
  action: Action
): ToastState => {
  switch (action.type) {
    case ActionType.UPDATE_TOAST:
      return {
        ...state,
        toastOpen: action.payload.toastOpen,
        toastType: action.payload.toastType,
        toastMessage: action.payload.toastMessage,
      };
    default:
      return state;
  }
};

export default reducer;
