import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Footer } from "../";
import { useActions, useSelector } from "../../hooks";
import { NftSearchParams } from "../../models/nft";
import { UserSearchParams } from "../../models/user";
import CollectionCard from "../Partials/CollectionCard";
import CreatorSellerCard from "../Partials/CreatorSellerCard";
import NftGrid from "../Partials/NftGrid";

const Explore: React.FC = () => {
  const { fetchNfts, fetchCollections, fetchAuthors, clearAuthors, clearNfts } =
    useActions();
  const { nfts, error: nftsError } = useSelector((state: any) => state.nfts);

  const {
    authors,
    total: totalAuthors,
    error: authorsError,
    loading: authorsLoading,
  } = useSelector((state: any) => state.authors);

  const {
    collections,
    total: totalCollections,
    error: collectionsError,
    loading: collectionsLoading,
  } = useSelector((state: any) => state.collections);
  const { user } = useSelector((state) => state.auth);

  let [searchParams] = useSearchParams();

  const handleFetchCollections = async () => {
    let search = searchParams.get("search");
    fetchCollections({ search });
  };

  const handleFetchNfts = async (params?: NftSearchParams, append = false) => {
    let search = searchParams.get("search");
    fetchNfts({ search, ...params }, append);
  };

  const handleFetchAuthors = async (params?: UserSearchParams) => {
    let search = searchParams.get("search");
    fetchAuthors({ search, ...params });
  };

  useEffect(() => {
    window.document.title = "ZingIt - Explore Nfts";
    handleFetchNfts();
    handleFetchAuthors();
    handleFetchCollections();
    return () => {
      clearAuthors();
      clearNfts();
    };
  }, [user?.id]);

  // if (
  //   !authors ||
  //   !nfts ||
  //   !collections ||
  //   nftLoading ||
  //   authorsLoading ||
  //   collectionsLoading
  // ) {
  //   return (
  //     <div className="col-lg-12">
  //       <h2 className="style-2 text-center">Loading...</h2>
  //     </div>
  //   );
  // }

  return (
    <div>
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb">
          <div className="container">
            <div className="text-center-sm">
              <h2 className="style-2">Explore</h2>
            </div>

            <div className="row">
              {nfts?.length > 0 && (
                <div className="col-lg-12">
                  {/* <h2 className='style-2'>Nfts</h2> */}
                  {/* <TopFilterBar onSearch={fetchNfts} /> */}
                </div>
              )}
            </div>
            <NftGrid
              showEmptyText={!collections?.length && !authors?.length}
              list={nfts?.nfts}
              total={nfts?.total}
              loading={nfts?.loading}
              error={nftsError}
              showLoadMore={true}
              fetchMore={(params: NftSearchParams) =>
                handleFetchNfts(params, true)
              }
            />

            {
              <div className="col-lg-12">
                <h2 className="style-2">Collections</h2>
              </div>
            }

            {
              <CollectionCard
                loading={collectionsLoading}
                error={collectionsError}
                collections={collections?.collections}
              />
            }

            {
              <div className="text-center-sm">
                <h2 className="style-2">Creators</h2>
              </div>
            }
            {
              <CreatorSellerCard
                loading={authorsLoading}
                error={authorsError}
                authors={authors.authors}
              />
            }
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default Explore;
