const Page500 = () => {
  return (
    <div className="error-500">
      <div>
        <img
          src="/img/zenft-logo.white.png"
          style={{ maxHeight: "70px" }}
          className="img-fluid d-block"
          alt="#"
        />
        <h2 className="mt-5 exclaim">WHOOPS!!!</h2>
        <h4>We need to learn from our mostakes. Please help...</h4>
        <p>
          That one is definitely on us. Tell us what happened and give us your
          email and <br /> we’ll let you know when we have it fixed.
        </p>
        <div>
          <label>Issue (What broke...) </label>
          <input className="form-control input-1" type="text" />
        </div>

        <div>
          <label>Email Address</label>
          <input className="form-control input-2" type="text" />
        </div>

        <button>TELL US WHAT WENT WRONG</button>
      </div>

      <div>
        <img
          src="/img/error_500.png"
          className="img-fluid lonely-guy"
          alt="#"
        />
      </div>
    </div>
  );
};

export default Page500;
