import { OverlayTrigger, Tooltip } from "react-bootstrap";

const FeeInfo = (props: any) => {
  const tooltip = <Tooltip>Only charged if NFT sells</Tooltip>;

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 0, hide: 400 }}
      overlay={tooltip}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default FeeInfo;
