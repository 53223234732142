import { NftSearchParams } from "../../models/nft";
import setAuthToken from "../utils/setAuthToken";

const instance = setAuthToken();

const createCollection = (
  name: string,
  logo: string,
  featuredImg: string,
  bannerImg: string,
  description: string,
  siteUrl?: string,
  discordUrl?: string,
  instagramUrl?: string,
  mediumUrl?: string,
  telegramUrl?: string
) => {
  return instance.post("/collection", {
    name,
    logo,
    featuredImg,
    bannerImg,
    siteUrl,
    discordUrl,
    instagramUrl,
    mediumUrl,
    telegramUrl,
    description,
  });
};

const editCollection = (
  id: string,
  name: string,
  logo: string,
  featuredImg: string,
  bannerImg: string,
  description: string,
  siteUrl?: string,
  discordUrl?: string,
  instagramUrl?: string,
  mediumUrl?: string,
  telegramUrl?: string
) => {
  return instance.put(`/collection/${id}`, {
    name,
    logo,
    featuredImg,
    bannerImg,
    siteUrl,
    discordUrl,
    instagramUrl,
    mediumUrl,
    telegramUrl,
    description,
  });
};

const fetchCollections = (params?: NftSearchParams) => {
  return instance.get("/collections", { params });
};

const fetchCollection = (collectionId: string) => {
  return instance.get(`/collection/${collectionId}`);
};

const fetchFeaturedCollections = (params?: NftSearchParams) => {
  return instance.get(`/featured/collections`, { params });
};

const fetchMyCollections = () => {
  return instance.get(`/mycollections`);
};

export {
  createCollection,
  editCollection,
  fetchCollections,
  fetchCollection,
  fetchMyCollections,
  fetchFeaturedCollections,
};
