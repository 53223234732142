import moment from "moment";
import { memo, useEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { PixelCrop } from "react-image-crop";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createGlobalStyle } from "styled-components";
import { Footer } from "..";
import { useActions, useSelector } from "../../hooks";
import { NftSearchParams } from "../../models/nft";
import {
  getUserBannerImageUrl,
  getUserSmallImageUrl,
  truncateAddress,
} from "../../models/user";
import { canvasToFile } from "../../state/utils";
import ProfileEditModal from "../Partials/Modals/ProfileEditModal";
import NftGrid from "../Partials/NftGrid";
import NoWallet from "../Partials/NoWallet";
import MintGreenInfo from "../Partials/ToolTips/MintGreenInfo";
import UserActivityTab from "../Partials/UserActivityTab";
import UserOffersReceivedTab from "../Partials/UserOffersReceivedTab";
import {
  DEFAULT_BANNER_IMAGE,
  DEFAULT_USER_IMAGE,
} from "../Partials/constants";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    border-bottom: solid 1px #ccc !important;
  }
`;

const MyProfile = () => {
  const { user } = useSelector((state) => state.auth);
  const {
    fetchLikedNfts,
    fetchAuthorCreatedNfts,
    fetchPurchasedNfts,
    updateProfile,
    fetchUserOffersReceived,
    fetchUser,
  } = useActions();
  const [userImage, setUserImage] = useState("");
  const [bannerImage, setBannerImage] = useState(DEFAULT_BANNER_IMAGE);
  const { likedNfts, createdNfts, purchasedNfts, loading } = useSelector(
    (state) => state.nfts
  );
  const [showSell, setShowSell] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const { active_tab } = useParams();
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const pageSize = searchParams.get("page_size") || "20";
  const previewCanvasRefShow = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    window.document.title = "ZingIt - My Profile";
  }, []);

  useEffect(() => {
    handleTabSelected(active_tab || "Created");
  }, [active_tab]);

  useEffect(() => {
    if (user?.id) {
      fetchUser();
      handleFetchCreatedNfts();
    }
  }, [user?.id]);

  useEffect(() => {
    if (user?.userImage) setUserImage(user?.userImage);
    else setUserImage("");

    if (user?.bannerImage) setBannerImage(user?.bannerImage);
    else setBannerImage(DEFAULT_BANNER_IMAGE);
  }, [user?.bannerImage, user?.userImage]);

  const handleFetchLikedNfts = (params?: NftSearchParams) => {
    if (user) fetchLikedNfts(user?.id!, params);
  };

  const handleFetchCreatedNfts = (params?: NftSearchParams) => {
    if (user) fetchAuthorCreatedNfts(user?.id!, params);
  };

  const handleFetchPurchasedNfts = (params?: NftSearchParams) => {
    if (user) fetchPurchasedNfts(user?.id!, params);
  };

  const [tabs] = useState([
    "Created",
    "Purchased",
    "Liked",
    "Activity",
    "Offers",
  ]);

  const [selectedTab, setSelectedTab] = useState(active_tab || "Created");

  const noNfts = (
    <>
      <h2 className="no-result-h2">You have not created any NFTs yet.</h2>
      <span onClick={() => navigate("/create-nft")} className="btn-main lead">
        Create a NFT!
      </span>
    </>
  );

  const handleTabSelected = (tab: string) => {
    setSelectedTab(tab);
    const params: NftSearchParams = {
      page: Number(page),
      page_size: Number(pageSize),
    };
    switch (tab.toLowerCase()) {
      case "created":
        handleFetchCreatedNfts(params);
        break;
      case "purchased":
        handleFetchPurchasedNfts(params);
        break;
      case "liked":
        handleFetchLikedNfts(params);
        break;
      default:
        break; //Activities handled by the component
    }
  };

  const [showProfileEdit, setShowProfileEdit] = useState(false);

  const handleShowProfileEditModal = () => setShowProfileEdit(true);

  const handleCloseProfileEditModal = async () => {
    try {
      const croppedProfileImg = await canvasToFile(previewCanvasRefShow);
      await updateProfile(user!, user?.id!, croppedProfileImg, null, () => {
        setShowProfileEdit(false);
      });
    } catch (err) {
      toast.error("Error updating profile");
    }
  };

  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  return user?.id ? (
    <div>
      <GlobalStyles />
      <section
        className="profile_banner jumbotron breadcumb no-bg"
        style={{
          backgroundImage: `url(${bannerImage})`,
        }}
      >
        {/* <div className='mainbreadcumb'></div> */}
      </section>
      <section className="container mb-4 mt-profile">
        <div className="my_profile">
          <div className="my_profile_avatar">
            <img
              src={
                userImage ? getUserSmallImageUrl(userImage) : DEFAULT_USER_IMAGE
              }
              alt={userImage}
            />

            {!!completedCrop && (
              <canvas
                ref={previewCanvasRefShow}
                style={{
                  border: "1px solid black",
                  objectFit: "cover",
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          </div>
          <div className="my_profile_name">
            <h4>
              {user?.firstName
                ? user?.firstName + " " + user?.lastName
                : user?.username}
              {/* <span>
                <i className="fa fa-heart-o" />
              </span> */}
            </h4>
            <div className="d-flex align-items-center profile-item-details">
              <p className="my_profile_username mb-0">
                {user?.username || "New User"}
              </p>
              <p id="wallet" className="my_profile_wallet mb-0">
                {truncateAddress(user?.walletAddress)}
              </p>

              <CopyToClipboard
                onCopy={() => {
                  setShowCopied(true);
                  setTimeout(() => {
                    setShowCopied(false);
                  }, 3000);
                }}
                text={user?.walletAddress || ""}
              >
                <div style={{ width: "100px" }}>
                  <button className="btn_copy ms-2 fs-12" title="copy">
                    copy
                  </button>
                  {showCopied && (
                    <i
                      style={{ color: "green" }}
                      className="fa solid fa-check ps-3"
                    ></i>
                  )}
                </div>
              </CopyToClipboard>
              {user && (
                <p className="profile_joined mb-0">
                  Joined {moment(user?.createdAt).format("MMMM YYYY")}{" "}
                </p>
              )}
            </div>
            <div className="profile_stats">
              <div className="total">
                <span>
                  <i className="fa fa-table"></i>
                </span>
                <span>{user?.totalNfts || 0} total</span>
              </div>
              {/* <div className="views">
                <span>
                  <i className="fa fa-eye"></i>
                </span>
                <span>7.6k views</span>
              </div> */}
              <div className="faves">
                <span>
                  <i className="fa fa-heart liked" />
                </span>
                <span>{user?.totalFavoriteNfts} favorites</span>
              </div>
              <div className="profile-mint">
                <MintGreenInfo>
                  <div>
                    <img src="/img/mint-small.png" alt="Mint Green" />
                    <span className="text-green ms-2">
                      {user?.totalGreenNfts || 0} Mint Green
                    </span>
                  </div>
                </MintGreenInfo>
              </div>
            </div>

            <div className="profile_desc">
              <p>{user?.bio}</p>
            </div>
            <button
              id="btn_copy"
              className="btn_copy"
              title="Edit profile"
              onClick={() => navigate("/profile/edit")}
            >
              Edit
            </button>
          </div>
        </div>
      </section>
      <section className="container no-top">
        {selectedTab !== "Activity" && (
          <div className="row">
            <div className="col-lg-12">
              <div className="items_filter mb-2">
                <ul className="profile_nav">
                  {tabs.map((t, i) => {
                    return (
                      <li className={selectedTab === t ? "active" : ""} key={i}>
                        <span onClick={() => navigate(`/profile/${t}`)}>
                          {t}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        )}

        {selectedTab === "Created" && (
          <div id="CreatedTab" className="onStep fadeIn">
            <NftGrid
              list={createdNfts.nfts}
              loading={createdNfts.loading}
              showLoadMore={false}
              fetchMore={(params?: NftSearchParams) =>
                handleFetchCreatedNfts(params)
              }
              showSell={() => setShowSell(true)}
              // refetch={() => user && fetchAuthorCreatedNfts(user?.id!)}
              noNftComponent={noNfts}
              emptyText="You have not created any NFTs yet."
            />
          </div>
        )}
        {selectedTab === "Purchased" && (
          <div id="PurchasedTab" className="onStep fadeIn">
            <NftGrid
              list={purchasedNfts.nfts}
              loading={purchasedNfts.loading}
              showLoadMore={false}
              showSell={() => setShowSell(true)}
              // refetch={() => user && fetchPurchasedNfts(user?.id!)}
              fetchMore={(params?: NftSearchParams) =>
                handleFetchPurchasedNfts(params)
              }
              emptyText="You have not purchased any NFTs yet."
            />
          </div>
        )}

        {selectedTab === "Liked" && (
          <div id="LikedTab" className="onStep fadeIn">
            <NftGrid
              list={likedNfts.nfts}
              loading={likedNfts.loading}
              showLoadMore={false}
              fetchMore={(params?: NftSearchParams) =>
                handleFetchLikedNfts(params)
              }
              emptyText="You have not liked any NFTs yet."
            />
          </div>
        )}

        {selectedTab === "Activity" && (
          <UserActivityTab user={user} tabs={tabs} selectedTab={selectedTab} />
        )}

        {selectedTab === "Offers" && (
          <UserOffersReceivedTab
            user={user}
            tabs={tabs}
            selectedTab={selectedTab}
          />
        )}
      </section>
      <Footer />

      <ProfileEditModal
        show={showProfileEdit}
        handleClose={handleCloseProfileEditModal}
        img={userImage}
        setUserImage={setUserImage}
        previewCanvasRefShow={previewCanvasRefShow}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
      />
    </div>
  ) : (
    <NoWallet />
  );
};
export default memo(MyProfile);
