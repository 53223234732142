import axios from "axios";
import { BASE_URL } from "../../components/Partials/constants";

// send token with every request
const setAuthToken = () => {
  const instance = axios.create({
    baseURL: BASE_URL,
  });

  // if (token) {
  //   axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  // }
  instance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers!.Authorization = `Bearer ${token}`;
        config.headers!.Accept = "application/json";
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async function (error) {
      const originalRequest = error.config;
      if (
        (error.response.status === 403 || error.response.status === 401) &&
        !originalRequest._retry
      ) {
        localStorage.clear();
        // originalRequest._retry = true;
        // const access_token = await refreshAccessToken();
        // axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
        // return instance(originalRequest);
      }
      return Promise.reject(error);
    }
  );
  return instance;
};

export default setAuthToken;
