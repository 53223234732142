import Modal from "react-bootstrap/Modal";

import { ExternalProvider } from "@ethersproject/providers";
import { ethers } from "ethers";
import { useState } from "react";
import { SwapWidget } from "@uniswap/widgets";
import { blockChainData } from "../../../api/modules/nft";

const WrapEthModal = ({ show, handleClose }: any) => {
  const [networkName, setNetworkName] = useState("");
  if (!window.ethereum) return <></>;
  const provider = new ethers.providers.Web3Provider(
    window.ethereum as ExternalProvider
  );
  provider.getNetwork().then((network) => {
    setNetworkName(network.name);
  });
  return (
    <Modal className="custom modal-bg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="">Wrap your ETH</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <SwapWidget
            provider={provider}
            defaultOutputTokenAddress={blockChainData[networkName]?.wrappedCoin}
            defaultInputTokenAddress={"NATIVE"}
            theme={{
              primary: "#000",
              secondary: "#000",
              interactive: "#8364e2",
              container: "#FFF",
              module: "#fff",
              accent: "#8364e2",
              outline: "#000",
              dialog: "#FFF",
              fontFamily: "familjenGrotesk",
            }}
            jsonRpcUrlMap={{
              5: [
                "https://eth-goerli.g.alchemy.com/v2/MtcJE9CWlNCeCQ-_6BTyseYB3bg2a1eM",
              ],
              80001: [
                "https://polygon-mumbai.g.alchemy.com/v2/GdiVM39TVUQC7a4_P86GLbvXEy6jhGfx",
              ],
              137: [
                "https://polygon-mainnet.g.alchemy.com/v2/hE9PTFUS-O_Fpwiin_6IcXkmGzcOwtET",
              ],
              1: [
                "https://eth-mainnet.g.alchemy.com/v2/e-xOq-MYcEzf_9zhdVKwNNAMA5BhgBdi",
              ],
            }}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default WrapEthModal;
