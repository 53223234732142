import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { ERROR_CODES } from "../../../constants";
import { useForceSwitchNetwork } from "../../../hooks";
import { mint } from "../../../services/seaport.service";
import { getNftSmallUrl } from "../../../models/nft";

const errCodes: any = {
  INSUFFICIENT_FUNDS: "Insufficient funds for minting",
};
const MintNow = ({
  show,
  handleClose,
  nft,
  refetch,
  noteText,
  noteColor,
}: any) => {
  const [startMinting, setStartMinting] = useState(false);
  const { isCorrectNetwork, checkNetwork } = useForceSwitchNetwork(nft);

  async function mintThis() {
    const _isCorrectNetwork = await checkNetwork();

    if (!nft || !_isCorrectNetwork) return;
    setStartMinting(true);

    try {
      const transaction = await mint(nft);
      await transaction.wait();

      setStartMinting(false);
      if (refetch) {
        refetch();
      }

      handleClose();
    } catch (e: any) {
      setTimeout(() => {
        setStartMinting(false);
      }, 500);
      toast.error(ERROR_CODES[e.code] || "Minting Failed");
    }
  }

  return (
    <Modal
      className="custom modal-bg"
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header closeButton>
        {/* <Modal.Title className="">Mint Now</Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mt-2 mb-3 offer-wallet-body">
          <div className="modal-nft-img w-auto">
            {" "}
            <img
              src={getNftSmallUrl(nft?.nftMetaData?.thumbnail_image)}
              className="sell-this-nft"
              alt=""
            />
            <p className="f-16 mt-1 mb-1 f-700">
              <span className="text-gray me-1">Qty:</span>
              {nft?.supply}
            </p>
          </div>
          <div className="ms-1">
            <h2 className="mb-0">{nft?.name}</h2>
            <p className="f-11 mb-2">
              Creator:{" "}
              <span className="text-purple me-4">{nft?.creator?.username}</span>{" "}
            </p>
            <div className="mb-4">
              <p className="f-600 mt-3 mb-1">
                This NFT is not yet minted, Mint now or continue enjoying lazy
                minting
              </p>
              <p className="f-12 f-300">{""}</p>
            </div>
            {startMinting ? (
              <div className="mb-2 d-flex flex-column w-100 align-items-center">
                <ThreeDots
                  height="60"
                  width="60"
                  color="#8364e2"
                  ariaLabel="loading"
                />
                <span> Minting...</span>
              </div>
            ) : (
              <button className="btn-main" onClick={() => mintThis()}>
                Mint Now
              </button>
            )}

            {noteText && (
              <p className={`${noteColor} f-11 text-center mt-2`}>
                NOTE: {noteText}
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MintNow;
