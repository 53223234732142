/* eslint-disable react-hooks/exhaustive-deps */
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import * as Yup from "yup";
import { Footer } from "..";
import { useActions, useSelector } from "../../hooks";

import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import { canvasPreview } from "../Partials/imageCrop/canvasPreview";
import { useDebounceEffect } from "../Partials/imageCrop/useDebounceEffect";

import centerAspectCrop from "../Partials/imageCrop/centerAspectCrop";

import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import { User } from "../../models/user";
import { canvasToFile } from "../../state/utils";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    background: #fff;
  }
  .fa-user{
    padding:5px
  }
  @media only screen and (max-width: 1199px) {
    .navbar .menu-line, .navbar .menu-line1, .navbar .menu-line2{
      background: #111;
    }
    .item-dropdown .dropdown a{
      color: #111 !important;
    }
`;

const instagramUrlRegex =
  /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_](?:(?:[a-zA-Z0-9_]|(?:\.(?!\.))){0,28}(?:[a-zA-Z0-9_]))?)\/?$/;

const twitterUrlRegex =
  /^(?:https?:\/\/)?(?:www\.)?twitter\.com\/([a-zA-Z0-9_]{1,15})\/?$/;

const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  social: Yup.object().shape({
    instagram: Yup.string()
      .matches(instagramUrlRegex, "Please enter a valid Instagram URL")
      .nullable(),
    twitter: Yup.string()
      .matches(twitterUrlRegex, "Please enter a valid Twitter URL")
      .nullable(),
  }),
});

type valueTypes = {
  firstName?: string;
  lastName?: string;
  username?: string;
  userImageTemp?: string;
  bio?: string;
  email?: string;
  social?: any;
  walletAddress?: string;
  phoneNumber?: string;
};

const EditProfile = () => {
  const { updateProfile, fetchUser } = useActions();
  const navigate = useNavigate();
  const { user, error, loading } = useSelector((state) => state.auth);
  const [profileBanner, setProfileBanner] = useState("");
  const [userImage, setUserImage] = useState("");
  const [profileBannerTemp, setProfileBannerTemp] = useState<null | any>("");
  const [userImageTemp, setUserImageTemp] = useState<null | any>("");

  const profileImageInputRef = useRef<any>(null);
  const bannerImageInputRef = useRef<any>(null);

  useEffect(() => {
    window.document.title = "ZingIt - Edit My Profile";
  }, []);

  useEffect(() => {
    if (!user?.id) return;

    fetchUser();
    user?.userImage && setUserImageTemp(`${user?.userImage}`);
    user?.userImage && setImgSrc(`${user?.userImage}`);

    user?.bannerImage && setProfileBannerTemp(`${user?.bannerImage}`);
    setImgSrcBanner(`${user?.bannerImage}`);
  }, [JSON.stringify(user)]);

  const handleSubmitForm = async (values: valueTypes) => {
    try {
      const croppedProfileImg = await canvasToFile(previewCanvasRefShow);
      const croppedBannerImg = await canvasToFile(previewCanvasRefBannerShow);

      return await updateProfile(
        values,
        user?.id!,
        croppedProfileImg,
        croppedBannerImg,
        (username) => {
          navigate(`/profile`);
        }
      );
    } catch (err: any) {
      toast.error("Error updating profile");
    }
  };

  //Profile image
  const [imgSrc, setImgSrc] = useState<null | any>("");
  const previewCanvasRefShow = useRef<HTMLCanvasElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(16 / 12); //(16 / 9);

  function onSelectFile(e: any) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      setImgSrc(e.target.files[0]);
      reader.onloadend = () => {
        setUserImageTemp(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRefShow.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRefShow.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    //@ts-ignore
    [completedCrop, scale, rotate]
  );

  useEffect(() => {
    const canvas = previewCanvasRefShow.current;
    if (canvas !== null) {
      const ctx = canvas.getContext("2d");
      if (ctx !== null) {
        ctx.fillStyle = "#000000";
        ctx.beginPath();
        ctx.arc(50, 100, 20, 0, 2 * Math.PI);
        ctx.fill();
      }
    }
  }, [completedCrop]);

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined);
    } else if (imgRef.current) {
      const { width, height } = imgRef.current;
      setAspect(16 / 9);
      setCrop(centerAspectCrop(width, height, 16 / 9));
    }
  }

  // Profile Banner
  const [imgSrcBanner, setImgSrcBanner] = useState<null | any>("");
  const previewCanvasRefBannerShow = useRef<HTMLCanvasElement>(null);
  const imgRefBanner = useRef<HTMLImageElement>(null);
  const [cropBanner, setCropBanner] = useState<Crop>();
  const [completedCropBanner, setCompletedCropBanner] = useState<PixelCrop>();
  const [scaleBanner, setScaleBanner] = useState(1);
  const [rotateBanner, setRotateBanner] = useState(0);
  const [aspectBanner, setAspectBanner] = useState<number | undefined>(16 / 12);

  function onSelectFileBanner(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCropBanner(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      setImgSrcBanner(e.target.files[0]);
      reader.onloadend = () => {
        setProfileBannerTemp(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoadBanner(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspectBanner) {
      const { width, height } = e.currentTarget;
      setCropBanner(centerAspectCrop(width, height, aspectBanner));
    }
  }

  // for Preview up
  useDebounceEffect(
    async () => {
      if (
        completedCropBanner?.width &&
        completedCropBanner?.height &&
        imgRefBanner.current &&
        previewCanvasRefBannerShow.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRefBanner.current,
          previewCanvasRefBannerShow.current,
          completedCropBanner,
          scaleBanner,
          rotateBanner
        );
      }
    },
    100,
    //@ts-ignore
    [completedCropBanner, scaleBanner, rotateBanner]
  );

  useEffect(() => {
    const canvas = previewCanvasRefBannerShow.current;
    if (canvas !== null) {
      const ctx = canvas.getContext("2d");
      if (ctx !== null) {
        ctx.fillStyle = "#000000";
        ctx.beginPath();
        ctx.arc(50, 100, 20, 0, 2 * Math.PI);
        ctx.fill();
      }
    }
  }, [completedCrop]);

  function handleToggleAspectClickBanner() {
    if (aspectBanner) {
      setAspectBanner(undefined);
    } else if (imgRefBanner.current) {
      const { width, height } = imgRefBanner.current;
      setAspectBanner(16 / 9);
      setCropBanner(centerAspectCrop(width, height, 16 / 9));
    }
  }

  return (
    user && (
      <div>
        <GlobalStyles />
        <section
          id="section-main"
          aria-label="section"
          className="jumbotron breadcumb no-bg"
          style={{ paddingTop: 50 }}
        >
          <div className="container">
            <h2 className="style-2">EDIT PROFILE</h2>
            {error ? (
              <div
                className="alert alert-danger"
                role="alert"
                style={{ marginTop: 10 }}
              >
                {error}
              </div>
            ) : (
              <div />
            )}

            <span>
              <span className="text-danger">*</span>Required Fields
            </span>
            <div className="spacer-20"></div>

            <Formik
              enableReinitialize
              validateOnBlur={true}
              validateOnChange={true}
              validationSchema={validationSchema}
              initialValues={user!}
              validateOnMount={true}
              onSubmit={async (values: User, { setSubmitting }: any) => {
                const changedValues: any = {};
                Object.keys(values).forEach((key: any) => {
                  if (values[key as keyof User] !== user[key as keyof User]) {
                    changedValues[key] = values[key as keyof User];
                  }
                });

                await handleSubmitForm(changedValues);
                setSubmitting(false);
              }}
            >
              {({ isSubmitting, isValid }) => {
                return (
                  <div className="row">
                    <div className="col-lg-7 pe-4 d-flex">
                      <Form className="form-border w-100">
                        <div className="de_tab tab_simple">
                          <div className="de_tab_content">
                            <div className="tab-1">
                              <div
                                className="row wow fadeIn animated"
                                style={{
                                  backgroundSize: "cover",
                                  visibility: "visible",
                                  animationName: "fadeIn",
                                }}
                              >
                                <div className="mb-sm-20">
                                  <div className="field-set">
                                    <div className="full-name">
                                      <div className="first-name">
                                        <h5>
                                          First Name
                                          <span className="text-danger">*</span>
                                        </h5>
                                        <Field
                                          type="text"
                                          name="firstName"
                                          disabled={isSubmitting}
                                          id="firstName"
                                          className="form-control"
                                          placeholder="Enter your first name"
                                        />
                                        <ErrorMessage
                                          name="firstName"
                                          className="text-danger"
                                          component="div"
                                        />
                                      </div>
                                      <div>
                                        <h5>
                                          Last Name
                                          <span className="text-danger">*</span>
                                        </h5>
                                        <Field
                                          type="text"
                                          name="lastName"
                                          disabled={isSubmitting}
                                          id="lastName"
                                          className="form-control"
                                          placeholder="Enter your Last Name"
                                        />
                                        <ErrorMessage
                                          name="lastName"
                                          className="text-danger"
                                          component="div"
                                        />
                                      </div>
                                    </div>
                                    <div className="spacer-20"></div>
                                    <h5>
                                      Username
                                      <span className="text-danger">*</span>
                                    </h5>
                                    <Field
                                      type="text"
                                      disabled={isSubmitting}
                                      name="username"
                                      id="username"
                                      className="form-control"
                                      placeholder="Enter username"
                                    />
                                    <ErrorMessage
                                      name="username"
                                      className="text-danger"
                                      component="div"
                                    />
                                    <div className="spacer-20"></div>

                                    <h5>Bio</h5>
                                    <Field
                                      component="textarea"
                                      name="bio"
                                      disabled={isSubmitting}
                                      id="bio"
                                      className="form-control"
                                      placeholder="Tell the world who you are!"
                                    />
                                    <ErrorMessage
                                      name="bio"
                                      className="text-danger"
                                      component="div"
                                    />
                                    <div className="spacer-20"></div>
                                    <h5>
                                      Email
                                      <span className="text-danger">*</span>
                                    </h5>
                                    <Field
                                      type="text"
                                      name="email"
                                      disabled={isSubmitting}
                                      id="email"
                                      className="form-control"
                                      placeholder="Enter your email"
                                    />
                                    <ErrorMessage
                                      name="email"
                                      className="text-danger"
                                      component="div"
                                    />
                                    <div className="spacer-20"></div>
                                    <h5>Twitter</h5>
                                    <Field
                                      type="text"
                                      name="social.twitter"
                                      id="twitter"
                                      disabled={isSubmitting}
                                      className="form-control"
                                      placeholder="Enter your Twitter URL"
                                    />
                                    <ErrorMessage
                                      name="social.twitter"
                                      className="text-danger"
                                      component="div"
                                    />
                                    <div className="spacer-20"></div>
                                    <h5>Instagram</h5>
                                    <Field
                                      type="text"
                                      disabled={isSubmitting}
                                      name="social.instagram"
                                      id="instagram"
                                      className="form-control"
                                      placeholder="Enter your Instagram URL"
                                    />
                                    <ErrorMessage
                                      name="social.instagram"
                                      className="text-danger"
                                      component="div"
                                    />
                                    <div className="spacer-20"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="update-btn desktop">
                          {!isSubmitting ? (
                            <button
                              type="submit"
                              disabled={isSubmitting || !isValid}
                              id="submit"
                              className="btn-main"
                            >
                              Update Profile
                            </button>
                          ) : (
                            <ThreeDots
                              height="60"
                              width="60"
                              color="#8364e2"
                              ariaLabel="Saving..."
                            />
                          )}
                        </div>
                      </Form>
                    </div>
                    <div id="sidebar" className="col-lg-5">
                      <div className="user-preview">
                        {imgSrcBanner !== "" && (
                          <div className="banner-preview">
                            <div>
                              {!!completedCropBanner && (
                                <canvas
                                  ref={previewCanvasRefBannerShow}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "65px",
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        )}
                        {imgSrc !== "" && (
                          <div className="profile-preview">
                            <div className="round-img">
                              {!!completedCrop && (
                                <canvas
                                  ref={previewCanvasRefShow}
                                  style={{
                                    border: "1px solid black",
                                    objectFit: "cover",
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              )}
                            </div>
                            <div className="user-text">
                              <h6>{user?.firstName + " " + user?.lastName}</h6>
                              <h6 className="text-purple">{user?.username}</h6>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="profile-crop mt-5">
                        <h5>
                          Profile image{" "}
                          <i
                            className="fa fa-info-circle id-color-2"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Recommend 400 x 400. Max size: 50MB. Click the image to upload."
                            aria-label="Recommend 400 x 400. Max size: 50MB. Click the image to upload."
                          ></i>
                        </h5>

                        {!!imgSrc && (
                          <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                          >
                            <img
                              crossOrigin="anonymous"
                              ref={imgRef}
                              alt=""
                              src={userImageTemp}
                              style={{
                                transform: `scale(${scale}) rotate(${rotate}deg)`,
                              }}
                              onLoad={onImageLoad}
                            />
                          </ReactCrop>
                        )}

                        <div className="Crop-Controls mt-2">
                          <input
                            type="file"
                            accept="image/*"
                            onChange={onSelectFile}
                            className="text-purple bg-white"
                          />
                        </div>
                      </div>

                      <div className="spacer-30"></div>

                      <div className="banner-crop">
                        <h5>
                          Profile banner{" "}
                          <i
                            className="fa fa-info-circle id-color-2"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title=""
                            data-bs-original-title="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."
                            aria-label="Recommend 1500 x 500. Max size: 50MB. Click the image to upload."
                          ></i>
                        </h5>
                        {!!imgSrcBanner && (
                          <ReactCrop
                            crop={cropBanner}
                            onChange={(_, percentCrop) =>
                              setCropBanner(percentCrop)
                            }
                            onComplete={(c) => setCompletedCropBanner(c)}
                            aspect={aspectBanner}
                          >
                            <img
                              crossOrigin="anonymous"
                              ref={imgRefBanner}
                              alt=""
                              src={profileBannerTemp}
                              style={{
                                transform: `scale(${scaleBanner}) rotate(${rotateBanner}deg)`,
                              }}
                              onLoad={onImageLoadBanner}
                            />
                          </ReactCrop>
                        )}

                        <div className="Crop-Controls my-2">
                          <input
                            type="file"
                            disabled={isSubmitting}
                            accept="image/*"
                            onChange={onSelectFileBanner}
                            className="text-purple bg-white"
                          />
                        </div>
                        <div className="update-btn mobile">
                          {isSubmitting ? (
                            <button
                              type="submit"
                              disabled={isSubmitting || !isValid}
                              id="submit"
                              className="btn-main"
                            >
                              {isSubmitting ? "Saving..." : "Update Profile"}
                            </button>
                          ) : (
                            <ThreeDots
                              height="60"
                              width="60"
                              color="#8364e2"
                              ariaLabel="Saving..."
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              }}
            </Formik>
          </div>
        </section>
        <Footer />
      </div>
    )
  );
};

export default EditProfile;
