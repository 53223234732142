/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { memo, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate, useParams } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Footer } from "..";
import { useActions, useSelector } from "../../hooks";
import {
  getUserBannerImageUrl,
  getUserSmallImageUrl,
  truncateAddress,
} from "../../models/user";
import NftGrid from "../Partials/NftGrid";
import NoWallet from "../Partials/NoWallet";
import {
  DEFAULT_BANNER_IMAGE,
  DEFAULT_USER_IMAGE,
} from "../Partials/constants";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    border-bottom: solid 1px #ccc !important;
  }
`;
const Author = () => {
  const { id } = useParams();
  const { author } = useSelector((state) => state.authors);
  const { fetchAuthor, fetchAuthorCreatedNfts, fetchAuthorAvailableNfts } =
    useActions();
  const [userImage, setUserImage] = useState("");
  const [bannerImage, setBannerImage] = useState(DEFAULT_BANNER_IMAGE);
  const { createdNfts, availableNfts, loading } = useSelector(
    (state) => state.nfts
  );
  const [showSell, setShowSell] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    window.document.title = "ZingIt - Author Profile";
  }, []);

  useEffect(() => {
    if (id) {
      fetchAuthor(id);
      fetchAuthorAvailableNfts(id);
      fetchAuthorCreatedNfts(id);
    }
  }, [id]);

  useEffect(() => {
    if (author?.userImage) setUserImage(author?.userImage);
    else setUserImage("");

    if (author?.bannerImage) setBannerImage(author?.bannerImage);
    else setBannerImage(DEFAULT_BANNER_IMAGE);
  }, [author?.bannerImage, author?.userImage]);

  const handleFetchCreatedNfts = () => {
    if (author) fetchAuthorCreatedNfts(author?.id!);
  };

  const handleFetchAvailableNfts = () => {
    if (author) fetchAuthorAvailableNfts(author?.id!);
  };

  const [tabs, setTabs] = React.useState(["Created", "Available"]);

  const [selectedTab, setSelectedTab] = React.useState("Created");

  const noNfts = (
    <>
      <h2 className="no-result-h2">You have not created any NFTs yet.</h2>
      <span onClick={() => navigate("/create-nft")} className="btn-main lead">
        Create a NFT!
      </span>
    </>
  );
  const handleTabSelected = (tab: string) => {
    setSelectedTab(tab);
    switch (tab) {
      case "Created":
        handleFetchCreatedNfts();
        break;
      case "Available":
        handleFetchAvailableNfts();
        break;
    }
  };

  return author ? (
    <div>
      <GlobalStyles />
      <section
        className="profile_banner jumbotron breadcumb no-bg"
        style={{
          backgroundImage: `url(${bannerImage})`,
        }}
      ></section>
      <section className="container mb-4 mt-profile">
        <div className="my_profile">
          <div className="my_profile_avatar">
            <img
              src={
                userImage ? getUserSmallImageUrl(userImage) : DEFAULT_USER_IMAGE
              }
              alt=""
            />
          </div>
          <div className="my_profile_name">
            <h4>
              {author?.firstName
                ? author?.firstName + " " + author?.lastName
                : author?.username}
              {/* <span>
                <i className="fa fa-heart-o" />
              </span> */}
            </h4>
            <div className="d-flex align-items-center profile-item-details">
              <p className="my_profile_username mb-0">
                {author?.username || "New User"}
              </p>
              <p id="wallet" className="my_profile_wallet mb-0">
                {truncateAddress(author?.walletAddress)}
              </p>

              <CopyToClipboard
                onCopy={() => {
                  setShowCopied(true);
                  setTimeout(() => {
                    setShowCopied(false);
                  }, 3000);
                }}
                text={author?.walletAddress || ""}
              >
                <div style={{ width: "100px" }}>
                  <button className="addr_copy" title="copy">
                    copy
                  </button>
                  {showCopied && (
                    <i
                      style={{ color: "green" }}
                      className="fa solid fa-check ps-3"
                    ></i>
                  )}
                </div>
              </CopyToClipboard>
              {author && (
                <p className="profile_joined mb-0">
                  Joined {moment(author?.createdAt).format("MMMM YYYY")}{" "}
                </p>
              )}
            </div>
            <div className="profile_stats">
              {author.social?.instagram && (
                <div
                  className="total"
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(author.social.instagram, "_blank")}
                >
                  <span>
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-instagram"
                    ></i>
                  </span>
                  <span>Instagram</span>
                </div>
              )}
              {author.social?.twitter && (
                <div
                  className="total"
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open(author.social.twitter, "_blank")}
                >
                  <span>
                    <i
                      style={{ cursor: "pointer" }}
                      className="fa fa-twitter"
                    ></i>
                  </span>
                  <span>Twitter</span>
                </div>
              )}
              {/* <div className="views">
                <span>
                  <i className="fa fa-eye"></i>
                </span>
                <span>7.6k views</span>
              </div>
              <div className="faves">
                <span>
                  <i className="fa fa-heart liked" />
                </span>
                <span>124 favorites</span>
              </div>
              <div className="tags">
                <div className="tag">tag</div>
                <span>14 tags</span>
              </div> */}
            </div>

            <div className="profile_desc mt-2">
              <p>{author?.bio}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="container no-top">
        <div className="row">
          <div className="col-lg-12">
            <div className="items_filter mb-2">
              <ul className="profile_nav">
                {tabs.map((t, i) => {
                  return (
                    <li className={selectedTab === t ? "active" : ""} key={i}>
                      <span onClick={() => handleTabSelected(t)}>{t}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        {selectedTab === "Created" && (
          <div id="CreatedTab" className="onStep fadeIn">
            <NftGrid
              list={createdNfts.nfts}
              loading={createdNfts.loading}
              showLoadMore={false}
              fetchMore={() => handleFetchCreatedNfts()}
              showSell={() => setShowSell(true)}
              // refetch={() => user && fetchAuthorCreatedNfts(user?.id!)}
              noNftComponent={noNfts}
              emptyText={`${author.firstName} has not created any NFTs yet.`}
            />
          </div>
        )}

        {selectedTab === "Available" && (
          <div id="AvailableTab" className="onStep fadeIn">
            <NftGrid
              list={availableNfts.nfts}
              loading={availableNfts.loading}
              showLoadMore={false}
              fetchMore={() => handleFetchAvailableNfts()}
              showSell={() => setShowSell(true)}
              // refetch={() => user && fetchAuthorCreatedNfts(user?.id!)}
              noNftComponent={noNfts}
              emptyText={`${author.firstName} does not have any available Nfts.`}
            />
          </div>
        )}
      </section>
      <Footer />
    </div>
  ) : (
    <NoWallet />
  );
};
export default memo(Author);
