import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import {
  User,
  getUserSmallImageUrl,
  truncateAddress,
} from "../../../models/user";
import { DEFAULT_USER_IMAGE } from "../constants";
import { NFT } from "../../../models/nft";
import { useSelector } from "../../../hooks";

interface ListOfOwnersProps {
  show: boolean;
  handleClose: any;
  nft: NFT;
}

const ListOfOwners = ({ show, handleClose, nft }: ListOfOwnersProps) => {
  const { user } = useSelector((state) => state.auth);

  return (
    <Modal className="custom modal-bg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="">Owners</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          {nft.owners?.map((owner, index) => (
            <div key={index}>
              <div className="d-flex author_list_pp align-items-center mb-2 justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="p_list_info ms-1">
                    <a href={`/author/${owner.id}`}>
                      <img
                        className="lazy"
                        src={
                          owner.userImage
                            ? getUserSmallImageUrl(owner.userImage)
                            : DEFAULT_USER_IMAGE
                        }
                        alt=""
                      />
                    </a>
                  </div>
                  <div className="p_list_info ms-3">
                    <a href={`/author/${owner.id}`}>
                      {owner.username || "UNKNOWN"}
                    </a>
                    <br />
                    <span>{truncateAddress(owner?.walletAddress)}</span>
                  </div>
                </div>
                {owner.id === user?.id && (
                  <div className="p_list_info">
                    <span>NFTs Owned: {nft.amountIOwn}</span>
                  </div>
                )}
              </div>
              {index < nft.owners!.length - 1 && (
                <hr className="my-3 opacity-75" />
              )}
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ListOfOwners;
