import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Breakpoint } from "react-socks";
import { useAccount } from "wagmi";
import WalletBalances from "./WalletBalances";

const MobileHeaderMenu = ({
  showMobileNavigation,
  setCurrPage,
  toggleMobileNavigation,
  currPage,
  refWalletMenu,
  handleWalletClick,
  showWalletMenu,
  handleLogout,
  setShowConnectWallet,
}: any) => {
  const account = useAccount();
  const { user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const handleCurrPage = (page: string) => setCurrPage(page);

  return (
    <>
      <Breakpoint large down>
        {showMobileNavigation && (
          <div className="menu">
            <div
              className={`navbar-item ${currPage === "/" ? "active" : ""}`}
              onClick={() => handleCurrPage("/")}
            >
              <Link
                to="/"
                onClick={() => toggleMobileNavigation(!showMobileNavigation)}
              >
                Home
              </Link>
            </div>

            <div
              className={`navbar-item ${
                currPage === "/explore" ? "active" : ""
              }`}
              onClick={() => handleCurrPage("/explore")}
            >
              <Link
                to="/explore"
                onClick={() => toggleMobileNavigation(!showMobileNavigation)}
              >
                Explore
              </Link>
            </div>

            <div
              className={`navbar-item ${
                currPage === "/search-page" ? "active" : ""
              }`}
              onClick={() => handleCurrPage("/search-page")}
            >
              <Link
                to="/search-page"
                onClick={() => toggleMobileNavigation(!showMobileNavigation)}
              >
                Search
              </Link>
            </div>

            <div className="mobile-link">
              {user?.walletAddress && account.isConnected && (
                <div className="side-feature create">
                  <div
                    className={`navbar-item`}
                    onClick={() => navigate("/create-nft")}
                  >
                    <a href="/explore">Create</a>
                  </div>
                </div>
              )}

              {account.isConnected && user?.walletAddress ? (
                <div
                  id="de-click-menu-profile"
                  className="de-menu-profile1"
                  ref={refWalletMenu}
                  onClick={() => handleWalletClick()}
                >
                  <div className={`navbar-item`}>
                    <a>My Wallet</a>
                  </div>
                  {account.isConnected &&
                  user?.walletAddress &&
                  showWalletMenu ? (
                    <WalletBalances />
                  ) : (
                    <div />
                  )}
                </div>
              ) : (
                <div />
              )}

              <div className="side-feature">
                <button
                  onClick={
                    account.isConnected && user
                      ? () => handleLogout()
                      : () => setShowConnectWallet(true)
                  }
                  className={"btn-main w-100"}
                >
                  {account.isConnected && user?.walletAddress
                    ? "Sign Out"
                    : "Sign In"}
                </button>
              </div>
            </div>
          </div>
        )}
      </Breakpoint>
      <Breakpoint xlarge className="menu">
        <div
          className={`navbar-item ${currPage === "/" ? "active" : ""}`}
          onClick={() => handleCurrPage("/")}
        >
          <Link to="/">
            Home
            <span className="lines"></span>
          </Link>
        </div>
        <div
          className={`navbar-item ${currPage === "/explore" ? "active" : ""}`}
          onClick={() => handleCurrPage("/explore")}
        >
          <Link to="/explore">
            Explore
            <span className="lines"></span>
          </Link>
        </div>
      </Breakpoint>
    </>
  );
};

export default MobileHeaderMenu;
