import { useEffect, useState } from "react";
import { useSelector } from "../modules/useTypedSelector";

export const useConversion = (price: any, symbol: string) => {
  const [priceUSD, setPriceUSD] = useState("0");
  const { conversions } = useSelector((state) => state.web3);

  useEffect(() => {
    if (!price || Number.isNaN(price) || !conversions || !conversions.USD) {
      setPriceUSD("0");
      return;
    }
    const cost = price / conversions.USD[symbol];
    const usd = cost.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    setPriceUSD(usd);
  }, [price, conversions, conversions.USD, symbol]);

  return { priceUSD };
};
