import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import FadeLoader from "react-spinners/ClipLoader";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Footer } from "../";
import { useActions, useSelector } from "../../hooks";

const EditCollection: React.FC = () => {
  const [logoTemp, setLogoTemp] = useState<any | null>("");
  const [featuredTemp, setFeaturedTemp] = useState<any | null>("");
  const [bannerTemp, setBannerTemp] = useState<any | null>("");
  const [pageLoading, setPageLoading] = useState(true);
  const { user } = useSelector((state) => state.auth);
  let { id: collectionId } = useParams();
  const { collection } = useSelector((state) => state.collections);

  const { editCollection, fetchCollection } = useActions();
  const navigate = useNavigate();

  useEffect(() => {
    window.document.title = "ZingIt | Edit Collection ";
  }, []);

  useEffect(() => {
    if (collectionId) {
      fetchCollection(collectionId);
    }
  }, [collectionId]);

  useEffect(() => {
    if (collection) {
      setLogoTemp(collection?.logo);
      setFeaturedTemp(collection?.featuredImg);
      setBannerTemp(collection?.bannerImg);
      setPageLoading(false);
    }
  }, [collection]);

  const handleImage = (event: any, type: string, setFieldValue: any) => {
    let file = event.target.files[0];
    let reader = new FileReader();
    if (!file) return;

    setFieldValue(type, file);
    reader.onloadend = () => {
      switch (type) {
        case "logo":
          setLogoTemp(reader?.result);
          break;
        case "featuredImg":
          setFeaturedTemp(reader?.result);
          break;
        case "bannerImg":
          setBannerTemp(reader?.result);
          break;
      }
    };
    reader.readAsDataURL(file);
  };

  const handleSaveChanges = async (values: any) => {
    const {
      logo,
      featuredImg,
      bannerImg,
      name,
      description,
      siteUrl,
      discordUrl,
      instagramUrl,
      mediumUrl,
    } = values;
    if (logo && bannerImg && featuredImg && name && user) {
      await editCollection(
        collection?.id!,
        name,
        user?.id!,
        logo,
        featuredImg,
        bannerImg,
        description,
        (collection) => {
          navigate("/collection?id=" + collection?.id);
        },
        siteUrl,
        discordUrl,
        instagramUrl,
        mediumUrl
      );
    } else {
      toast.warn("You forgot something");
    }
  };

  const instagramUrlRegex =
    /^(?:https?:\/\/)?(?:www\.)?instagram\.com\/([a-zA-Z0-9_](?:(?:[a-zA-Z0-9_]|(?:\.(?!\.))){0,28}(?:[a-zA-Z0-9_]))?)\/?$/;
  const discordInviteUrlRegex =
    /^(?:https?:\/\/)?(?:www\.)?discord(?:app)?\.com\/invite\/[\w-]+$/;
  const mediumUrlRegex =
    /^(?:https?:\/\/)?(?:www\.)?medium\.com\/@?[\w-]+(?:\/[\w-]+)*$/;

  const validationSchema = Yup.object({
    name: Yup.string().required("Collection Name is required"),
    logo: Yup.mixed()
      .required("Logo is required")
      .test("fileSize", "Logo File size is too large", (value: any) => {
        if (!value?.size) return true;
        return value.size <= 100 * 1024 * 1024; // 100 MB limit
      }),
    featuredImg: Yup.mixed()
      .required("Featured Image is required")
      .test(
        "fileSize",
        "Featured Image File size is too large",
        (value: any) => {
          if (!value?.size) return true;
          return value.size <= 100 * 1024 * 1024; // 100 MB limit
        }
      ),
    bannerImg: Yup.mixed()
      .required("Banner Image is required")
      .test("fileSize", "Banner Image File size is too large", (value: any) => {
        if (!value?.size) return true;
        return value.size <= 100 * 1024 * 1024; // 100 MB limit
      }),
    siteUrl: Yup.string().url("Please enter a valid URL").nullable(),
    discordUrl: Yup.string()
      .matches(discordInviteUrlRegex, "Please enter a valid Discord URL")
      .nullable(),
    instagramUrl: Yup.string()
      .matches(instagramUrlRegex, "Please enter a valid Instagram URL")
      .nullable(),
    mediumUrl: Yup.string()
      .matches(mediumUrlRegex, "Please enter a valid Medium URL")
      .nullable(),
  });

  if (pageLoading)
    return (
      <div className="loader">
        <FadeLoader
          loading={pageLoading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );

  return (
    <Formik
      enableReinitialize
      validateOnBlur={true}
      validateOnChange={true}
      validationSchema={validationSchema}
      initialValues={collection!}
      onSubmit={async (values: any, { setSubmitting }: any) => {
        await handleSaveChanges(values);
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, isValid, errors, setFieldValue }) => {
        return (
          <div>
            <Form className="form-border">
              <div className="container create-coll">
                <h1 className="f-40 mt-4">Update Collection</h1>

                <div className="mt-4">
                  <div>
                    <h3 className="f-22 mt-4 mb-0">
                      Name <small className="text-danger">*</small>
                    </h3>
                    <div className="coll-input">
                      <Field
                        type="text"
                        name="name"
                        disabled={isSubmitting}
                        id="name"
                        className="form-control"
                        placeholder="Enter your Collection name"
                      />
                      <ErrorMessage
                        name="name"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  </div>

                  <div>
                    <h3 className="f-22 mt-4 mb-0">Description</h3>
                    <div className="coll-input">
                      <Field
                        component="textarea"
                        name="description"
                        disabled={isSubmitting}
                        id="description"
                        className="form-control"
                        placeholder="Tell the world about this collection!"
                      />
                      <ErrorMessage
                        name="description"
                        className="text-danger"
                        component="div"
                      />
                    </div>
                  </div>

                  <div className="col-12 row mt-5">
                    <div className="col-xs-12 col-md-6">
                      <h3 className="f-22 mb-1">
                        Logo image <small className="text-danger">*</small>
                      </h3>
                      <p className="text-secondary f-12 f-600">
                        This image will also be used for navigation. 350 x 350
                        recommended.
                        {errors.logo && (
                          <p className="text-danger"> {errors.logo}</p>
                        )}
                      </p>
                      <div
                        className="cc-img-wrap logo"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          document.getElementById("upload-logo")?.click()
                        }
                      >
                        <img
                          src={
                            logoTemp
                              ? logoTemp
                              : "https://excellance.com/wp-content/plugins/photonic/include/images/placeholder.png"
                          }
                          alt=""
                        />
                      </div>
                      <input
                        name="logo"
                        type="file"
                        id="upload-logo"
                        hidden
                        accept="image/*"
                        onChange={(event) =>
                          handleImage(event, "logo", setFieldValue)
                        }
                      />
                    </div>

                    <div className="col-xs-12 col-md-6">
                      <h3 className="f-22 mb-0">
                        Featured image <small className="text-danger">*</small>
                      </h3>
                      <p className="text-secondary f-12 f-600">
                        This image will be used for featuring your collection on
                        the homepage, category pages, or other promotional areas
                        of ZingIt. 600 x 400 recommended.
                        {errors.featuredImg && (
                          <p className="text-danger"> {errors?.featuredImg}</p>
                        )}
                      </p>

                      <ErrorMessage
                        name="featuredImg"
                        className="text-danger"
                        component="div"
                      />
                      <div
                        className="cc-img-wrap featured"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          document.getElementById("upload-featured")?.click()
                        }
                      >
                        <img
                          src={
                            featuredTemp
                              ? featuredTemp
                              : "https://excellance.com/wp-content/plugins/photonic/include/images/placeholder.png"
                          }
                          alt=""
                        />
                      </div>
                      <input
                        name="featuredImg"
                        type="file"
                        id="upload-featured"
                        hidden
                        accept="image/*"
                        onChange={(event) =>
                          handleImage(event, "featuredImg", setFieldValue)
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <h3 className="f-22 mt-4 mb-0">
                      Banner image <small className="text-danger">*</small>
                    </h3>
                    <p className="text-secondary f-12 f-600">
                      This image will appear at the top of your collection page.
                      Avoid including too much text in this banner image, as the
                      dimensions change on different devices. 1400 x 350
                      recommended.
                      {errors.bannerImg && (
                        <p className="text-danger"> {errors.bannerImg}</p>
                      )}
                    </p>
                    <div
                      className="cc-img-wrap banner"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        document.getElementById("upload-banner")?.click()
                      }
                    >
                      <img
                        src={
                          bannerTemp
                            ? bannerTemp
                            : "https://excellance.com/wp-content/plugins/photonic/include/images/placeholder.png"
                        }
                        alt=""
                      />
                    </div>
                    <input
                      name="bannerImg"
                      type="file"
                      id="upload-banner"
                      hidden
                      accept="image/*"
                      onChange={(event) =>
                        handleImage(event, "bannerImg", setFieldValue)
                      }
                    />
                  </div>
                  <div className="mt-5">
                    <h3 className="f-22 mt-4 mb-0">Links</h3>
                    <div className="coll-links">
                      <div>
                        <Field
                          type="text"
                          disabled={isSubmitting}
                          name="siteUrl"
                          id="siteUrl"
                          className="form-control"
                          placeholder="Yoursite.io"
                        />
                        <ErrorMessage
                          name="siteUrl"
                          className="text-danger"
                          component="div"
                        />
                        <span>
                          <i className="fa fa-globe"></i>
                        </span>
                      </div>
                      <div>
                        <Field
                          type="text"
                          disabled={isSubmitting}
                          name="discordUrl"
                          id="discordUrl"
                          className="form-control"
                          placeholder="https://discord.gg"
                        />
                        <ErrorMessage
                          name="discordUrl"
                          className="text-danger"
                          component="div"
                        />
                        <span>
                          <i className="fa fa-discord"></i>
                        </span>
                      </div>
                      <div>
                        <Field
                          type="text"
                          disabled={isSubmitting}
                          name="instagramUrl"
                          id="instagramUrl"
                          className="form-control"
                          placeholder="https://www.instagram.com"
                        />
                        <ErrorMessage
                          name="instagramUrl"
                          className="text-danger"
                          component="div"
                        />
                        <span>
                          <i className="fa fa-instagram"></i>
                        </span>
                      </div>
                      <div>
                        <Field
                          type="text"
                          disabled={isSubmitting}
                          name="mediumUrl"
                          id="mediumUrl"
                          className="form-control"
                          placeholder="https://www.medium.com"
                        />
                        <ErrorMessage
                          name="mediumUrl"
                          className="text-danger"
                          component="div"
                        />
                        <span>
                          <i className="fa fa-medium-m"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  {!isSubmitting ? (
                    <div className="create-btn">
                      <button
                        type="submit"
                        disabled={isSubmitting || !isValid}
                        id="submit"
                        className="btn-main"
                      >
                        Update Collection
                      </button>
                    </div>
                  ) : (
                    <div>
                      <ThreeDots
                        height="60"
                        width="60"
                        color="#8364e2"
                        ariaLabel="loading"
                      />
                      <p style={{ color: "#8364e2" }}>Updating Collection</p>
                    </div>
                  )}

                  <div className="spacer-30"></div>
                </div>
              </div>
            </Form>

            <Footer />
          </div>
        );
      }}
    </Formik>
  );
};
export default EditCollection;
