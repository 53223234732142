import { OverlayTrigger, Tooltip } from "react-bootstrap";

const SepoliaInfo = (props: any) => {
  const tooltip = (
    <Tooltip>
      Sepolia Testnet is a free Ethereum testing ground where you can safely
      experiment with blockchain using test Ether without real value.
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={tooltip}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default SepoliaInfo;
