/* eslint-disable react-hooks/exhaustive-deps */
import { ItemType } from "@opensea/seaport-js/lib/constants";
import { Editor } from "@tinymce/tinymce-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { blockChainData } from "../../api/modules/nft";
import ZingItArt721Json from "../../blockchain/artifacts/contracts/ZingItArt721.json";
import ZingItTradable1155Json from "../../blockchain/artifacts/contracts/ZingItTradable1155.json";
import { PLATFORM_FEE_PERCENTAGE } from "../../constants";
import { useActions, useConversion, useSelector } from "../../hooks";
import { useDisplayPrice } from "../../hooks/modules/useDisplayPrice";
import { Collection } from "../../models/collection";
import { GREEN_CHAINS, IAttribute, NftType } from "../../models/nft";
import { toCapitalized } from "../../models/user";
import { validateCurrencyValue } from "../../utils/validation";
import Footer from "../Partials/Footer";
import AddPropertiesModal from "../Partials/Modals/AddPropertiesModal";
import CreateCollectionModal from "../Partials/Modals/CreateCollectionModal";
import GoogleCaptchaModal from "../Partials/Modals/GoogleCaptchaModal";
import NftDisplay from "../Partials/NftDisplay";
import EthereumInfo from "../Partials/ToolTips/EthereumInfo";
import GoerliInfo from "../Partials/ToolTips/GoerliInfo";
import MintGreenInfo from "../Partials/ToolTips/MintGreenInfo";
import MumbaiInfo from "../Partials/ToolTips/MumbaiInfo";
import PolygonInfo from "../Partials/ToolTips/PolygonInfo";
import SepoliaInfo from "../Partials/ToolTips/SepoliaInfo";
import ZingIt1155Info from "../Partials/ToolTips/ZingIt1155Info";
import ZingIt721Info from "../Partials/ToolTips/ZingIt721Info";
import { DEFAULT_USER_IMAGE, DEFAULT_AUDIO_IMAGE } from "../Partials/constants";
import FadeLoader from "react-spinners/ClipLoader";
const CreateNFT = () => {
  const acceptedFiles =
    ".PNG, .JPG, .GIF, .WEBP, .MP4, .SVG, .WEBM, .MP3, .WAV, .OGG, .GLB, .GLTF";
  const { userId, user } = useSelector((state) => state.auth);
  const { fetchMyCollections, createCollection } = useActions();
  const { myCollections, loading: collectionsLoading } = useSelector(
    (state) => state.collections
  );
  const ZingItArt721: any = ZingItArt721Json;
  const ZingItTradable1155: any = ZingItTradable1155Json;
  useEffect(() => {
    fetchMyCollections();
  }, []);

  useEffect(() => {
    if (myCollections?.length) {
      setCollection(myCollections[0].id);
    }
  }, [myCollections]);

  const [showCaptcha, setShowCaptcha] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("0");
  const [description, setDescription] = useState("");
  const [nftFile, setNftFile] = useState<any>();
  const [previewImageFile, setPreviewImageFile] = useState<any>();
  const [requirePreviewImage, setRequirePreviewImage] = useState(false);
  const [link, setLink] = useState("");
  const [properties, setProperties] = useState<IAttribute[]>([]);
  const [hasUnlockable, setHasUnlockable] = useState(false);
  const [unlockableContent, setUnlockableContent] = useState("");
  const [explicit, setExplicit] = useState(false);
  const [supply, setSupply] = useState(1);
  const [collection, setCollection] = useState("undefined");
  const [selectedBlockchain, setSelectedBlockchain] = useState(
    process.env.REACT_APP_ENV_NAME !== "production" ? "goerli" : "mainnet"
  );
  const [nftType, setNftType] = useState<NftType>(NftType.ZingItTradable1155);
  const { createNft } = useActions();
  const navigate = useNavigate();
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [royaltyPercentage, setRoyaltyPercentage] = useState(0);
  const [priceError, setPriceError] = useState("");
  const [royaltyError, setRoyaltyError] = useState("");
  const [displayPrice] = useDisplayPrice(Number(price));
  const [invalidLink, setInvalidLink] = useState(false);

  const [previewUrl, setPreviewUrl] = useState("");
  const [displayUrl, setDisplayUrl] = useState("");

  const { priceUSD } = useConversion(
    Number(price),
    blockChainData[selectedBlockchain].symbol
  );
  const displayRef: any = useRef();

  const handleImageData = async (data: string) => {
    const base64Response = await fetch(data);
    const blob = await base64Response.blob();
    setPreviewImageFile(blob);
    setPreviewUrl(URL.createObjectURL(blob));
  };
  const inValidateSize = (filesArr: File[]) =>
    filesArr.find((x: File) => {
      const fileSize = Math.round(x.size / 1024);
      return fileSize >= 200000 || fileSize < 1;
    });

  const handleFileImport = (e: any) => {
    const filesArr = Array.prototype.slice.call(e.target.files);
    if (inValidateSize(filesArr)) {
      toast.error("Invalid File Size.");
      return;
    }
    const file = filesArr[0];
    if (!file) return;

    setPreviewImageFile(null);
    setPreviewUrl("");

    const mediaType = file.type.split("/")[0];
    setNftFile(file);
    setDisplayUrl(URL.createObjectURL(file));
    if (mediaType === "audio") {
      setPreviewImageFile(DEFAULT_AUDIO_IMAGE);
      setPreviewUrl(DEFAULT_AUDIO_IMAGE);
    }
    setRequirePreviewImage(mediaType === "audio" || mediaType === "video");
  };

  const handlePreviewImageImport = (e: any) => {
    const filesArr = Array.prototype.slice.call(e.target.files);
    if (inValidateSize(filesArr)) {
      toast.error("Invalid File Size.");
      return;
    }

    const file = filesArr[0];
    if (!file) return;
    setPreviewImageFile(file);
    setPreviewUrl(URL.createObjectURL(file));
    resetVideo();
  };
  const resetVideo = () => {
    const videoElement = displayRef.current;
    if (!videoElement) return;
    videoElement.currentTime = 0;
    videoElement.pause();
    videoElement.load();
  };

  const handleCreateCollection = async (
    collectionName: string,
    image: string
  ) => {
    setLoading(true);
    await createCollection(
      collectionName,
      user?.id!,
      image,
      image,
      image,
      "",
      async (collection?: Collection, error?: string) => {
        if (collection) {
          setLoading(true);
          setShowCollectionModal(false);
          await fetchMyCollections();
          setCollection(collection?.id?.toString() || "undefined");
        } else {
          toast.error(error || "Collection Creation Failed!");
        }
        setLoading(false);
      },
      "",
      "",
      "",
      "",
      ""
    );
    setLoading(false);
  };

  const handleBackendMinting = async (captchaToken: string) => {
    toast.dismiss();
    setShowCaptcha(false);
    if (!captchaToken) {
      toast.error("Captcha Failed!");
      window.scrollTo(0, 0);
      return;
    }

    if (requirePreviewImage && !previewImageFile) {
      toast.error("Preview Image Required!");
      window.scrollTo(0, 0);
      return;
    }

    if (name && user?.id && captchaToken) {
      setLoading(true);
      const nftFileType = nftFile.type;
      const tokenAddress =
        nftType === NftType.ZingItArt721
          ? ZingItArt721.networks[selectedBlockchain].address
          : nftType === NftType.ZingItTradable1155
          ? ZingItTradable1155.networks[selectedBlockchain].address
          : "";

      const _iItemType =
        nftType === NftType.ZingItArt721
          ? ItemType.ERC721
          : nftType === NftType.ZingItTradable1155
          ? ItemType.ERC1155
          : undefined;
      await createNft({
        captchaToken,
        name,
        tokenAddress,
        previewImage: previewImageFile,
        nftFile,
        nftFileType,
        creator: user.id,
        royaltyPercentage,
        callback: (nft: any) => {
          setLoading(false);
          if (nft) navigate("/item/" + nft.data.id);
          else {
            toast.error("A Error occurred creating the NFT!");
          }
        },
        description,
        link,
        hasUnlockable,
        unlockableContent,
        properties,
        supply,
        explicit,
        blockchain: selectedBlockchain,
        nftCollection: collection,
        price: Number(price),
        type: nftType,
        tokenType: _iItemType,
      });
    } else {
      toast.error("Check Required Fields!");
      window.scrollTo(0, 0);
      console.error("You forgot something", {
        name,
        description,
        nftFile,
        userId,
      });
    }
  };

  useEffect(() => {
    window.document.title = "ZingIt | Create NFT ";
  }, []);

  const handleSetPrice = async (newValue: string) => {
    toast.dismiss();
    const errorMessage = validateCurrencyValue(newValue);
    if (errorMessage) {
      setPriceError(errorMessage);
    } else {
      setPriceError("");
      setPrice(newValue);
    }
  };
  const handleSetRoyalty = async (newValue: string) => {
    toast.dismiss();
    const errorMessage = validateCurrencyValue(newValue, 2, true, 100);
    if (errorMessage) {
      setRoyaltyError(errorMessage);
    } else {
      setRoyaltyError("");
      setRoyaltyPercentage(Number(newValue));
    }
  };

  const handleBlockchainSelection = (chain: string) =>
    setSelectedBlockchain(chain);

  const handleNftType = (b: NftType) => setNftType(b);

  const [showCollectionModal, setShowCollectionModal] = useState(false);

  const handleShowAddCollectionModal = () => setShowCollectionModal(true);

  const handleCloseModal = () => setShowCollectionModal(false);

  const handleDescriptionChange = useCallback((value: string) => {
    setDescription(value);
  }, []);

  const handleUnlockableContentChange = useCallback((value: string) => {
    setUnlockableContent(value);
  }, []);

  const handleLinkChange = (event: any) => {
    const value = event.target.value;

    if (value.match(/^(http:\/\/|https:\/\/)/i) || value === "") {
      setLink(value);
      setInvalidLink(false);
    } else {
      setInvalidLink(true);
    }
  };

  if (collectionsLoading) {
    return (
      <div className="loader">
        <FadeLoader
          size={100}
          loading={collectionsLoading}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }
  return (
    <div>
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb">
          <div className="container">
            <div className="row m-10-hor">
              <div className="col-12">
                <h2 className="style-2">Create NFT</h2>
              </div>
            </div>
          </div>
        </div>
        <section className="container">
          <div className="row mx-0">
            <div className="col-lg-8 mb-5">
              <form
                id="form-create-item"
                className="form-border c-nft-wrapper"
                action="#"
              >
                <div className="field-set">
                  <h5 className="f-300">
                    <span>
                      Upload your NFT Image, Video or Audio
                      <span className="text-danger">*</span>
                    </span>
                  </h5>
                  <div className="d-create-file">
                    <p id="file_name">{acceptedFiles} Max 200mb.</p>

                    <div className="browse">
                      <input
                        type="button"
                        disabled={loading}
                        id="get_file"
                        className="btn-transparent"
                        value="BROWSE"
                        onClick={() =>
                          document.getElementById("upload_file")?.click()
                        }
                      />
                      <input
                        id="upload_file"
                        type="file"
                        multiple={false}
                        disabled={loading}
                        accept={acceptedFiles}
                        onChange={(event) => {
                          handleFileImport(event);
                        }}
                      />
                    </div>
                  </div>
                  <div className="spacer-10" />
                  {requirePreviewImage && (
                    <>
                      <h5>
                        <span>Preview Image</span>
                      </h5>
                      <div className="d-create-file">
                        <p id="file_name">
                          Provide a custom preview image (PNG, JPG, or GIF)
                        </p>
                        <div className="browse">
                          <input
                            type="button"
                            id="get_file"
                            className="btn-transparent"
                            disabled={loading}
                            value="Browse"
                          />
                          <input
                            id="upload_file"
                            type="file"
                            disabled={loading}
                            multiple={false}
                            accept={".PNG, .JPG, .GIF"}
                            onChange={(event) => {
                              handlePreviewImageImport(event);
                            }}
                          />
                        </div>
                      </div>
                      <div className="spacer-10" />
                    </>
                  )}
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-2 f-300 d-flex flex-column">
                      <span>
                        Title<span className="text-danger">*</span>
                      </span>
                      <span className="my-0 f-12 c-nft-info">
                        Your title will appear on the item's detail page.
                      </span>
                    </h5>
                    <p className="mb-0">
                      <span className="text-danger">*</span>Required Fields
                    </p>
                  </div>
                  <input
                    type="text"
                    name="item_title"
                    id="item_title"
                    disabled={loading}
                    className="form-control"
                    placeholder="e.g. 'Crypto Funk"
                    onChange={(event) => setName(event.target.value)}
                  />
                  <div className="spacer-10"></div>
                  <h5 className="mb-2 f-300 d-flex flex-column">
                    <span>Description</span>
                    <span className="my-0 f-12 c-nft-info">
                      Your description will appear on the item’s detail page.
                    </span>
                  </h5>
                  <Editor
                    apiKey="tldx88f1crkakdy51vzb6cm2ljlvl4v075uxcpt3v0ug8wwk"
                    onEditorChange={handleDescriptionChange}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | link " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                  <div className="spacer-10"></div>
                  <h5 className="mb-2 f-300 d-flex flex-column">
                    Item Link
                    <span className="my-0 f-12 c-nft-info">
                      ZingIt will include a URL to your website, showcasing your
                      NFT.
                    </span>
                    {invalidLink && (
                      <span className="text-danger f-12 ms-0 mt-1">
                        Enter valid Link
                      </span>
                    )}
                  </h5>
                  <input
                    type="text"
                    disabled={loading}
                    name="ext_link"
                    id="ext_link"
                    className="form-control"
                    pattern="^(http:\/\/|https:\/\/).*$"
                    placeholder="https://yoursite.com/asset/1"
                    onChange={handleLinkChange}
                  />
                  <div className="spacer-10"></div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h5 className="mb-2 f-300 d-flex flex-column">
                        Collection
                        <span className="my-0 f-12 c-nft-info">
                          Add your NFT to a Collection
                        </span>
                      </h5>
                    </div>
                    <div>
                      <input
                        disabled={collectionsLoading || loading}
                        type="button"
                        id="submit"
                        className="btn-transparent"
                        value="ADD +"
                        onClick={() => handleShowAddCollectionModal()}
                      />
                    </div>
                  </div>
                  <select
                    className="form-select"
                    disabled={loading}
                    value={collection}
                    aria-label="Default select example"
                    onChange={(e) => setCollection(e.target.value)}
                  >
                    {!myCollections?.length && (
                      <option value={"undefined"}>
                        {user?.username
                          ? toCapitalized(user?.username)
                          : "Undefined"}{" "}
                        Collection
                      </option>
                    )}
                    {myCollections?.length &&
                      myCollections?.map((item) => {
                        return (
                          <React.Fragment key={item.id}>
                            <option value={item.id}>{item.name}</option>
                          </React.Fragment>
                        );
                      })}
                  </select>
                  <div className="spacer-20"></div>
                  <h5 className="mb-2 f-300 d-flex flex-column">
                    Blockchain
                    <span className="mb-1 f-12 c-nft-info">
                      Select which blockchain to utilize
                    </span>
                  </h5>
                  <div className="c-nft-blockchain d-flex flex-wrap justify-content-start">
                    {process.env.REACT_APP_ENV_NAME !== "production" && (
                      <div
                        className={`c-eth me-2 me-lg-4 ${
                          selectedBlockchain === "goerli" && "selected"
                        }`}
                        onClick={() => handleBlockchainSelection("goerli")}
                      >
                        <span className="inner">
                          <i className="fa fa-ethereum"></i>
                        </span>
                        <p>Goerli</p>
                        <GoerliInfo>
                          <span className="exclaim">
                            <i className="fa-solid fa-exclamation"></i>
                          </span>
                        </GoerliInfo>
                      </div>
                    )}
                    {process.env.REACT_APP_ENV_NAME !== "production" && (
                      <div
                        style={{ opacity: 0.3 }}
                        className={`c-eth me-2 me-lg-4  ${
                          selectedBlockchain === "sepolia" && "selected"
                        }`}
                        onClick={() =>
                          false && handleBlockchainSelection("sepolia")
                        }
                      >
                        <span className="inner">
                          <i className="fa fa-ethereum"></i>
                        </span>
                        <p>Sepolia</p>
                        <SepoliaInfo>
                          <span className="exclaim">
                            <i className="fa-solid fa-exclamation"></i>
                          </span>
                        </SepoliaInfo>
                      </div>
                    )}
                    {process.env.REACT_APP_ENV_NAME !== "production" && (
                      <div
                        className={`c-poly me-2 me-lg-4  ${
                          selectedBlockchain === "polygonMumbai" && "selected"
                        }`}
                        onClick={() =>
                          handleBlockchainSelection("polygonMumbai")
                        }
                      >
                        <span className="text-white inner">
                          {GREEN_CHAINS.includes(selectedBlockchain) ? (
                            <img src="/img/polygon.png" alt="" />
                          ) : (
                            <img src="/img/polygon-purple.png" alt="" />
                          )}
                        </span>
                        <p>Mumbai</p>
                        <MumbaiInfo>
                          <span className="exclaim">
                            <i className="fa-solid fa-exclamation"></i>
                          </span>
                        </MumbaiInfo>
                        <MintGreenInfo>
                          <span className="text-white mint-small-white">
                            {GREEN_CHAINS.includes(selectedBlockchain) ? (
                              <img
                                src="/img/mint-small-white.png"
                                alt="Mint Green"
                              />
                            ) : (
                              <img src="/img/mint-small.png" alt="Mint Green" />
                            )}
                          </span>
                        </MintGreenInfo>
                      </div>
                    )}
                    {process.env.REACT_APP_ENV_NAME === "production" && (
                      <div
                        className={`c-eth me-2 me-lg-4  ${
                          selectedBlockchain === "mainnet" && "selected"
                        }`}
                        onClick={() => handleBlockchainSelection("mainnet")}
                      >
                        <span className="inner">
                          <i className="fa fa-ethereum"></i>
                        </span>
                        <p>Ethereum</p>
                        <EthereumInfo>
                          <span className="exclaim">
                            <i className="fa-solid fa-exclamation"></i>
                          </span>
                        </EthereumInfo>
                      </div>
                    )}
                    {process.env.REACT_APP_ENV_NAME === "production" && (
                      <div
                        className={`c-poly me-2 me-lg-4 ${
                          selectedBlockchain === "polygon" && "selected"
                        }`}
                        onClick={() => handleBlockchainSelection("polygon")}
                      >
                        <span className="text-white inner">
                          {selectedBlockchain === "polygon" ? (
                            <img src="/img/polygon.png" alt="" />
                          ) : (
                            <img src="/img/polygon-purple.png" alt="" />
                          )}
                        </span>
                        <p>Polygon</p>
                        <PolygonInfo>
                          <span className="exclaim">
                            <i className="fa-solid fa-exclamation"></i>
                          </span>
                        </PolygonInfo>

                        <MintGreenInfo>
                          <span className="text-white mint-small-white">
                            {selectedBlockchain === "polygon" ? (
                              <img src="/img/mint-small.png" alt="Mint Green" />
                            ) : (
                              <img src="/img/mint-small.png" alt="Mint Green" />
                            )}
                          </span>
                        </MintGreenInfo>
                      </div>
                    )}
                  </div>
                  <div className="spacer-20"></div>
                  <div className="d-flex c-nft-price">
                    <div>
                      <h5 className="mb-0 f-300">Price</h5>
                      {priceError && (
                        <span className="text-danger">{priceError}</span>
                      )}
                      <div className="inner mb-2">
                        <div className="c-nft-input">
                          <input
                            type="text"
                            name="item_title"
                            id="item_title"
                            disabled={loading}
                            className="form-control"
                            placeholder={`0 ${blockChainData[selectedBlockchain].symbol}`}
                            onChange={(event) => {
                              handleSetPrice(event.target.value);
                            }}
                          />
                          <small>${priceUSD} USD</small>
                        </div>

                        <img
                          style={{
                            marginTop: "5px",
                            width: "auto",
                            height: "25px",
                          }}
                          className="ms-1"
                          src={blockChainData[selectedBlockchain].logo}
                          alt={blockChainData[selectedBlockchain].symbol}
                        />
                      </div>
                    </div>
                    <div>
                      <h5 className="mb-0 f-300">Royalties</h5>
                      {royaltyError && (
                        <div className="mb-1">
                          <span className="text-danger">{royaltyError}</span>
                        </div>
                      )}
                      <div className="d-flex flex-column align-items-start">
                        <div className="d-inline-flex align-items-center justify-content-end">
                          <input
                            type="text"
                            name="item_title"
                            id="item_title"
                            disabled={loading}
                            className="form-control mb-0"
                            placeholder={"0%"}
                            onChange={(event) => {
                              handleSetRoyalty(event.target.value);
                            }}
                          />
                          <small>%</small>
                        </div>
                        <span className="my-0 f-12 c-nft-info">
                          Enter % requested on future sales
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="spacer-20"></div>
                  {/* <h5 className="mb-2 f-300 d-flex flex-column">
                    NFT Type
                    <span className="mb-1 f-12 c-nft-info">
                      Select the type of NFT
                    </span>
                  </h5> */}
                  <div className="c-nft-blockchain n-type ">
                    {/* <div className="d-flex">
                      <div
                        className={`c-eth me-2 me-lg-4 ${
                          nftType === NftType.ZingItArt721 && "selected"
                        }`}
                        onClick={() => handleNftType(NftType.ZingItArt721)}
                      >
                        <span className="inner">
                          <img src="/img/one-kind.png" alt="" />
                        </span>
                        <p className="mb-1">One of a Kind</p>
                        <p className="f-12 mt-0">(ERC-721)</p>
                        <ZingIt721Info>
                          <span className="exclaim">
                            <i className="fa-solid fa-exclamation"></i>
                          </span>
                        </ZingIt721Info>
                      </div>
                      <div
                        className={`c-poly me-2 me-lg-4 ${
                          nftType === NftType.ZingItTradable1155 && "selected"
                        }`}
                        onClick={() =>
                          handleNftType(NftType.ZingItTradable1155)
                        }
                      >
                        <span className="inner">
                          <img src="/img/limited-ed.png" alt="" />
                        </span>
                        <p className="mb-1">Limited Editions</p>
                        <p className="f-12 mt-0">(ERC-1155)</p>
                        <ZingIt1155Info>
                          <span className="exclaim">
                            <i className="fa-solid fa-exclamation"></i>
                          </span>
                        </ZingIt1155Info>
                      </div>
                    </div> */}
                    {nftType === NftType.ZingItTradable1155 && (
                      <div className="number-nft ms-0">
                        <h5 className="mb-0 f-300">Number of NFTs</h5>
                        <div>
                          <input
                            type="number"
                            name="supply"
                            disabled={loading}
                            id="supply"
                            className="form-control"
                            defaultValue={supply}
                            onChange={(event) =>
                              setSupply(Number(event.target.value))
                            }
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="spacer-20"></div>
                  <>
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <h5 className="mb-2 f-300 d-flex flex-column">
                          Properties
                          <span className="my-0 f-12 c-nft-info">
                            Add the description of the unique properties of your
                            NFT
                          </span>
                        </h5>
                      </div>
                      <div style={{ marginLeft: "40%" }}>
                        <input
                          disabled={collectionsLoading || loading}
                          type="button"
                          id="submit"
                          className="btn-transparent"
                          value="ADD +"
                          onClick={() => setShowAddProperty(true)}
                        />
                      </div>
                    </div>

                    {properties.length > 0 ? (
                      <div>
                        {properties.map((item, i) => {
                          return (
                            <div key={i}>
                              <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex justify-content-between align-items-start">
                                  <div className="ms-2 me-auto">
                                    <div className="fw-bold">
                                      {item.trait_type}
                                    </div>
                                    {item.value}
                                    {/* - {item.statistic} */}
                                  </div>
                                  <div className="icontype">
                                    <i
                                      className="fa fa-trash fa-lg"
                                      style={{
                                        color: "purple",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        let tempArr = [...properties];
                                        tempArr.splice(i, 1);
                                        setProperties(tempArr);
                                      }}
                                    ></i>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div />
                    )}
                  </>
                  <div className="spacer-20"></div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="mb-2 f-300 d-flex flex-column">
                        Unlockable Content
                        <span className="my-0 f-12 c-nft-info">
                          Include unlockable content that can only be revealed
                          by the owner of the item.
                        </span>
                        {hasUnlockable && !unlockableContent && (
                          <span className="text-danger f-12 c-nft-info ms-0 ">
                            Content Required
                          </span>
                        )}
                      </h5>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          disabled={loading}
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={hasUnlockable}
                          onChange={() => setHasUnlockable(!hasUnlockable)}
                        />
                      </div>
                    </div>
                  </div>
                  {hasUnlockable ? (
                    <>
                      <Editor
                        id="unlockable_content"
                        apiKey="tldx88f1crkakdy51vzb6cm2ljlvl4v075uxcpt3v0ug8wwk"
                        initialValue=""
                        onEditorChange={handleUnlockableContentChange}
                        init={{
                          height: 200,
                          menubar: false,
                          plugins: [
                            "advlist",
                            "autolink",
                            "lists",
                            "link",
                            "image",
                            "charmap",
                            "preview",
                            "anchor",
                            "searchreplace",
                            "visualblocks",
                            "code",
                            "fullscreen",
                            "insertdatetime",
                            "media",
                            "table",
                            "code",
                            "help",
                            "wordcount",
                          ],
                          toolbar:
                            "undo redo | blocks | link " +
                            "bold italic forecolor | alignleft aligncenter " +
                            "alignright alignjustify | bullist numlist outdent indent | " +
                            "removeformat | help",
                          content_style:
                            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                        }}
                      />
                      <div className="spacer-20" />
                    </>
                  ) : null}
                  <div className="spacer-20"></div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5 className="mb-2 f-300 d-flex flex-column">
                        Explicit & Sensitive Content
                        <span className="my-0 f-12 c-nft-info">
                          Set this item as explicit and sensitive content
                        </span>
                      </h5>
                    </div>
                    <div>
                      <div className="form-check form-switch">
                        <input
                          className="form-check-input"
                          disabled={loading}
                          type="checkbox"
                          role="switch"
                          id="flexSwitchCheckChecked"
                          checked={explicit}
                          onChange={() => setExplicit(!explicit)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="spacer-30"></div>
                  <div className="mt-3 custom-fee">
                    <div className="d-flex align-items-center">
                      <div className="f-15 sellthis-title fw-600 mb-0">
                        <h5 className="mb-0 f-300 d-inline-flex align-items-center">
                          Fees
                          <span className="text-purple f-12 ms-1">
                            <i className="fa-solid fa-circle-exclamation"></i>
                          </span>
                        </h5>
                      </div>
                    </div>
                    <p className="text-gray my-1 f-13 mb-0">
                      <span>
                        {PLATFORM_FEE_PERCENTAGE}% Final Sale Fee (only charged
                        if NFT sells)
                      </span>
                    </p>
                  </div>
                  {!loading ? (
                    <div className="create-btn d-flex justify-content-end mt-2">
                      <input
                        type="button"
                        id="submit"
                        className="btn-main text-white"
                        value="Create NFT"
                        disabled={
                          collectionsLoading ||
                          !nftFile ||
                          invalidLink ||
                          loading ||
                          !name ||
                          !!priceError ||
                          !!royaltyError ||
                          (hasUnlockable && !unlockableContent) ||
                          (requirePreviewImage && !previewImageFile)
                        }
                        onClick={() => setShowCaptcha(true)}
                      />
                    </div>
                  ) : (
                    <div>
                      <ThreeDots
                        height="60"
                        width="60"
                        color="#8364e2"
                        ariaLabel="loading"
                      />
                      <p style={{ color: "#8364e2" }}>Creating NFT</p>
                    </div>
                  )}
                </div>
              </form>
            </div>

            <div className="col-lg-4 col-sm-6 col-xs-12 ml-custom">
              <h5>Preview item</h5>
              <div className="nft__item m-0">
                <div className="author_list_pp mb-3 p-2">
                  <span>
                    <img
                      className="lazy"
                      src={user?.userImage || DEFAULT_USER_IMAGE}
                      alt=""
                    />
                    {/* <i className='fa fa-check'></i> */}
                  </span>
                </div>
                <div className="nft__item_wrap">
                  {nftFile && (
                    <NftDisplay
                      displayRef={displayRef}
                      onImageData={handleImageData}
                      doNotShrink
                      extractPreview
                      className={"lazy nft__item_preview"}
                      fileType={nftFile.type}
                      nftFile={displayUrl || ""}
                      previewImageFile={previewUrl || ""}
                    />
                  )}
                </div>
                <div className="nft__item_info p-2">
                  <span>
                    <h4>{name}</h4>
                  </span>
                  <div className="nft__item_price">
                    <p className="curr_price">Current price</p>
                    <p className="d-flex" style={{ margin: "5px 0px" }}>
                      <img
                        style={{
                          width: "auto",
                          height: "25px",
                          marginRight: "8px",
                        }}
                        src={blockChainData[selectedBlockchain].logo}
                        alt={blockChainData[selectedBlockchain].symbol}
                      />
                      <span className="price-eth">
                        {displayPrice}{" "}
                        {blockChainData[selectedBlockchain].symbol}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>

      <Footer />

      <AddPropertiesModal
        show={showAddProperty}
        handleClose={setShowAddProperty}
        handleAddProperty={(newAttribute: IAttribute) => {
          const { trait_type, value, display_type } = newAttribute;
          let tempArr = properties;
          tempArr.push({ trait_type, value, display_type });
          setProperties(tempArr);
        }}
      />

      {showCaptcha && <GoogleCaptchaModal handleClose={handleBackendMinting} />}
      <CreateCollectionModal
        show={showCollectionModal}
        handleClose={() => setShowCollectionModal(false)}
        handleCreateCollection={handleCreateCollection}
      />
    </div>
  );
};

export default CreateNFT;
