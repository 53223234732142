import * as authentication from "./modules/authentication";
import * as author from "./modules/author";
import * as coinMarketCap from "./modules/coinMarketCap";
import * as collection from "./modules/collection";
import * as event from "./modules/event";
import * as nft from "./modules/nft";
import * as upload from "./modules/upload";

const api = {
  event,
  authentication,
  nft,
  upload,
  author,
  coinMarketCap,
  collection,
};

export default api;
