import moment from "moment";
import { useEffect, useState } from "react";
import { blockChainData } from "../../api/modules/nft";
import { NFT } from "../../models/nft";
import { Order, OrderType } from "../../models/order";
import { User, getUserSmallImageUrl } from "../../models/user";
import MakeOfferModal from "./Modals/MakeOfferModal";
import OfferInfo from "./Modals/OfferInfo";
import { DEFAULT_USER_IMAGE } from "./constants";
import {
  useActions,
  useConversion,
  useForceSwitchNetwork,
  useSelector,
} from "../../hooks";
import { toast } from "react-toastify";
import { cancelOrders } from "../../services/seaport.service";
import BuyNft from "./Modals/BuyNft";
import CongratulationsModal from "./Modals/CongratulationsModal";
import BuyButtonDisabledInfo from "./ToolTips/BuyButtonDisabledInfo";
import { useAccount, useBalance } from "wagmi";

type propType = { nft: NFT; user: User; refetch: () => {} };
const NftListings = (props: propType) => {
  const { nft, user, refetch } = props;
  // const { getNftOrders } = useActions();
  // const { listings } = useSelector((state) => state.listing);
  const listings = nft.listings;
  // useEffect(() => {
  //   if (nft) {
  //     getNftOrders(nft.id!, () => {});
  //   }
  // }, []);

  if (!listings?.length) return <></>;

  return (
    <div className="tab-1 onStep fadeIn mt-4 item_author_desc">
      {listings && listings.length! && (
        <>
          <h4>Listings</h4>
          <div
            style={{
              maxHeight: "250px",
              overflowY: "scroll",
            }}
          >
            {listings?.map((listing, index) => (
              <ListingComponent
                key={index}
                listing={listing}
                listings={listings}
                nft={nft}
                refetch={refetch}
                user={user}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

// Create a new ListingComponent
const ListingComponent: React.FC<{
  listing: any;
  nft: any;
  listings: Order[];
  user: any;
  refetch: any;
}> = ({ listing, listings, nft, user, refetch }) => {
  const { isCorrectNetwork, checkNetwork } = useForceSwitchNetwork(nft);
  const [showPurchase, setShowPurchase] = useState(false);
  const [showCongratsPurchase, setShowCongratsPurchase] = useState(false);
  const { isConnected, address } = useAccount();
  const { data: walletData } = useBalance({ address });
  const { priceUSD } = useConversion(
    Number(listing.amount),
    blockChainData[nft.blockchain].symbol
  );
  const { cancelOrder } = useActions();
  const price = nft.defaultListing?.amount || nft.price || 0;

  const cheapestListing: Order | undefined = listings?.reduce(
    (min: Order, listing: Order) => {
      return listing?.amount < min?.amount ? listing : min;
    },
    listings[0]
  );
  const now = Number(moment().unix());

  const isNotExpired = () => {
    if (!nft.forSale) return true;
    return nft.forSale && Number(nft.defaultListing?.endTime) > now;
  };
  const isNotPreOrder = () => {
    if (!nft.forSale) return true;
    return nft.forSale && Number(nft.defaultListing?.startTime) < now;
  };

  const hasListingOtherThanMine = () =>
    listings?.some((listing: any) => listing.seller?.id !== user?.id);
  const canBuyItem = () => {
    //not on sale, not minted
    //on sale, not on auction, not expired
    //on sale, on auction, price is lower than highest bid
    const hasHigherBid = nft.bids?.some((x: any) => x.amount > price);
    return (isNotExpired() && isNotPreOrder()) || !nft.tokenId || hasHigherBid;
  };

  async function handleCancelOrder(listing: Order) {
    if (!listing) return;
    try {
      toast.info(`Canceling order...`);
      const transaction = await cancelOrders([JSON.parse(listing.hash)]);
      await cancelOrder(listing.id!, async () => {
        await transaction.wait();
        await refetch();
        toast.dismiss();
        toast.success(`Order canceled!`);
      }); // TODO: work around for now, the should be in the webhook
    } catch (e) {
      setTimeout(() => {
        toast.error(`Failed to cancel order`);
      }, 500);
      console.error(e);
      toast.error(`Error canceling order`);
    }
  }

  const getDisabledReason = (buyButton = false) => {
    if (!hasListingOtherThanMine()) {
      return "You cannot buy your own NFT.";
    }
    if (!isNotExpired() && nft.tokenId) {
      return `This ${nft.defaultListing?.type} is expired.`;
    }
    if (!isNotPreOrder() && nft.tokenId) {
      return `This ${nft.defaultListing?.type} has not started.`;
    }
    if (!nft.forSale && nft.tokenId) {
      return "This NFT is not for sale.";
    }
    if (buyButton && nft.bids?.some((x: any) => x.amount > price)) {
      return "This NFT has a higher offer.";
    }
  };

  const handleClickBuyNft = async () => {
    await checkNetwork();
    if (parseFloat(walletData!.formatted) <= parseFloat(price?.toString())) {
      toast.error(
        "Please add money to your wallet. Click the wallet icon at the top."
      );
    } else {
      setShowPurchase(true);
    }
  };
  return (
    <div className="d-flex author_list_pp align-items-center mb-2">
      <div className="p_list_info ms-1">
        <a href={`/author/${listing.seller?.id}`}>
          <img
            className="lazy"
            src={
              listing.seller?.userImage
                ? getUserSmallImageUrl(listing.seller?.userImage)
                : DEFAULT_USER_IMAGE
            }
            alt=""
          />
        </a>
      </div>
      <div className="p_list_info ms-3">
        <div style={{ display: "flex", alignItems: "center" }}>
          <b>
            {listing.amount} W{blockChainData[nft.blockchain].symbol}
          </b>
          <span className="ms-1 price-usd">(${priceUSD})</span>
        </div>
        <span>
          by{" "}
          <a href={`/author/${listing.seller?.id}`}>
            <b>{listing.seller.username || "UNKNOWN"}</b>
          </a>{" "}
          ends {moment.unix(Number(listing.endTime)).fromNow()}
        </span>
      </div>
      {isConnected &&
        (nft.forSale || !nft.tokenId) &&
        listing.seller?.id !== user?.id && (
          <BuyButtonDisabledInfo
            show={!canBuyItem()}
            text={getDisabledReason(true)}
          >
            <div className="p_list_info ms-3">
              <button
                className="btn-main"
                disabled={!canBuyItem()}
                onClick={handleClickBuyNft}
              >
                Buy Now
              </button>
            </div>
          </BuyButtonDisabledInfo>
        )}
      {user?.id === listing?.seller?.id && (
        <div className="p_list_info ms-3">
          <button
            className="btn-secondary"
            onClick={() => handleCancelOrder(listing)}
          >
            Cancel Listing
          </button>
        </div>
      )}

      <BuyNft
        showLoading={true}
        show={showPurchase}
        handleClose={() => setShowPurchase(false)}
        label="Purchase This NFT"
        buttonAction="PURCHASE"
        noteColor="text-green"
        btnColor="btn-main wide"
        noteText="Check Your wallet to sign this transaction"
        quantity={1}
        nft={nft}
        order={listing}
        handleComplete={() => {
          setShowPurchase(false);
          setShowCongratsPurchase(true);
        }}
      />

      <CongratulationsModal
        show={showCongratsPurchase}
        handleClose={async () => {
          await refetch();
          setShowCongratsPurchase(false);
        }}
        label="Congratulations! It’s Yours!"
        noteText="Tell the world about your new purchase!"
        noteColor="text-green"
        nft={nft}
      />
    </div>
  );
};

export default NftListings;
