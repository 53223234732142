import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";

const SiteAccessModal = ({
  show = true,
  handleClose,
  password,
  children,
}: any) => {
  const [isOpen, setIsOpen] = useState(true);
  const [passwordInput, setPasswordInput] = useState("");
  const location = useLocation();

  useEffect(() => {
    setIsOpen(
      location.pathname !== "/creators" &&
        localStorage.getItem("tmpxpa") !== password
    );
  }, [setIsOpen, password, location.pathname]);

  function handlePasswordChange(event: React.ChangeEvent<HTMLInputElement>) {
    setPasswordInput(event.target.value);
  }

  function handleFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (passwordInput === password) {
      localStorage.setItem("tmpxpa", password);
      setIsOpen(false);
    }
  }

  return (
    <>
      {isOpen && show && (
        <Modal className="custom modal-bg" show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title className="">Restricted Access</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="col-5 mt-2 mb-3">
                <img src="../../../img/zingitcolored.svg" />
              </div>
              <div>
                <p className="f-20">
                  Please enter the password to access our Alpha.
                </p>
              </div>
              <form onSubmit={handleFormSubmit}>
                <div className="d-flex">
                  <input
                    type="password"
                    value={passwordInput}
                    onChange={handlePasswordChange}
                  />
                  <button className="btn-main ms-1" onClick={handleClose}>
                    Access
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {(!isOpen || !show) && children}
    </>
  );
};
export default SiteAccessModal;
