import Modal from "react-bootstrap/Modal";
import { useParams } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import { blockChainData } from "../../../api/modules/nft";
import { useConversion } from "../../../hooks";
import { getNftSmallUrl } from "../../../models/nft";

const CongratulationsModal = ({
  show,
  handleClose,
  label,
  noteText,
  nft,
}: any) => {
  const { id } = useParams();
  const price = nft.defaultListing?.amount || nft.price || 0;

  const { priceUSD } = useConversion(
    price,
    nft ? blockChainData[nft.blockchain].symbol : "ETH"
  );
  return (
    <Modal className="custom modal-bg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="">{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mt-2 mb-3">
          <div className="modal-nft-img w-auto me-4">
            {" "}
            <img
              src={getNftSmallUrl(nft?.nftMetaData?.thumbnail_image)}
              className="sell-this-nft"
              alt=""
            />
          </div>
          <div className="ms-1 flex-1">
            <h2 className="mb-0">{nft?.name}</h2>
            <div>
              <p className="text-gray f-16 mb-1">by {nft?.creator?.username}</p>
              <p className="text-gray f-16 mb-1">
                Qty: <span className="text-black">1</span>
              </p>
              <p className="text-gray f-16">
                Price: <span className="text-black me-3">{price}</span>
                <span className="f-11">{`$${priceUSD} USD`}</span>
              </p>
              <div className="mb-3 d-flex">
                <button
                  className="btn-green text-gray me-3"
                  onClick={handleClose}
                >
                  SUCCESS!
                </button>
              </div>
              <div className="border-top w-100 pt-2">
                <p className="f-12">{noteText}</p>
                <div className="socials custom">
                  <FacebookShareButton
                    url={`https://zing.it/item/${id}`}
                    quote={nft?.name}
                    hashtag="#zingit"
                  >
                    <FacebookIcon size={36} target="_blank" />
                  </FacebookShareButton>
                  <span>
                    <TwitterShareButton
                      url={`https://zing.it/item/${id}`}
                      via="zingit"
                      title={nft?.name}
                    >
                      <TwitterIcon size={36} target="_blank" />
                    </TwitterShareButton>
                  </span>
                  <span>
                    <LinkedinShareButton
                      url={`https://zing.it/item/${id}`}
                      source={"https://zing.it"}
                      summary={"ZingIt"}
                      title={nft?.name}
                    >
                      <LinkedinIcon size={36} target="_blank" />
                    </LinkedinShareButton>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CongratulationsModal;
