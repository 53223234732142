import { useRef } from "react";
//@ts-ignore
import ReCAPTCHA from "react-google-recaptcha-enterprise";

const GoogleCaptchaModal = ({ handleClose }: any) => {
  const recaptchaRef = useRef();

  return (
    <div className="checkout">
      <div className="maincheckout p-3">
        <button
          className="btn-close"
          onClick={() => handleClose(null)}
        ></button>
        <div>
          <h3 className="text-center">Are You Human?</h3>
        </div>

        <div className="d-flex justify-content-center my-5">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Ley6qQlAAAAAEsXN-pUAg8BioMknVSdtHxdS4NS"
            onChange={(value: any) => {
              handleClose(value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default GoogleCaptchaModal;
