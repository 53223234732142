import { NFT } from "./nft";
import { Order } from "./order";
import { User } from "./user";

export type Event = {
  id?: string;
  user: User;
  nft: NFT;
  from?: User;
  to?: User;
  type: EventType;
  createdAt: string;
  updatedAt: string;
  transaction?: any;
  price: number;
  supply: number;
  quantity: number;
  order?: Order;
};

export interface EventSearchParams {
  types?: EventType[];
  page?: number;
  pageSize?: number;
}

export enum EventType {
  NftLiked = "NFT_LIKED",
  NftUnliked = "NFT_UNLIKED",
  NftDisabled = "NFT_DISABLED",
  NftBurned = "NftBurned",
  NftEnabled = "NFT_ENABLED",
  NftFeatured = "NFT_FEATURED",
  NftUnFeatured = "NFT_UNFEATURED",
  NftUpdated = "NFT_UPDATED",
  NftBid = "NFT_BID",
  NftCreated = "NFT_CREATED",
  NftMinted = "NFT_MINTED",
  NftLazyMinted = "NFT_LAZY_MINTED",
  MetaDataFrozen = "META_DATA_FROZEN",
  Login = "LOGIN",
  NftListed = "NFT_LISTED",
  NftListingCanceled = "NFT_LISTING_CANCELED",
  NftListingUpdated = "NFT_LISTING_UPDATED",
  NftAuctionEnabled = "NFT_AUCTION_ENABLED",
  NftAuctionDisabled = "NFT_AUCTION_DISABLED",
  CollectionLike = "COLLECTION_LIKED",
  CollectionUnliked = "COLLECTION_UNLIKED",
  CollectionDisabled = "COLLECTION_DISABLED",
  CollectionEnabled = "COLLECTION_ENABLED",
  CollectionCreated = "COLLECTION_CREATED",
  CollectionUpdated = "COLLECTION_UPDATED",
  CollectionFeatured = "COLLECTION_FEATURED",
  CollectionUnFeatured = "COLLECTION_UNFEATURED",
  OfferSubmitted = "OFFER_SUBMITTED",
  OfferAccepted = "OFFER_ACCEPTED",
  UserFollow = "USER_FOLLOW",
  NftTransferred = "NFT_TRANSFERRED",
  TransactionFailed = "TRANSACTION_FAILED",
  OfferCanceled = "OFFER_CANCELED",
  NftPurchased = "NFT_PURCHASED",
  NftAuctionCreated = "NFT_AUCTION_CREATED",
  BidAccepted = "NFT_BID_ACCEPTED",
  UserUpdated = "USER_UPDATED",
  UserUnFeatured = "USER_UNFEATURED",
  UserEnabled = "USER_ENABLED",
  UserDisabled = "USER_DISABLED",
  UserFeatured = "USER_FEATURED",
}
