import moment from "moment";
import { useState } from "react";
import { blockChainData } from "../../api/modules/nft";
import { NFT } from "../../models/nft";
import { Order, OrderType } from "../../models/order";
import { User } from "../../models/user";
import MakeOfferModal from "./Modals/MakeOfferModal";
import OfferInfo from "./Modals/OfferInfo";
import { DEFAULT_USER_IMAGE } from "./constants";

type propType = { nft: any; user: User; refetch: () => {} };
const NftOffers = (props: propType) => {
  const { nft, user, refetch } = props;

  const [showCancelOffer, setShowCancelOffer] = useState(false);
  const [showAcceptOffer, setShowAcceptOffer] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState<Order>();

  const isNotExpired = () => {
    const now = Number(moment().unix());
    return (
      !nft.forSale || (nft.forSale && Number(nft.defaultListing?.endTime) > now)
    );
  };

  const isAnOwner = () =>
    nft.owners?.some((owner: User) => owner.id === user?.id);
  const isSaleTypeValid = (bid: Order) => {
    const isSale = nft.defaultListing?.type === OrderType.SALE;
    const isHighestBid = nft.highestBid?.id === bid.id;
    const notForSale = !nft.forSale;

    return isSale || (!isSale && isHighestBid) || notForSale;
  };
  const isUserTheBidder = (bid: Order) => user?.id !== bid?.buyer?.id;
  const shouldShowAcceptOffer = (bid: Order) =>
    isAnOwner() &&
    isUserTheBidder(bid) &&
    isSaleTypeValid(bid) &&
    isNotExpired();

  return (
    <div className="tab-1 onStep fadeIn mt-4 item_author_desc">
      {nft.bids && nft.bids.length! > 0 && (
        <div className="item_net_activity ">
          <h4>
            {nft.defaultListing?.type !== OrderType.SALE && nft.forSale
              ? "Bids"
              : "Offers"}
          </h4>
          <div
            style={{
              maxHeight: "250px",
              overflowY: "scroll",
            }}
          >
            <div className="table-wrapper">
              <table className="table table-borderless table-bordered m-0">
                <thead>
                  <tr>
                    <th>Amount</th>
                    <th>User</th>
                    <th>Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {nft.bids.map((bid: Order, index: number) => (
                    <tr key={index}>
                      {/* <td className="p_list_info ms-1">
                        <a href={`/author/${bid.buyer?.id}`}>
                          <img
                            className="lazy"
                            src={bid.buyer?.userImage || DEFAULT_USER_IMAGE}
                            alt=""
                          />
                        </a>
                      </td> */}
                      <td className="">
                        <b>
                          {bid.amount} W{blockChainData[nft.blockchain].symbol}
                        </b>
                      </td>
                      <td>
                        <b>{bid.buyer.username || "UNKNOWN"}</b>
                      </td>
                      <td>{moment(bid.createdAt).format("L, LT")}</td>
                      <td>
                        {shouldShowAcceptOffer(bid) && (
                          <div className="">
                            <button
                              className="btn-secondary"
                              onClick={() => {
                                setSelectedOffer(bid);
                                setShowAcceptOffer(true);
                              }}
                            >
                              Accept{" "}
                              {nft.defaultListing?.type !== OrderType.SALE &&
                              nft.forSale
                                ? "Bid"
                                : "Offer"}
                            </button>
                          </div>
                        )}
                        {user?.id === bid?.buyer?.id && (
                          <div className="">
                            <button
                              className="btn-secondary"
                              onClick={() => {
                                setSelectedOffer(bid);
                                setShowCancelOffer(true);
                              }}
                            >
                              Cancel{" "}
                              {nft.defaultListing?.type !== OrderType.SALE &&
                              nft.forSale
                                ? "Bid"
                                : "Offer"}
                            </button>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <OfferInfo
        offer={selectedOffer}
        nft={nft}
        show={showCancelOffer}
        handleClose={async (refresh = false) => {
          if (refresh) await refetch();
          setShowCancelOffer(false);
        }}
        label="Cancel Offer"
        info={""}
        showInfo={false}
        noteText="Check Your wallet to sign this transaction"
        noteColor="text-green"
        moreInfo={""}
        isCancelOffer={true}
        showBtn={true}
      />

      <OfferInfo
        show={showAcceptOffer}
        offer={selectedOffer}
        nft={nft}
        handleClose={async (refresh = false) => {
          if (refresh) await refetch();
          setShowAcceptOffer(false);
        }}
        label="Accept Offer"
        info={""}
        showInfo={false}
        moreInfo={""}
        noteText="Check Your wallet to sign this transaction"
        noteColor="text-green"
        showBtn={true}
        isCancelOffer={false}
      />
    </div>
  );
};

export default NftOffers;
