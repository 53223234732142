import { useState } from "react";
import { useSelector } from "react-redux";
import FadeLoader from "react-spinners/ClipLoader";
import { useAccount } from "wagmi";

const Notifications = () => {
  const account = useAccount();
  const [pageLoading, setPageLoading] = useState(false);
  const { user } = useSelector((state: any) => state.auth);

  return (
    <div className="popshow">
      {!pageLoading ? (
        <div className="d-balance mb-4">
          <h4>Notifications</h4>

          {/* {user.notifications.map((bid, index) => (
            <div
              className="d-flex author_list_pp align-items-center mb-2"
              key={index}
            >
              <div className="p_list_info">
                <a href={`/author/${nft.creator?.id}`}>
                  <img
                    className="lazy"
                    src={bid.buyer?.userImage || DEFAULT_USER_IMAGE}
                    alt=""
                  />
                </a>
              </div>
              <div className="p_list_info ms-3">
                Bid {bid.buyer.id === nft?.owner?.id && "accepted"}{" "}
                <b>
                  {bid.amount} W{blockChainData[nft.blockchain].symbol}
                </b>
                <span>
                  by <b>{bid.buyer.username || "UNKNOWN"}</b> at{" "}
                  {moment(bid.createdAt).format("L, LT")}
                </span>
              </div>
              {user?.id === nft?.owner?.id && user?.id !== bid?.buyer?.id && (
                <div className="p_list_info ms-3">
                  <button
                    className="btn-secondary"
                    onClick={() => {
                      setSelectedOffer(bid);
                      setShowAcceptOffer(true);
                    }}
                  >
                    Accept Offer
                  </button>
                </div>
              )}
              {user?.id === bid?.buyer?.id && (
                <div className="p_list_info ms-3">
                  <button
                    className="btn-secondary"
                    onClick={() => {
                      setSelectedOffer(bid);
                      setShowCancelOffer(true);
                    }}
                  >
                    Cancel Offer
                  </button>
                </div>
              )}
            </div>
          ))} */}
        </div>
      ) : (
        <div className="loader">
          <FadeLoader
            loading={pageLoading}
            size={100}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </div>
  );
};

export default Notifications;
