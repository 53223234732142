import moment from "moment";
import { memo, useEffect, useRef, useState } from "react";
import { blockChainData } from "../../api/modules/nft";
import { useActions, useConversion } from "../../hooks";
import { useDisplayPrice } from "../../hooks/modules/useDisplayPrice";
import { GREEN_CHAINS } from "../../models/nft";
import Clock from "./Clock";
import CreateListing from "./Modals/CreateListing";
import SellNft from "./Modals/SellNft";
import { NftActionButtons } from "./NftActionButtons";
import NftDisplay from "./NftDisplay";
import MintGreenInfo from "./ToolTips/MintGreenInfo";
import { NFT } from "../../models/nft";
import { OrderType } from "../../models/order";

interface NftCardProps {
  nft: NFT;
  DisplayComponent?: React.ComponentType;
  className?: string;
  clockTop?: boolean;
}
const NftCard = ({
  nft,
  DisplayComponent,
  className = "d-item col-6 col-lg-3 col-md-4 mb-4",
  clockTop = true,
}: NftCardProps) => {
  const now = moment();
  const deadlineMoment = moment.unix(
    parseInt(nft.defaultListing?.endTime || "0")
  );
  const isLessThanXHoursAway = deadlineMoment.diff(now, "hours") < 3;
  const { toggleLikeNft } = useActions();
  const [showSellModal, setShowSellModal] = useState(false);
  const [showFilledModal, setShowFilledModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isAuction, setIsAuction] = useState(false);
  const [price, setPrice] = useState(nft.defaultListing?.amount || nft.price);

  const [displayPrice] = useDisplayPrice(Number(price));

  const { priceUSD } = useConversion(
    Number(price),
    blockChainData[nft.blockchain].symbol
  );
  const displayRef: any = useRef();

  const handleToggleLike = async (id: any) => {
    toggleLikeNft(id);
  };

  // If it's a Dutch auction
  useEffect(() => {
    let timer: any;

    const calculateCurrentPrice = () => {
      if (!nft.defaultListing) return;
      const endTime = Number(nft.defaultListing.endTime);
      const startTime = Number(nft.defaultListing.startTime);
      const currentTime = moment().unix();
      const endAmount = nft.defaultListing?.endAmount || 0;
      const startAmount = nft.defaultListing!.amount;
      const timeDifference = endTime - startTime;
      const timeElapsed = currentTime - startTime;
      const timeRemaining = endTime - currentTime;

      const decrementPerSecond = (startAmount - endAmount) / timeDifference;
      const amountToSubtract = decrementPerSecond * timeElapsed;
      const currentPrice = startAmount - amountToSubtract;
      if (timeRemaining > -1 && currentPrice >= endAmount) {
        setPrice(currentPrice);
      } else {
        clearInterval(timer);
      }
    };
    if (nft.defaultListing?.type === "auction_dutch") {
      // Initial calculation
      calculateCurrentPrice();

      // Recalculate every second
      timer = setInterval(() => {
        calculateCurrentPrice();
      }, 1000);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [nft]);

  return (
    nft && (
      <div className={className}>
        <div className="nft__item m-0">
          <div style={{ height: "100%" }} className="nft__item_wrap mb-0">
            <div
              className="nft__item_like cursor-pointer"
              onClick={() => handleToggleLike(nft.id)}
            >
              {nft.isLiked ? (
                <i className="fa fa-heart liked" />
              ) : (
                <i className="fa fa-heart-o" />
              )}
            </div>
            <a href={`/item/${nft.id}`}>
              {DisplayComponent ? (
                <DisplayComponent />
              ) : (
                <NftDisplay
                  displayRef={displayRef}
                  thumbnail={true}
                  hideControls={true}
                  className={"lazy nft__item_preview"}
                  nftFile={nft.nftMetaData.animation_url}
                  fileType={nft.nftMetaData.file_type}
                  previewImageFile={nft.nftMetaData.thumbnail_image}
                />
              )}
            </a>
          </div>

          {Number(nft.defaultListing?.endTime) < now.unix() &&
            Number(nft.defaultListing?.startTime) < now.unix() && (
              <div
                className="uppercase-first-letter badge bg-danger"
                style={{
                  position: "absolute",
                  left: "20px",
                  top: "5px",
                }}
              >
                {nft.defaultListing?.type} Ended!
              </div>
            )}

          {/* {Number(nft.defaultListing?.endTime) > now.unix() &&
            isLessThanXHoursAway &&
            Number(nft.defaultListing?.startTime) < now.unix() && (
              <div className="uppercase-first-letter de_countdown">
                {nft.defaultListing?.type} Ends in{" "}
                <Clock deadline={nft?.defaultListing?.endTime} />
              </div>
            )}

          {Number(nft.defaultListing?.startTime) > now.unix() && (
            <div className="uppercase-first-letter de_countdown">
              {nft.defaultListing?.type} Starts in{" "}
              <Clock deadline={nft?.defaultListing?.startTime} />
            </div>
          )} */}
          <div className="nft__item_info m-2 mt-3">
            <a href={`/item/${nft.id}`}>
              <h5>
                {nft.name.length > 36
                  ? nft.name.substring(0, 36) + "..."
                  : nft.name}
              </h5>
            </a>

            {price.toString() && (
              <div className="nft__item_price">
                <p
                  className="d-flex flex-wrap align-items-center"
                  style={{ margin: "5px 0px" }}
                >
                  <img
                    style={{
                      height: "25px",
                    }}
                    className="w-auto me-2 mt-1"
                    src={blockChainData[nft.blockchain].logo}
                    alt={blockChainData[nft.blockchain].symbol}
                  />
                  <span className="price-eth">
                    {displayPrice} {blockChainData[nft.blockchain].symbol}
                  </span>{" "}
                  <span className="price-usd">(${priceUSD})</span>
                  {GREEN_CHAINS.includes(nft.blockchain) && (
                    <MintGreenInfo>
                      <span className="mint-round mb-2 ms-2">
                        <img src="/img/mint-small.png" alt="Mint Green" />
                      </span>
                    </MintGreenInfo>
                  )}
                </p>
              </div>
            )}

            <div className="my-2 pe-1 d-flex flex-row justify-content-between">
              <NftActionButtons nft={nft} maxButtons={2} />
            </div>
          </div>
        </div>

        <CreateListing
          show={showSellModal}
          handleClose={() => setShowSellModal(false)}
          handleFinish={(saleType: OrderType) => {
            saleType === OrderType.SALE
              ? setIsAuction(false)
              : setIsAuction(true);
            setShowFilledModal(true);
            setShowSellModal(false);
          }}
          editing={editing}
          nft={nft}
        />
        <SellNft
          show={showFilledModal}
          handleClose={() => setShowFilledModal(false)}
          nft={nft}
          isAuction={isAuction}
          handleContinue={() => {
            setShowFilledModal(false);
            setShowSellModal(true);
            setEditing(true);
          }}
        />
      </div>
    )
  );
};

export default memo(NftCard);
