import { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Footer } from "../";
import { useActions } from "../../hooks";
import NftGrid from "../Partials/NftGrid";

const MintGreen = () => {
  const { featuredGreen } = useSelector((state: any) => state.nfts);
  const { fetchFeaturedGreenNfts } = useActions();
  const navigate = useNavigate();

  useEffect(() => {
    fetchFeaturedGreenNfts();
  }, []);

  return (
    <div>
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb">
          <div className="container mint-green-info">
            <div className="minting-nft">
              <div>
                <h4 className="text-green">
                  Minting NFTs that are Kind to the Planet
                </h4>
                <p className="f-15 mt-0 text-black">
                  Mint Green is Zingit's environmentally sustainable solution
                  for NFTs. We're committed to protecting the environment, and
                  our Mint Green technology is an ongoing program designed to
                  minimize the negative impact of NFT creation.
                </p>
                <div className="d-flex justify-content-end">
                  <button
                    className="btn-mint-green"
                    onClick={() => navigate("/create-nft")}
                  >
                    CREATE WITH MINT GREEN
                  </button>
                </div>
              </div>
              <div>
                <img src="/img/mint-green-icon.png" alt="mint green icon" />
              </div>
            </div>
            <hr className="break-line" />
            <div className="look-for-mint">
              <h4 className="text-green text-center">Look for Mint Green</h4>
              <p className="f-15 mt-0 text-black">
                You'll see the Mint Green icon on our platform to easily
                identify environmentally sustainable transactions. <br /> <br />{" "}
                With Zingit, you can create, buy, and sell NFTs with confidence,
                knowing that you're supporting sustainability and making a
                positive impact on the planet. Our platform is cutting-edge and
                eco-friendly, so you don't have to compromise on your values.
                We're proud to offer the latest technology while minimizing our
                carbon footprint. <br /> <br /> Join us at Zingit and become
                part of a community that cares about creating a better future
                for everyone. With Mint Green NFTs, you can enjoy the latest
                technology without harming the environment. We're committed to
                ongoing innovation and sustainability, and we invite you to be a
                part of it.
              </p>
            </div>
            <hr className="break-line" />
            <div className="look-for-mint">
              <h4 className="text-green text-center">Find Mint Green NFTs</h4>
              <NftGrid
                list={featuredGreen.nfts}
                showLoadMore={false}
                fetchMore={() => {}}
                total={featuredGreen.total}
                loading={featuredGreen.loading}
                emptyText="There are no Featured NFT's yet"
              />
              <div className="d-flex justify-content-center mt-4">
                <button
                  className="btn-mint-green"
                  onClick={() => navigate("/search-page?onlyGreen=true")}
                >
                  SEARCH MINT GREEN NOW
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};
export default memo(MintGreen);
