import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

const ShowFullImage = ({ show, handleClose, fullImage }: any) => {
  const [trait_type, setTrait_type] = useState("");
  const [value, setValue] = useState("");

  return (
    <Modal
      className="custom modal-bg"
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-between mb-4">
          <img src={fullImage} id="get_file_2" alt="" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShowFullImage;
