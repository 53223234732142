import { EventSearchParams } from "../../models/event";
import setAuthToken from "../utils/setAuthToken";

const instance = setAuthToken();

const getEvents = (params?: EventSearchParams) => {
  return instance.get(`/events`, { params });
};

const getNftEvents = (id: string, params?: EventSearchParams) => {
  return instance.get(`/events/${id}/events`, { params });
};

const getUserEvents = (id: string, params?: EventSearchParams) => {
  return instance.get(`/user/${id}/events`, { params });
};

export { getEvents, getNftEvents, getUserEvents };
