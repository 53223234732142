import { Order } from "../../models/order";
import { User } from "../../models/user";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface AuthorState {
  loading: boolean;
  error: string | null;
  author: User | null;
  authors: {
    total: number;
    authors: User[] | null;
  };
  featuredAuthors: {
    total: number;
    authors: User[] | null;
  };
  topAuthors: {
    total: number;
    authors: User[] | null;
  };
  newAuthors: {
    total: number;
    authors: User[] | null;
  };
  offersReceived: {
    total: number;
    offers: Order[] | null;
    loading: boolean;
  };
}

const initialState = {
  loading: false,
  error: null,
  author: null,
  authors: {
    total: 0,
    authors: [],
  },
  featuredAuthors: {
    total: 0,
    authors: [],
  },
  topAuthors: {
    total: 0,
    authors: [],
  },
  newAuthors: {
    total: 0,
    authors: [],
  },
  offersReceived: {
    total: 0,
    offers: [],
    loading: false,
  },
};

const reducer = (
  state: AuthorState = initialState,
  action: Action
): AuthorState => {
  switch (action.type) {
    case ActionType.FETCH_FEATURED_AUTHORS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.FETCH_FEATURED_AUTHORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        featuredAuthors: action.payload,
      };
    case ActionType.FETCH_FEATURED_AUTHORS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionType.FETCH_TOP_AUTHORS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.FETCH_TOP_AUTHORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        topAuthors: action.payload,
      };
    case ActionType.FETCH_TOP_AUTHORS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.FETCH_NEW_AUTHORS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.FETCH_NEW_AUTHORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        newAuthors: action.payload,
      };
    case ActionType.FETCH_NEW_AUTHORS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.FETCH_AUTHORS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.FETCH_AUTHORS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        authors: action.payload,
      };
    case ActionType.FETCH_AUTHORS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.FETCH_AUTHOR:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ActionType.FETCH_AUTHOR_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        author: action.payload,
      };
    case ActionType.FETCH_AUTHOR_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionType.FETCH_USER_OFFERS_RECEIVED:
      return {
        ...state,
        offersReceived: { ...state.offersReceived, loading: true },
      };
    case ActionType.FETCH_USER_OFFERS_RECEIVED_SUCCESS:
      return {
        ...state,
        offersReceived: { ...action.payload, loading: false },
      };
    case ActionType.FETCH_USER_OFFERS_RECEIVED_ERROR:
      return {
        ...state,
        loading: false,
        offersReceived: { ...state.offersReceived, loading: false },
        error: action.payload,
      };
    case ActionType.CLEAR_AUTHORS:
      return {
        ...state,
        author: null,
        authors: {
          total: 0,
          authors: [],
        },
        featuredAuthors: {
          total: 0,
          authors: [],
        },
        topAuthors: {
          total: 0,
          authors: [],
        },
        newAuthors: {
          total: 0,
          authors: [],
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default reducer;
