import { ItemType } from "@opensea/seaport-js/lib/constants";
import { ethers } from "ethers";
import moment from "moment";
import React, { useContext, useEffect, useState, CSSProperties } from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useAccount, useBalance } from "wagmi";
import { blockChainData } from "../../../api/modules/nft";
import { ERROR_CODES } from "../../../constants";
import { useActions, useConversion, useSelector } from "../../../hooks";
import { Order, OrderStatus, OrderType } from "../../../models/order";
import {
  checkAllowance,
  makeOffer,
  requestAllowance,
} from "../../../services/seaport.service";
import { SeaportContext } from "../../../state/context/SeaportContext";
import { validateCurrencyValue } from "../../../utils/validation";
import { getNftSmallUrl } from "../../../models/nft";
const MakeOfferModal = ({
  nft,
  show,
  handleClose,
  amountError,
  noteText,
  noteColor,
}: any) => {
  const [loading, setLoading] = React.useState(false);
  const [offerPrice, setOfferPrice] = useState(0);
  const seaport = useContext(SeaportContext);
  const { address } = useAccount();
  const { showWrapCurrencyModal } = useSelector((state) => state.web3);
  const { placeOrder, setShowWrapCurrencyModal } = useActions();
  const [duration, setDuration] = useState(30);
  const [priceError, setPriceError] = useState("");
  const [showCheckingApproval, setShowCheckingApproval] = useState(false);
  const [showGettingApproval, setShowGettingApproval] = useState(false);
  const [lowBalance, setLowBalance] = useState(false);
  const { data: walletData } = useBalance({ address });
  const [unreviewedCollection, setUnreviewedCollection] = useState(false);
  const [quantity, setQuantity] = useState<number>(1);
  const { priceUSD } = useConversion(
    Number(offerPrice),
    blockChainData[nft?.blockchain].symbol
  );
  const price = nft.defaultListing?.amount || nft.price || 0;

  const minNextBid = (() => {
    const lastBid = nft.highestBid?.amount || price;
    const minBid = 0.00004;
    const maxPercentageIncrease = 0.2; // 20%
    const minPercentageIncrease = 0.05; // 5%
    const maxBound = 10000;

    let percentageIncrease;

    if (lastBid < minBid) {
      percentageIncrease = maxPercentageIncrease; // If last bid is less than the minimum allowed bid, use the maximum percentage increase
    } else if (lastBid < maxBound) {
      // Linearly decrease the percentage increase from max to min as last bid increases from minBid to maxBound
      const slope =
        (minPercentageIncrease - maxPercentageIncrease) / (maxBound - minBid);
      const intercept = maxPercentageIncrease - slope * minBid;
      percentageIncrease = slope * lastBid + intercept;
    } else {
      percentageIncrease = minPercentageIncrease; // If last bid is more than or equal to the maxBound, use the minimum percentage increase
    }

    const minNextBid = lastBid * (1 + percentageIncrease);
    return parseFloat(minNextBid.toFixed(4));
  })();

  useEffect(() => {
    if (nft) {
      setOfferPrice(minNextBid);

      if (nft?.defaultListing?.type === "auction_dutch") {
        const endTime = Number(nft.defaultListing.endTime);
        const currentTime = moment().unix();
        const endAmount = nft.defaultListing?.endAmount;
        const startAmount = nft.defaultListing?.amount;
        const timeRemaining = endTime - currentTime;

        if (!endAmount) {
          return;
        }
        if (timeRemaining > 0) {
          const decrementPerSecond =
            (startAmount - endAmount) / (timeRemaining / 1000);

          setOfferPrice(Math.max(price - decrementPerSecond, endAmount));
        }
      }
    }
  }, [nft]);

  const checkBalance = () => {
    setLowBalance(false);
    if (!walletData) return false;
    if (
      parseFloat(walletData!.formatted) <= parseFloat(offerPrice?.toString())
    ) {
      setLowBalance(true);
      return false;
    }
    return true;
  };
  useEffect(() => {
    if (!showWrapCurrencyModal) {
      checkBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showWrapCurrencyModal]);

  const handleSetPrice = async (newValue: string) => {
    setLowBalance(false);
    toast.dismiss();
    setPriceError("");

    const newPrice = parseFloat(newValue);
    let errorMessage = validateCurrencyValue(newValue);
    if (errorMessage) {
      setPriceError(errorMessage);
      return;
    }

    // Check if the input has more than 4 decimal points.
    const decimalParts = newValue.split(".");
    if (decimalParts[1] && decimalParts[1].length > 4) {
      setPriceError("Value cannot have more than 4 decimal points!");
      return; // Exit function early as the provided value is invalid.
    }

    if (
      nft.defaultListing?.type !== OrderType.SALE &&
      nft.highestBid &&
      newPrice < minNextBid
    ) {
      errorMessage = `Offer must be higher than ${minNextBid}`;
    }

    if (nft.defaultListing?.type === OrderType.SALE && newPrice < price) {
      //errorMessage = `Offer must be higher than ${price}`;
    }

    setOfferPrice(newPrice);
  };
  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let newQuantity = Number(e.target.value);
    // Ensure newQuantity is at least 1 and not more than maxQuantity
    newQuantity = Math.max(1, Math.min(nft?.supply, newQuantity));
    setQuantity(newQuantity);
  };
  async function makeAnOffer() {
    toast.dismiss();
    if (seaport === null || nft === null || loading || address === undefined)
      return;
    let endTime = "";
    const wrappedEthAddress = blockChainData[nft?.blockchain]?.wrappedCoin;
    try {
      const validBalance = checkBalance();
      if (!validBalance) return;

      setShowCheckingApproval(true);
      const amountToTransfer = ethers.utils.parseEther(offerPrice.toString()); // amount of WETH to transfer
      const isAllowed = await checkAllowance(
        nft,
        wrappedEthAddress,
        amountToTransfer
      );
      setShowCheckingApproval(false);

      if (!isAllowed) {
        setShowGettingApproval(true);

        const approveTx = await requestAllowance(
          nft,
          wrappedEthAddress,
          amountToTransfer
        );

        if (approveTx) {
          await approveTx.wait();
          setShowGettingApproval(false);
        } else {
          setShowGettingApproval(false);
          setShowCheckingApproval(false);
          toast.error(
            `Error occurred while increasing our allowance for your W${
              blockChainData[nft?.blockchain]?.symbol
            }`
          );
          return;
        }
      }
    } catch (error: any) {
      setShowGettingApproval(false);
      setShowCheckingApproval(false);
      toast.error(
        `Error occurred while checking our W${
          blockChainData[nft?.blockchain]?.symbol
        } Allowance`
      );
      return;
    }

    const startTime = moment().unix().toString();
    if (duration > 0) {
      //use moment to calculate end time
      endTime = moment().add(duration, "days").unix().toString();
    }
    const tokenType =
      nft?.tokenType.toString() === ItemType.ERC721.toString()
        ? ItemType.ERC721
        : ItemType.ERC1155;

    const tokenData = {
      address: nft?.tokenAddress,
      tokenId: nft?.tokenId,
      type: tokenType,
      id: nft?.id,
      quantity: quantity,
    };
    try {
      setLoading(true);
      const offer = await makeOffer(
        nft!,
        tokenData,
        offerPrice.toString(),
        blockChainData[nft?.blockchain]?.wrappedCoin,
        startTime,
        endTime
      );
      setTimeout(() => {}, 500);
      await placeOrder(
        {
          hash: JSON.stringify(offer),
          nftId: nft?.id,
          status: OrderStatus.CREATED,
          amount: offerPrice,
          quantity: quantity,
          type: OrderType.OFFER,
          buyerAddress: address,
          sellerAddress: "",
          startTime: offer.parameters.startTime.toString(),
          endTime: offer.parameters.endTime.toString(),
        },
        (order?: Order) => {
          setLoading(false);
          handleClose(order);
        }
      );
    } catch (e: any) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      toast.error(
        ERROR_CODES[e.code] ||
          `Error Making Offer, Be sure you have enough W${
            blockChainData[nft?.blockchain]?.symbol
          } in your wallet.`
      );
    }
  }

  return (
    <Modal
      className="custom modal-bg"
      show={show}
      onHide={() => {
        !loading &&
          !showGettingApproval &&
          !showCheckingApproval &&
          handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="">
          {nft.defaultListing?.type !== OrderType.SALE && nft.forSale
            ? "Place a Bid"
            : "Make an Offer"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mt-2 mb-3 make-offer-body">
          <div className="modal-nft-img w-auto me-4">
            <img
              src={getNftSmallUrl(nft?.nftMetaData?.thumbnail_image)}
              className="sell-this-nft"
              alt=""
            />
          </div>
          <div className="ms-1 min-265 ">
            <span className="text-purple">{nft.nftCollection.name}</span>
            <h2 className="mb-0">{nft.name}</h2>
            <p className="f-12 mb-2">
              Creator:{" "}
              <span className="text-purple me-4">{nft.creator.username}</span>
              {/* {" "}
              Owner: <span className="text-purple">{nft.owner.username}</span> */}
            </p>
            <div>
              <label htmlFor="">Amount</label>
              {priceError && (
                <div>
                  <span className="text-danger ms-0">{priceError}</span>
                </div>
              )}{" "}
              <div className="offer-amount">
                <input
                  type="text"
                  className="outer-input"
                  placeholder="Offer Price"
                  defaultValue={offerPrice}
                  onChange={(e: any) => handleSetPrice(e.target.value)}
                />
                <div className="position-relative ms-1 flex-1">
                  <p className="d-flex" style={{ margin: "5px 0px" }}>
                    <span className="text-gray me-1">
                      W{blockChainData[nft?.blockchain]?.symbol}
                    </span>
                    <img
                      style={{
                        width: "auto",
                        height: "25px",
                        marginRight: "8px",
                      }}
                      src={blockChainData[nft?.blockchain]?.logo}
                      alt={blockChainData[nft?.blockchain]?.symbol}
                    />
                    <span className="price-usd">(${priceUSD})</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mt-2 mb-4 col-6">
                <label htmlFor="">Quantity</label>
                <div>
                  <input
                    type="number"
                    disabled={true} //TODO: change this to if supply > 1
                    max={nft?.supply}
                    min={1}
                    value={quantity}
                    onChange={handleQuantityChange}
                    className="w-50"
                  />
                  <small className="text-gray ms-1">NFTs</small>
                </div>
              </div>
              <div className="mt-2 mb-4 col-6">
                <label htmlFor="">Duration</label>
                <div>
                  <input
                    value={duration}
                    min={1}
                    type="number"
                    className="w-50"
                    onChange={(e) => setDuration(Number(e.target.value))}
                  />
                  <small className="text-gray ms-1">days</small>
                </div>
              </div>
            </div>
            {nft.hasExistingBid && (
              <div className="w-100 mb-1">
                <span className="text-danger">
                  Note: You already have{" "}
                  {nft.defaultListing?.type !== OrderType.SALE && nft.forSale
                    ? "a bid"
                    : "an offer"}{" "}
                  on this NFT
                </span>
              </div>
            )}
            {loading || showGettingApproval || showCheckingApproval ? (
              <div className="w-100">
                <ThreeDots
                  height="20"
                  width="60"
                  color="#8364e2"
                  ariaLabel="loading"
                />
                <span>
                  {showCheckingApproval && (
                    <span> Checking our Allowance....</span>
                  )}
                  {showGettingApproval && (
                    <span> Requesting Allowance Increase....</span>
                  )}
                  {loading && <span> Submitting Your Offer</span>}
                </span>
              </div>
            ) : (
              <>
                {!lowBalance ? (
                  <button
                    onClick={() => makeAnOffer()}
                    disabled={
                      !!priceError ||
                      lowBalance ||
                      !offerPrice ||
                      !unreviewedCollection
                    }
                    className="btn-main px-2"
                  >
                    {nft.defaultListing?.type !== OrderType.SALE && nft.forSale
                      ? `Place ${nft.hasExistingBid ? "ANOTHER" : ""} Bid`
                      : `MAKE ${nft.hasExistingBid ? "ANOTHER" : ""} OFFER`}
                  </button>
                ) : (
                  <>
                    <button
                      disabled={!unreviewedCollection}
                      onClick={() => setShowWrapCurrencyModal(true)}
                      className="btn-main"
                    >
                      Wrap Eth Now
                    </button>
                    {lowBalance && (
                      <p className="text-danger text-center mb-0 mt-3 ms-0">
                        Your W{blockChainData[nft?.blockchain]?.symbol} Balance
                        is too low!
                      </p>
                    )}
                  </>
                )}
              </>
            )}
            <div className="my-3">
              <input
                type="checkbox"
                name="UnreviewedCollection"
                disabled={
                  loading || showGettingApproval || showCheckingApproval
                }
                className="me-2"
                onChange={(e) => setUnreviewedCollection(e.target.checked)}
                checked={unreviewedCollection}
              />
              I understand that ZingIt has not reviewed this collection and that
              blockchain transactions are irreversible.
            </div>
            {noteText && (
              <p className={`${noteColor} f-11 text-center mt-2`}>
                NOTE: {noteText}
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MakeOfferModal;
