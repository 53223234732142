import { useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/ClipLoader";
import { Collection } from "../../models/collection";
import { DEFAULT_BANNER_IMAGE, DEFAULT_USER_IMAGE } from "./constants";
import { getUserSmallImageUrl } from "../../models/user";

const CollectionCard = ({
  collections,
  loading,
  emptyText = "No Collections's Found, Please Adjust Your Search.",
}: any) => {
  const navigate = useNavigate();

  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <FadeLoader
          className="text-center"
          loading={loading}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );

  if (!collections || !collections.length) return <h2> {emptyText}</h2>;

  return (
    <div className="row collections">
      {collections?.map((item: Collection, i: number) => {
        return (
          <div className="collection col-lg-4 col-md-6 col-sm-12 mb-4 " key={i}>
            <div className="nft__item m-0">
              <div
                className="coll-img-wrap"
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/collection?id=" + item?.id)}
              >
                <img
                  alt={item.name}
                  src={
                    item.bannerImg ||
                    (item.totalNfts &&
                      item.nfts![0].nftMetaData.thumbnail_image) ||
                    DEFAULT_BANNER_IMAGE
                  }
                />
              </div>
              <div className="coll-content">
                <h4
                  onClick={() => navigate("/collection?id=" + item?.id)}
                  style={{ cursor: "pointer" }}
                >
                  {item.name}
                  {/* <span>
                  {liked ? (
                    <i className='fa fa-heart liked' />
                  ) : (
                    <i className='fa fa-heart-o' />
                  )}
                </span> */}
                </h4>
                <div className="row">
                  <div className="col-4">
                    <div className="author-wrap">
                      <div className="author_list_pp">
                        <a href={`/author/${item.owner?.id}`}>
                          <img
                            className="lazy"
                            src={
                              item.owner?.userImage
                                ? getUserSmallImageUrl(item.owner?.userImage)
                                : DEFAULT_USER_IMAGE
                            }
                            alt=""
                          />
                          {/* <i className='fa fa-check'></i> */}
                        </a>
                      </div>
                      <div
                        className="author-list-name ms-2"
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/author/${item.owner.id}`)}
                      >
                        <h6>Creator</h6>
                        <p>{item?.owner?.username}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-8">
                    <div className="coll-more-info">
                      <div className="coll_nft_owner">
                        <p>
                          <span>{item?.totalNfts}</span> <span>NFTs</span>
                        </p>
                        {/* <p>
                          <span>30</span> <span>Owners</span>
                        </p> */}
                      </div>
                      {/* <div className='coll_tags_vol'>
                      <p>
                        <span>10</span> <span>Tags</span>
                      </p>
                      <p>
                        <span>89</span> <span>Total Volume</span>
                      </p>
                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CollectionCard;
