import { combineReducers } from "redux";
import authorReducer from "./authorReducer";
import authReducer from "./authReducer";
import collectionReducer from "./collectionReducer";
import eventReducer from "./eventReducer";
import listingReducer from "./listingReducer";
import nftReducer from "./nftReducer";
import toastReducer from "./toastReducer";
import web3Reducer from "./web3Reducer";

const reducers = combineReducers({
  events: eventReducer,
  auth: authReducer,
  nfts: nftReducer,
  web3: web3Reducer,
  toast: toastReducer,
  authors: authorReducer,
  collections: collectionReducer,
  listing: listingReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
