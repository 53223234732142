import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const OnBoardingSuccess = ({ show, handleClose }: any) => {
  const navigate = useNavigate();
  return (
    <Modal className="custom modal-bg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="">Conngratulations!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <p className="f-15 fw-600">
            You're signed up for zingit! Now see what we have to offer or make
            your own NFT!
          </p>
          <div className="d-flex align-items-center mb-4">
            <button
              className="btn-main me-2"
              onClick={() => {
                handleClose();
                navigate("/explore");
              }}
            >
              EXPLORE NFTS
            </button>
            <button
              className="btn-transparent"
              onClick={() => {
                handleClose();
                navigate("/create-nft");
              }}
            >
              CREATE NFT
            </button>
          </div>
          <div className="border-top w-100 pt-2">
            <p className="f-15 fw-600">Tell the world about zing!!</p>
            <div className="socials custom onboarding">
              <span>
                <i className="fa-brands fa-facebook"></i>
              </span>
              <span>
                <i className="fa-brands fa-twitter"></i>
              </span>
              <span>
                <i className="fa-brands fa-instagram"></i>
              </span>
              <span>
                <i className="fa-brands fa-linkedin"></i>
              </span>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OnBoardingSuccess;
