import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";

const AddPropertiesModal = ({ show, handleClose, handleAddProperty }: any) => {
  const [trait_type, setTrait_type] = useState("");
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue("");
    setTrait_type("");
  }, [show]);

  return (
    <Modal
      className="custom modal-bg"
      show={show}
      onHide={() => handleClose(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title className="">Create A Property</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column justify-content-between mb-4">
          <div className="detailcheckout mt-4">
            <div className="listcheckout">
              <h6>Property Name</h6>
              <input
                type="text"
                name="buy_now_qty"
                id="buy_now_qty"
                placeholder="e.g. 'Background Color'"
                onChange={(event) => setTrait_type(event.target.value)}
                className="form-control"
              />
            </div>
          </div>
          <div className="detailcheckout mt-4">
            <div className="listcheckout">
              <h6>Property Value</h6>
              <input
                type="text"
                name="buy_now_qty"
                id="buy_now_qty"
                placeholder="e.g. 'Green"
                onChange={(event) => setValue(event.target.value)}
                className="form-control"
              />
            </div>
          </div>

          <button
            className="btn-main lead mb-5"
            disabled={trait_type && value ? false : true}
            onClick={async () => {
              await handleAddProperty({
                trait_type,
                value,
                display_type: "string",
              });
              handleClose(false);
            }}
          >
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AddPropertiesModal;
