import { off } from "process";
import { Order } from "../../models/order";
import { ActionType } from "../action-types";
import { Action } from "../actions";

interface ListingState {
  loading: boolean;
  error: string | null;
  listing: any | null;
  listings: Order[] | null;
  offers: Order[] | null;
}

const initialState = {
  loading: false,
  error: null,
  listing: null,
  listings: [],
  offers: [],
};

const reducer = (
  state: ListingState = initialState,
  action: Action
): ListingState => {
  switch (action.type) {
    case ActionType.SAVE_LISTING:
      return {
        ...state,
        loading: true,
        error: null,
        listing: null,
      };
    case ActionType.SAVE_LISTING_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        listing: action.payload,
      };
    case ActionType.SAVE_LISTING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
        listing: null,
      };
    case ActionType.FETCH_NFT_ORDERS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ActionType.FETCH_NFT_ORDERS_SUCCESS:
      return {
        ...state,
        listings: action.payload.listings,
        loading: false,
      };
    case ActionType.FETCH_NFT_ORDERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ActionType.FETCH_NFT_OFFERS:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case ActionType.FETCH_NFT_OFFERS_SUCCESS:
      return {
        ...state,
        offers: action.payload.offers,
        loading: false,
      };
    case ActionType.FETCH_NFT_OFFERS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
