import { useEffect, useState } from "react";
import { createGlobalStyle } from "styled-components";
import { useActions, useSelector } from "../../hooks";
import CollectionCard from "../Partials/CollectionCard";
import CreatorSellerCard from "../Partials/CreatorSellerCard";
import FeatureBox from "../Partials/FeatureBox";
import Footer from "../Partials/Footer";
import Jumbotron from "../Partials/Jumbotron";
import WalletModal from "../Partials/Modals/WalletModal";
import NftGrid from "../Partials/NftGrid";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar .search #quick_search{
    background: rgba(255, 255, 255, .1);
  }
  header#myHeader .logo .d-block{
    display: none !important;
  }
  header#myHeader .logo .d-none{
    display: none !important;
  }
  header#myHeader .logo .d-3{
    display: block !important;
  }
  footer.footer-light .subfooter span img.d-1{
    display: none !important;
  }
  footer.footer-light .subfooter span img.d-3{
    display: inline-block !important;
  }
  .author_list_pp{
    margin-left:0;
  }
  footer.footer-light .subfooter{
    border-top: 1px solid rgba(255,255,255,.1);
  }
  .f-54{
    font-size: 54px
  }
  .eth_cost{
    font-size:24px
  }
  .title{
    font-size:36px;
    font-weight: inherit;
  }
  .featured_btn{
    border:none;
    padding:0px;
    cursor:pointer;
  }
`;

const Home = () => {
  const {
    fetchFeaturedNfts,
    fetchNewNfts,
    fetchTopNfts,
    fetchFeaturedAuthors,
    fetchFeaturedCollections,
    clearAuthors,
    clearNfts,
  } = useActions();

  const { newNfts } = useSelector((state) => state.nfts);
  const { user } = useSelector((state) => state.auth);

  const { featuredCollections, loading: collectionsLoading } = useSelector(
    (state) => state.collections
  );

  const { featuredAuthors } = useSelector((state) => state.authors);
  const [showConnectWallet, setShowConnectWallet] = useState(false);

  useEffect(() => {
    window.document.title = "ZingIt- NFT Marketplace";
    handleFetchFeaturedNfts();
    handleFetchNewNfts();
    handleFetchTopNfts();
    handleFetchFeaturedAuthors();
    handleFetchFeaturedCollections();
    return () => {
      clearAuthors();
      clearNfts();
    };
  }, [user?.id]);

  const handleFetchFeaturedNfts = async () => {
    fetchFeaturedNfts();
  };

  const handleFetchFeaturedCollections = async () => {
    fetchFeaturedCollections();
  };

  const handleFetchNewNfts = async () => {
    fetchNewNfts();
  };

  const handleFetchTopNfts = async () => {
    fetchTopNfts();
  };

  const handleFetchFeaturedAuthors = async () => {
    fetchFeaturedAuthors();
  };

  return (
    <div>
      <GlobalStyles />
      <section className="jumbotron no-bg">
        <Jumbotron
          collections={featuredCollections}
          setShowConnectWallet={setShowConnectWallet}
        />
      </section>

      <section className="container no-top">
        <div className="container px-0 wallet-cont">
          <FeatureBox showWallet={() => setShowConnectWallet(true)} />
        </div>
      </section>

      <section className="container">
        <div className="mt-4 text-center-sm">
          <h2 className="header f-36">New Items</h2>
        </div>
        <NftGrid
          list={newNfts.nfts}
          loading={newNfts.loading}
          showLoadMore={false}
          fetchMore={() => handleFetchNewNfts()}
        />
      </section>

      <section className="container no-top">
        <div className="text-center-sm">
          <h2 className="header f-36">Hot Collections</h2>
        </div>
        <CollectionCard
          loading={collectionsLoading}
          collections={featuredCollections.collections}
        />
      </section>

      <section className="container no-top">
        <div className="row mt-4">
          <div className="text-center-sm">
            <h2 className="header f-36">Top Sellers</h2>
          </div>
          <div className="top-sellers">
            <CreatorSellerCard authors={featuredAuthors.authors} />
          </div>
        </div>
      </section>

      <Footer />
      <WalletModal
        show={showConnectWallet}
        handleClose={() => setShowConnectWallet(false)}
      />
    </div>
  );
};
export default Home;
