import validator from "validator";

export const validateCurrencyValue = (
  value: string,
  maxDecimalPlaces = 18,
  allowNull = true,
  maxNumber = 100000000000000
): string | null => {
  if (!value && allowNull) return null;
  if (
    !value ||
    !validator.isCurrency(value, {
      allow_negatives: false,
      allow_decimal: true,
      digits_after_decimal: Array.from(
        { length: maxDecimalPlaces + 1 },
        (_, index) => index
      ),
      allow_space_after_digits: true,
    })
  ) {
    return "Please enter a valid number.";
  } else if (parseFloat(value) >= maxNumber) {
    return `Number must be less than ${maxNumber}`;
  }
  return null;
};
