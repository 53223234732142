import { OverlayTrigger, Tooltip } from "react-bootstrap";

const BuyButtonDisabledInfo = (props: any) => {
  const { text, show, children } = props;
  const tooltip = text ? <Tooltip>{text}</Tooltip> : <></>;

  if (!show) {
    return <>{children}</>;
  }
  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 0, hide: 400 }}
      overlay={tooltip}
    >
      {children}
    </OverlayTrigger>
  );
};

export default BuyButtonDisabledInfo;
