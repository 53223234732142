import moment from "moment";
import { useActions } from "../../hooks";
import { GREEN_CHAINS, NFT } from "../../models/nft";
import { OrderType } from "../../models/order";
import Clock from "./Clock";
import MintGreenInfo from "./ToolTips/MintGreenInfo";
import { useState } from "react";
import ListOfOwners from "./Modals/ListOfOwners";

type propTypes = {
  nft: NFT;
  stats: any;
};
const NftStats = (props: propTypes) => {
  const { nft, stats } = props;
  const { toggleLikeNft } = useActions();
  const now = moment().unix();
  const handleToggleLike = async () => {
    toggleLikeNft(nft?.id!);
  };
  const [showOwners, setShowOwners] = useState(false);

  const oneHourLater = moment().add(1, "hours");
  const endTime = moment.unix(Number(nft.defaultListing?.endTime || 0));
  const isEndTimeWithinNextHour =
    endTime.isAfter(moment()) && endTime.isBefore(oneHourLater);

  return (
    <>
      {nft?.defaultListing?.type !== OrderType.SALE &&
        Number(nft.defaultListing?.endTime) < now && (
          <div className="uppercase-first-letter badge bg-danger">
            {nft.defaultListing?.type} Ended!
          </div>
        )}

      {nft?.defaultListing?.type !== OrderType.SALE &&
        Number(nft.defaultListing?.endTime) > now && (
          <>
            <span
              className={`${
                isEndTimeWithinNextHour ? "text-danger" : "text-success"
              } ms-0`}
            >
              Auction ends in{" "}
            </span>
            <div
              className={`de_countdown px-1 ${
                isEndTimeWithinNextHour && "bg-danger text-white"
              }`}
            >
              <Clock deadline={nft?.defaultListing?.endTime} />
            </div>
          </>
        )}

      {nft?.defaultListing?.type === OrderType.SALE &&
        isEndTimeWithinNextHour && (
          <>
            <span className="text-danger ms-0">Sale ends in </span>
            <div
              className={`de_countdown px-1 ${
                isEndTimeWithinNextHour && "bg-danger text-white"
              }`}
            >
              <Clock deadline={nft?.defaultListing?.endTime} />
            </div>
          </>
        )}

      {Number(nft.defaultListing?.startTime) > now && (
        <div className="uppercase-first-letter">
          <span
            className={`${
              isEndTimeWithinNextHour ? "text-danger" : "text-success"
            } ms-0`}
          >
            {nft.defaultListing?.type} Starts in{" "}
          </span>
          <div className="de_countdown">
            <Clock deadline={nft?.defaultListing?.startTime} />
          </div>
        </div>
      )}

      <p
        className="collection_name"
        onClick={() =>
          window.open(`/collection?id=${nft?.nftCollection?.id}`, "_self")
        }
      >
        {nft?.nftCollection?.name}
      </p>

      <h2 className="detail_name mb-0">
        {nft?.name}
        <span onClick={() => handleToggleLike()}>
          {nft?.isLiked ? (
            <i className="fa fa-heart liked" />
          ) : (
            <i className="fa fa-heart-o" />
          )}
        </span>
      </h2>
      <div className="profile_stats">
        <div
          className="owner"
          onClick={() => nft.owners!.length > 1 && setShowOwners(true)}
          style={{ cursor: nft.owners!.length > 1 ? "pointer" : "default" }}
        >
          <span>
            <i className="fa fa-users"></i>
          </span>
          <span>
            {nft.owners?.length} Owner{nft.owners?.length !== 1 && "s"}
          </span>
        </div>

        <div className="views">
          <span>
            <i className="fa fa-eye"></i>
          </span>
          <span>{stats.pageViews} views</span>
        </div>

        {nft.supply > 1 && (
          <div className="views">
            <span>
              <i className="fa fa-cubes"></i>
            </span>
            <span>{nft.supply} tokens</span>
          </div>
        )}

        <div className="faves">
          <span>
            <i className="fa fa-heart" />
          </span>
          <span>{nft?.likes} favorites</span>
        </div>

        {GREEN_CHAINS.includes(nft.blockchain) && (
          <div className="profile-mint">
            <MintGreenInfo>
              <div>
                <img src="/img/mint-small.png" alt="Mint Green" />
                <span className="text-green ms-2">Mint Green</span>
              </div>
            </MintGreenInfo>
          </div>
        )}
      </div>
      <ListOfOwners
        show={showOwners}
        handleClose={() => setShowOwners(false)}
        nft={nft}
      />
    </>
  );
};

export default NftStats;
