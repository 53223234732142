import React from "react";
import Modal from "react-bootstrap/Modal";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";
import { blockChainData } from "../../../api/modules/nft";
import {
  useActions,
  useConversion,
  useForceSwitchNetwork,
} from "../../../hooks";
import { acceptOffer, cancelOrders } from "../../../services/seaport.service";
import { getNftSmallUrl } from "../../../models/nft";

const OfferInfo = ({
  nft,
  show,
  handleClose,
  label,
  info,
  moreInfo,
  showInfo,
  noteText,
  noteColor,
  showBtn,
  isCancelOffer,
  offer,
}: any) => {
  const [loading, setLoading] = React.useState(false);
  const { isCorrectNetwork, checkNetwork } = useForceSwitchNetwork(nft);
  const { cancelOrder, updateNft, updateOrderPurchase } = useActions();
  const { address } = useAccount();
  const { priceUSD } = useConversion(
    Number(offer?.amount),
    blockChainData[nft.blockchain].symbol
  );

  async function handleAcceptOffer() {
    const _isCorrectNetwork = await checkNetwork();
    if (!nft || !_isCorrectNetwork || loading || address === undefined) return;

    try {
      setLoading(true);
      const orderObj = JSON.parse(offer?.hash);
      const transaction = await acceptOffer(orderObj);

      updateOrderPurchase(offer.id, 1);

      await transaction.wait();

      setLoading(false);
      handleClose(true);
    } catch (e: any) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      toast.error("There was an error placing this order");
    }
  }

  async function handleCancelOffer() {
    const _isCorrectNetwork = await checkNetwork();

    if (!nft || !_isCorrectNetwork || loading || address === undefined) return;

    try {
      setLoading(true);
      const orderObj = JSON.parse(offer?.hash);
      const transaction = await cancelOrders([orderObj]);
      await cancelOrder(offer.id, () => {}); //TODO: work around for now, the should be in the webhook
      await transaction.wait();
      handleClose(true);
      setLoading(false);
    } catch (e: any) {
      setTimeout(() => {
        setLoading(false);
      }, 500);
      toast.error("There was an error canceling this order");
    }
  }

  return (
    <Modal
      className="custom modal-bg"
      show={show}
      onHide={() => {
        toast.dismiss();
        !loading && handleClose();
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title className="">{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex mt-2 mb-3 offer-wallet-body">
          <div className="modal-nft-img w-auto me-4">
            <img
              src={getNftSmallUrl(nft?.nftMetaData?.thumbnail_image)}
              className="sell-this-nft"
              alt=""
            />
            <p className="f-16 mt-1 mb-1 f-700">
              <span className="text-gray me-1">Qty:</span>
              {offer?.quantity}
            </p>
          </div>
          <div className="ms-1">
            <h2 className="mb-0">{nft?.name}</h2>
            <p className="f-12 mb-2">
              Creator:{" "}
              <span className="text-purple me-4">{nft?.creator?.username}</span>{" "}
            </p>
            <div className="f-22">
              {offer?.amount}{" "}
              <span className="text-gray me-1">
                W{blockChainData[nft?.blockchain]?.symbol}
              </span>{" "}
              <img
                style={{
                  width: "auto",
                  height: "25px",
                  marginRight: "8px",
                }}
                src={blockChainData[nft?.blockchain]?.logo}
                alt={blockChainData[nft?.blockchain]?.symbol}
              />
              <span className="price-usd">(${priceUSD})</span>
            </div>
            {showInfo && (
              <div>
                <p className="f-600 mt-3 mb-1">{info}</p>
                <p className="f-12 f-300">{moreInfo}</p>
              </div>
            )}
            {showBtn && (
              <>
                {!loading && (
                  <button
                    onClick={() =>
                      isCancelOffer ? handleCancelOffer() : handleAcceptOffer()
                    }
                    className="btn-main wide mt-3 w-100"
                  >
                    {label}
                  </button>
                )}

                {loading && (
                  <ThreeDots
                    height="60"
                    width="60"
                    color="#8364e2"
                    ariaLabel="loading"
                  />
                )}
              </>
            )}
            {noteText && (
              <p className={`${noteColor} f-11 text-center mt-2`}>
                NOTE: {noteText}
              </p>
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OfferInfo;
