import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useAccount, useNetwork, useSwitchNetwork } from "wagmi";
import { blockChainData } from "../../api/modules/nft";
import { NFT } from "../../models/nft";
import { truncateAddress } from "../../models/user";

export const useForceSwitchNetwork = (nft: NFT) => {
  const { user } = useSelector((state: any) => state.auth);
  const { address } = useAccount();
  const { chain } = useNetwork();
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(
    chain?.id !== blockChainData[nft.blockchain].chainId
  );
  const { switchNetworkAsync, chains } = useSwitchNetwork({
    throwForSwitchChainNotSupported: true,
  });

  const checkNetwork = async () => {
    try {
      toast.dismiss();
    } catch (error) {}

    let result = true;
    if (!user || !address) {
      toast.error("Please connect your wallet to start this transaction.");
      return false;
    }

    if (address != user.walletAddress) {
      toast.error(
        `Switch your wallet to ${truncateAddress(user.walletAddress)}.`
      );
      return false;
    }

    if (chain?.id !== blockChainData[nft.blockchain].chainId) {
      const chainName = chains.find(
        (x) => x.id === blockChainData[nft.blockchain].chainId
      )?.name;
      toast.error(
        `Please switch to the ${chainName} network to start this transaction.`
      );
      result = !!(await switchNetworkAsync!(
        blockChainData[nft.blockchain].chainId
      ));
    }
    try {
      if (result) toast.dismiss();
    } catch (error) {}

    setIsCorrectNetwork(result);
    return result;
  };
  return { isCorrectNetwork, checkNetwork };
};
