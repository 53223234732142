import { OverlayTrigger, Tooltip } from "react-bootstrap";

const MintGreenInfo = (props: any) => {
  const tooltip = (
    <Tooltip>
      {props.text ||
        "Mint Green technology is an ongoing program designed to minimize the environmental impact of NFT creation"}
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={tooltip}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default MintGreenInfo;
