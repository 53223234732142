import { ItemType } from "@opensea/seaport-js/lib/constants";
import axios from "axios";
import { Dispatch } from "redux";
import api from "../../api";
import setAuthToken from "../../api/utils/setAuthToken";
import { Collection } from "../../models/collection";
import { EventSearchParams } from "../../models/event";
import { NFT, NftSearchParams, NftType } from "../../models/nft";
import { Order, OrderAttrs } from "../../models/order";
import { User, UserSearchParams } from "../../models/user";
import { ActionType } from "../action-types";
import { Action } from "../actions";
import { DEFAULT_AUDIO_IMAGE } from "../../components/Partials/constants";

interface SignUpInterface {
  walletAddress: string;
  callback?: (user: User) => void;
  redirect?: (user: User) => void;
}

export interface UpdateNFTType {
  nft: NFT;
  callback: (nft: NFT | null) => void;
  tokenURI?: string;
  tokenId?: string;
  nftId: string;
  name?: string;
  previewImage?: any;
  nftFile?: any;
  nftFileType?: string;
  owner?: string;
  description?: string;
  link?: string;
  hasUnlockable?: boolean;
  unlockableContent?: string;
  properties?: any[];
  supply?: Number;
  explicit?: Boolean;
  blockchain?: string;
  nftCollection?: string;
  forSale?: Boolean;
  status?: string;
  price?: number;
  royaltyPercentage?: number;
  metaDataFrozen?: boolean;
}
export interface CreateNFTType {
  captchaToken: string;
  name: string;
  tokenAddress: string;
  previewImage: any;
  nftFile: any;
  nftFileType: string;
  creator: string;
  royaltyPercentage: number;
  callback: (nft: NFT | null) => void;
  description?: string;
  link?: string;
  hasUnlockable?: boolean;
  unlockableContent?: string;
  properties?: any[];
  supply?: Number;
  explicit?: Boolean;
  blockchain?: string;
  nftCollection?: string;
  price?: number;
  type?: NftType;
  tokenType?: ItemType;
  tokenURI?: string;
  s3UploadComplete?: (numberOfFiles: number) => void;
}

export const refreshFreshDeskTokenCallback = function () {
  return async (dispatch: Dispatch<Action>) => {
    const { data }: any = await api.authentication.refreshFreshDeskToken();
    /**@ts-ignore */
    FreshworksWidget("authenticate", { token: data.freshDeskToken });
  };
};

export const setShowWrapCurrencyModal = (show: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SHOW_WRAP_CURRENCY_MODAL,
      payload: show,
    });
  };
};

export const uploadToS3 = async (files: any[]) => {
  try {
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        let item = files[i];

        await axios.put(item.url, item.file, {
          transformRequest: (data, headers) => {
            /**@ts-ignore */
            if (headers?.common) {
              delete headers.common["Authorization"];
            }
            return data;
          },
          headers: {
            "Content-Type": item.file.type,
          },
        });
        if (localStorage.token) setAuthToken();
      }
    }
  } catch (err: any) {
    throw Error(err);
  }
};

export const downloadFromS3 = async (url: string) => {
  return axios.get(url, {
    transformRequest: (data, headers) => {
      /**@ts-ignore */
      delete headers?.common["Authorization"];
      return data;
    },
    responseType: "blob",
  });
};

export const updateToast = (
  toastOpen: boolean,
  toastType: string,
  toastMessage: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_TOAST,
      payload: { toastOpen, toastMessage, toastType },
    });
  };
};

export const updateProfile = (
  profile: User,
  id: string,
  profileImage: any,
  bannerImage: any,
  callback: (username: string) => void,
  close?: () => {}
) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: ActionType.UPDATE_USER,
      });

      let profileUploadConfig;
      let bannerUploadConfig;
      let imgArr = [];

      if (profileImage?.type) {
        profileUploadConfig = await api.upload.getSignedUrl({
          folder: "images",
          userId: id,
          contentType: profileImage?.type,
        });

        if (profileUploadConfig.data.url) {
          profile.userImage = profileUploadConfig.data.url.split("?")[0];
          imgArr.push({
            url: profileUploadConfig.data.url,
            file: profileImage,
          });
        }
      }

      if (bannerImage?.type) {
        bannerUploadConfig = await api.upload.getSignedUrl({
          folder: "images",
          userId: id,
          contentType: bannerImage.type,
        });

        if (bannerUploadConfig.data.url) {
          profile.bannerImage = bannerUploadConfig.data.url.split("?")[0];
          imgArr.push({ url: bannerUploadConfig.data.url, file: bannerImage });
        }
      }

      if (imgArr?.length) {
        await uploadToS3(imgArr);
      }

      const { data } = await api.authentication.updateProfile(profile, id);
      dispatch({ type: ActionType.UPDATE_USER_SUCCESS, payload: data.profile });
      if (callback && data.profile) {
        localStorage.setItem("user", JSON.stringify(data.profile));
        callback(data.profile.username);
      }
      if (close) {
        close();
      }
    } catch (err: any) {
      dispatch({
        type: ActionType.UPDATE_USER_ERROR,
        payload: err.response.data.error,
      });
    }
  };
};

export const createNft = ({
  captchaToken,
  name,
  tokenAddress,
  previewImage,
  nftFile,
  nftFileType,
  creator,
  royaltyPercentage,
  callback,
  description,
  link,
  hasUnlockable,
  unlockableContent,
  properties,
  supply,
  explicit,
  blockchain,
  nftCollection,
  price,
  type,
  tokenType,
  tokenURI,
  s3UploadComplete,
}: CreateNFTType) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.CREATE_NFT,
    });

    let imageConfig;
    let imgArr = [];

    if (previewImage && previewImage != DEFAULT_AUDIO_IMAGE) {
      imageConfig = await api.upload.getSignedUrl({
        folder: "nfts",
        userId: creator,
        contentType: previewImage.type,
      });
      if (imageConfig.data.url) {
        imgArr.push({ url: imageConfig.data.url, file: previewImage });
        previewImage = imageConfig.data.url.split("?")[0];
      }
    }

    if (nftFile) {
      imageConfig = await api.upload.getSignedUrl({
        folder: "nfts",
        userId: creator,
        contentType: nftFile.type,
      });
      if (imageConfig.data.url) {
        imgArr.push({ url: imageConfig.data.url, file: nftFile });
        nftFile = imageConfig.data.url.split("?")[0];

        if (!previewImage) {
          previewImage = imageConfig.data.url.split("?")[0];
        }
      }
    }
    try {
      const promiseArray = [];
      if (imgArr.length) {
        promiseArray.push(
          uploadToS3(imgArr).then((x) => {
            s3UploadComplete && s3UploadComplete(imgArr.length);
          })
        );
      }

      const createPromise = api.nft.createNft({
        captchaToken,
        name,
        tokenAddress,
        description,
        link,
        tokenType,
        hasUnlockable,
        unlockableContent,
        properties,
        supply,
        explicit,
        blockchain,
        nftFile,
        nftFileType,
        previewImage,
        nftCollection,
        price,
        type,
        tokenURI,
        royaltyPercentage,
      });

      promiseArray.push(createPromise);
      const [, createNftResponse] = await Promise.all(promiseArray);

      dispatch({
        type: ActionType.CREATE_NFT_SUCCESS,
        payload: createNftResponse?.data,
      });
      callback(createNftResponse?.data);
    } catch (err: any) {
      callback(null);
      dispatch({ type: ActionType.CREATE_NFT_ERROR, payload: err.message });
    }
  };
};

export const placeOrder = (
  attr: OrderAttrs,
  callback: (order?: Order) => void
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ORDER_PLACED,
    });

    try {
      const { data } = await api.nft.placeOrder(attr);
      dispatch({ type: ActionType.ORDER_PLACED_SUCCESS, payload: data });
      callback(data);
    } catch (err: any) {
      callback();
      dispatch({ type: ActionType.ORDER_PLACED_ERROR, payload: err.message });
    }
  };
};

export const getNftOrders = (id: string, callback: (order?: Order) => void) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_NFT_ORDERS,
    });

    try {
      const { data } = await api.nft.getNftOrders(id);
      dispatch({ type: ActionType.FETCH_NFT_ORDERS_SUCCESS, payload: data });
      callback(data);
    } catch (err: any) {
      callback();
      dispatch({
        type: ActionType.FETCH_NFT_ORDERS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const getNftOffers = (id: string, callback: (order?: Order) => void) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_NFT_OFFERS,
    });

    try {
      const { data } = await api.nft.getNftOffers(id);
      dispatch({ type: ActionType.FETCH_NFT_OFFERS_SUCCESS, payload: data });
      callback(data);
    } catch (err: any) {
      callback();
      dispatch({
        type: ActionType.FETCH_NFT_OFFERS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const cancelOrder = (
  orderId: string,
  callback: (nft: NFT | null) => void
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.ORDER_CANCELED,
    });

    try {
      const { data } = await api.nft.cancelOrder(orderId);

      dispatch({ type: ActionType.ORDER_CANCELED_SUCCESS, payload: data });
      callback(data);
    } catch (err: any) {
      callback(null);
      dispatch({ type: ActionType.ORDER_CANCELED_ERROR, payload: err.message });
    }
  };
};

export const updateNft = (
  {
    nft,
    callback,
    nftId,
    tokenId,
    name,
    price,
    previewImage,
    nftFile,
    nftFileType,
    owner,
    description,
    link,
    hasUnlockable,
    unlockableContent,
    properties,
    royaltyPercentage,
    supply,
    explicit,
    blockchain,
    nftCollection,
    forSale,
    status,
    metaDataFrozen,
  }: UpdateNFTType,
  freezeMetadata = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_NFT,
    });

    let fileConfig;
    let imgArr = [];

    if (
      previewImage &&
      nft.nftMetaData.thumbnail_image != previewImage &&
      previewImage != DEFAULT_AUDIO_IMAGE
    ) {
      fileConfig = await api.upload.getSignedUrl({
        folder: "nfts",
        userId: owner!,
        contentType: previewImage.type,
      });
      if (fileConfig.data.url) {
        imgArr.push({ url: fileConfig.data.url, file: previewImage });
        previewImage = fileConfig.data.url.split("?")[0];
      }
    }

    if (nftFile && nft.nftMetaData.animation_url != nftFile) {
      fileConfig = await api.upload.getSignedUrl({
        folder: "nfts",
        userId: owner!,
        contentType: nftFile.type,
      });
      if (fileConfig.data.url) {
        imgArr.push({ url: fileConfig.data.url, file: nftFile });
        nftFile = fileConfig.data.url.split("?")[0];
        if (!previewImage) {
          previewImage = fileConfig.data.url.split("?")[0];
        }
      }
    }

    if (imgArr.length) {
      await uploadToS3(imgArr);
    }

    try {
      const { data } = await api.nft.updateNft(
        {
          nftId,
          price,
          tokenId,
          owner: owner!,
          name,
          description,
          link,
          hasUnlockable,
          unlockableContent,
          properties,
          supply,
          explicit,
          blockchain,
          previewImage,
          nftFile,
          nftFileType,
          royaltyPercentage,
          forSale,
          status,
          nftCollection,
          metaDataFrozen,
        },
        freezeMetadata
      );

      dispatch({ type: ActionType.UPDATE_NFT_SUCCESS, payload: data });
      callback(data.nft);
    } catch (err: any) {
      dispatch({ type: ActionType.UPDATE_NFT_ERROR, payload: err.message });
    }
  };
};

export const setWalletIsConnected = (signature: string) => {
  return async (dispatch: Dispatch<Action>) => {
    localStorage.setItem("signature", signature);
    dispatch({ type: ActionType.IS_WALLET_CONNECTED, payload: signature });
  };
};

export const clearNfts = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.CLEAR_NFTS });
  };
};

export const clearAuthors = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.CLEAR_AUTHORS });
  };
};
export const clearEvents = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.CLEAR_EVENTS });
  };
};

export const clearWalletAddress = () => {
  return async (dispatch: Dispatch<Action>) => {
    localStorage.clear();
    dispatch({ type: ActionType.CLEAR_WALLET, payload: false });
  };
};
export const setCurrentChain = (chainId: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.UPDATE_CURRENT_CHAIN, payload: chainId });
  };
};
export const setWalletInstalled = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_WALLET_INSTALLATION_STATUS,
      payload: true,
    });
  };
};

export const setIsConnected = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_WALLET_CONNECTION_STATUS,
      payload: true,
    });
  };
};
export const setWalletNotInstalled = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_WALLET_INSTALLATION_STATUS,
      payload: false,
    });
  };
};
export const setIsNotConnected = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_WALLET_CONNECTION_STATUS,
      payload: false,
    });
  };
};
export const updateWalletModal = (show: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.UPDATE_WALLET_MODAL, payload: show });
  };
};

export const setAuthState = (isAuthenticated: boolean) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.UPDATE_AUTH_STATUS, payload: isAuthenticated });
  };
};

export const updateConversions = () => {
  return async (dispatch: Dispatch<Action>) => {
    await api.coinMarketCap.ethToFiat().then((response) => {
      let result: any = { USD: { ETH: 0 } };
      if (!response.data.data) return;
      const list = response.data.data;
      list.forEach((conversion: any) => {
        const symbol = conversion.symbol.toString();
        result[symbol] = {
          ETH: conversion.quote.ETH.price,
          MATIC: conversion.quote.MATIC?.price,
          WMATIC: conversion.quote.WMATIC?.price,
          WETH: conversion.quote.WETH?.price,
        };
      });
      dispatch({ type: ActionType.UPDATE_CONVERSIONS, payload: result });
    });
  };
};

export const fetchLikedNfts = (
  userId: string,
  params?: NftSearchParams,
  append = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_LIKED_NFTS,
    });

    try {
      const { data } = await api.nft.getLikedNfts(userId, params);
      dispatch({
        type: ActionType.FETCH_LIKED_NFTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_LIKED_NFTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchPurchasedNfts = (
  userId: string,
  params?: NftSearchParams,
  append = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_PURCHASED_NFTS,
    });

    try {
      const { data } = await api.nft.getPurchasedNfts(userId, params);
      dispatch({
        type: ActionType.FETCH_PURCHASED_NFTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_PURCHASED_NFTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchUserOffersReceived = (
  userId: string,
  params?: NftSearchParams,
  append = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_USER_OFFERS_RECEIVED,
    });

    try {
      const { data } = await api.author.fetchUserOffersReceived(userId, params);
      dispatch({
        type: ActionType.FETCH_USER_OFFERS_RECEIVED_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_USER_OFFERS_RECEIVED_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchNfts = (params: NftSearchParams, append = false) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_NFTS,
    });

    try {
      const { data } = await api.nft.listNfts(params);
      dispatch({
        type: ActionType.FETCH_NFTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({ type: ActionType.FETCH_NFTS_ERROR, payload: err.message });
    }
  };
};

export const fetchNft = (
  id: string,
  loader: () => void,
  callback?: () => void
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_NFT,
    });

    try {
      const { data } = await api.nft.getOneNft(id);
      dispatch({ type: ActionType.FETCH_NFT_SUCCESS, payload: data.nft });

      if (callback) {
        callback();
      }

      if (loader) {
        loader();
      }
    } catch (err: any) {
      dispatch({ type: ActionType.FETCH_NFT_ERROR, payload: err.message });
    }
  };
};

export const fetchNftMetaData = (id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_NFT,
    });

    try {
      const { data } = await api.nft.refreshNftMetaData(id);
      dispatch({ type: ActionType.FETCH_NFT_SUCCESS, payload: data.nft });
    } catch (err: any) {
      dispatch({ type: ActionType.FETCH_NFT_ERROR, payload: err.message });
    }
  };
};
export const fetchNftPageViews = (id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_NFT_PAGE_VIEWS,
    });

    try {
      const { data } = await api.nft.nftPageViews(id);
      dispatch({
        type: ActionType.FETCH_NFT_PAGE_VIEWS_SUCCESS,
        payload: data.results.visitors.value,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_NFT_PAGE_VIEWS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const toggleLikeNft = (id: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.TOGGLE_LIKE_NFT,
    });

    try {
      const { data } = await api.nft.toggleLikeNft(id);
      dispatch({ type: ActionType.TOGGLE_LIKE_NFT_SUCCESS, payload: data });
    } catch (err: any) {
      dispatch({
        type: ActionType.TOGGLE_LIKE_NFT_ERROR,
        payload: err.message,
      });
    }
  };
};

export const updatePrice = (id: string, newPrice: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_PRICE,
    });

    try {
      const { data } = await api.nft.updatePrice(id, newPrice);
      dispatch({ type: ActionType.UPDATE_PRICE_SUCCESS, payload: data.nft });
    } catch (err: any) {
      dispatch({
        type: ActionType.UPDATE_PRICE_ERROR,
        payload: err.message,
      });
    }
  };
};

export const updateOrderPurchase = (id: string, amountPurchased: number) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_ORDER,
    });

    try {
      const { data } = await api.nft.updateOrderPurchase(id, amountPurchased);
      dispatch({ type: ActionType.UPDATE_ORDER_SUCCESS, payload: data.nft });
    } catch (err: any) {
      dispatch({
        type: ActionType.UPDATE_ORDER_ERROR,
        payload: err.message,
      });
    }
  };
};

export const freezeMetadata = (id: string, ipfsUrl: string) => {
  return async (dispatch: Dispatch<Action>) => {
    if (!ipfsUrl) return;
    dispatch({
      type: ActionType.FREEZE_METADATA,
    });

    try {
      const { data } = await api.nft.freezeMetadata(id, ipfsUrl);
      dispatch({ type: ActionType.FREEZE_METADATA_SUCCESS, payload: data });
    } catch (err: any) {
      dispatch({
        type: ActionType.FREEZE_METADATA_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchFeaturedNfts = (params?: NftSearchParams, append = false) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_FEATURED_NFTS,
    });

    try {
      const { data } = await api.nft.getFeaturedNfts(params);
      dispatch({
        type: ActionType.FETCH_FEATURED_NFTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_FEATURED_NFTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchFeaturedGreenNfts = (
  params?: NftSearchParams,
  append = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_FEATURED_GREEN_NFTS,
    });

    try {
      const { data } = await api.nft.getFeaturedGreenNfts(params);
      dispatch({
        type: ActionType.FETCH_FEATURED_GREEN_NFTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_FEATURED_GREEN_NFTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchFeaturedCollections = (
  params?: NftSearchParams,
  append = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_FEATURED_COLLECTIONS,
    });

    try {
      const { data } = await api.collection.fetchFeaturedCollections(params);
      dispatch({
        type: ActionType.FETCH_FEATURED_COLLECTIONS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_FEATURED_COLLECTIONS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchNewNfts = (params?: NftSearchParams, append = false) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_NEW_NFTS,
    });

    try {
      const { data } = await api.nft.getNewNfts(params);
      dispatch({
        type: ActionType.FETCH_NEW_NFTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_NEW_NFTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchTopNfts = (params?: NftSearchParams, append = false) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_TOP_NFTS,
    });

    try {
      const { data } = await api.nft.getTopNfts(params);
      dispatch({
        type: ActionType.FETCH_TOP_NFTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_TOP_NFTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchAuthor = (authorId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_AUTHOR,
    });

    try {
      if (authorId) {
        const { data } = await api.author.fetchAuthor(authorId);
        dispatch({
          type: ActionType.FETCH_AUTHOR_SUCCESS,
          payload: data.author,
        });
      }
    } catch (err: any) {
      dispatch({ type: ActionType.FETCH_AUTHOR_ERROR, payload: err.message });
    }
  };
};

export const fetchFollowers = (userId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_FOLLOWERS,
    });

    try {
      const { data } = await api.author.fetchFollowers(userId);
      dispatch({
        type: ActionType.FETCH_FOLLOWERS_SUCCESS,
        payload: data.authors,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_FOLLOWERS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchUserEvents = (
  id: string,
  params?: EventSearchParams,
  append = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_USER_EVENTS,
    });
    try {
      const { data } = await api.event.getUserEvents(id, params);
      dispatch({
        type: ActionType.FETCH_USER_EVENTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_USER_EVENTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchNftEvents = (
  id: string,
  params?: EventSearchParams,
  append = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_NFT_EVENTS,
    });

    try {
      const { data } = await api.event.getNftEvents(id, params);
      dispatch({
        type: ActionType.FETCH_NFT_EVENTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_NFT_EVENTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchEvents = (params?: EventSearchParams, append = false) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_EVENTS,
    });

    try {
      const { data } = await api.event.getEvents(params);
      dispatch({
        type: ActionType.FETCH_EVENTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_EVENTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchFollowedUsers = (userId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_FOLLOWED_USERS,
    });

    try {
      const { data } = await api.author.fetchFollowedUsers(userId);
      dispatch({
        type: ActionType.FETCH_FOLLOWED_USERS_SUCCESS,
        payload: data.authors,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_FOLLOWED_USERS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchAuthors = (params: UserSearchParams, append = false) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_AUTHORS,
    });

    try {
      const { data } = await api.author.getAuthors(params);
      dispatch({
        type: ActionType.FETCH_AUTHORS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_AUTHORS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchTopAuthors = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_TOP_AUTHORS,
    });

    try {
      const { data } = await api.author.getTopAuthors();
      dispatch({
        type: ActionType.FETCH_TOP_AUTHORS_SUCCESS,
        payload: data,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_TOP_AUTHORS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchFeaturedAuthors = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_FEATURED_AUTHORS,
    });

    try {
      const { data } = await api.author.getFeaturedAuthors();
      dispatch({
        type: ActionType.FETCH_FEATURED_AUTHORS_SUCCESS,
        payload: data,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_FEATURED_AUTHORS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchNewAuthors = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_NEW_AUTHORS,
    });

    try {
      const { data } = await api.author.getFeaturedAuthors();
      dispatch({
        type: ActionType.FETCH_NEW_AUTHORS_SUCCESS,
        payload: data,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_NEW_AUTHORS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchAuthorAvailableNfts = (
  authorId: string,
  params?: NftSearchParams,
  append = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_AUTHOR_AVAILABLE_NFTS,
    });

    try {
      const { data } = await api.author.fetchAuthorAvailableNfts(
        authorId,
        params
      );
      dispatch({
        type: ActionType.FETCH_AUTHOR_AVAILABLE_NFTS_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_AUTHOR_AVAILABLE_NFTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchAuthorCreatedNfts = (
  authorId: string,
  params?: NftSearchParams,
  append = false
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_CREATED_NFTS,
    });

    try {
      const { data } = await api.author.fetchAuthorNfts(authorId, params);
      dispatch({
        type: ActionType.FETCH_CREATED_NFTS_SUCCESSS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_CREATED_NFTS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const editCollection = (
  id: string,
  name: string,
  creator: string,
  logo: any,
  featuredImg: any,
  bannerImg: any,
  description: string,
  callback: (collection: Collection) => void,
  siteUrl?: string,
  discordUrl?: string,
  instagramUrl?: string,
  mediumUrl?: string,
  telegramUrl?: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.UPDATE_COLLECTION });

    let logoConfig;
    let fileArray = [];
    let featuredConfig;
    let bannerConfig;

    if (logo?.type) {
      logoConfig = await api.upload.getSignedUrl({
        folder: "collections",
        userId: creator,
        contentType: logo.type,
      });
      if (logoConfig.data.url) {
        fileArray.push({ url: logoConfig.data.url, file: logo });
        logo = logoConfig.data.url.split("?")[0];
      }
    }

    if (featuredImg?.type) {
      featuredConfig = await api.upload.getSignedUrl({
        folder: "collections",
        userId: creator,
        contentType: featuredImg.type,
      });
      if (featuredConfig.data.url) {
        fileArray.push({ url: featuredConfig.data.url, file: featuredImg });
        featuredImg = featuredConfig.data.url.split("?")[0];
      }
    }

    if (bannerImg?.type) {
      bannerConfig = await api.upload.getSignedUrl({
        folder: "collections",
        userId: creator,
        contentType: bannerImg.type,
      });
      if (bannerConfig.data.url) {
        fileArray.push({ url: bannerConfig.data.url, file: bannerImg });
        bannerImg = bannerConfig.data.url.split("?")[0];
      }
    }

    if (fileArray.length) await uploadToS3(fileArray);

    try {
      const { data } = await api.collection.editCollection(
        id,
        name,
        logo,
        featuredImg,
        bannerImg,
        description,
        siteUrl,
        discordUrl,
        instagramUrl,
        mediumUrl,
        telegramUrl
      );

      dispatch({
        type: ActionType.UPDATE_COLLECTION_SUCCESS,
        payload: data.collection,
      });
      callback(data.collection);
    } catch (err: any) {
      dispatch({
        type: ActionType.UPDATE_COLLECTION_ERROR,
        payload: err.message,
      });
    }
  };
};
export const createCollection = (
  name: string,
  creator: string,
  logo: any,
  featuredImg: any,
  bannerImg: any,
  description: string,
  callback: (collection?: Collection, error?: string) => void,
  siteUrl?: string,
  discordUrl?: string,
  instagramUrl?: string,
  mediumUrl?: string,
  telegramUrl?: string
) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.CREATE_COLLECTION });

    let logoConfig;
    let fileArray = [];
    let featuredConfig;
    let bannerConfig;

    if (logo) {
      logoConfig = await api.upload.getSignedUrl({
        folder: "collections",
        userId: creator,
        contentType: logo.type,
      });
      if (logoConfig.data.url) {
        fileArray.push({ url: logoConfig.data.url, file: logo });
        logo = logoConfig.data.url.split("?")[0];
      }
    }

    if (featuredImg) {
      featuredConfig = await api.upload.getSignedUrl({
        folder: "collections",
        userId: creator,
        contentType: featuredImg.type,
      });
      if (featuredConfig.data.url) {
        fileArray.push({ url: featuredConfig.data.url, file: featuredImg });
        featuredImg = featuredConfig.data.url.split("?")[0];
      }
    }

    if (bannerImg) {
      bannerConfig = await api.upload.getSignedUrl({
        folder: "collections",
        userId: creator,
        contentType: bannerImg.type,
      });
      if (bannerConfig.data.url) {
        fileArray.push({ url: bannerConfig.data.url, file: bannerImg });
        bannerImg = bannerConfig.data.url.split("?")[0];
      }
    }

    if (fileArray.length) await uploadToS3(fileArray);

    try {
      const { data } = await api.collection.createCollection(
        name,
        logo,
        featuredImg,
        bannerImg,
        description,
        siteUrl,
        discordUrl,
        instagramUrl,
        mediumUrl,
        telegramUrl
      );

      dispatch({
        type: ActionType.CREATE_COLLECTION_SUCCESS,
        payload: data.collection,
      });
      callback(data.collection);
    } catch (err: any) {
      callback(undefined, err.message);
      dispatch({
        type: ActionType.CREATE_COLLECTION_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchCollections = (params: NftSearchParams, append = false) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.FETCH_COLLECTION });

    try {
      const { data } = await api.collection.fetchCollections(params);
      dispatch({
        type: ActionType.FETCH_COLLECTION_SUCCESS,
        payload: { ...data, append },
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_COLLECTION_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchCollection = (collectionId: string) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({ type: ActionType.FETCH_SINGLE_COLLECTION });
    try {
      const { data } = await api.collection.fetchCollection(collectionId);
      dispatch({
        type: ActionType.FETCH_SINGLE_COLLECTION_SUCCESS,
        payload: data,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_SINGLE_COLLECTION_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchMyCollections = (callback?: any) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: ActionType.FETCH_MY_COLLECTIONS,
      });

      const { data } = await api.collection.fetchMyCollections();
      callback && callback();
      dispatch({
        type: ActionType.FETCH_MY_COLLECTIONS_SUCCESS,
        payload: data.collections,
      });
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_MY_COLLECTIONS_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchNonce = () => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: ActionType.FETCH_NONCE,
      });

      const { data } = await api.authentication.getNonce();
      dispatch({
        type: ActionType.FETCH_NONCE_SUCCESS,
        payload: data.nonce,
      });

      return data.nonce;
    } catch (err: any) {
      dispatch({
        type: ActionType.FETCH_NONCE_ERROR,
        payload: err.message,
      });
      return null;
    }
  };
};

export const verifyMessage = (
  message: any,
  signature: string,
  address: string,
  callback: (newUser: any) => {},
  disconnect: () => {}
) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      dispatch({
        type: ActionType.VERIFY_SIGNATURE,
      });

      const { data } = await api.authentication.verifyMessage(
        message,
        signature,
        address
      );

      if (data?.user?.id) {
        localStorage.setItem("user_address", data.user.walletAddress);
        localStorage.setItem("user", JSON.stringify(data.user));

        dispatch({
          type: ActionType.VERIFY_SIGNATURE_SUCCESS,
          payload: data,
        });

        callback(data);
      }

      if (data.freshDeskToken) {
        /**@ts-ignore */
        window.FreshworksWidget("authenticate", {
          token: data.freshDeskToken,
          callback: refreshFreshDeskTokenCallback,
        });
      }

      if (data.token) {
        localStorage.setItem("token", data.token);
        location.reload();
      }
    } catch (err: any) {
      disconnect();
      dispatch({
        type: ActionType.VERIFY_SIGNATURE_ERROR,
        payload: err.message,
      });
    }
  };
};

export const fetchUser = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_USER,
    });
    const token = localStorage.getItem("token");
    if (!token) return;
    try {
      const { data } = await api.authentication.getUser();
      const user = localStorage.getItem("user");
      if (user?.length) {
        const newuser = JSON.parse(user);
        if (newuser?.id !== data.user.id) {
          localStorage.setItem("user", JSON.stringify(data.user));
        }
      }
      dispatch({ type: ActionType.FETCH_USER_SUCCESS, payload: data.user });
    } catch (err: any) {
      dispatch({ type: ActionType.FETCH_USER_ERROR, payload: err.message });
    }
  };
};

export const logout = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.LOGOUT_USER,
    });

    try {
      await api.authentication.logout();
      localStorage.clear();
      dispatch({
        type: ActionType.LOGOUT_USER_SUCCESS,
      });
    } catch (err: any) {
      dispatch({ type: ActionType.LOGOUT_USER_ERROR, payload: err.message });
    }
  };
};

export const saveListing = (listing: any, callback: () => void) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SAVE_LISTING,
    });

    try {
      dispatch({ type: ActionType.SAVE_LISTING_SUCCESS, payload: listing });
      callback();
    } catch (err: any) {
      dispatch({ type: ActionType.SAVE_LISTING_ERROR, payload: err.message });
    }
  };
};

export const burnNft = (ndtId: any, callback: () => void) => {
  return async (dispatch: Dispatch<Action>) => {
    try {
      await api.nft.burnNft(ndtId);
      callback();
    } catch (err: any) {
      // dispatch({ type: ActionType.SAVE_LISTING_ERROR, payload: err.message });
    }
  };
};
