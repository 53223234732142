export const initEntityState = (initialValue: any, loading = true) => ({
  loading,
  data: initialValue,
  loadFailed: false,
  canceler: null,
});

export const entityLoadingStarted = (state: any, canceler: any) => ({
  ...state,
  canceler,
  loading: true,
  loadFailed: false,
});

export const entityLoadingSucceeded = (state: any, data: any) => ({
  ...state,
  data,
  loading: false,
  loadFailed: false,
  canceler: null,
});

export const entityLoadingFailed = (state: any) => ({
  ...state,
  loading: false,
  loadFailed: true,
  canceler: null,
});

export const handleSelection = (
  selectedIds: any,
  selectId: unknown,
  singleSelect = false
) => {
  const selected = new Set(selectedIds || []);

  if (singleSelect) return new Set([selectId]);

  if (selected.has(selectId)) {
    selected.delete(selectId);
  } else {
    selected.add(selectId);
  }

  return selected;
};

export const shuffleArray = (array: any) => {
  let shuffeled = array;

  for (let i = shuffeled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffeled[i], shuffeled[j]] = [shuffeled[j], shuffeled[i]];
  }

  return shuffeled;
};

export const canvasToFile = (canvasRef: any) => {
  return new Promise((resolve) => {
    if (canvasRef.current) {
      canvasRef.current.toBlob(async (profileImgBlob: BlobPart) => {
        const file = new File([profileImgBlob], "fileName.jpg", {
          type: "image/jpeg",
        });
        resolve(file);
      }, "image/jpeg");
    } else {
      resolve(null);
    }
  });
};
