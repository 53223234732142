import { memo, useEffect, useRef, useState } from "react";
import ShowFullImage from "./Modals/FullImage";

const processUrl = (url: string) => {
  const baseUrl = "https://assets.zing.it/";
  let strippedUrl = url.replace(baseUrl, "");
  const isGif = strippedUrl.toLowerCase().endsWith(".gif");
  strippedUrl = strippedUrl.replace(
    "https://zenft-bucket.s3.amazonaws.com/",
    ""
  );
  strippedUrl = strippedUrl.replace(
    "https://zenft-bucket.s3.us-east-1.amazonaws.com/",
    ""
  );
  return {
    imageKey: strippedUrl,
    isGif: isGif,
  };
};

const NftDisplay = ({
  nftFile,
  previewImageFile,
  fileType,
  className,
  displayRef,
  hideControls = false,
  thumbnail = false,
  doNotShrink = false,
  extractPreview = false,
  onImageData = (data: string) => {},
}: any) => {
  if (!nftFile && !fileType && !previewImageFile) return <></>;
  const [showFullImage, setShowFullImage] = useState(false);
  const [loading, setLoading] = useState(true);

  const { imageKey, isGif } = processUrl(previewImageFile);

  const imageRequest = {
    bucket: "zenft-bucket",
    key: imageKey,
    edits: {
      resize: {
        width: thumbnail ? 300 : 600,
        fit: "cover",
      },
    },
  };

  const encodedObject = btoa(JSON.stringify(imageRequest));
  const displayURL = `https://d26z45c7morr93.cloudfront.net/${encodedObject}`;

  const extractFrame = () => {
    if (previewImageFile) return;
    const video = displayRef.current;

    const timeToSeek = video.duration * 0.3;
    video.currentTime = timeToSeek;

    video.onseeked = () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx?.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = canvas.toDataURL();
      onImageData(imageData); // Call the callback provided in props
    };
  };

  useEffect(() => {
    if (displayRef.current?.load) {
      displayRef.current.load();
    }
  }, [nftFile]);

  return (
    <>
      {loading && (
        <div
          className="skeleton shimmer"
          style={{ height: thumbnail ? "200px" : "auto", width: "100%" }}
        ></div>
      )}
      {fileType.includes("image") && (
        <img
          onError={() => console.log("Image load error!")}
          onClick={() => !thumbnail && setShowFullImage(true)}
          src={isGif || doNotShrink ? nftFile : displayURL}
          onLoad={() => setLoading(false)}
          ref={displayRef}
          id="get_file_2"
          className={`${className} ${loading ? "off-screen" : ""}`}
          style={{ cursor: "pointer" }}
          alt=""
        />
      )}

      {fileType.includes("audio") && (
        <div className="custom-video-player">
          <video
            style={{
              width: "100%",
              maxWidth: "640px",
              height: "100%",
              backgroundImage: `url(${
                isGif || doNotShrink ? previewImageFile : displayURL
              })`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            ref={displayRef}
            onLoadedData={() => setLoading(false)}
            controls
            controlsList="nodownload"
            className={`video ${loading ? "off-screen" : ""}`}
          >
            <source src={nftFile} type="audio/mpeg" />
            Your browser does not support the video element.
          </video>
        </div>
      )}

      {fileType.includes("video") && (
        <video
          style={{ width: "100%", cursor: "pointer" }}
          preload="none"
          className={`${className} ${loading ? "off-screen" : ""}`}
          ref={displayRef}
          onLoadedData={() => {
            setLoading(false);
            extractPreview && extractFrame();
          }}
          controls
          controlsList="nodownload"
          poster={
            isGif || doNotShrink
              ? previewImageFile || "/img/image_required.png"
              : displayURL
          }
        >
          <source src={nftFile} type={fileType} />
          Your browser does not support the video tag.
        </video>
      )}
      <ShowFullImage
        fullImage={nftFile}
        show={showFullImage}
        handleClose={setShowFullImage}
      />
    </>
  );
};

export default memo(NftDisplay);
