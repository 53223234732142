import { ItemType } from "@opensea/seaport-js/lib/constants";
import moment from "moment";
import { useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { blockChainData } from "../../api/modules/nft";
import { useActions } from "../../hooks";
import { NFT } from "../../models/nft";
import { truncateAddress } from "../../models/user";
type propType = { nft: NFT };

const NftDetails = (props: propType) => {
  const { nft } = props;
  const [refreshingMetaData, setRefreshingMetaData] = useState(false);
  const { fetchNftMetaData } = useActions();

  const handleRefreshMetaData = async () => {
    setRefreshingMetaData(true);
    if (nft) {
      await fetchNftMetaData(nft.id!);
      setRefreshingMetaData(false);
    }
  };
  return (
    <div className="item_details">
      {nft?.nftMetaData.external_url && (
        <div className="mb-4">
          <h4>Item Link</h4>
          <div className="d-flex ">
            <span>
              <i className="fa-solid fa-link me-1"></i>
            </span>
            <a target="_blank" href={nft?.nftMetaData.external_url}>
              Website
            </a>
          </div>
        </div>
      )}
      <div
        style={{
          float: "right",
          cursor: "pointer",
          marginRight: "5px",
          color: "#8364e2",
        }}
      >
        {!refreshingMetaData && (
          <i
            className="fa fa-refresh mr-5 mt-2"
            aria-hidden="true"
            onClick={() => handleRefreshMetaData()}
          ></i>
        )}
        {refreshingMetaData && (
          <ThreeDots
            height="12x"
            width="30"
            color="#8364e2"
            ariaLabel="loading"
          />
        )}
      </div>
      <h4>Item Details</h4>
      <table className="table table-borderless table-sm">
        <tbody>
          <tr>
            <th scope="row">Contract Address</th>
            <td>
              <a
                target={"_blank"}
                rel="noreferrer"
                href={`${blockChainData[nft.blockchain].baseUrl}/address/${
                  nft?.tokenAddress
                }`}
              >
                {truncateAddress(nft?.tokenAddress)}
              </a>
            </td>
          </tr>
          <tr>
            <th scope="row">Token ID</th>
            <td>{nft?.tokenId}</td>
          </tr>
          <tr>
            <th scope="row">Token Standard</th>
            <td>{nft && ItemType[nft.tokenType!]}</td>
          </tr>
          <tr>
            <th scope="row">Chain </th>
            <td>{nft?.blockchain}</td>
          </tr>
          <tr>
            <th scope="row">Last Updated</th>
            <td>{moment(nft?.updatedAt).fromNow()}</td>
          </tr>
          <tr>
            <th scope="row">Creator Royalty </th>
            <td>{nft.royaltyPercentage}%</td>
          </tr>
          <tr>
            <th scope="row">Metadata Frozen </th>
            <td>{nft?.metaDataFrozen ? "Yes" : "No"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default NftDetails;
