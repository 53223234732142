import axios from "axios";

// send token with every request
const getPlausibleInstance = () => {
  const instance = axios.create({
    baseURL: `https://plausible.zing.it/api/v1`,
  });

  instance.interceptors.request.use(
    async (config) => {
      config.headers!.Authorization = `Bearer nFUE8gj2RK9YGgx2doW6R0GhOjIVuF-fE5n4Jhw2BqBmHB-XntHieulD1LX2xqCe`;
      config.headers!.Accept = `application/json`;
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );
  return instance;
};

export default getPlausibleInstance;
