import moment from "moment";
import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import FadeLoader from "react-spinners/ClipLoader";
import { blockChainData } from "../../api/modules/nft";
import { useActions, useSelector } from "../../hooks";
import { EventType } from "../../models/event";
import { NftSearchParams, getNftSmallUrl } from "../../models/nft";
import { User } from "../../models/user";

type propType = {
  tabs: string[];
  selectedTab: string;
  user: User;
};
const UserOffersReceivedTab = (props: propType) => {
  const { tabs, selectedTab, user } = props;
  const navigate = useNavigate();
  const { fetchUserOffersReceived } = useActions();
  const { offersReceived } = useSelector((state) => state.authors);
  const [percentageLoaded, setPercentageLoaded] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(40);

  const loadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    handleFetchOffersReceived(newPage);
  };

  const handleFetchOffersReceived = (newPage: number) => {
    const params: NftSearchParams = {
      page: newPage,
      page_size: pageSize,
    };

    if (user) fetchUserOffersReceived(user?.id!, params, Number(newPage) > 1);
  };

  useEffect(() => {
    setPage(1);
    handleFetchOffersReceived(1);
  }, []);

  const getPercentage = () =>
    offersReceived?.offers
      ? Math.floor((offersReceived?.offers.length / offersReceived.total) * 100)
      : 0;

  useEffect(() => {
    setPercentageLoaded(getPercentage());
  }, [JSON.stringify(offersReceived?.offers)]);

  return (
    <div id="ActivityTab" className="onStep fadeIn">
      <div className="search-section mt-0">
        <div className="activity-table filter-content mt-0 pe-0">
          {offersReceived.loading && page === 1 ? (
            <div className="d-flex justify-content-center align-items-center">
              <FadeLoader
                className="text-center"
                loading={offersReceived.loading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div className="activity-table-wrapper">
              <table className="table table-borderless table-bordered mt-3">
                <thead>
                  <tr>
                    <th className="col-3">Item</th>
                    <th className="col-1">Price</th>
                    <th className="col-1">Qty</th>
                    <th className="col-1">From</th>
                    <th className="col-2">Time</th>
                  </tr>
                </thead>
                <tbody>
                  {offersReceived.offers?.map((offer: any, i: number) => {
                    const eventPrice = offer.amount
                      ? offer.amount.toLocaleString(undefined, {
                          maximumFractionDigits: 6,
                        })
                      : 0;

                    switch (offer.type) {
                      case EventType.OfferSubmitted:
                        return (
                          <tr key={i}>
                            <td className="item-td-img">
                              <img
                                src={getNftSmallUrl(
                                  offer?.nft?.nftMetaData?.thumbnail_image
                                )}
                                alt=""
                              />
                              <Link to={`/item/${offer?.nft?.id}`}>
                                {offer?.nft?.name}
                              </Link>
                            </td>
                            <td>
                              {eventPrice ? (
                                <>
                                  {" "}
                                  {eventPrice}{" "}
                                  {offer?.nft &&
                                    blockChainData[offer?.nft?.blockchain]
                                      .symbol}
                                </>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td className="text-center">
                              {offer?.nft?.supply}
                            </td>
                            <td>
                              <a href={`/author/${offer?.buyer?.id}`}>
                                {offer?.buyer?.username}
                              </a>
                            </td>
                            <td>{moment(offer.updatedAt).fromNow()}</td>
                          </tr>
                        );

                      default:
                        return (
                          <tr key={i}>
                            <td className="item-td-img">
                              <img
                                src={getNftSmallUrl(
                                  offer?.nft?.nftMetaData?.thumbnail_image
                                )}
                                alt=""
                              />
                              <Link to={`/item/${offer?.nft?.id}`}>
                                {offer?.nft?.name}
                              </Link>
                            </td>
                            <td>
                              {eventPrice ? (
                                <>
                                  {" "}
                                  {eventPrice}{" "}
                                  {offer?.nft &&
                                    blockChainData[offer?.nft?.blockchain]
                                      .symbol}
                                </>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td className="text-center">
                              {offer?.nft?.supply}
                            </td>
                            <td className="activity-from">
                              <a href={`/author/${offer.buyer?.id}`}>
                                {offer?.buyer?.username}
                              </a>
                            </td>
                            <td>{moment(offer.updatedAt).fromNow()}</td>
                          </tr>
                        );
                    }
                  })}
                </tbody>
              </table>
            </div>
          )}
          {!offersReceived.loading && percentageLoaded < 100 && (
            <div className="col-lg-12">
              <div className="spacer-single"></div>
              <span onClick={loadMore} className="btn-main lead m-auto">
                Load More ({percentageLoaded}%)
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(UserOffersReceivedTab);
