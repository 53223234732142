import { keyframes } from "@emotion/react";
import Reveal from "react-awesome-reveal";
import { toast } from "react-toastify";
import { useAccount } from "wagmi";

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateY(40px);
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
`;

const FeatureBox = ({ showWallet }: any) => {
  const account = useAccount();

  return (
    <div className="row">
      <div className="col-lg-4 col-md-6 mb-3">
        <div className="feature-box f-boxed style-3">
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={100}
            duration={600}
            triggerOnce
          >
            <i className="bg-color-2 i-boxed icon_wallet"></i>
          </Reveal>
          <div className="text">
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={100}
              duration={600}
              triggerOnce
            >
              <h4 className="f-24 header">Connect Your Wallet</h4>
            </Reveal>
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={200}
              duration={600}
              triggerOnce
            >
              <p className="">
                Set up a new wallet or attach your existing crypto wallet to see
                what Zingit has to offer.
              </p>
              <br />
              <button
                onClick={() =>
                  account.isConnected
                    ? toast.error("You’re already signed in!")
                    : showWallet()
                }
                className="btn-secondary lead w-100"
              >
                ATTACH YOUR WALLET
              </button>
            </Reveal>
          </div>
          <i
            onClick={() => !account.isConnected && showWallet()}
            className="wm icon_wallet cursor-pointer"
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 mb-3">
        <div className="feature-box f-boxed style-3">
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={100}
            duration={600}
            triggerOnce
          >
            <i className=" bg-color-2 i-boxed icon_cloud-upload_alt"></i>
          </Reveal>
          <div className="text">
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={100}
              duration={600}
              triggerOnce
            >
              <h4 className="f-24 header">See What’s Available</h4>
            </Reveal>
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={200}
              duration={600}
              triggerOnce
            >
              <p className="">
                From eclectic art and photography to NFTs with great utility,
                Zingit has it all. Enjoy!
              </p>
              <br />
              <button
                onClick={() => window.open("/explore", "_self")}
                className="btn-secondary lead w-100"
              >
                EXPLORE WHAT’S HERE
              </button>
            </Reveal>
          </div>
          <i
            onClick={() => window.open("/explore", "_self")}
            className="wm icon_cloud-upload_alt cursor-pointer"
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      </div>

      <div className="col-lg-4 col-md-6 mb-3">
        <div className="feature-box f-boxed style-3">
          <Reveal
            className="onStep"
            keyframes={fadeInUp}
            delay={100}
            duration={600}
            triggerOnce
          >
            <i className=" bg-color-2 i-boxed icon_tags_alt"></i>
          </Reveal>
          <div className="text">
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={100}
              duration={600}
              triggerOnce
            >
              <h4 className="f-24 header">Create Like Crazy</h4>
            </Reveal>
            <Reveal
              className="onStep"
              keyframes={fadeInUp}
              delay={200}
              duration={600}
              triggerOnce
            >
              <p className="">
                List everything you’ve created and, this is the best part,
                there’s no upfront cost to you.
              </p>
              <br />
              <button
                onClick={() => {
                  if (account.isConnected) window.open("/create-nft", "_self");
                  else showWallet();
                }}
                className="btn-secondary lead w-100"
              >
                START SELLING
              </button>
            </Reveal>
          </div>
          <i
            onClick={() => {
              if (account.isConnected) window.open("/create-nft", "_self");
              else showWallet();
            }}
            className="wm icon_tags_alt"
            style={{ cursor: "pointer" }}
          ></i>
        </div>
      </div>
    </div>
  );
};
export default FeatureBox;
