import { User } from "../../models/user";
import setAuthToken from "../utils/setAuthToken";

const instance = setAuthToken();

const login = (email: string, password: string) => {
  return instance.post("/signin", { email, password });
};

const updateProfile = (profile: User, id: string) => {
  return instance.put(`/profile/`, profile);
};

const refreshFreshDeskToken = () => {
  const token = localStorage.getItem("token");
  return instance.post(`/refreshFreshDesk`, { token });
};

const getNonce = () => {
  return instance.get("/nonce");
};

const logout = () => {
  return instance.get("/logout");
};

const verifyMessage = (message: any, signature: string, address: string) => {
  return instance.post("/verify-message", {
    message,
    signature,
    address,
  });
};

const getUser = () => {
  return instance.get("/me");
};

export {
  login,
  updateProfile,
  refreshFreshDeskToken,
  getNonce,
  logout,
  verifyMessage,
  getUser,
};
