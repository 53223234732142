import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { Footer } from "../";
import { useActions, useSelector } from "../../hooks";
import CreatorSellerCard from "../Partials/CreatorSellerCard";
import WalletModal from "../Partials/Modals/WalletModal";

const Landing: React.FC = () => {
  const handleScheduleCall = () => {
    const url = "https://bit.ly/zingitonboarding";
    window.open(url, "_blank");
  };
  const [builtFor, setBuiltFor] = useState([
    "Visual Artists",
    "Cartoonists",
    "Comic Book Artists",
    "Painters",
    "Graphic Artists",
    "Screenwriters",
    "Photographers",
    "AR & VR Artists",
    "Filmmakers",
    "Illustrators",
    "Actors",
    "Gamers",
    "Street Artists",
    "Musicians",
    "Comedians",
    "Sculptors",
    "Writers",
    "Spoken Word Artists",
    "Digital Artists",
    "Novelists",
    "Knitters",
    "3D Artists",
    "Poets",
    "Woodworkers",
    "Animators",
    "Performance Artists",
    "and more!",
  ]);

  const [benefits, setBenefits] = useState([
    {
      title: "Reach a Wider Audience",
      description:
        "Showcase your work to collectors from across the web and the world.",
    },
    {
      title: "Discover a New Way to Sell Your Art",
      description:
        "Break free from traditional art sales models reaching new audiences and generating new revenue streams. With Zingit, artists can tap into a new kind of art sales and create a sustainable business built around their passion.",
    },
    {
      title: "Engage Deeply With Your Collectors",
      description:
        "Connect with fans differently, growing an unique personal relationship with collectors supporting your work.",
    },
    {
      title: "Tap into a New Stream of Income",
      description:
        "Monetize your digital creations, creating a new or additional revenue stream.",
    },
    {
      title: "Explore a New Kind of Creativity",
      description:
        "The unique characteristics of NFTs, such as digital ownership and scarcity, open up new possibilities for creators to express themselves and create works that can be sold and traded in a way that was not previously possible.",
    },
    {
      title: "Authenticate Your Work",
      description:
        "Give your fans a level of authenticity and transparency that is unique to the digital world, ensuring they're getting an original work.",
    },
    {
      title: "Create a Lasting Legacy",
      description:
        "Preserve your work for future generations, creating a lasting legacy for your art and creativity.",
    },
    {
      title: "Leverage a Safe and Secure Marketplace",
      description:
        "When you sell your work on our platform, you can rest assured that your digital assets and transactions are protected by advanced security measures. Read more under our security and privacy promise below.",
    },
  ]);

  const [howItWorks, setHowItWorks] = useState([
    {
      id: 1,
      title: "Schedule an Introductory Call",
      description:
        "Schedule a call to discover if NFTs are the right fit for you and your creative work. If it seems like a good match, we'll pair you with a dedicated Zingit Account Manager who will help bring your digital creations to life.",
      buttonText: "SCHEDULE CALL NOW",
      buttonAction: handleScheduleCall,
    },
    {
      id: 2,
      title: "Plan Your Collection",
      description:
        "Your Zingit Account Manager will help you choose which pieces might make great NFTs.",
    },
    {
      id: 3,
      title: "Create Your NFTs",
      description:
        "We'll show you how to set up a digital wallet and get your collection ready for sale.",
    },
    {
      id: 4,
      title: "Sell Your Work as NFTs",
      description:
        "Once your NFT collection is live, we'll guide you through how to share and promote your collection.",
    },
  ]);

  const [faqs, setFaqs] = useState([
    {
      question: "What is Zingit?",
      answer:
        "Zingit is an NFT (Non-Fungible Token) marketplace for professional artists who want to sell their digital art. Our platform is designed to be easy to use for artists who may not be familiar with technology, so they can easily monetize their work.",
    },
    {
      question: "What is an NFT?",
      answer:
        "An NFT is a unique digital asset that represents ownership of a piece of content, such as a piece of digital art. NFTs are stored on a blockchain, which provides a secure and verifiable record of ownership.",
    },
    {
      question: "Why should I use Zingit to sell my digital art?",
      answer:
        "Zingit provides an easy-to-use platform for artists to sell their NFTs, without the need for extensive technical knowledge. Our platform also provides a secure and transparent marketplace for buyers to purchase NFTs, ensuring that you receive fair compensation for your work.",
    },
    {
      question: "How do I know if NFTs are a good fit for my work or business?",
      answer:
        "NFTs may be a good fit for your work or business if you are looking for a way to digitally represent and sell unique, one-of-a-kind items. NFTs can be used to sell digital art, music, videos, and other creative works, or even physical goods with a digital certificate of authenticity. If you are interested in exploring new revenue streams and reaching a wider audience for your work, NFTs may be worth considering.",
    },
    {
      question: "How do I list my digital art for sale on Zingit?",
      answer:
        "To list your digital art for sale on Zingit, you simply need to create an account and follow the steps provided to upload and publish your work. Our platform makes it easy for you to set a price, specify the details of your NFT, and manage the sale process.",
    },
    {
      question: "How many pieces do you recommend uploading?",
      answer:
        "The number of pieces you upload will depend on the nature of your work and your overall business strategy. Some creators may choose to upload a large number of works to reach a wider audience, while others may choose to focus on selling a smaller number of more valuable pieces. Your Zingit Account Manager will also help you evaluate how many pieces you should put in your collection.",
    },
    {
      question: "How much does Zingit cost?",
      answer:
        "Creating NFTs on Zingit is completely free. There are no upfront costs for the artist. A small transaction fee is taken when your NFT is sold. This fee helps us maintain the platform and support artists in their journey.",
    },
    {
      question: "What are the fees for selling my digital art on Zingit?",
      answer:
        "Zingit only takes a small transaction fee when your NFT is sold.",
    },
    {
      question: "How do I receive payment for my digital art sold on Zingit?",
      answer:
        "Payments for your digital art sold on Zingit are processed securely and transferred directly to your account. You can choose to receive payment through a variety of methods, such as bank transfer or cryptocurrency.",
    },
    {
      question: "What happens if my digital art is purchased on Zingit?",
      answer:
        "If your digital art is purchased on Zingit, the ownership of the NFT will be transferred to the buyer, and you will receive payment for the sale. You will have the option to transfer the proceeds to your bank account or keep it in a wallet on our platform.",
    },
    {
      question: "Is my digital art protected on Zingit?",
      answer:
        "Yes, your digital art is protected on Zingit through the use of blockchain technology, which provides a secure and verifiable record of ownership. Additionally, our platform employs state-of-the-art security measures to protect your artwork and your payment information.",
    },
    {
      question: "What's the environmental impact of using NFTs?",
      answer:
        "Zingit's Mint Green program is working to reduce the environmental impact of “minting” NFTs by using renewable energy sources and optimizing our energy usage. As a responsible NFT marketplace, we are committed to reducing our environmental impact and supporting the use of sustainable technology.",
    },
  ]);

  const [security, setSecurity] = useState([
    {
      title: "Secure Infrastructure:",
      description:
        "Our NFT marketplace is built on a secure, decentralized blockchain platform that ensures the immutability and integrity of your digital assets. Our servers and databases are regularly audited and maintained to ensure the highest level of security.",
    },
    {
      title: "Confidential Transactions:",
      description:
        "All transactions made on our platform are confidential and only accessible to the parties involved. Your personal and financial information is never shared with third parties without your explicit consent.",
    },
    {
      title: "Encrypted Data Storage:",
      description:
        "All data stored on our servers is encrypted to ensure that it cannot be accessed without proper authorization. We also regularly perform security backups to protect against data loss.",
    },
    {
      title: "Controlled Access:",
      description:
        "Zingit's platform uses advanced authentication methods to ensure that only authorized users have access to your work and transactions. We also monitor activity on the platform to detect and prevent any suspicious activity.",
    },
    {
      title: "Privacy Policy:",
      description:
        "Our privacy policy outlines how we collect, use, and protect your personal information. We take this policy seriously and regularly review and update it to ensure that it remains compliant with industry standards and regulations.",
    },
    {
      title: "Customer Support:",
      description:
        "Zingit’s dedicated customer support team is always available to answer any questions or concerns you may have about your security and privacy on our platform.",
    },
  ]);
  const { featuredNfts } = useSelector((state) => state.nfts);
  const { featuredAuthors } = useSelector((state) => state.authors);
  const { featuredCollections, loading: collectionsLoading } = useSelector(
    (state) => state.collections
  );
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const account = useAccount();

  const {
    fetchFeaturedNfts,
    fetchFeaturedAuthors,
    fetchFeaturedCollections,
    clearAuthors,
    clearNfts,
  } = useActions();
  useEffect(() => {
    window.document.title = "ZingIt- NFT Marketplace";
    handleFetchFeaturedNfts();
    handleFetchFeaturedAuthors();
    handleFetchFeaturedCollections();
    return () => {
      clearAuthors();
      clearNfts();
    };
  }, []);

  const handleFetchFeaturedNfts = async () => {
    fetchFeaturedNfts();
  };
  const handleFetchFeaturedAuthors = async () => {
    fetchFeaturedAuthors();
  };
  const handleFetchFeaturedCollections = async () => {
    fetchFeaturedCollections();
  };

  return (
    <div>
      <section className="jumbotron breadcumb no-bg">
        <div className="mainbreadcumb">
          <div className="container landing">
            <div className="landing-intro">
              <div>
                <h2 className="mb-1">Share Your Creativity Across The Web</h2>
                <p className="f-14 text-dark mt-0 mb-2">
                  Zingit empowers creatives to transfer their work into NFTs –
                  showcasing your art, monetizing digital creations, and opening
                  you up to a community of fans & fellow artists.
                </p>
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => {
                      if (account.isConnected)
                        window.open("/create-nft", "_self");
                      else setShowConnectWallet(true);
                    }}
                    className="btn-main"
                  >
                    GET STARTED
                  </button>
                </div>
              </div>
              <div>
                <div className="landing-img-wrap">
                  <img src="/img/yourWorkHere.png" alt="yourWork" />
                  {/* <div className="your-work">
                    <span className="text-white f-22">Your work here</span>
                  </div> */}
                </div>
                <div className="landing-right">
                  <h3 className="mb-0">Create Your NFTs</h3>
                  <p className="text-purple my-0">Get Started</p>
                  <p className="f-12 mt-0">So easy on Zingit</p>
                </div>
              </div>
            </div>
            <hr className="landing-line" />
            <div className="built-for">
              <p className="f-30 f-600 text-purple text-center m--20">
                Built for...
              </p>
              <div className="built-for-list">
                {builtFor.map((b, i) => (
                  <p key={i}>{b}</p>
                ))}
              </div>
            </div>
            <hr className="landing-line" />
            <div className="benefits">
              <p className="f-30 f-600 text-purple text-center m--20">
                Benefits
              </p>
              <div className="benefits-list">
                {benefits.map((b, i) => {
                  const { title, description } = b;
                  return (
                    <div key={i}>
                      <h3 className="text-purple f-500">{title}</h3>
                      <p className="f-12 text-dark">{description}</p>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  onClick={() => handleScheduleCall()}
                  className="btn-main"
                >
                  SCHEDULE A CALL
                </button>
              </div>
            </div>
            <hr className="landing-line" />
            <div className="comm-creators">
              <p className="f-30 f-600 text-purple text-center m--20">
                Join Our Community of Creators
              </p>
              <CreatorSellerCard authors={featuredAuthors.authors} />
            </div>
            <hr className="landing-line" />
            <div className="landing-hiw">
              <p className="f-30 f-600 text-purple text-center m--20">
                How It Works
              </p>
              <div className="how-it-works">
                {howItWorks.map((h, i) => {
                  const { id, title, description, buttonAction, buttonText } =
                    h;
                  return (
                    <div key={i}>
                      <div className="number-wrap">{id}</div>
                      <div>
                        <p className="hiw-title">{title}</p>
                        <p className="hiw-desc">{description}</p>
                        {buttonText && (
                          <button
                            onClick={buttonAction}
                            className="btn-transparent"
                          >
                            {buttonText}
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="d-flex justify-content-center">
                <button
                  onClick={() => {
                    if (account.isConnected)
                      window.open("/create-nft", "_self");
                    else setShowConnectWallet(true);
                  }}
                  className="btn-main"
                >
                  GET STARTED
                </button>
              </div>
            </div>
            <hr className="landing-line" />
            <div className="brainstorm pb-4">
              <h1 className="f-30 f-600 text-purple text-center m--20">
                “Let's Brainstorm Something Cool!”
              </h1>
              <p className="text-black f-12">
                The possibilities of NFTs are endless. Want to include an NFT as
                part of the sale of a physical item? Interested in using NFTs to
                promote special discounts or access to special events? We love
                working with creatives to find new ways NFTs can be used to
                enhance your work or business.
              </p>
              <div className="d-flex justify-content-center">
                <button
                  onClick={() => {
                    if (account.isConnected)
                      window.open("/create-nft", "_self");
                    else setShowConnectWallet(true);
                  }}
                  className="btn-main"
                >
                  GET STARTED
                </button>
              </div>
            </div>
            {/* <hr className="landing-line" />
            <div className="creators-love">
              <h1 className="text-purple text-center m--20">
                Creators LOVE Zingit
              </h1>
            </div> */}
            <hr className="landing-line" />
            <div className="faq">
              <h1 className="text-purple text-center m--20">
                Frequently Asked Questions
              </h1>
              <div className="faq-list">
                {faqs.map((f, i) => {
                  const { question, answer } = f;
                  return (
                    <div key={i}>
                      <p className="faq-question">
                        <span className="text-purple me-1">Q:</span>
                        <span>{question}</span>
                      </p>
                      <p className="faq-answer">
                        <span className="me-1 f-600">A:</span>
                        <span>{answer}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <hr className="landing-line" />
            <div className="security">
              <h1 className="text-purple text-center m--20">
                Security & Privacy Promise
              </h1>
              <p className="f-12 text-black">
                At Zingit, the security and privacy of our creators' work is our
                top priority. We understand the trust you place in us to protect
                and manage your digital assets, and we take this responsibility
                seriously. That's why we promise the following:
              </p>
              <div className="sec-list">
                {security.map((s, i) => {
                  const { title, description } = s;
                  return (
                    <div key={i}>
                      <p className="f-600 text-black my-0">{title}</p>
                      <p className="f-12 text-black my-0">{description}</p>
                    </div>
                  );
                })}
              </div>
              <p className="f-12 text-black">
                We believe in transparency and accountability, and we are
                committed to upholding our security and privacy promise. If you
                have any questions or concerns, please don't hesitate to{" "}
                <a
                  href="https://support.zing.it/support/solutions"
                  className="text-purple"
                >
                  contact us.
                </a>
              </p>
              <div className="d-flex justify-content-center pt-4">
                <button
                  onClick={() => {
                    if (account.isConnected)
                      window.open("/create-nft", "_self");
                    else setShowConnectWallet(true);
                  }}
                  className="btn-main"
                >
                  GET STARTED
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <WalletModal
        show={showConnectWallet}
        handleClose={() => setShowConnectWallet(false)}
      />
    </div>
  );
};
export default Landing;
