import { Helmet } from "react-helmet";

function HelmetMetadata({ nft }: any) {
  const currentUrl = window.location.href;
  const { name, description, image: imageUrl } = nft.nftMetaData;
  
  
  return (
    <Helmet>
      <title>{name}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={name} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={currentUrl} />
      <meta name="twitter:title" content={name} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
    </Helmet>
  );
}

export default HelmetMetadata;
