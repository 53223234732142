import Modal from "react-bootstrap/Modal";

const CreateNewWallet = ({ show, handleClose }: any) => {
  return (
    <Modal className="custom modal-bg" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="">Create New Wallet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <p className="f-13">
            Choose one of the wallet providers to begin setting up your new
            wallet.
          </p>
        </div>
        <div className="d-flex flex-column align-items-center">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="rounded bigger custom m-0">
              <img
                style={{ cursor: "pointer" }}
                alt="Coinbase Wallet"
                src="../../../img/coinbase-logo.png"
                onClick={() =>
                  window.open("https://www.coinbase.com/wallet", "_blank")
                }
              />
            </div>
            <p className="f-12 mb-2">Coinbase</p>
            <button
              className="btn-main mb-3"
              onClick={() =>
                window.open("https://www.coinbase.com/wallet", "_blank")
              }
            >
              CHOOSE
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateNewWallet;
