/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import { Footer } from "..";
import { useActions, useSelector } from "../../hooks";
import NftGrid from "../Partials/NftGrid";
import {
  DEFAULT_BANNER_IMAGE,
  DEFAULT_USER_IMAGE,
} from "../Partials/constants";
import { getUserSmallImageUrl } from "../../models/user";

const GlobalStyles = createGlobalStyle`
  header#myHeader.navbar.white {
    border-bottom: solid 1px #ccc !important;
  }
  .sing-coll-links i {
    width: 25px;
  }
  .sing-coll-links a {
    cursor: pointer;
  }
`;
const SingleCollection = () => {
  const [showNftModal, setShowNftModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { fetchLikedNfts, fetchAuthorCreatedNfts, fetchPurchasedNfts } =
    useActions();
  const [userImage, setUserImage] = useState("");
  const [bannerImage, setBannerImage] = useState(DEFAULT_BANNER_IMAGE);
  const { fetchCollection } = useActions();
  const { collection, loading } = useSelector((state) => state.collections);
  const { purchasedNfts } = useSelector((state) => state.nfts);
  let [searchParams] = useSearchParams();
  let collectionId = searchParams.get("id");
  const navigate = useNavigate();

  useEffect(() => {
    window.document.title = `ZingIt - ${collection?.name}`;
  }, [collection]);

  useEffect(() => {
    if (collectionId) {
      fetchCollection(collectionId);
    }
  }, [collectionId]);

  const handleFetchLikedNfts = () => {
    if (user) fetchLikedNfts(user.id!);
  };

  const handleFetchCreatedNfts = () => {
    if (user) fetchAuthorCreatedNfts(user.id!);
  };

  const handleFetchPurchasedNfts = () => {
    if (user) fetchPurchasedNfts(user.id!);
  };
  const [tabs, setTabs] = useState(["All"]);

  const [selectedTab, setSelectedTab] = React.useState("All");

  const handleTabSelected = (tab: any) => {
    setSelectedTab(tab);
    handleFetchCreatedNfts();
  };

  return (
    <div>
      <GlobalStyles />
      <section className="jumbotron breadcumb no-bg pt-2 pb-0">
        <div className="container">
          <div className="row">
            <div className="col">
              <h2>Collections</h2>
            </div>
            {/* <div className='col-md-1'>
              <button
                className='btn-main'
                style={{ marginTop: 10 }}
                onClick={() => setShowNftModal(true)}
              >
                Add NFTs
              </button>
            </div> */}
          </div>
        </div>
      </section>
      <section
        className="profile_banner no_index coll_banner jumbotron breadcumb no-bg"
        style={{
          backgroundImage: `url(${
            collection?.bannerImg ||
            (collection?.totalNfts &&
              collection.nfts![0].nftMetaData.thumbnail_image) ||
            DEFAULT_BANNER_IMAGE
          })`,
        }}
      >
        <div className="container">
          <div className="single-coll-textWrap">
            <h4 className="sing-coll-title mb-0">{collection?.name}</h4>
            {/* <span>
              <i className="fa fa-heart-o" />
            </span> */}
            <div>
              {user?.id === collection?.owner.id && (
                <button
                  className="btn-transparent"
                  style={{ marginTop: 10, cursor: "pointer" }}
                  onClick={() => {
                    navigate("/edit-collection/" + collection?.id);
                  }}
                >
                  Edit
                </button>
              )}
            </div>
          </div>
          <p className="f-13 text-white max-520">{collection?.description}</p>
        </div>
      </section>
      <section className="container mb-4">
        <div className="my_profile coll_profile">
          <div className="my_profile_avatar">
            <img
              src={
                collection?.owner?.userImage
                  ? getUserSmallImageUrl(collection?.owner?.userImage)
                  : DEFAULT_USER_IMAGE
              }
              alt=""
            />
          </div>
          <div className="coll-list-name">
            <h6>Creator</h6>
            <p>{collection?.owner?.username}</p>
          </div>
          <div className="my_profile_name sing-coll">
            <div className="profile_stats">
              {/* <div className='owner'>
                <span>
                  <i className='fa fa-users'></i>
                </span>
                <span>406 Owners</span>
              </div> */}
              <div className="total">
                <span>
                  <i className="fa fa-table"></i>
                </span>
                <span>{collection?.totalNfts} Nfts</span>
              </div>
              {/* <div className='views'>
                <span>
                  <i className='fa fa-eye'></i>
                </span>
                <span>7.6k views</span>
              </div>
              <div className='faves'>
                <span>
                  <i className='fa fa-heart liked' />
                </span>
                <span>124 favorites</span>
              </div> 
              <div className='tags'>
                <div className='tag'>tag</div>
                <span>14 tags</span>
              </div>
              */}
            </div>
            {/* <button
              id='btn_copy'
              title='Edit profile'
              onClick={() => navigate('/profile/edit')}
            >
              Edit
            </button> */}
          </div>
          <div className="sing-coll-links mt-3">
            {collection?.siteUrl && (
              <div className="me-4 d-flex align-items-center">
                <i className="fa-solid fa-link me-1"></i>
                <a target="_blank" href={collection?.siteUrl}>
                  {collection?.siteUrl}
                </a>
              </div>
            )}
            {collection?.discordUrl && (
              <div className="me-4 d-flex align-items-center">
                <i className="fa-brands fa-discord me-1"></i>
                <a target="_blank" href={collection?.discordUrl}>
                  {collection?.discordUrl}
                </a>
              </div>
            )}
            {collection?.instagramUrl && (
              <div className="me-4 d-flex align-items-center">
                <i className="fa-brands fa-instagram me-1"></i>
                <a target="_blank" href={collection?.instagramUrl}>
                  {collection?.instagramUrl}
                </a>
              </div>
            )}
            {collection?.mediumUrl && (
              <div className="me-4 d-flex align-items-center">
                <i className="fa-brands fa-medium me-1"></i>
                <a target="_blank" href={collection?.mediumUrl}>
                  {collection?.mediumUrl}
                </a>
              </div>
            )}
          </div>
        </div>
      </section>

      <section className="container no-top">
        <div className="row">
          <div className="col-lg-12">
            <div className="items_filter mb-2">
              <ul className="profile_nav">
                {tabs.map((t, i) => {
                  return (
                    <li className={selectedTab === t ? "active" : ""} key={i}>
                      <span onClick={() => handleTabSelected(t)}>{t}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        {selectedTab === "All" && (
          <div id="AllTab" className="onStep fadeIn">
            <NftGrid
              list={collection?.nfts}
              total={purchasedNfts.total}
              loading={purchasedNfts.loading || loading}
              showLoadMore={true}
              fetchMore={() => handleFetchPurchasedNfts()}
            />
          </div>
        )}
        {/* {selectedTab === 'B-rad' && (
          <div id='BradTab' className='onStep fadeIn'>
            <NftGrid
              list={createdNfts.nfts}
              total={createdNfts.total}
              loading={loading}
              showLoadMore={false}
              fetchMore={() => handleFetchCreatedNfts()}
            />
          </div>
        )}
        {selectedTab === 'Animated' && (
          <div id='AnimatedTab' className='onStep fadeIn'>
            <NftGrid
              list={likedNfts.nfts}
              total={likedNfts.total}
              loading={loading}
              showLoadMore={false}
              fetchMore={() => handleFetchLikedNfts()}
            />
          </div>
        )}
        {selectedTab === 'Video' && (
          <div id='VideoTab' className='onStep fadeIn'>
            <NftGrid
              list={likedNfts.nfts}
              total={likedNfts.total}
              loading={loading}
              showLoadMore={false}
              fetchMore={() => handleFetchLikedNfts()}
            />
          </div>
        )} */}
      </section>
      <Footer />
    </div>
  );
};
export default memo(SingleCollection);
