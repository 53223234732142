import moment from "moment";
import { Fragment, memo, useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FadeLoader from "react-spinners/ClipLoader";
import { blockChainData } from "../../api/modules/nft";
import { useActions, useSelector } from "../../hooks";
import { EventSearchParams, EventType } from "../../models/event";
import { User, truncateAddress } from "../../models/user";
import { getNftSmallUrl } from "../../models/nft";

type propType = {
  tabs: string[];
  selectedTab: string;
  user: User;
};
type filterType = {
  choice: string;
  value: EventType[];
  selected: boolean;
};

const UserActivityTab = (props: propType) => {
  const { tabs, selectedTab, user } = props;
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const { fetchUserEvents } = useActions();
  const { userEvents } = useSelector((state) => state.events);
  const [tags, setTags] = useState<filterType[]>([]);
  const [percentageLoaded, setPercentageLoaded] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(40);
  const defaultFilters = [
    {
      choice: "Sale/Transfer",
      value: [EventType.NftTransferred],
      selected: false,
    },
    {
      choice: "Creates",
      value: [EventType.NftTransferred],
      selected: false,
    },
    {
      choice: "Mints",
      value: [EventType.NftMinted, EventType.NftLazyMinted],
      selected: false,
    },
    { choice: "Listings", value: [EventType.NftListed], selected: false },
    {
      choice: "Offers",
      value: [EventType.OfferSubmitted],
      selected: false,
    },
  ];

  useEffect(() => {
    handleFetchUserEvents(1, []);

    const typesInUrl = searchParams.getAll("types").map((x) => JSON.parse(x));
    const eventTypes = typesInUrl.map((x) => x as EventType);
    const newFilterOptions = filterOptions.map((f) => {
      const hasMatchingItem = eventTypes.some((item: EventType) =>
        f.value.includes(item)
      );
      f.selected = hasMatchingItem;
      return f;
    });

    setFilterOptions(newFilterOptions);
  }, []);

  const loadMore = async () => {
    const newPage = page + 1;
    setPage(newPage);
    handleFetchUserEvents(newPage, tags);
  };

  const handleFetchUserEvents = (newPage: number, newTags: filterType[]) => {
    const params: EventSearchParams = {
      page: newPage,
      pageSize: pageSize,
      types: newTags.map((t) => t.value).flat(1),
    };
    if (user) fetchUserEvents(user?.id!, params, Number(newPage) > 1);
  };
  const removeTag = (choice: string) => {
    const newFilterOptions = filterOptions.map((f, i) => {
      if (f.choice === choice) {
        f.selected = false;
      }
      return f;
    });

    setFilterOptions(newFilterOptions);
  };

  const clearTags = () => {
    const newFilterOptions = filterOptions.map((f) => {
      f.selected = false;
      return f;
    });
    setSearchParams({});
    setFilterOptions(newFilterOptions);
  };

  const [filterOptions, setFilterOptions] = useState(defaultFilters);

  const handleCheckboxSelection = (e: any, choice: any) => {
    const newFilterOptions = filterOptions.map((f, i) => {
      if (f.choice === choice) {
        f.selected = !f.selected;
      }
      return f;
    });
    setFilterOptions(newFilterOptions);
  };

  useEffect(() => {
    const newTags = filterOptions.filter((f) => f.selected);
    setTags(newTags);
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newTags.length &&
      newSearchParams.set(
        "types",
        JSON.stringify(newTags.map((t) => t.value).flat(1))
      );
    setSearchParams(newSearchParams);

    setPage(1);
    handleFetchUserEvents(1, newTags);
  }, [JSON.stringify(filterOptions)]);

  const getPercentage = () =>
    userEvents?.events
      ? Math.floor((userEvents?.events.length / userEvents.total) * 100)
      : 0;

  useEffect(() => {
    setPercentageLoaded(getPercentage());
  }, [JSON.stringify(userEvents?.events)]);

  const eventUser = (owner: User) => {
    if (!owner) return "";
    if (owner?.id === user?.id) return "you";
    return owner.username || truncateAddress(owner.walletAddress);
  };

  return (
    <div id="ActivityTab" className="onStep fadeIn">
      <div className="search-section mt-5">
        <div className="filters desktop-accord myProfile">
          <div className="top">
            <div className="filter-icon">
              <i className="fa-solid fa-filter"></i>
            </div>
            <h6>Filter</h6>
            {/* <span className="text-purple f-12">close</span> */}
          </div>

          <div className="myProfile-select-wrapper">
            {filterOptions.map((f, index) => {
              const { choice, selected } = f;
              return (
                <div className="myProfile-select" key={index}>
                  <span>{choice}</span>
                  <input
                    type="checkbox"
                    onChange={(e) => handleCheckboxSelection(e, choice)}
                    checked={selected}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="activity-table filter-content mt-0 pe-0">
          <div className="d-flex align-items-center justify-content-end">
            <div className="mobile-filter mb-3">
              <Dropdown>
                <Dropdown.Toggle className="btn-main" id="dropdown-basic">
                  Filters
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="filters mobile-accord myProfile">
                    <div className="top">
                      <div className="filter-icon">
                        <i className="fa-solid fa-filter"></i>
                      </div>
                      <h6>Filter</h6>
                      <span className="text-purple f-12">close</span>
                    </div>

                    <div className="myProfile-select-wrapper">
                      {filterOptions.map((f, index) => {
                        const { choice, selected } = f;
                        return (
                          <div className="myProfile-select" key={index}>
                            <span>{choice}</span>
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                handleCheckboxSelection(e, choice)
                              }
                              checked={selected}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div className="items_filter mb-2">
            <ul className="profile_nav mt-2">
              {tabs.map((t, i) => {
                return (
                  <li className={selectedTab === t ? "active" : ""} key={i}>
                    <span onClick={() => navigate(`/profile/${t}`)}>{t}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="filter-tags myProfile">
            {filterOptions.map((f, i) => {
              const { choice, selected } = f;
              return (
                <Fragment key={i}>
                  {selected && (
                    <div onClick={() => removeTag(choice)}>
                      <>{choice}</>
                      <span className="ms-2">
                        <i className="fa-solid fa-xmark"></i>
                      </span>
                    </div>
                  )}
                </Fragment>
              );
            })}

            {tags.length !== 0 && (
              <span
                className="f-12 text-purple"
                style={{ lineHeight: "24px" }}
                onClick={clearTags}
              >
                Clear All
              </span>
            )}
          </div>

          {userEvents.loading && page === 1 ? (
            <div className="d-flex justify-content-center align-items-center">
              <FadeLoader
                className="text-center"
                loading={userEvents.loading}
                size={100}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div className="activity-table-wrapper">
              <table className="table table-borderless table-bordered mt-3">
                <thead>
                  <tr>
                    <th className="col-1">Event</th>
                    <th className="col-3">Item</th>
                    <th className="col-1">Price</th>
                    <th className="col-1">Qty</th>
                    <th className="col-1">From</th>
                    <th className="col-1">To</th>
                    <th className="col-2">Time</th>
                  </tr>
                </thead>
                <tbody>
                  {userEvents.events?.map((event: any, i: number) => {
                    const eventPrice = event.price
                      ? event.price.toLocaleString(undefined, {
                          maximumFractionDigits: 6,
                        })
                      : 0;

                    switch (event.type) {
                      case EventType.OfferSubmitted:
                        return (
                          <tr key={i}>
                            <td>{event.type}</td>
                            <td className="item-td-img">
                              <img
                                src={getNftSmallUrl(
                                  event?.nft?.nftMetaData?.thumbnail_image
                                )}
                                alt=""
                              />
                              <Link to={`/item/${event?.nft?.id}`}>
                                {event?.nft?.name}
                              </Link>
                            </td>
                            <td>
                              {eventPrice ? (
                                <>
                                  {" "}
                                  {eventPrice}{" "}
                                  {event?.nft &&
                                    blockChainData[event?.nft?.blockchain]
                                      .symbol}
                                </>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td className="text-center">
                              {event?.nft?.supply}
                            </td>
                            <td>
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.from && eventUser(event.from)}
                              </a>
                            </td>
                            <td>{""}</td>
                            <td>{moment(event.updatedAt).fromNow()}</td>
                          </tr>
                        );
                      case EventType.NftTransferred:
                        return (
                          <tr key={i}>
                            <td>{event.type}</td>
                            <td className="item-td-img">
                              <img
                                src={getNftSmallUrl(
                                  event?.nft?.nftMetaData?.thumbnail_image
                                )}
                                alt=""
                              />
                              <Link to={`/item/${event?.nft?.id}`}>
                                {event?.nft?.name}
                              </Link>
                            </td>
                            <td>
                              {eventPrice ? (
                                <>
                                  {" "}
                                  {eventPrice}{" "}
                                  {event?.nft &&
                                    blockChainData[event?.nft?.blockchain]
                                      .symbol}
                                </>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td className="text-center">
                              {event?.nft?.supply}
                            </td>
                            <td>
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.from && eventUser(event.from)}
                              </a>
                            </td>
                            <td>
                              <a href={`/author/${event.to?.id}`}>
                                {" "}
                                {event.to && eventUser(event.to)}
                              </a>
                            </td>
                            <td>{moment(event.updatedAt).fromNow()}</td>
                          </tr>
                        );
                      case EventType.NftMinted:
                      case EventType.NftLazyMinted:
                        return (
                          <tr key={i}>
                            <td>{event.type}</td>{" "}
                            <td className="item-td-img">
                              <img
                                src={getNftSmallUrl(
                                  event?.nft?.nftMetaData?.thumbnail_image
                                )}
                                alt=""
                              />
                              <Link to={`/item/${event?.nft?.id}`}>
                                {event?.nft?.name}
                              </Link>
                            </td>
                            <td>
                              {eventPrice ? (
                                <>
                                  {" "}
                                  {eventPrice}{" "}
                                  {event?.nft &&
                                    blockChainData[event?.nft?.blockchain]
                                      .symbol}
                                </>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td className="text-center">
                              {event?.nft?.supply}
                            </td>
                            <td>0x000000....</td>
                            <td>
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.from && eventUser(event.from)}
                              </a>
                            </td>
                            <td>{moment(event.updatedAt).fromNow()}</td>
                          </tr>
                        );
                      case EventType.NftCreated:
                        return (
                          <tr key={i}>
                            <td>{event.type}</td>
                            <td className="item-td-img">
                              <img
                                src={getNftSmallUrl(
                                  event?.nft?.nftMetaData?.thumbnail_image
                                )}
                                alt=""
                              />
                              <Link to={`/item/${event?.nft?.id}`}>
                                {event?.nft?.name}
                              </Link>
                            </td>
                            <td>
                              {eventPrice ? (
                                <>
                                  {" "}
                                  {eventPrice}{" "}
                                  {event?.nft &&
                                    blockChainData[event?.nft?.blockchain]
                                      .symbol}
                                </>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td className="text-center">
                              {event?.nft?.supply}
                            </td>
                            <td>0x000000....</td>
                            <td>
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.from && eventUser(event.from)}
                              </a>
                            </td>
                            <td>{moment(event.updatedAt).fromNow()}</td>
                          </tr>
                        );
                      case EventType.NftListed:
                        return (
                          <tr key={i}>
                            <td className="event-td-img">
                              {/* <img src={eventIcon} alt="" /> */}
                              <span>{event.type}</span>
                            </td>
                            <td className="item-td-img">
                              <img
                                src={getNftSmallUrl(
                                  event?.nft?.nftMetaData?.thumbnail_image
                                )}
                                alt=""
                              />
                              <Link to={`/item/${event?.nft?.id}`}>
                                {event?.nft?.name}
                              </Link>
                            </td>
                            <td>
                              {eventPrice ? (
                                <>
                                  {" "}
                                  {eventPrice}{" "}
                                  {event?.nft &&
                                    blockChainData[event?.nft?.blockchain]
                                      .symbol}
                                </>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td className="text-center">
                              {event?.nft?.supply}
                            </td>
                            <td className="activity-from">
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.from && eventUser(event.from)}
                              </a>
                            </td>
                            <td className="activity-to">
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.to && eventUser(event.to)}
                              </a>
                            </td>
                            <td>{moment(event.updatedAt).fromNow()}</td>
                          </tr>
                        );
                      case EventType.NftUpdated:
                        return (
                          <tr key={i}>
                            <td className="event-td-img">
                              {/* <img src={eventIcon} alt="" /> */}
                              <span>{event.type}</span>
                            </td>
                            <td className="item-td-img">
                              <img
                                src={getNftSmallUrl(
                                  event?.nft?.nftMetaData?.thumbnail_image
                                )}
                                alt=""
                              />
                              <Link to={`/item/${event?.nft?.id}`}>
                                {event?.nft?.name}
                              </Link>
                            </td>
                            <td>
                              {eventPrice ? (
                                <>
                                  {" "}
                                  {eventPrice}{" "}
                                  {event?.nft &&
                                    blockChainData[event?.nft?.blockchain]
                                      .symbol}
                                </>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td className="text-center">
                              {event?.nft?.supply}
                            </td>
                            <td className="activity-from">---</td>
                            <td className="activity-to">
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.from && eventUser(event.from)}
                              </a>
                            </td>
                            <td>{moment(event.updatedAt).fromNow()}</td>
                          </tr>
                        );
                      case EventType.CollectionCreated:
                      case EventType.CollectionUpdated:
                        return (
                          <tr key={i}>
                            <td className="event-td-img">
                              {/* <img src={eventIcon} alt="" /> */}
                              <span>{event.type}</span>
                            </td>
                            <td className="item-td-img">
                              <img src={event?.collectionItem.logo} alt="" />
                              <Link
                                to={`/collection?${event?.collectionItem?.id}`}
                              >
                                {event?.collectionItem?.name}
                              </Link>
                            </td>
                            <td>---</td>
                            <td className="text-center">---</td>
                            <td className="activity-from">---</td>
                            <td className="activity-to">
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.from && eventUser(event.from)}
                              </a>
                            </td>
                            <td>{moment(event.updatedAt).fromNow()}</td>
                          </tr>
                        );
                      default:
                        return (
                          <tr key={i}>
                            <td className="event-td-img">
                              {/* <img src={eventIcon} alt="" /> */}
                              <span>{event.type}</span>
                            </td>
                            <td className="item-td-img">
                              <img
                                src={getNftSmallUrl(
                                  event?.nft?.nftMetaData?.thumbnail_image
                                )}
                                alt=""
                              />
                              <Link to={`/item/${event?.nft?.id}`}>
                                {event?.nft?.name}
                              </Link>
                            </td>
                            <td>
                              {eventPrice ? (
                                <>
                                  {" "}
                                  {eventPrice}{" "}
                                  {event?.nft &&
                                    blockChainData[event?.nft?.blockchain]
                                      .symbol}
                                </>
                              ) : (
                                "---"
                              )}
                            </td>
                            <td className="text-center">
                              {event?.nft?.supply}
                            </td>
                            <td className="activity-from">
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.from && eventUser(event.from)}
                              </a>
                            </td>
                            <td className="activity-to">
                              <a href={`/author/${event.from?.id}`}>
                                {" "}
                                {event.to && eventUser(event.to)}
                              </a>
                            </td>
                            <td>{moment(event.updatedAt).fromNow()}</td>
                          </tr>
                        );
                    }
                  })}
                </tbody>
              </table>
            </div>
          )}
          {!userEvents.loading && percentageLoaded < 100 && (
            <div className="col-lg-12">
              <div className="spacer-single"></div>
              <span onClick={loadMore} className="btn-main lead m-auto">
                Load More ({percentageLoaded}%)
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(UserActivityTab);
