import { OverlayTrigger, Tooltip } from "react-bootstrap";

const EthereumInfo = (props: any) => {
  const tooltip = (
    <Tooltip>
      Ethereum Mainnet is the primary public blockchain for real transactions
      and decentralized applications using Ether with actual value.
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={tooltip}
    >
      {props.children}
    </OverlayTrigger>
  );
};

export default EthereumInfo;
