import FadeLoader from "react-spinners/ClipLoader";
import { User, getUserSmallImageUrl } from "../../models/user";
import { DEFAULT_USER_IMAGE } from "./constants";

const CreatorSellerCard = ({ authors, loading }: any) => {
  if (loading)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <FadeLoader
          className="text-center"
          loading={loading}
          aria-label="Loading Spinner"
          size={100}
          data-testid="loader"
        />
      </div>
    );
  return (
    <div className="creator-sellers justify-content-center ms-0">
      {authors.slice(0, 4).map((user: User, i: number) => {
        const { userImage, username, totalNfts, id } = user;
        return (
          <div className="cs" key={i}>
            <div className="cs-img-wrap">
              <a href={`/author/${id}`}>
                <img
                  className="lazy"
                  src={
                    userImage
                      ? getUserSmallImageUrl(userImage)
                      : DEFAULT_USER_IMAGE
                  }
                  alt=""
                />
              </a>
            </div>
            <div className="cs-more-info">
              <p className="cs-title">Creator</p>
              <a href={`/author/${id}`}>
                <p className="cs-name">{username}</p>
              </a>
              <p className="cs-nfts">{totalNfts} NFTs</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CreatorSellerCard;
